import { GeneralModal } from "./GeneralModal";

interface DeleteFinishedModalProps {
  isOpen: boolean;
  onClose: () => void;
  onGoToListings: () => void;
}

export function DeleteFinishedModal({ isOpen, onClose, onGoToListings }: DeleteFinishedModalProps) {
  return (
    <GeneralModal
      isOpen={isOpen}
      onClose={onClose}
      title="削除完了"
      body="商品の削除が完了しました。"
      mainAction={onGoToListings}
      mainActionLabel={`${window.history.state?.idx !== 0 ? "前の画面に戻る" : "出品一覧に戻る"}`}
      showCancelButton={false} // キャンセルボタンを非表示にする
    />
  );
}
