import { ExhibitionStatusIndicator } from "./ExhibitionStatusIndicator";
import { GeneralModal } from "./GeneralModal";

interface ListingFinishedModalProps {
  isOpen: boolean;
  onClose: () => void;
  onGoToSell: () => void;
  onGoToListings: () => void;
  itemId?: string;
  isNew?: boolean;
  itemStatus?: "DRAFT" | "ACTIVE";
}

export function ListingFinishedModal({
  isOpen,
  onClose,
  onGoToSell,
  onGoToListings,
  itemId,
  isNew,
  itemStatus,
}: ListingFinishedModalProps) {
  return (
    <GeneralModal
      isOpen={isOpen}
      onClose={onClose}
      title={itemStatus === "DRAFT" ? "下書き保存完了" : isNew ? "作成完了" : "更新完了"}
      body={
        isOpen && itemId && itemStatus === "ACTIVE" ? (
          <ExhibitionStatusIndicator itemId={itemId} />
        ) : itemStatus === "DRAFT" ? (
          "商品データの下書きを保存しました"
        ) : (
          "商品データの登録が完了しました"
        )
      }
      mainAction={onGoToSell}
      mainActionLabel={`${itemStatus === "DRAFT" ? "続けて商品登録する" : "続けて出品する"}`}
      secondAction={onGoToListings}
      secondActionLabel={`${window.history.state?.idx !== 0 ? "前の画面に戻る" : "出品一覧に戻る"}`}
      showCancelButton={false} // キャンセルボタンを非表示にする
    />
  );
}
