import { Tab, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react";
import type { ReactNode } from "react";
import { Link, type LinkProps, useLocation } from "react-router-dom";

import HeaderMenu from "../components/HeaderMenu";
import { ListedItemsTab } from "../components/ListedItemsTab";
import { SoldItemsTab } from "../components/SoldItemsTab";
import { useShopContext } from "../shop";

type CustomTabProps = {
  to: LinkProps["to"];
  children: ReactNode;
};

const CustomTab: React.FC<CustomTabProps> = ({ to, children }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <Link to={to}>
      <Tab
        _selected={{
          color: isActive ? "yellow.700" : "inherit",
          borderBottom: isActive ? "2px solid" : "none",
          borderBottomColor: "yellow.700",
          boxShadow: "none",
        }}
        _focus={{ boxShadow: "none" }}
      >
        {children}
      </Tab>
    </Link>
  );
};

export function Listings() {
  const { currentShopId } = useShopContext();

  return (
    <div className="App">
      <HeaderMenu />
      <Text mt="4" pb="2" textAlign="left" fontSize="sm" fontWeight="bold" color="gray.700">
        商品一覧
      </Text>
      <Tabs align="start" variant="enclosed">
        <TabList>
          <CustomTab to="/listings/listed">出品中の商品</CustomTab>
          <CustomTab to="/listings/ordered">購入された商品</CustomTab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <ListedItemsTab />
          </TabPanel>
          <TabPanel>
            {currentShopId ? <SoldItemsTab shopId={currentShopId} /> : <Text>ショップが選択されていません</Text>}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
}
