import { Text } from "@chakra-ui/react";
import HeaderMenu from "../components/HeaderMenu";
import { ItemForm } from "../components/ItemForm";

export function Sell() {
  return (
    <div className="App">
      <HeaderMenu />
      <Text mt="4" pb="2" textAlign="left" fontSize="sm" fontWeight="bold" color="gray.700">
        商品の情報
      </Text>
      <ItemForm />
    </div>
  );
}
