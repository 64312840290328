import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  _integer: { input: any; output: any };
  _text: { input: any; output: any };
  _varchar: { input: any; output: any };
  jsonb: { input: any; output: any };
  timestamptz: { input: any; output: any };
  uuid: { input: any; output: any };
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["Boolean"]["input"]>;
  _gt?: InputMaybe<Scalars["Boolean"]["input"]>;
  _gte?: InputMaybe<Scalars["Boolean"]["input"]>;
  _in?: InputMaybe<Array<Scalars["Boolean"]["input"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lte?: InputMaybe<Scalars["Boolean"]["input"]>;
  _neq?: InputMaybe<Scalars["Boolean"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["Boolean"]["input"]>>;
};

export type Category = {
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  path: Scalars["String"]["output"];
};

export type CreateIntegrationInput = {
  apiKey?: InputMaybe<Scalars["String"]["input"]>;
  platform: Scalars["String"]["input"];
  shopId: Scalars["uuid"]["input"];
};

export type CreateIntegrationOutput = {
  integrationId: Scalars["uuid"]["output"];
};

export type CreateUserAndPaymentInput = {
  linkUrl: Scalars["String"]["input"];
  mailAddress: Scalars["String"]["input"];
  ownerName: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  phoneNumber: Scalars["String"]["input"];
  plan: Plan;
  shopName: Scalars["String"]["input"];
  token: Scalars["String"]["input"];
  trialUser: Scalars["Boolean"]["input"];
};

export type CreateUserAndPaymentOutput = {
  userId: Scalars["String"]["output"];
};

export type FetchBaseOrdersInput = {
  limit: Scalars["Int"]["input"];
  offset: Scalars["Int"]["input"];
  shopId: Scalars["String"]["input"];
};

export type FetchBaseOrdersOutput = {
  orders: Array<Order>;
};

export type GetMercariShopsShopIdInput = {
  shopId: Scalars["uuid"]["input"];
};

export type GetMercariShopsShopIdOutput = {
  error?: Maybe<Scalars["String"]["output"]>;
  shopId?: Maybe<Scalars["String"]["output"]>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["Int"]["input"]>;
  _gt?: InputMaybe<Scalars["Int"]["input"]>;
  _gte?: InputMaybe<Scalars["Int"]["input"]>;
  _in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["Int"]["input"]>;
  _lte?: InputMaybe<Scalars["Int"]["input"]>;
  _neq?: InputMaybe<Scalars["Int"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type ListYahooShoppingCategoriesInput = {
  parentCode?: InputMaybe<Scalars["Int"]["input"]>;
  shopId: Scalars["uuid"]["input"];
};

export type ListYahooShoppingCategoriesOutput = {
  yahooShoppingCategories?: Maybe<Array<Maybe<YahooShoppingCategory>>>;
};

export type ListYahooStoreCategoriesInput = {
  pageKey?: InputMaybe<Scalars["String"]["input"]>;
  shopId: Scalars["uuid"]["input"];
};

export type ListYahooStoreCategoriesOutput = {
  yahooStoreCategories?: Maybe<Array<Maybe<YahooStoreCategory>>>;
};

export type Order = {
  detail_url: Scalars["String"]["output"];
  ordered: Scalars["Int"]["output"];
  platform: Scalars["String"]["output"];
  total: Scalars["Int"]["output"];
  unique_key: Scalars["String"]["output"];
};

export type PingInput = {
  text: Scalars["String"]["input"];
};

export type PingOutput = {
  pong: Scalars["String"]["output"];
};

export enum Plan {
  Enterprise = "ENTERPRISE",
  Growth = "GROWTH",
  Starter = "STARTER",
}

export type PlatformCategoriesInput = {
  platform: Scalars["String"]["input"];
  shopId: Scalars["uuid"]["input"];
};

export type PlatformCategoriesOutput = {
  categories: Array<Category>;
};

export type PredictCategoryInput = {
  description: Scalars["String"]["input"];
  shopId: Scalars["uuid"]["input"];
  title: Scalars["String"]["input"];
};

export type PredictCategoryOutput = {
  results: Array<PredictedResult>;
};

export type PredictedCategory = {
  id: Scalars["String"]["output"];
  isLeaf: Scalars["Boolean"]["output"];
  namePath: Scalars["String"]["output"];
  score: Scalars["Int"]["output"];
};

export type PredictedResult = {
  predictedCategories: Array<PredictedCategory>;
  target: Scalars["String"]["output"];
};

export type SearchYahooShoppingCategoriesInput = {
  query: Scalars["String"]["input"];
  shopId: Scalars["uuid"]["input"];
};

export type SearchYahooShoppingCategoriesOutput = {
  yahooShoppingCategories?: Maybe<Array<Maybe<SearchedYahooShoppingCategory>>>;
};

export type SearchedYahooShoppingCategory = {
  code: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  namePath: Scalars["String"]["output"];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["String"]["input"]>;
  _gt?: InputMaybe<Scalars["String"]["input"]>;
  _gte?: InputMaybe<Scalars["String"]["input"]>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars["String"]["input"]>;
  _in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars["String"]["input"]>;
  _is_null?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars["String"]["input"]>;
  _lt?: InputMaybe<Scalars["String"]["input"]>;
  _lte?: InputMaybe<Scalars["String"]["input"]>;
  _neq?: InputMaybe<Scalars["String"]["input"]>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars["String"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars["String"]["input"]>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars["String"]["input"]>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars["String"]["input"]>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars["String"]["input"]>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars["String"]["input"]>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars["String"]["input"]>;
};

export type YahooShoppingCategory = {
  code: Scalars["Int"]["output"];
  hasChild: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
};

export type YahooStoreCategory = {
  isHidden: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  pageKey: Scalars["String"]["output"];
};

/** Boolean expression to compare columns of type "_integer". All fields are combined with logical 'AND'. */
export type _Integer_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Scalars["_integer"]["input"]>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Scalars["_integer"]["input"]>;
  _eq?: InputMaybe<Scalars["_integer"]["input"]>;
  _gt?: InputMaybe<Scalars["_integer"]["input"]>;
  _gte?: InputMaybe<Scalars["_integer"]["input"]>;
  _in?: InputMaybe<Array<Scalars["_integer"]["input"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["_integer"]["input"]>;
  _lte?: InputMaybe<Scalars["_integer"]["input"]>;
  _neq?: InputMaybe<Scalars["_integer"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["_integer"]["input"]>>;
};

/** Boolean expression to compare columns of type "_text". All fields are combined with logical 'AND'. */
export type _Text_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Scalars["_text"]["input"]>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Scalars["_text"]["input"]>;
  _eq?: InputMaybe<Scalars["_text"]["input"]>;
  _gt?: InputMaybe<Scalars["_text"]["input"]>;
  _gte?: InputMaybe<Scalars["_text"]["input"]>;
  _in?: InputMaybe<Array<Scalars["_text"]["input"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["_text"]["input"]>;
  _lte?: InputMaybe<Scalars["_text"]["input"]>;
  _neq?: InputMaybe<Scalars["_text"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["_text"]["input"]>>;
};

/** Boolean expression to compare columns of type "_varchar". All fields are combined with logical 'AND'. */
export type _Varchar_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Scalars["_varchar"]["input"]>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Scalars["_varchar"]["input"]>;
  _eq?: InputMaybe<Scalars["_varchar"]["input"]>;
  _gt?: InputMaybe<Scalars["_varchar"]["input"]>;
  _gte?: InputMaybe<Scalars["_varchar"]["input"]>;
  _in?: InputMaybe<Array<Scalars["_varchar"]["input"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["_varchar"]["input"]>;
  _lte?: InputMaybe<Scalars["_varchar"]["input"]>;
  _neq?: InputMaybe<Scalars["_varchar"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["_varchar"]["input"]>>;
};

/** ラクダスのブランドとメルカリShopsのブランドのマッピング */
export type Brand_Mercari_Shops_Mappings = {
  brand_id: Scalars["uuid"]["output"];
  platform_mercari_shops_brand_id: Scalars["String"]["output"];
};

/** aggregated selection of "brand_mercari_shops_mappings" */
export type Brand_Mercari_Shops_Mappings_Aggregate = {
  aggregate?: Maybe<Brand_Mercari_Shops_Mappings_Aggregate_Fields>;
  nodes: Array<Brand_Mercari_Shops_Mappings>;
};

/** aggregate fields of "brand_mercari_shops_mappings" */
export type Brand_Mercari_Shops_Mappings_Aggregate_Fields = {
  count: Scalars["Int"]["output"];
  max?: Maybe<Brand_Mercari_Shops_Mappings_Max_Fields>;
  min?: Maybe<Brand_Mercari_Shops_Mappings_Min_Fields>;
};

/** aggregate fields of "brand_mercari_shops_mappings" */
export type Brand_Mercari_Shops_Mappings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Brand_Mercari_Shops_Mappings_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Boolean expression to filter rows from the table "brand_mercari_shops_mappings". All fields are combined with a logical 'AND'. */
export type Brand_Mercari_Shops_Mappings_Bool_Exp = {
  _and?: InputMaybe<Array<Brand_Mercari_Shops_Mappings_Bool_Exp>>;
  _not?: InputMaybe<Brand_Mercari_Shops_Mappings_Bool_Exp>;
  _or?: InputMaybe<Array<Brand_Mercari_Shops_Mappings_Bool_Exp>>;
  brand_id?: InputMaybe<Uuid_Comparison_Exp>;
  platform_mercari_shops_brand_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "brand_mercari_shops_mappings" */
export enum Brand_Mercari_Shops_Mappings_Constraint {
  /** unique or primary key constraint on columns "brand_id" */
  BrandMercariShopsMappingsPkey = "brand_mercari_shops_mappings_pkey",
  /** unique or primary key constraint on columns "platform_mercari_shops_brand_id" */
  BrandMercariShopsMappingsPlatformMercariShopsBrandIKey = "brand_mercari_shops_mappings_platform_mercari_shops_brand_i_key",
}

/** input type for inserting data into table "brand_mercari_shops_mappings" */
export type Brand_Mercari_Shops_Mappings_Insert_Input = {
  brand_id?: InputMaybe<Scalars["uuid"]["input"]>;
  platform_mercari_shops_brand_id?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type Brand_Mercari_Shops_Mappings_Max_Fields = {
  brand_id?: Maybe<Scalars["uuid"]["output"]>;
  platform_mercari_shops_brand_id?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type Brand_Mercari_Shops_Mappings_Min_Fields = {
  brand_id?: Maybe<Scalars["uuid"]["output"]>;
  platform_mercari_shops_brand_id?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "brand_mercari_shops_mappings" */
export type Brand_Mercari_Shops_Mappings_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Brand_Mercari_Shops_Mappings>;
};

/** input type for inserting object relation for remote table "brand_mercari_shops_mappings" */
export type Brand_Mercari_Shops_Mappings_Obj_Rel_Insert_Input = {
  data: Brand_Mercari_Shops_Mappings_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Brand_Mercari_Shops_Mappings_On_Conflict>;
};

/** on_conflict condition type for table "brand_mercari_shops_mappings" */
export type Brand_Mercari_Shops_Mappings_On_Conflict = {
  constraint: Brand_Mercari_Shops_Mappings_Constraint;
  update_columns?: Array<Brand_Mercari_Shops_Mappings_Update_Column>;
  where?: InputMaybe<Brand_Mercari_Shops_Mappings_Bool_Exp>;
};

/** Ordering options when selecting data from "brand_mercari_shops_mappings". */
export type Brand_Mercari_Shops_Mappings_Order_By = {
  brand_id?: InputMaybe<Order_By>;
  platform_mercari_shops_brand_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: brand_mercari_shops_mappings */
export type Brand_Mercari_Shops_Mappings_Pk_Columns_Input = {
  brand_id: Scalars["uuid"]["input"];
};

/** select columns of table "brand_mercari_shops_mappings" */
export enum Brand_Mercari_Shops_Mappings_Select_Column {
  /** column name */
  BrandId = "brand_id",
  /** column name */
  PlatformMercariShopsBrandId = "platform_mercari_shops_brand_id",
}

/** input type for updating data in table "brand_mercari_shops_mappings" */
export type Brand_Mercari_Shops_Mappings_Set_Input = {
  brand_id?: InputMaybe<Scalars["uuid"]["input"]>;
  platform_mercari_shops_brand_id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Streaming cursor of the table "brand_mercari_shops_mappings" */
export type Brand_Mercari_Shops_Mappings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Brand_Mercari_Shops_Mappings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Brand_Mercari_Shops_Mappings_Stream_Cursor_Value_Input = {
  brand_id?: InputMaybe<Scalars["uuid"]["input"]>;
  platform_mercari_shops_brand_id?: InputMaybe<Scalars["String"]["input"]>;
};

/** update columns of table "brand_mercari_shops_mappings" */
export enum Brand_Mercari_Shops_Mappings_Update_Column {
  /** column name */
  BrandId = "brand_id",
  /** column name */
  PlatformMercariShopsBrandId = "platform_mercari_shops_brand_id",
}

export type Brand_Mercari_Shops_Mappings_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Brand_Mercari_Shops_Mappings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Brand_Mercari_Shops_Mappings_Bool_Exp;
};

/** ブランド名が入るテーブル */
export type Brands = {
  created_at: Scalars["timestamptz"]["output"];
  id: Scalars["uuid"]["output"];
  /** An object relationship */
  mercari_shops_mapping?: Maybe<Brand_Mercari_Shops_Mappings>;
  name: Scalars["String"]["output"];
  updated_at: Scalars["timestamptz"]["output"];
};

/** aggregated selection of "brands" */
export type Brands_Aggregate = {
  aggregate?: Maybe<Brands_Aggregate_Fields>;
  nodes: Array<Brands>;
};

/** aggregate fields of "brands" */
export type Brands_Aggregate_Fields = {
  count: Scalars["Int"]["output"];
  max?: Maybe<Brands_Max_Fields>;
  min?: Maybe<Brands_Min_Fields>;
};

/** aggregate fields of "brands" */
export type Brands_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Brands_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Boolean expression to filter rows from the table "brands". All fields are combined with a logical 'AND'. */
export type Brands_Bool_Exp = {
  _and?: InputMaybe<Array<Brands_Bool_Exp>>;
  _not?: InputMaybe<Brands_Bool_Exp>;
  _or?: InputMaybe<Array<Brands_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  mercari_shops_mapping?: InputMaybe<Brand_Mercari_Shops_Mappings_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "brands" */
export enum Brands_Constraint {
  /** unique or primary key constraint on columns "name" */
  BrandsNameKey = "brands_name_key",
  /** unique or primary key constraint on columns "id" */
  BrandsPkey = "brands_pkey",
}

/** input type for inserting data into table "brands" */
export type Brands_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  mercari_shops_mapping?: InputMaybe<Brand_Mercari_Shops_Mappings_Obj_Rel_Insert_Input>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate max on columns */
export type Brands_Max_Fields = {
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** aggregate min on columns */
export type Brands_Min_Fields = {
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** response of any mutation on the table "brands" */
export type Brands_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Brands>;
};

/** input type for inserting object relation for remote table "brands" */
export type Brands_Obj_Rel_Insert_Input = {
  data: Brands_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Brands_On_Conflict>;
};

/** on_conflict condition type for table "brands" */
export type Brands_On_Conflict = {
  constraint: Brands_Constraint;
  update_columns?: Array<Brands_Update_Column>;
  where?: InputMaybe<Brands_Bool_Exp>;
};

/** Ordering options when selecting data from "brands". */
export type Brands_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mercari_shops_mapping?: InputMaybe<Brand_Mercari_Shops_Mappings_Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: brands */
export type Brands_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "brands" */
export enum Brands_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "brands" */
export type Brands_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** Streaming cursor of the table "brands" */
export type Brands_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Brands_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Brands_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** update columns of table "brands" */
export enum Brands_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Brands_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Brands_Set_Input>;
  /** filter the rows which have to be updated */
  where: Brands_Bool_Exp;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = "ASC",
  /** descending ordering of the cursor */
  Desc = "DESC",
}

/** 出品に対するBASEのカテゴリ指定 */
export type Exhibition_Base_Categories = {
  created_at: Scalars["timestamptz"]["output"];
  /** An object relationship */
  exhibition: Exhibitions;
  exhibition_id: Scalars["uuid"]["output"];
  id: Scalars["uuid"]["output"];
  platform_base_category_id: Scalars["Int"]["output"];
  updated_at: Scalars["timestamptz"]["output"];
};

/** aggregated selection of "exhibition_base_categories" */
export type Exhibition_Base_Categories_Aggregate = {
  aggregate?: Maybe<Exhibition_Base_Categories_Aggregate_Fields>;
  nodes: Array<Exhibition_Base_Categories>;
};

export type Exhibition_Base_Categories_Aggregate_Bool_Exp = {
  count?: InputMaybe<Exhibition_Base_Categories_Aggregate_Bool_Exp_Count>;
};

export type Exhibition_Base_Categories_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Exhibition_Base_Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Exhibition_Base_Categories_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "exhibition_base_categories" */
export type Exhibition_Base_Categories_Aggregate_Fields = {
  avg?: Maybe<Exhibition_Base_Categories_Avg_Fields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<Exhibition_Base_Categories_Max_Fields>;
  min?: Maybe<Exhibition_Base_Categories_Min_Fields>;
  stddev?: Maybe<Exhibition_Base_Categories_Stddev_Fields>;
  stddev_pop?: Maybe<Exhibition_Base_Categories_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Exhibition_Base_Categories_Stddev_Samp_Fields>;
  sum?: Maybe<Exhibition_Base_Categories_Sum_Fields>;
  var_pop?: Maybe<Exhibition_Base_Categories_Var_Pop_Fields>;
  var_samp?: Maybe<Exhibition_Base_Categories_Var_Samp_Fields>;
  variance?: Maybe<Exhibition_Base_Categories_Variance_Fields>;
};

/** aggregate fields of "exhibition_base_categories" */
export type Exhibition_Base_Categories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Exhibition_Base_Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "exhibition_base_categories" */
export type Exhibition_Base_Categories_Aggregate_Order_By = {
  avg?: InputMaybe<Exhibition_Base_Categories_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Exhibition_Base_Categories_Max_Order_By>;
  min?: InputMaybe<Exhibition_Base_Categories_Min_Order_By>;
  stddev?: InputMaybe<Exhibition_Base_Categories_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Exhibition_Base_Categories_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Exhibition_Base_Categories_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Exhibition_Base_Categories_Sum_Order_By>;
  var_pop?: InputMaybe<Exhibition_Base_Categories_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Exhibition_Base_Categories_Var_Samp_Order_By>;
  variance?: InputMaybe<Exhibition_Base_Categories_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "exhibition_base_categories" */
export type Exhibition_Base_Categories_Arr_Rel_Insert_Input = {
  data: Array<Exhibition_Base_Categories_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Exhibition_Base_Categories_On_Conflict>;
};

/** aggregate avg on columns */
export type Exhibition_Base_Categories_Avg_Fields = {
  platform_base_category_id?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "exhibition_base_categories" */
export type Exhibition_Base_Categories_Avg_Order_By = {
  platform_base_category_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "exhibition_base_categories". All fields are combined with a logical 'AND'. */
export type Exhibition_Base_Categories_Bool_Exp = {
  _and?: InputMaybe<Array<Exhibition_Base_Categories_Bool_Exp>>;
  _not?: InputMaybe<Exhibition_Base_Categories_Bool_Exp>;
  _or?: InputMaybe<Array<Exhibition_Base_Categories_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  exhibition?: InputMaybe<Exhibitions_Bool_Exp>;
  exhibition_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  platform_base_category_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "exhibition_base_categories" */
export enum Exhibition_Base_Categories_Constraint {
  /** unique or primary key constraint on columns "platform_base_category_id", "exhibition_id" */
  ExhibitionBaseCategoriesExhibitionIdPlatformBaseCategory = "exhibition_base_categories_exhibition_id_platform_base_category",
  /** unique or primary key constraint on columns "id" */
  ExhibitionBaseCategoriesPkey = "exhibition_base_categories_pkey",
}

/** input type for incrementing numeric columns in table "exhibition_base_categories" */
export type Exhibition_Base_Categories_Inc_Input = {
  platform_base_category_id?: InputMaybe<Scalars["Int"]["input"]>;
};

/** input type for inserting data into table "exhibition_base_categories" */
export type Exhibition_Base_Categories_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  exhibition?: InputMaybe<Exhibitions_Obj_Rel_Insert_Input>;
  exhibition_id?: InputMaybe<Scalars["uuid"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  platform_base_category_id?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate max on columns */
export type Exhibition_Base_Categories_Max_Fields = {
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  exhibition_id?: Maybe<Scalars["uuid"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  platform_base_category_id?: Maybe<Scalars["Int"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by max() on columns of table "exhibition_base_categories" */
export type Exhibition_Base_Categories_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  exhibition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  platform_base_category_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Exhibition_Base_Categories_Min_Fields = {
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  exhibition_id?: Maybe<Scalars["uuid"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  platform_base_category_id?: Maybe<Scalars["Int"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by min() on columns of table "exhibition_base_categories" */
export type Exhibition_Base_Categories_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  exhibition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  platform_base_category_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "exhibition_base_categories" */
export type Exhibition_Base_Categories_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Exhibition_Base_Categories>;
};

/** on_conflict condition type for table "exhibition_base_categories" */
export type Exhibition_Base_Categories_On_Conflict = {
  constraint: Exhibition_Base_Categories_Constraint;
  update_columns?: Array<Exhibition_Base_Categories_Update_Column>;
  where?: InputMaybe<Exhibition_Base_Categories_Bool_Exp>;
};

/** Ordering options when selecting data from "exhibition_base_categories". */
export type Exhibition_Base_Categories_Order_By = {
  created_at?: InputMaybe<Order_By>;
  exhibition?: InputMaybe<Exhibitions_Order_By>;
  exhibition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  platform_base_category_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: exhibition_base_categories */
export type Exhibition_Base_Categories_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "exhibition_base_categories" */
export enum Exhibition_Base_Categories_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExhibitionId = "exhibition_id",
  /** column name */
  Id = "id",
  /** column name */
  PlatformBaseCategoryId = "platform_base_category_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "exhibition_base_categories" */
export type Exhibition_Base_Categories_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  exhibition_id?: InputMaybe<Scalars["uuid"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  platform_base_category_id?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate stddev on columns */
export type Exhibition_Base_Categories_Stddev_Fields = {
  platform_base_category_id?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "exhibition_base_categories" */
export type Exhibition_Base_Categories_Stddev_Order_By = {
  platform_base_category_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Exhibition_Base_Categories_Stddev_Pop_Fields = {
  platform_base_category_id?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev_pop() on columns of table "exhibition_base_categories" */
export type Exhibition_Base_Categories_Stddev_Pop_Order_By = {
  platform_base_category_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Exhibition_Base_Categories_Stddev_Samp_Fields = {
  platform_base_category_id?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev_samp() on columns of table "exhibition_base_categories" */
export type Exhibition_Base_Categories_Stddev_Samp_Order_By = {
  platform_base_category_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "exhibition_base_categories" */
export type Exhibition_Base_Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Exhibition_Base_Categories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Exhibition_Base_Categories_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  exhibition_id?: InputMaybe<Scalars["uuid"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  platform_base_category_id?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate sum on columns */
export type Exhibition_Base_Categories_Sum_Fields = {
  platform_base_category_id?: Maybe<Scalars["Int"]["output"]>;
};

/** order by sum() on columns of table "exhibition_base_categories" */
export type Exhibition_Base_Categories_Sum_Order_By = {
  platform_base_category_id?: InputMaybe<Order_By>;
};

/** update columns of table "exhibition_base_categories" */
export enum Exhibition_Base_Categories_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExhibitionId = "exhibition_id",
  /** column name */
  Id = "id",
  /** column name */
  PlatformBaseCategoryId = "platform_base_category_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Exhibition_Base_Categories_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Exhibition_Base_Categories_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Exhibition_Base_Categories_Set_Input>;
  /** filter the rows which have to be updated */
  where: Exhibition_Base_Categories_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Exhibition_Base_Categories_Var_Pop_Fields = {
  platform_base_category_id?: Maybe<Scalars["Float"]["output"]>;
};

/** order by var_pop() on columns of table "exhibition_base_categories" */
export type Exhibition_Base_Categories_Var_Pop_Order_By = {
  platform_base_category_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Exhibition_Base_Categories_Var_Samp_Fields = {
  platform_base_category_id?: Maybe<Scalars["Float"]["output"]>;
};

/** order by var_samp() on columns of table "exhibition_base_categories" */
export type Exhibition_Base_Categories_Var_Samp_Order_By = {
  platform_base_category_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Exhibition_Base_Categories_Variance_Fields = {
  platform_base_category_id?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "exhibition_base_categories" */
export type Exhibition_Base_Categories_Variance_Order_By = {
  platform_base_category_id?: InputMaybe<Order_By>;
};

/** メルカリShops用の出品設定 */
export type Exhibition_Mercari_Shops_Settings = {
  brand_id?: Maybe<Scalars["String"]["output"]>;
  category_id: Scalars["String"]["output"];
  condition: Scalars["String"]["output"];
  created_at: Scalars["timestamptz"]["output"];
  /** An object relationship */
  exhibition: Exhibitions;
  exhibition_id: Scalars["uuid"]["output"];
  id: Scalars["uuid"]["output"];
  shipping_duration: Scalars["String"]["output"];
  shipping_from_state_id: Scalars["String"]["output"];
  shipping_method: Scalars["String"]["output"];
  shipping_payer: Scalars["String"]["output"];
  updated_at: Scalars["timestamptz"]["output"];
};

/** aggregated selection of "exhibition_mercari_shops_settings" */
export type Exhibition_Mercari_Shops_Settings_Aggregate = {
  aggregate?: Maybe<Exhibition_Mercari_Shops_Settings_Aggregate_Fields>;
  nodes: Array<Exhibition_Mercari_Shops_Settings>;
};

/** aggregate fields of "exhibition_mercari_shops_settings" */
export type Exhibition_Mercari_Shops_Settings_Aggregate_Fields = {
  count: Scalars["Int"]["output"];
  max?: Maybe<Exhibition_Mercari_Shops_Settings_Max_Fields>;
  min?: Maybe<Exhibition_Mercari_Shops_Settings_Min_Fields>;
};

/** aggregate fields of "exhibition_mercari_shops_settings" */
export type Exhibition_Mercari_Shops_Settings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Exhibition_Mercari_Shops_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Boolean expression to filter rows from the table "exhibition_mercari_shops_settings". All fields are combined with a logical 'AND'. */
export type Exhibition_Mercari_Shops_Settings_Bool_Exp = {
  _and?: InputMaybe<Array<Exhibition_Mercari_Shops_Settings_Bool_Exp>>;
  _not?: InputMaybe<Exhibition_Mercari_Shops_Settings_Bool_Exp>;
  _or?: InputMaybe<Array<Exhibition_Mercari_Shops_Settings_Bool_Exp>>;
  brand_id?: InputMaybe<String_Comparison_Exp>;
  category_id?: InputMaybe<String_Comparison_Exp>;
  condition?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  exhibition?: InputMaybe<Exhibitions_Bool_Exp>;
  exhibition_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  shipping_duration?: InputMaybe<String_Comparison_Exp>;
  shipping_from_state_id?: InputMaybe<String_Comparison_Exp>;
  shipping_method?: InputMaybe<String_Comparison_Exp>;
  shipping_payer?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "exhibition_mercari_shops_settings" */
export enum Exhibition_Mercari_Shops_Settings_Constraint {
  /** unique or primary key constraint on columns "exhibition_id" */
  ExhibitionMercariShopsSettingsExhibitionIdKey = "exhibition_mercari_shops_settings_exhibition_id_key",
  /** unique or primary key constraint on columns "id" */
  ExhibitionMercariShopsSettingsPkey = "exhibition_mercari_shops_settings_pkey",
}

/** input type for inserting data into table "exhibition_mercari_shops_settings" */
export type Exhibition_Mercari_Shops_Settings_Insert_Input = {
  brand_id?: InputMaybe<Scalars["String"]["input"]>;
  category_id?: InputMaybe<Scalars["String"]["input"]>;
  condition?: InputMaybe<Scalars["String"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  exhibition?: InputMaybe<Exhibitions_Obj_Rel_Insert_Input>;
  exhibition_id?: InputMaybe<Scalars["uuid"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  shipping_duration?: InputMaybe<Scalars["String"]["input"]>;
  shipping_from_state_id?: InputMaybe<Scalars["String"]["input"]>;
  shipping_method?: InputMaybe<Scalars["String"]["input"]>;
  shipping_payer?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate max on columns */
export type Exhibition_Mercari_Shops_Settings_Max_Fields = {
  brand_id?: Maybe<Scalars["String"]["output"]>;
  category_id?: Maybe<Scalars["String"]["output"]>;
  condition?: Maybe<Scalars["String"]["output"]>;
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  exhibition_id?: Maybe<Scalars["uuid"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  shipping_duration?: Maybe<Scalars["String"]["output"]>;
  shipping_from_state_id?: Maybe<Scalars["String"]["output"]>;
  shipping_method?: Maybe<Scalars["String"]["output"]>;
  shipping_payer?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** aggregate min on columns */
export type Exhibition_Mercari_Shops_Settings_Min_Fields = {
  brand_id?: Maybe<Scalars["String"]["output"]>;
  category_id?: Maybe<Scalars["String"]["output"]>;
  condition?: Maybe<Scalars["String"]["output"]>;
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  exhibition_id?: Maybe<Scalars["uuid"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  shipping_duration?: Maybe<Scalars["String"]["output"]>;
  shipping_from_state_id?: Maybe<Scalars["String"]["output"]>;
  shipping_method?: Maybe<Scalars["String"]["output"]>;
  shipping_payer?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** response of any mutation on the table "exhibition_mercari_shops_settings" */
export type Exhibition_Mercari_Shops_Settings_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Exhibition_Mercari_Shops_Settings>;
};

/** input type for inserting object relation for remote table "exhibition_mercari_shops_settings" */
export type Exhibition_Mercari_Shops_Settings_Obj_Rel_Insert_Input = {
  data: Exhibition_Mercari_Shops_Settings_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Exhibition_Mercari_Shops_Settings_On_Conflict>;
};

/** on_conflict condition type for table "exhibition_mercari_shops_settings" */
export type Exhibition_Mercari_Shops_Settings_On_Conflict = {
  constraint: Exhibition_Mercari_Shops_Settings_Constraint;
  update_columns?: Array<Exhibition_Mercari_Shops_Settings_Update_Column>;
  where?: InputMaybe<Exhibition_Mercari_Shops_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "exhibition_mercari_shops_settings". */
export type Exhibition_Mercari_Shops_Settings_Order_By = {
  brand_id?: InputMaybe<Order_By>;
  category_id?: InputMaybe<Order_By>;
  condition?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  exhibition?: InputMaybe<Exhibitions_Order_By>;
  exhibition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  shipping_duration?: InputMaybe<Order_By>;
  shipping_from_state_id?: InputMaybe<Order_By>;
  shipping_method?: InputMaybe<Order_By>;
  shipping_payer?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: exhibition_mercari_shops_settings */
export type Exhibition_Mercari_Shops_Settings_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "exhibition_mercari_shops_settings" */
export enum Exhibition_Mercari_Shops_Settings_Select_Column {
  /** column name */
  BrandId = "brand_id",
  /** column name */
  CategoryId = "category_id",
  /** column name */
  Condition = "condition",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExhibitionId = "exhibition_id",
  /** column name */
  Id = "id",
  /** column name */
  ShippingDuration = "shipping_duration",
  /** column name */
  ShippingFromStateId = "shipping_from_state_id",
  /** column name */
  ShippingMethod = "shipping_method",
  /** column name */
  ShippingPayer = "shipping_payer",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "exhibition_mercari_shops_settings" */
export type Exhibition_Mercari_Shops_Settings_Set_Input = {
  brand_id?: InputMaybe<Scalars["String"]["input"]>;
  category_id?: InputMaybe<Scalars["String"]["input"]>;
  condition?: InputMaybe<Scalars["String"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  exhibition_id?: InputMaybe<Scalars["uuid"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  shipping_duration?: InputMaybe<Scalars["String"]["input"]>;
  shipping_from_state_id?: InputMaybe<Scalars["String"]["input"]>;
  shipping_method?: InputMaybe<Scalars["String"]["input"]>;
  shipping_payer?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** Streaming cursor of the table "exhibition_mercari_shops_settings" */
export type Exhibition_Mercari_Shops_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Exhibition_Mercari_Shops_Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Exhibition_Mercari_Shops_Settings_Stream_Cursor_Value_Input = {
  brand_id?: InputMaybe<Scalars["String"]["input"]>;
  category_id?: InputMaybe<Scalars["String"]["input"]>;
  condition?: InputMaybe<Scalars["String"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  exhibition_id?: InputMaybe<Scalars["uuid"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  shipping_duration?: InputMaybe<Scalars["String"]["input"]>;
  shipping_from_state_id?: InputMaybe<Scalars["String"]["input"]>;
  shipping_method?: InputMaybe<Scalars["String"]["input"]>;
  shipping_payer?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** update columns of table "exhibition_mercari_shops_settings" */
export enum Exhibition_Mercari_Shops_Settings_Update_Column {
  /** column name */
  BrandId = "brand_id",
  /** column name */
  CategoryId = "category_id",
  /** column name */
  Condition = "condition",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExhibitionId = "exhibition_id",
  /** column name */
  Id = "id",
  /** column name */
  ShippingDuration = "shipping_duration",
  /** column name */
  ShippingFromStateId = "shipping_from_state_id",
  /** column name */
  ShippingMethod = "shipping_method",
  /** column name */
  ShippingPayer = "shipping_payer",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Exhibition_Mercari_Shops_Settings_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Exhibition_Mercari_Shops_Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Exhibition_Mercari_Shops_Settings_Bool_Exp;
};

/** columns and relationships of "exhibition_shopify_settings" */
export type Exhibition_Shopify_Settings = {
  category: Scalars["String"]["output"];
  created_at: Scalars["timestamptz"]["output"];
  /** An object relationship */
  exhibition: Exhibitions;
  exhibition_id: Scalars["uuid"]["output"];
  id: Scalars["uuid"]["output"];
  product_type: Scalars["String"]["output"];
  tags: Scalars["_text"]["output"];
  updated_at: Scalars["timestamptz"]["output"];
  vendor: Scalars["String"]["output"];
};

/** aggregated selection of "exhibition_shopify_settings" */
export type Exhibition_Shopify_Settings_Aggregate = {
  aggregate?: Maybe<Exhibition_Shopify_Settings_Aggregate_Fields>;
  nodes: Array<Exhibition_Shopify_Settings>;
};

/** aggregate fields of "exhibition_shopify_settings" */
export type Exhibition_Shopify_Settings_Aggregate_Fields = {
  count: Scalars["Int"]["output"];
  max?: Maybe<Exhibition_Shopify_Settings_Max_Fields>;
  min?: Maybe<Exhibition_Shopify_Settings_Min_Fields>;
};

/** aggregate fields of "exhibition_shopify_settings" */
export type Exhibition_Shopify_Settings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Exhibition_Shopify_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Boolean expression to filter rows from the table "exhibition_shopify_settings". All fields are combined with a logical 'AND'. */
export type Exhibition_Shopify_Settings_Bool_Exp = {
  _and?: InputMaybe<Array<Exhibition_Shopify_Settings_Bool_Exp>>;
  _not?: InputMaybe<Exhibition_Shopify_Settings_Bool_Exp>;
  _or?: InputMaybe<Array<Exhibition_Shopify_Settings_Bool_Exp>>;
  category?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  exhibition?: InputMaybe<Exhibitions_Bool_Exp>;
  exhibition_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  product_type?: InputMaybe<String_Comparison_Exp>;
  tags?: InputMaybe<_Text_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendor?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "exhibition_shopify_settings" */
export enum Exhibition_Shopify_Settings_Constraint {
  /** unique or primary key constraint on columns "exhibition_id" */
  ExhibitionShopifySettingsExhibitionIdKey = "exhibition_shopify_settings_exhibition_id_key",
  /** unique or primary key constraint on columns "id" */
  ExhibitionShopifySettingsPkey = "exhibition_shopify_settings_pkey",
}

/** input type for inserting data into table "exhibition_shopify_settings" */
export type Exhibition_Shopify_Settings_Insert_Input = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  exhibition?: InputMaybe<Exhibitions_Obj_Rel_Insert_Input>;
  exhibition_id?: InputMaybe<Scalars["uuid"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  product_type?: InputMaybe<Scalars["String"]["input"]>;
  tags?: InputMaybe<Scalars["_text"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  vendor?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type Exhibition_Shopify_Settings_Max_Fields = {
  category?: Maybe<Scalars["String"]["output"]>;
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  exhibition_id?: Maybe<Scalars["uuid"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  product_type?: Maybe<Scalars["String"]["output"]>;
  tags?: Maybe<Scalars["_text"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
  vendor?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type Exhibition_Shopify_Settings_Min_Fields = {
  category?: Maybe<Scalars["String"]["output"]>;
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  exhibition_id?: Maybe<Scalars["uuid"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  product_type?: Maybe<Scalars["String"]["output"]>;
  tags?: Maybe<Scalars["_text"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
  vendor?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "exhibition_shopify_settings" */
export type Exhibition_Shopify_Settings_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Exhibition_Shopify_Settings>;
};

/** input type for inserting object relation for remote table "exhibition_shopify_settings" */
export type Exhibition_Shopify_Settings_Obj_Rel_Insert_Input = {
  data: Exhibition_Shopify_Settings_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Exhibition_Shopify_Settings_On_Conflict>;
};

/** on_conflict condition type for table "exhibition_shopify_settings" */
export type Exhibition_Shopify_Settings_On_Conflict = {
  constraint: Exhibition_Shopify_Settings_Constraint;
  update_columns?: Array<Exhibition_Shopify_Settings_Update_Column>;
  where?: InputMaybe<Exhibition_Shopify_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "exhibition_shopify_settings". */
export type Exhibition_Shopify_Settings_Order_By = {
  category?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  exhibition?: InputMaybe<Exhibitions_Order_By>;
  exhibition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_type?: InputMaybe<Order_By>;
  tags?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor?: InputMaybe<Order_By>;
};

/** primary key columns input for table: exhibition_shopify_settings */
export type Exhibition_Shopify_Settings_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "exhibition_shopify_settings" */
export enum Exhibition_Shopify_Settings_Select_Column {
  /** column name */
  Category = "category",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExhibitionId = "exhibition_id",
  /** column name */
  Id = "id",
  /** column name */
  ProductType = "product_type",
  /** column name */
  Tags = "tags",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Vendor = "vendor",
}

/** input type for updating data in table "exhibition_shopify_settings" */
export type Exhibition_Shopify_Settings_Set_Input = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  exhibition_id?: InputMaybe<Scalars["uuid"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  product_type?: InputMaybe<Scalars["String"]["input"]>;
  tags?: InputMaybe<Scalars["_text"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  vendor?: InputMaybe<Scalars["String"]["input"]>;
};

/** Streaming cursor of the table "exhibition_shopify_settings" */
export type Exhibition_Shopify_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Exhibition_Shopify_Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Exhibition_Shopify_Settings_Stream_Cursor_Value_Input = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  exhibition_id?: InputMaybe<Scalars["uuid"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  product_type?: InputMaybe<Scalars["String"]["input"]>;
  tags?: InputMaybe<Scalars["_text"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  vendor?: InputMaybe<Scalars["String"]["input"]>;
};

/** update columns of table "exhibition_shopify_settings" */
export enum Exhibition_Shopify_Settings_Update_Column {
  /** column name */
  Category = "category",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExhibitionId = "exhibition_id",
  /** column name */
  Id = "id",
  /** column name */
  ProductType = "product_type",
  /** column name */
  Tags = "tags",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Vendor = "vendor",
}

export type Exhibition_Shopify_Settings_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Exhibition_Shopify_Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Exhibition_Shopify_Settings_Bool_Exp;
};

/** 出品ステータス */
export type Exhibition_Statuses = {
  key: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

/** aggregated selection of "exhibition_statuses" */
export type Exhibition_Statuses_Aggregate = {
  aggregate?: Maybe<Exhibition_Statuses_Aggregate_Fields>;
  nodes: Array<Exhibition_Statuses>;
};

/** aggregate fields of "exhibition_statuses" */
export type Exhibition_Statuses_Aggregate_Fields = {
  count: Scalars["Int"]["output"];
  max?: Maybe<Exhibition_Statuses_Max_Fields>;
  min?: Maybe<Exhibition_Statuses_Min_Fields>;
};

/** aggregate fields of "exhibition_statuses" */
export type Exhibition_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Exhibition_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Boolean expression to filter rows from the table "exhibition_statuses". All fields are combined with a logical 'AND'. */
export type Exhibition_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Exhibition_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Exhibition_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Exhibition_Statuses_Bool_Exp>>;
  key?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "exhibition_statuses" */
export enum Exhibition_Statuses_Constraint {
  /** unique or primary key constraint on columns "key" */
  ExhibitionStatusesPkey = "exhibition_statuses_pkey",
}

/** input type for inserting data into table "exhibition_statuses" */
export type Exhibition_Statuses_Insert_Input = {
  key?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type Exhibition_Statuses_Max_Fields = {
  key?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type Exhibition_Statuses_Min_Fields = {
  key?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "exhibition_statuses" */
export type Exhibition_Statuses_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Exhibition_Statuses>;
};

/** on_conflict condition type for table "exhibition_statuses" */
export type Exhibition_Statuses_On_Conflict = {
  constraint: Exhibition_Statuses_Constraint;
  update_columns?: Array<Exhibition_Statuses_Update_Column>;
  where?: InputMaybe<Exhibition_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "exhibition_statuses". */
export type Exhibition_Statuses_Order_By = {
  key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: exhibition_statuses */
export type Exhibition_Statuses_Pk_Columns_Input = {
  key: Scalars["String"]["input"];
};

/** select columns of table "exhibition_statuses" */
export enum Exhibition_Statuses_Select_Column {
  /** column name */
  Key = "key",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "exhibition_statuses" */
export type Exhibition_Statuses_Set_Input = {
  key?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** Streaming cursor of the table "exhibition_statuses" */
export type Exhibition_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Exhibition_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Exhibition_Statuses_Stream_Cursor_Value_Input = {
  key?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** update columns of table "exhibition_statuses" */
export enum Exhibition_Statuses_Update_Column {
  /** column name */
  Key = "key",
  /** column name */
  Name = "name",
}

export type Exhibition_Statuses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Exhibition_Statuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Exhibition_Statuses_Bool_Exp;
};

/** 出品に対するSTORESのカテゴリ指定 */
export type Exhibition_Stores_Categories = {
  created_at: Scalars["timestamptz"]["output"];
  /** An object relationship */
  exhibition: Exhibitions;
  exhibition_id: Scalars["uuid"]["output"];
  id: Scalars["uuid"]["output"];
  platform_stores_category_id: Scalars["String"]["output"];
  updated_at: Scalars["timestamptz"]["output"];
};

/** aggregated selection of "exhibition_stores_categories" */
export type Exhibition_Stores_Categories_Aggregate = {
  aggregate?: Maybe<Exhibition_Stores_Categories_Aggregate_Fields>;
  nodes: Array<Exhibition_Stores_Categories>;
};

export type Exhibition_Stores_Categories_Aggregate_Bool_Exp = {
  count?: InputMaybe<Exhibition_Stores_Categories_Aggregate_Bool_Exp_Count>;
};

export type Exhibition_Stores_Categories_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Exhibition_Stores_Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Exhibition_Stores_Categories_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "exhibition_stores_categories" */
export type Exhibition_Stores_Categories_Aggregate_Fields = {
  count: Scalars["Int"]["output"];
  max?: Maybe<Exhibition_Stores_Categories_Max_Fields>;
  min?: Maybe<Exhibition_Stores_Categories_Min_Fields>;
};

/** aggregate fields of "exhibition_stores_categories" */
export type Exhibition_Stores_Categories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Exhibition_Stores_Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "exhibition_stores_categories" */
export type Exhibition_Stores_Categories_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Exhibition_Stores_Categories_Max_Order_By>;
  min?: InputMaybe<Exhibition_Stores_Categories_Min_Order_By>;
};

/** input type for inserting array relation for remote table "exhibition_stores_categories" */
export type Exhibition_Stores_Categories_Arr_Rel_Insert_Input = {
  data: Array<Exhibition_Stores_Categories_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Exhibition_Stores_Categories_On_Conflict>;
};

/** Boolean expression to filter rows from the table "exhibition_stores_categories". All fields are combined with a logical 'AND'. */
export type Exhibition_Stores_Categories_Bool_Exp = {
  _and?: InputMaybe<Array<Exhibition_Stores_Categories_Bool_Exp>>;
  _not?: InputMaybe<Exhibition_Stores_Categories_Bool_Exp>;
  _or?: InputMaybe<Array<Exhibition_Stores_Categories_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  exhibition?: InputMaybe<Exhibitions_Bool_Exp>;
  exhibition_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  platform_stores_category_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "exhibition_stores_categories" */
export enum Exhibition_Stores_Categories_Constraint {
  /** unique or primary key constraint on columns "platform_stores_category_id", "exhibition_id" */
  ExhibitionStoresCategoriesExhibitionIdPlatformStoresCate = "exhibition_stores_categories_exhibition_id_platform_stores_cate",
  /** unique or primary key constraint on columns "id" */
  ExhibitionStoresCategoriesPkey = "exhibition_stores_categories_pkey",
}

/** input type for inserting data into table "exhibition_stores_categories" */
export type Exhibition_Stores_Categories_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  exhibition?: InputMaybe<Exhibitions_Obj_Rel_Insert_Input>;
  exhibition_id?: InputMaybe<Scalars["uuid"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  platform_stores_category_id?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate max on columns */
export type Exhibition_Stores_Categories_Max_Fields = {
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  exhibition_id?: Maybe<Scalars["uuid"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  platform_stores_category_id?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by max() on columns of table "exhibition_stores_categories" */
export type Exhibition_Stores_Categories_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  exhibition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  platform_stores_category_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Exhibition_Stores_Categories_Min_Fields = {
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  exhibition_id?: Maybe<Scalars["uuid"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  platform_stores_category_id?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by min() on columns of table "exhibition_stores_categories" */
export type Exhibition_Stores_Categories_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  exhibition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  platform_stores_category_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "exhibition_stores_categories" */
export type Exhibition_Stores_Categories_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Exhibition_Stores_Categories>;
};

/** on_conflict condition type for table "exhibition_stores_categories" */
export type Exhibition_Stores_Categories_On_Conflict = {
  constraint: Exhibition_Stores_Categories_Constraint;
  update_columns?: Array<Exhibition_Stores_Categories_Update_Column>;
  where?: InputMaybe<Exhibition_Stores_Categories_Bool_Exp>;
};

/** Ordering options when selecting data from "exhibition_stores_categories". */
export type Exhibition_Stores_Categories_Order_By = {
  created_at?: InputMaybe<Order_By>;
  exhibition?: InputMaybe<Exhibitions_Order_By>;
  exhibition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  platform_stores_category_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: exhibition_stores_categories */
export type Exhibition_Stores_Categories_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "exhibition_stores_categories" */
export enum Exhibition_Stores_Categories_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExhibitionId = "exhibition_id",
  /** column name */
  Id = "id",
  /** column name */
  PlatformStoresCategoryId = "platform_stores_category_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "exhibition_stores_categories" */
export type Exhibition_Stores_Categories_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  exhibition_id?: InputMaybe<Scalars["uuid"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  platform_stores_category_id?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** Streaming cursor of the table "exhibition_stores_categories" */
export type Exhibition_Stores_Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Exhibition_Stores_Categories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Exhibition_Stores_Categories_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  exhibition_id?: InputMaybe<Scalars["uuid"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  platform_stores_category_id?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** update columns of table "exhibition_stores_categories" */
export enum Exhibition_Stores_Categories_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExhibitionId = "exhibition_id",
  /** column name */
  Id = "id",
  /** column name */
  PlatformStoresCategoryId = "platform_stores_category_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Exhibition_Stores_Categories_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Exhibition_Stores_Categories_Set_Input>;
  /** filter the rows which have to be updated */
  where: Exhibition_Stores_Categories_Bool_Exp;
};

/** columns and relationships of "exhibition_yahoo_shopping_settings" */
export type Exhibition_Yahoo_Shopping_Settings = {
  auction_category?: Maybe<Scalars["Int"]["output"]>;
  auction_shipping_from_prefecture?: Maybe<Scalars["String"]["output"]>;
  brand_code?: Maybe<Scalars["Int"]["output"]>;
  condition?: Maybe<Scalars["Int"]["output"]>;
  created_at: Scalars["timestamptz"]["output"];
  /** An object relationship */
  exhibition: Exhibitions;
  exhibition_id: Scalars["uuid"]["output"];
  id: Scalars["uuid"]["output"];
  lead_time_instock?: Maybe<Scalars["Int"]["output"]>;
  lead_time_outstock?: Maybe<Scalars["Int"]["output"]>;
  postage_set?: Maybe<Scalars["Int"]["output"]>;
  product_category: Scalars["Int"]["output"];
  shopping_category_hierarchy: Scalars["_integer"]["output"];
  store_category_hierarchies: Scalars["_text"]["output"];
  updated_at: Scalars["timestamptz"]["output"];
};

/** aggregated selection of "exhibition_yahoo_shopping_settings" */
export type Exhibition_Yahoo_Shopping_Settings_Aggregate = {
  aggregate?: Maybe<Exhibition_Yahoo_Shopping_Settings_Aggregate_Fields>;
  nodes: Array<Exhibition_Yahoo_Shopping_Settings>;
};

/** aggregate fields of "exhibition_yahoo_shopping_settings" */
export type Exhibition_Yahoo_Shopping_Settings_Aggregate_Fields = {
  avg?: Maybe<Exhibition_Yahoo_Shopping_Settings_Avg_Fields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<Exhibition_Yahoo_Shopping_Settings_Max_Fields>;
  min?: Maybe<Exhibition_Yahoo_Shopping_Settings_Min_Fields>;
  stddev?: Maybe<Exhibition_Yahoo_Shopping_Settings_Stddev_Fields>;
  stddev_pop?: Maybe<Exhibition_Yahoo_Shopping_Settings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Exhibition_Yahoo_Shopping_Settings_Stddev_Samp_Fields>;
  sum?: Maybe<Exhibition_Yahoo_Shopping_Settings_Sum_Fields>;
  var_pop?: Maybe<Exhibition_Yahoo_Shopping_Settings_Var_Pop_Fields>;
  var_samp?: Maybe<Exhibition_Yahoo_Shopping_Settings_Var_Samp_Fields>;
  variance?: Maybe<Exhibition_Yahoo_Shopping_Settings_Variance_Fields>;
};

/** aggregate fields of "exhibition_yahoo_shopping_settings" */
export type Exhibition_Yahoo_Shopping_Settings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Exhibition_Yahoo_Shopping_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type Exhibition_Yahoo_Shopping_Settings_Avg_Fields = {
  auction_category?: Maybe<Scalars["Float"]["output"]>;
  brand_code?: Maybe<Scalars["Float"]["output"]>;
  condition?: Maybe<Scalars["Float"]["output"]>;
  lead_time_instock?: Maybe<Scalars["Float"]["output"]>;
  lead_time_outstock?: Maybe<Scalars["Float"]["output"]>;
  postage_set?: Maybe<Scalars["Float"]["output"]>;
  product_category?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "exhibition_yahoo_shopping_settings". All fields are combined with a logical 'AND'. */
export type Exhibition_Yahoo_Shopping_Settings_Bool_Exp = {
  _and?: InputMaybe<Array<Exhibition_Yahoo_Shopping_Settings_Bool_Exp>>;
  _not?: InputMaybe<Exhibition_Yahoo_Shopping_Settings_Bool_Exp>;
  _or?: InputMaybe<Array<Exhibition_Yahoo_Shopping_Settings_Bool_Exp>>;
  auction_category?: InputMaybe<Int_Comparison_Exp>;
  auction_shipping_from_prefecture?: InputMaybe<String_Comparison_Exp>;
  brand_code?: InputMaybe<Int_Comparison_Exp>;
  condition?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  exhibition?: InputMaybe<Exhibitions_Bool_Exp>;
  exhibition_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  lead_time_instock?: InputMaybe<Int_Comparison_Exp>;
  lead_time_outstock?: InputMaybe<Int_Comparison_Exp>;
  postage_set?: InputMaybe<Int_Comparison_Exp>;
  product_category?: InputMaybe<Int_Comparison_Exp>;
  shopping_category_hierarchy?: InputMaybe<_Integer_Comparison_Exp>;
  store_category_hierarchies?: InputMaybe<_Text_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "exhibition_yahoo_shopping_settings" */
export enum Exhibition_Yahoo_Shopping_Settings_Constraint {
  /** unique or primary key constraint on columns "exhibition_id" */
  ExhibitionYahooShoppingSettingsExhibitionIdKey = "exhibition_yahoo_shopping_settings_exhibition_id_key",
  /** unique or primary key constraint on columns "id" */
  ExhibitionYahooShoppingSettingsPkey = "exhibition_yahoo_shopping_settings_pkey",
}

/** input type for incrementing numeric columns in table "exhibition_yahoo_shopping_settings" */
export type Exhibition_Yahoo_Shopping_Settings_Inc_Input = {
  auction_category?: InputMaybe<Scalars["Int"]["input"]>;
  brand_code?: InputMaybe<Scalars["Int"]["input"]>;
  condition?: InputMaybe<Scalars["Int"]["input"]>;
  lead_time_instock?: InputMaybe<Scalars["Int"]["input"]>;
  lead_time_outstock?: InputMaybe<Scalars["Int"]["input"]>;
  postage_set?: InputMaybe<Scalars["Int"]["input"]>;
  product_category?: InputMaybe<Scalars["Int"]["input"]>;
};

/** input type for inserting data into table "exhibition_yahoo_shopping_settings" */
export type Exhibition_Yahoo_Shopping_Settings_Insert_Input = {
  auction_category?: InputMaybe<Scalars["Int"]["input"]>;
  auction_shipping_from_prefecture?: InputMaybe<Scalars["String"]["input"]>;
  brand_code?: InputMaybe<Scalars["Int"]["input"]>;
  condition?: InputMaybe<Scalars["Int"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  exhibition?: InputMaybe<Exhibitions_Obj_Rel_Insert_Input>;
  exhibition_id?: InputMaybe<Scalars["uuid"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  lead_time_instock?: InputMaybe<Scalars["Int"]["input"]>;
  lead_time_outstock?: InputMaybe<Scalars["Int"]["input"]>;
  postage_set?: InputMaybe<Scalars["Int"]["input"]>;
  product_category?: InputMaybe<Scalars["Int"]["input"]>;
  shopping_category_hierarchy?: InputMaybe<Scalars["_integer"]["input"]>;
  store_category_hierarchies?: InputMaybe<Scalars["_text"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate max on columns */
export type Exhibition_Yahoo_Shopping_Settings_Max_Fields = {
  auction_category?: Maybe<Scalars["Int"]["output"]>;
  auction_shipping_from_prefecture?: Maybe<Scalars["String"]["output"]>;
  brand_code?: Maybe<Scalars["Int"]["output"]>;
  condition?: Maybe<Scalars["Int"]["output"]>;
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  exhibition_id?: Maybe<Scalars["uuid"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  lead_time_instock?: Maybe<Scalars["Int"]["output"]>;
  lead_time_outstock?: Maybe<Scalars["Int"]["output"]>;
  postage_set?: Maybe<Scalars["Int"]["output"]>;
  product_category?: Maybe<Scalars["Int"]["output"]>;
  shopping_category_hierarchy?: Maybe<Scalars["_integer"]["output"]>;
  store_category_hierarchies?: Maybe<Scalars["_text"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** aggregate min on columns */
export type Exhibition_Yahoo_Shopping_Settings_Min_Fields = {
  auction_category?: Maybe<Scalars["Int"]["output"]>;
  auction_shipping_from_prefecture?: Maybe<Scalars["String"]["output"]>;
  brand_code?: Maybe<Scalars["Int"]["output"]>;
  condition?: Maybe<Scalars["Int"]["output"]>;
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  exhibition_id?: Maybe<Scalars["uuid"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  lead_time_instock?: Maybe<Scalars["Int"]["output"]>;
  lead_time_outstock?: Maybe<Scalars["Int"]["output"]>;
  postage_set?: Maybe<Scalars["Int"]["output"]>;
  product_category?: Maybe<Scalars["Int"]["output"]>;
  shopping_category_hierarchy?: Maybe<Scalars["_integer"]["output"]>;
  store_category_hierarchies?: Maybe<Scalars["_text"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** response of any mutation on the table "exhibition_yahoo_shopping_settings" */
export type Exhibition_Yahoo_Shopping_Settings_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Exhibition_Yahoo_Shopping_Settings>;
};

/** input type for inserting object relation for remote table "exhibition_yahoo_shopping_settings" */
export type Exhibition_Yahoo_Shopping_Settings_Obj_Rel_Insert_Input = {
  data: Exhibition_Yahoo_Shopping_Settings_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Exhibition_Yahoo_Shopping_Settings_On_Conflict>;
};

/** on_conflict condition type for table "exhibition_yahoo_shopping_settings" */
export type Exhibition_Yahoo_Shopping_Settings_On_Conflict = {
  constraint: Exhibition_Yahoo_Shopping_Settings_Constraint;
  update_columns?: Array<Exhibition_Yahoo_Shopping_Settings_Update_Column>;
  where?: InputMaybe<Exhibition_Yahoo_Shopping_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "exhibition_yahoo_shopping_settings". */
export type Exhibition_Yahoo_Shopping_Settings_Order_By = {
  auction_category?: InputMaybe<Order_By>;
  auction_shipping_from_prefecture?: InputMaybe<Order_By>;
  brand_code?: InputMaybe<Order_By>;
  condition?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  exhibition?: InputMaybe<Exhibitions_Order_By>;
  exhibition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lead_time_instock?: InputMaybe<Order_By>;
  lead_time_outstock?: InputMaybe<Order_By>;
  postage_set?: InputMaybe<Order_By>;
  product_category?: InputMaybe<Order_By>;
  shopping_category_hierarchy?: InputMaybe<Order_By>;
  store_category_hierarchies?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: exhibition_yahoo_shopping_settings */
export type Exhibition_Yahoo_Shopping_Settings_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "exhibition_yahoo_shopping_settings" */
export enum Exhibition_Yahoo_Shopping_Settings_Select_Column {
  /** column name */
  AuctionCategory = "auction_category",
  /** column name */
  AuctionShippingFromPrefecture = "auction_shipping_from_prefecture",
  /** column name */
  BrandCode = "brand_code",
  /** column name */
  Condition = "condition",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExhibitionId = "exhibition_id",
  /** column name */
  Id = "id",
  /** column name */
  LeadTimeInstock = "lead_time_instock",
  /** column name */
  LeadTimeOutstock = "lead_time_outstock",
  /** column name */
  PostageSet = "postage_set",
  /** column name */
  ProductCategory = "product_category",
  /** column name */
  ShoppingCategoryHierarchy = "shopping_category_hierarchy",
  /** column name */
  StoreCategoryHierarchies = "store_category_hierarchies",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "exhibition_yahoo_shopping_settings" */
export type Exhibition_Yahoo_Shopping_Settings_Set_Input = {
  auction_category?: InputMaybe<Scalars["Int"]["input"]>;
  auction_shipping_from_prefecture?: InputMaybe<Scalars["String"]["input"]>;
  brand_code?: InputMaybe<Scalars["Int"]["input"]>;
  condition?: InputMaybe<Scalars["Int"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  exhibition_id?: InputMaybe<Scalars["uuid"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  lead_time_instock?: InputMaybe<Scalars["Int"]["input"]>;
  lead_time_outstock?: InputMaybe<Scalars["Int"]["input"]>;
  postage_set?: InputMaybe<Scalars["Int"]["input"]>;
  product_category?: InputMaybe<Scalars["Int"]["input"]>;
  shopping_category_hierarchy?: InputMaybe<Scalars["_integer"]["input"]>;
  store_category_hierarchies?: InputMaybe<Scalars["_text"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate stddev on columns */
export type Exhibition_Yahoo_Shopping_Settings_Stddev_Fields = {
  auction_category?: Maybe<Scalars["Float"]["output"]>;
  brand_code?: Maybe<Scalars["Float"]["output"]>;
  condition?: Maybe<Scalars["Float"]["output"]>;
  lead_time_instock?: Maybe<Scalars["Float"]["output"]>;
  lead_time_outstock?: Maybe<Scalars["Float"]["output"]>;
  postage_set?: Maybe<Scalars["Float"]["output"]>;
  product_category?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddev_pop on columns */
export type Exhibition_Yahoo_Shopping_Settings_Stddev_Pop_Fields = {
  auction_category?: Maybe<Scalars["Float"]["output"]>;
  brand_code?: Maybe<Scalars["Float"]["output"]>;
  condition?: Maybe<Scalars["Float"]["output"]>;
  lead_time_instock?: Maybe<Scalars["Float"]["output"]>;
  lead_time_outstock?: Maybe<Scalars["Float"]["output"]>;
  postage_set?: Maybe<Scalars["Float"]["output"]>;
  product_category?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddev_samp on columns */
export type Exhibition_Yahoo_Shopping_Settings_Stddev_Samp_Fields = {
  auction_category?: Maybe<Scalars["Float"]["output"]>;
  brand_code?: Maybe<Scalars["Float"]["output"]>;
  condition?: Maybe<Scalars["Float"]["output"]>;
  lead_time_instock?: Maybe<Scalars["Float"]["output"]>;
  lead_time_outstock?: Maybe<Scalars["Float"]["output"]>;
  postage_set?: Maybe<Scalars["Float"]["output"]>;
  product_category?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "exhibition_yahoo_shopping_settings" */
export type Exhibition_Yahoo_Shopping_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Exhibition_Yahoo_Shopping_Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Exhibition_Yahoo_Shopping_Settings_Stream_Cursor_Value_Input = {
  auction_category?: InputMaybe<Scalars["Int"]["input"]>;
  auction_shipping_from_prefecture?: InputMaybe<Scalars["String"]["input"]>;
  brand_code?: InputMaybe<Scalars["Int"]["input"]>;
  condition?: InputMaybe<Scalars["Int"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  exhibition_id?: InputMaybe<Scalars["uuid"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  lead_time_instock?: InputMaybe<Scalars["Int"]["input"]>;
  lead_time_outstock?: InputMaybe<Scalars["Int"]["input"]>;
  postage_set?: InputMaybe<Scalars["Int"]["input"]>;
  product_category?: InputMaybe<Scalars["Int"]["input"]>;
  shopping_category_hierarchy?: InputMaybe<Scalars["_integer"]["input"]>;
  store_category_hierarchies?: InputMaybe<Scalars["_text"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate sum on columns */
export type Exhibition_Yahoo_Shopping_Settings_Sum_Fields = {
  auction_category?: Maybe<Scalars["Int"]["output"]>;
  brand_code?: Maybe<Scalars["Int"]["output"]>;
  condition?: Maybe<Scalars["Int"]["output"]>;
  lead_time_instock?: Maybe<Scalars["Int"]["output"]>;
  lead_time_outstock?: Maybe<Scalars["Int"]["output"]>;
  postage_set?: Maybe<Scalars["Int"]["output"]>;
  product_category?: Maybe<Scalars["Int"]["output"]>;
};

/** update columns of table "exhibition_yahoo_shopping_settings" */
export enum Exhibition_Yahoo_Shopping_Settings_Update_Column {
  /** column name */
  AuctionCategory = "auction_category",
  /** column name */
  AuctionShippingFromPrefecture = "auction_shipping_from_prefecture",
  /** column name */
  BrandCode = "brand_code",
  /** column name */
  Condition = "condition",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExhibitionId = "exhibition_id",
  /** column name */
  Id = "id",
  /** column name */
  LeadTimeInstock = "lead_time_instock",
  /** column name */
  LeadTimeOutstock = "lead_time_outstock",
  /** column name */
  PostageSet = "postage_set",
  /** column name */
  ProductCategory = "product_category",
  /** column name */
  ShoppingCategoryHierarchy = "shopping_category_hierarchy",
  /** column name */
  StoreCategoryHierarchies = "store_category_hierarchies",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Exhibition_Yahoo_Shopping_Settings_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Exhibition_Yahoo_Shopping_Settings_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Exhibition_Yahoo_Shopping_Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Exhibition_Yahoo_Shopping_Settings_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Exhibition_Yahoo_Shopping_Settings_Var_Pop_Fields = {
  auction_category?: Maybe<Scalars["Float"]["output"]>;
  brand_code?: Maybe<Scalars["Float"]["output"]>;
  condition?: Maybe<Scalars["Float"]["output"]>;
  lead_time_instock?: Maybe<Scalars["Float"]["output"]>;
  lead_time_outstock?: Maybe<Scalars["Float"]["output"]>;
  postage_set?: Maybe<Scalars["Float"]["output"]>;
  product_category?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate var_samp on columns */
export type Exhibition_Yahoo_Shopping_Settings_Var_Samp_Fields = {
  auction_category?: Maybe<Scalars["Float"]["output"]>;
  brand_code?: Maybe<Scalars["Float"]["output"]>;
  condition?: Maybe<Scalars["Float"]["output"]>;
  lead_time_instock?: Maybe<Scalars["Float"]["output"]>;
  lead_time_outstock?: Maybe<Scalars["Float"]["output"]>;
  postage_set?: Maybe<Scalars["Float"]["output"]>;
  product_category?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type Exhibition_Yahoo_Shopping_Settings_Variance_Fields = {
  auction_category?: Maybe<Scalars["Float"]["output"]>;
  brand_code?: Maybe<Scalars["Float"]["output"]>;
  condition?: Maybe<Scalars["Float"]["output"]>;
  lead_time_instock?: Maybe<Scalars["Float"]["output"]>;
  lead_time_outstock?: Maybe<Scalars["Float"]["output"]>;
  postage_set?: Maybe<Scalars["Float"]["output"]>;
  product_category?: Maybe<Scalars["Float"]["output"]>;
};

/** アイテムの出品 */
export type Exhibitions = {
  /** An array relationship */
  base_categories: Array<Exhibition_Base_Categories>;
  /** An aggregate relationship */
  base_categories_aggregate: Exhibition_Base_Categories_Aggregate;
  created_at: Scalars["timestamptz"]["output"];
  error_message?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["uuid"]["output"];
  /** An array relationship */
  import_exhibitions: Array<Import_Exhibitions>;
  /** An aggregate relationship */
  import_exhibitions_aggregate: Import_Exhibitions_Aggregate;
  /** An object relationship */
  item: Items;
  item_id: Scalars["uuid"]["output"];
  /** An array relationship */
  item_variants: Array<Item_Variant_Exhibitions>;
  /** An aggregate relationship */
  item_variants_aggregate: Item_Variant_Exhibitions_Aggregate;
  /** An object relationship */
  mercari_shops_setting?: Maybe<Exhibition_Mercari_Shops_Settings>;
  platform: Scalars["String"]["output"];
  platform_item_id?: Maybe<Scalars["String"]["output"]>;
  shop_id: Scalars["uuid"]["output"];
  /** An object relationship */
  shopify_setting?: Maybe<Exhibition_Shopify_Settings>;
  status: Scalars["String"]["output"];
  /** An array relationship */
  stores_categories: Array<Exhibition_Stores_Categories>;
  /** An aggregate relationship */
  stores_categories_aggregate: Exhibition_Stores_Categories_Aggregate;
  updated_at: Scalars["timestamptz"]["output"];
  /** An object relationship */
  yahoo_shopping_setting?: Maybe<Exhibition_Yahoo_Shopping_Settings>;
};

/** アイテムの出品 */
export type ExhibitionsBase_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Exhibition_Base_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Exhibition_Base_Categories_Order_By>>;
  where?: InputMaybe<Exhibition_Base_Categories_Bool_Exp>;
};

/** アイテムの出品 */
export type ExhibitionsBase_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Exhibition_Base_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Exhibition_Base_Categories_Order_By>>;
  where?: InputMaybe<Exhibition_Base_Categories_Bool_Exp>;
};

/** アイテムの出品 */
export type ExhibitionsImport_ExhibitionsArgs = {
  distinct_on?: InputMaybe<Array<Import_Exhibitions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Import_Exhibitions_Order_By>>;
  where?: InputMaybe<Import_Exhibitions_Bool_Exp>;
};

/** アイテムの出品 */
export type ExhibitionsImport_Exhibitions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Import_Exhibitions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Import_Exhibitions_Order_By>>;
  where?: InputMaybe<Import_Exhibitions_Bool_Exp>;
};

/** アイテムの出品 */
export type ExhibitionsItem_VariantsArgs = {
  distinct_on?: InputMaybe<Array<Item_Variant_Exhibitions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Item_Variant_Exhibitions_Order_By>>;
  where?: InputMaybe<Item_Variant_Exhibitions_Bool_Exp>;
};

/** アイテムの出品 */
export type ExhibitionsItem_Variants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Item_Variant_Exhibitions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Item_Variant_Exhibitions_Order_By>>;
  where?: InputMaybe<Item_Variant_Exhibitions_Bool_Exp>;
};

/** アイテムの出品 */
export type ExhibitionsStores_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Exhibition_Stores_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Exhibition_Stores_Categories_Order_By>>;
  where?: InputMaybe<Exhibition_Stores_Categories_Bool_Exp>;
};

/** アイテムの出品 */
export type ExhibitionsStores_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Exhibition_Stores_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Exhibition_Stores_Categories_Order_By>>;
  where?: InputMaybe<Exhibition_Stores_Categories_Bool_Exp>;
};

/** aggregated selection of "exhibitions" */
export type Exhibitions_Aggregate = {
  aggregate?: Maybe<Exhibitions_Aggregate_Fields>;
  nodes: Array<Exhibitions>;
};

export type Exhibitions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Exhibitions_Aggregate_Bool_Exp_Count>;
};

export type Exhibitions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Exhibitions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Exhibitions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "exhibitions" */
export type Exhibitions_Aggregate_Fields = {
  count: Scalars["Int"]["output"];
  max?: Maybe<Exhibitions_Max_Fields>;
  min?: Maybe<Exhibitions_Min_Fields>;
};

/** aggregate fields of "exhibitions" */
export type Exhibitions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Exhibitions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "exhibitions" */
export type Exhibitions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Exhibitions_Max_Order_By>;
  min?: InputMaybe<Exhibitions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "exhibitions" */
export type Exhibitions_Arr_Rel_Insert_Input = {
  data: Array<Exhibitions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Exhibitions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "exhibitions". All fields are combined with a logical 'AND'. */
export type Exhibitions_Bool_Exp = {
  _and?: InputMaybe<Array<Exhibitions_Bool_Exp>>;
  _not?: InputMaybe<Exhibitions_Bool_Exp>;
  _or?: InputMaybe<Array<Exhibitions_Bool_Exp>>;
  base_categories?: InputMaybe<Exhibition_Base_Categories_Bool_Exp>;
  base_categories_aggregate?: InputMaybe<Exhibition_Base_Categories_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  error_message?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  import_exhibitions?: InputMaybe<Import_Exhibitions_Bool_Exp>;
  import_exhibitions_aggregate?: InputMaybe<Import_Exhibitions_Aggregate_Bool_Exp>;
  item?: InputMaybe<Items_Bool_Exp>;
  item_id?: InputMaybe<Uuid_Comparison_Exp>;
  item_variants?: InputMaybe<Item_Variant_Exhibitions_Bool_Exp>;
  item_variants_aggregate?: InputMaybe<Item_Variant_Exhibitions_Aggregate_Bool_Exp>;
  mercari_shops_setting?: InputMaybe<Exhibition_Mercari_Shops_Settings_Bool_Exp>;
  platform?: InputMaybe<String_Comparison_Exp>;
  platform_item_id?: InputMaybe<String_Comparison_Exp>;
  shop_id?: InputMaybe<Uuid_Comparison_Exp>;
  shopify_setting?: InputMaybe<Exhibition_Shopify_Settings_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  stores_categories?: InputMaybe<Exhibition_Stores_Categories_Bool_Exp>;
  stores_categories_aggregate?: InputMaybe<Exhibition_Stores_Categories_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  yahoo_shopping_setting?: InputMaybe<Exhibition_Yahoo_Shopping_Settings_Bool_Exp>;
};

/** unique or primary key constraints on table "exhibitions" */
export enum Exhibitions_Constraint {
  /** unique or primary key constraint on columns "item_id", "platform" */
  ExhibitionsItemIdPlatformKey = "exhibitions_item_id_platform_key",
  /** unique or primary key constraint on columns "id" */
  ExhibitionsPkey = "exhibitions_pkey",
}

/** input type for inserting data into table "exhibitions" */
export type Exhibitions_Insert_Input = {
  base_categories?: InputMaybe<Exhibition_Base_Categories_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  error_message?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  import_exhibitions?: InputMaybe<Import_Exhibitions_Arr_Rel_Insert_Input>;
  item?: InputMaybe<Items_Obj_Rel_Insert_Input>;
  item_id?: InputMaybe<Scalars["uuid"]["input"]>;
  item_variants?: InputMaybe<Item_Variant_Exhibitions_Arr_Rel_Insert_Input>;
  mercari_shops_setting?: InputMaybe<Exhibition_Mercari_Shops_Settings_Obj_Rel_Insert_Input>;
  platform?: InputMaybe<Scalars["String"]["input"]>;
  platform_item_id?: InputMaybe<Scalars["String"]["input"]>;
  shop_id?: InputMaybe<Scalars["uuid"]["input"]>;
  shopify_setting?: InputMaybe<Exhibition_Shopify_Settings_Obj_Rel_Insert_Input>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  stores_categories?: InputMaybe<Exhibition_Stores_Categories_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  yahoo_shopping_setting?: InputMaybe<Exhibition_Yahoo_Shopping_Settings_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Exhibitions_Max_Fields = {
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  error_message?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  item_id?: Maybe<Scalars["uuid"]["output"]>;
  platform?: Maybe<Scalars["String"]["output"]>;
  platform_item_id?: Maybe<Scalars["String"]["output"]>;
  shop_id?: Maybe<Scalars["uuid"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by max() on columns of table "exhibitions" */
export type Exhibitions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  error_message?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  platform?: InputMaybe<Order_By>;
  platform_item_id?: InputMaybe<Order_By>;
  shop_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Exhibitions_Min_Fields = {
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  error_message?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  item_id?: Maybe<Scalars["uuid"]["output"]>;
  platform?: Maybe<Scalars["String"]["output"]>;
  platform_item_id?: Maybe<Scalars["String"]["output"]>;
  shop_id?: Maybe<Scalars["uuid"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by min() on columns of table "exhibitions" */
export type Exhibitions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  error_message?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  platform?: InputMaybe<Order_By>;
  platform_item_id?: InputMaybe<Order_By>;
  shop_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "exhibitions" */
export type Exhibitions_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Exhibitions>;
};

/** input type for inserting object relation for remote table "exhibitions" */
export type Exhibitions_Obj_Rel_Insert_Input = {
  data: Exhibitions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Exhibitions_On_Conflict>;
};

/** on_conflict condition type for table "exhibitions" */
export type Exhibitions_On_Conflict = {
  constraint: Exhibitions_Constraint;
  update_columns?: Array<Exhibitions_Update_Column>;
  where?: InputMaybe<Exhibitions_Bool_Exp>;
};

/** Ordering options when selecting data from "exhibitions". */
export type Exhibitions_Order_By = {
  base_categories_aggregate?: InputMaybe<Exhibition_Base_Categories_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  error_message?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  import_exhibitions_aggregate?: InputMaybe<Import_Exhibitions_Aggregate_Order_By>;
  item?: InputMaybe<Items_Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_variants_aggregate?: InputMaybe<Item_Variant_Exhibitions_Aggregate_Order_By>;
  mercari_shops_setting?: InputMaybe<Exhibition_Mercari_Shops_Settings_Order_By>;
  platform?: InputMaybe<Order_By>;
  platform_item_id?: InputMaybe<Order_By>;
  shop_id?: InputMaybe<Order_By>;
  shopify_setting?: InputMaybe<Exhibition_Shopify_Settings_Order_By>;
  status?: InputMaybe<Order_By>;
  stores_categories_aggregate?: InputMaybe<Exhibition_Stores_Categories_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  yahoo_shopping_setting?: InputMaybe<Exhibition_Yahoo_Shopping_Settings_Order_By>;
};

/** primary key columns input for table: exhibitions */
export type Exhibitions_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "exhibitions" */
export enum Exhibitions_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ErrorMessage = "error_message",
  /** column name */
  Id = "id",
  /** column name */
  ItemId = "item_id",
  /** column name */
  Platform = "platform",
  /** column name */
  PlatformItemId = "platform_item_id",
  /** column name */
  ShopId = "shop_id",
  /** column name */
  Status = "status",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "exhibitions" */
export type Exhibitions_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  error_message?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  item_id?: InputMaybe<Scalars["uuid"]["input"]>;
  platform?: InputMaybe<Scalars["String"]["input"]>;
  platform_item_id?: InputMaybe<Scalars["String"]["input"]>;
  shop_id?: InputMaybe<Scalars["uuid"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** Streaming cursor of the table "exhibitions" */
export type Exhibitions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Exhibitions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Exhibitions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  error_message?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  item_id?: InputMaybe<Scalars["uuid"]["input"]>;
  platform?: InputMaybe<Scalars["String"]["input"]>;
  platform_item_id?: InputMaybe<Scalars["String"]["input"]>;
  shop_id?: InputMaybe<Scalars["uuid"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** update columns of table "exhibitions" */
export enum Exhibitions_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ErrorMessage = "error_message",
  /** column name */
  Id = "id",
  /** column name */
  ItemId = "item_id",
  /** column name */
  Platform = "platform",
  /** column name */
  PlatformItemId = "platform_item_id",
  /** column name */
  ShopId = "shop_id",
  /** column name */
  Status = "status",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Exhibitions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Exhibitions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Exhibitions_Bool_Exp;
};

export type FileOutput = {
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  id: Scalars["String"]["output"];
  mime_type?: Maybe<Scalars["String"]["output"]>;
  path: Scalars["String"]["output"];
};

/** columns and relationships of "import_exhibitions" */
export type Import_Exhibitions = {
  created_at: Scalars["timestamptz"]["output"];
  /** An object relationship */
  exhibition: Exhibitions;
  id: Scalars["uuid"]["output"];
  import_id: Scalars["uuid"]["output"];
  /** An object relationship */
  item: Items;
  item_id: Scalars["uuid"]["output"];
  order: Scalars["Int"]["output"];
  source_exhibition_id: Scalars["uuid"]["output"];
  updated_at: Scalars["timestamptz"]["output"];
};

/** aggregated selection of "import_exhibitions" */
export type Import_Exhibitions_Aggregate = {
  aggregate?: Maybe<Import_Exhibitions_Aggregate_Fields>;
  nodes: Array<Import_Exhibitions>;
};

export type Import_Exhibitions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Import_Exhibitions_Aggregate_Bool_Exp_Count>;
};

export type Import_Exhibitions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Import_Exhibitions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Import_Exhibitions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "import_exhibitions" */
export type Import_Exhibitions_Aggregate_Fields = {
  avg?: Maybe<Import_Exhibitions_Avg_Fields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<Import_Exhibitions_Max_Fields>;
  min?: Maybe<Import_Exhibitions_Min_Fields>;
  stddev?: Maybe<Import_Exhibitions_Stddev_Fields>;
  stddev_pop?: Maybe<Import_Exhibitions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Import_Exhibitions_Stddev_Samp_Fields>;
  sum?: Maybe<Import_Exhibitions_Sum_Fields>;
  var_pop?: Maybe<Import_Exhibitions_Var_Pop_Fields>;
  var_samp?: Maybe<Import_Exhibitions_Var_Samp_Fields>;
  variance?: Maybe<Import_Exhibitions_Variance_Fields>;
};

/** aggregate fields of "import_exhibitions" */
export type Import_Exhibitions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Import_Exhibitions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "import_exhibitions" */
export type Import_Exhibitions_Aggregate_Order_By = {
  avg?: InputMaybe<Import_Exhibitions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Import_Exhibitions_Max_Order_By>;
  min?: InputMaybe<Import_Exhibitions_Min_Order_By>;
  stddev?: InputMaybe<Import_Exhibitions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Import_Exhibitions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Import_Exhibitions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Import_Exhibitions_Sum_Order_By>;
  var_pop?: InputMaybe<Import_Exhibitions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Import_Exhibitions_Var_Samp_Order_By>;
  variance?: InputMaybe<Import_Exhibitions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "import_exhibitions" */
export type Import_Exhibitions_Arr_Rel_Insert_Input = {
  data: Array<Import_Exhibitions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Import_Exhibitions_On_Conflict>;
};

/** aggregate avg on columns */
export type Import_Exhibitions_Avg_Fields = {
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "import_exhibitions" */
export type Import_Exhibitions_Avg_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "import_exhibitions". All fields are combined with a logical 'AND'. */
export type Import_Exhibitions_Bool_Exp = {
  _and?: InputMaybe<Array<Import_Exhibitions_Bool_Exp>>;
  _not?: InputMaybe<Import_Exhibitions_Bool_Exp>;
  _or?: InputMaybe<Array<Import_Exhibitions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  exhibition?: InputMaybe<Exhibitions_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  import_id?: InputMaybe<Uuid_Comparison_Exp>;
  item?: InputMaybe<Items_Bool_Exp>;
  item_id?: InputMaybe<Uuid_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  source_exhibition_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "import_exhibitions" */
export enum Import_Exhibitions_Constraint {
  /** unique or primary key constraint on columns "id" */
  ImportExhibitionsPkey = "import_exhibitions_pkey",
}

/** input type for incrementing numeric columns in table "import_exhibitions" */
export type Import_Exhibitions_Inc_Input = {
  order?: InputMaybe<Scalars["Int"]["input"]>;
};

/** input type for inserting data into table "import_exhibitions" */
export type Import_Exhibitions_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  exhibition?: InputMaybe<Exhibitions_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  import_id?: InputMaybe<Scalars["uuid"]["input"]>;
  item?: InputMaybe<Items_Obj_Rel_Insert_Input>;
  item_id?: InputMaybe<Scalars["uuid"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  source_exhibition_id?: InputMaybe<Scalars["uuid"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate max on columns */
export type Import_Exhibitions_Max_Fields = {
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  import_id?: Maybe<Scalars["uuid"]["output"]>;
  item_id?: Maybe<Scalars["uuid"]["output"]>;
  order?: Maybe<Scalars["Int"]["output"]>;
  source_exhibition_id?: Maybe<Scalars["uuid"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by max() on columns of table "import_exhibitions" */
export type Import_Exhibitions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  import_id?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  source_exhibition_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Import_Exhibitions_Min_Fields = {
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  import_id?: Maybe<Scalars["uuid"]["output"]>;
  item_id?: Maybe<Scalars["uuid"]["output"]>;
  order?: Maybe<Scalars["Int"]["output"]>;
  source_exhibition_id?: Maybe<Scalars["uuid"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by min() on columns of table "import_exhibitions" */
export type Import_Exhibitions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  import_id?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  source_exhibition_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "import_exhibitions" */
export type Import_Exhibitions_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Import_Exhibitions>;
};

/** on_conflict condition type for table "import_exhibitions" */
export type Import_Exhibitions_On_Conflict = {
  constraint: Import_Exhibitions_Constraint;
  update_columns?: Array<Import_Exhibitions_Update_Column>;
  where?: InputMaybe<Import_Exhibitions_Bool_Exp>;
};

/** Ordering options when selecting data from "import_exhibitions". */
export type Import_Exhibitions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  exhibition?: InputMaybe<Exhibitions_Order_By>;
  id?: InputMaybe<Order_By>;
  import_id?: InputMaybe<Order_By>;
  item?: InputMaybe<Items_Order_By>;
  item_id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  source_exhibition_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: import_exhibitions */
export type Import_Exhibitions_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "import_exhibitions" */
export enum Import_Exhibitions_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  ImportId = "import_id",
  /** column name */
  ItemId = "item_id",
  /** column name */
  Order = "order",
  /** column name */
  SourceExhibitionId = "source_exhibition_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "import_exhibitions" */
export type Import_Exhibitions_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  import_id?: InputMaybe<Scalars["uuid"]["input"]>;
  item_id?: InputMaybe<Scalars["uuid"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  source_exhibition_id?: InputMaybe<Scalars["uuid"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate stddev on columns */
export type Import_Exhibitions_Stddev_Fields = {
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "import_exhibitions" */
export type Import_Exhibitions_Stddev_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Import_Exhibitions_Stddev_Pop_Fields = {
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev_pop() on columns of table "import_exhibitions" */
export type Import_Exhibitions_Stddev_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Import_Exhibitions_Stddev_Samp_Fields = {
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev_samp() on columns of table "import_exhibitions" */
export type Import_Exhibitions_Stddev_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "import_exhibitions" */
export type Import_Exhibitions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Import_Exhibitions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Import_Exhibitions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  import_id?: InputMaybe<Scalars["uuid"]["input"]>;
  item_id?: InputMaybe<Scalars["uuid"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  source_exhibition_id?: InputMaybe<Scalars["uuid"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate sum on columns */
export type Import_Exhibitions_Sum_Fields = {
  order?: Maybe<Scalars["Int"]["output"]>;
};

/** order by sum() on columns of table "import_exhibitions" */
export type Import_Exhibitions_Sum_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** update columns of table "import_exhibitions" */
export enum Import_Exhibitions_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  ImportId = "import_id",
  /** column name */
  ItemId = "item_id",
  /** column name */
  Order = "order",
  /** column name */
  SourceExhibitionId = "source_exhibition_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Import_Exhibitions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Import_Exhibitions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Import_Exhibitions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Import_Exhibitions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Import_Exhibitions_Var_Pop_Fields = {
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** order by var_pop() on columns of table "import_exhibitions" */
export type Import_Exhibitions_Var_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Import_Exhibitions_Var_Samp_Fields = {
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** order by var_samp() on columns of table "import_exhibitions" */
export type Import_Exhibitions_Var_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Import_Exhibitions_Variance_Fields = {
  order?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "import_exhibitions" */
export type Import_Exhibitions_Variance_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** columns and relationships of "imports" */
export type Imports = {
  created_at: Scalars["timestamptz"]["output"];
  destinations: Scalars["_varchar"]["output"];
  id: Scalars["uuid"]["output"];
  shop_id: Scalars["uuid"]["output"];
  sort_order?: Maybe<Scalars["String"]["output"]>;
  source: Scalars["String"]["output"];
  updated_at: Scalars["timestamptz"]["output"];
};

/** aggregated selection of "imports" */
export type Imports_Aggregate = {
  aggregate?: Maybe<Imports_Aggregate_Fields>;
  nodes: Array<Imports>;
};

/** aggregate fields of "imports" */
export type Imports_Aggregate_Fields = {
  count: Scalars["Int"]["output"];
  max?: Maybe<Imports_Max_Fields>;
  min?: Maybe<Imports_Min_Fields>;
};

/** aggregate fields of "imports" */
export type Imports_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Imports_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Boolean expression to filter rows from the table "imports". All fields are combined with a logical 'AND'. */
export type Imports_Bool_Exp = {
  _and?: InputMaybe<Array<Imports_Bool_Exp>>;
  _not?: InputMaybe<Imports_Bool_Exp>;
  _or?: InputMaybe<Array<Imports_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  destinations?: InputMaybe<_Varchar_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  shop_id?: InputMaybe<Uuid_Comparison_Exp>;
  sort_order?: InputMaybe<String_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "imports" */
export enum Imports_Constraint {
  /** unique or primary key constraint on columns "id" */
  ImportsPkey = "imports_pkey",
}

/** input type for inserting data into table "imports" */
export type Imports_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  destinations?: InputMaybe<Scalars["_varchar"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  shop_id?: InputMaybe<Scalars["uuid"]["input"]>;
  sort_order?: InputMaybe<Scalars["String"]["input"]>;
  source?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate max on columns */
export type Imports_Max_Fields = {
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  destinations?: Maybe<Scalars["_varchar"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  shop_id?: Maybe<Scalars["uuid"]["output"]>;
  sort_order?: Maybe<Scalars["String"]["output"]>;
  source?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** aggregate min on columns */
export type Imports_Min_Fields = {
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  destinations?: Maybe<Scalars["_varchar"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  shop_id?: Maybe<Scalars["uuid"]["output"]>;
  sort_order?: Maybe<Scalars["String"]["output"]>;
  source?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** response of any mutation on the table "imports" */
export type Imports_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Imports>;
};

/** on_conflict condition type for table "imports" */
export type Imports_On_Conflict = {
  constraint: Imports_Constraint;
  update_columns?: Array<Imports_Update_Column>;
  where?: InputMaybe<Imports_Bool_Exp>;
};

/** Ordering options when selecting data from "imports". */
export type Imports_Order_By = {
  created_at?: InputMaybe<Order_By>;
  destinations?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  shop_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: imports */
export type Imports_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "imports" */
export enum Imports_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Destinations = "destinations",
  /** column name */
  Id = "id",
  /** column name */
  ShopId = "shop_id",
  /** column name */
  SortOrder = "sort_order",
  /** column name */
  Source = "source",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "imports" */
export type Imports_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  destinations?: InputMaybe<Scalars["_varchar"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  shop_id?: InputMaybe<Scalars["uuid"]["input"]>;
  sort_order?: InputMaybe<Scalars["String"]["input"]>;
  source?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** Streaming cursor of the table "imports" */
export type Imports_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Imports_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Imports_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  destinations?: InputMaybe<Scalars["_varchar"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  shop_id?: InputMaybe<Scalars["uuid"]["input"]>;
  sort_order?: InputMaybe<Scalars["String"]["input"]>;
  source?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** update columns of table "imports" */
export enum Imports_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Destinations = "destinations",
  /** column name */
  Id = "id",
  /** column name */
  ShopId = "shop_id",
  /** column name */
  SortOrder = "sort_order",
  /** column name */
  Source = "source",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Imports_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Imports_Set_Input>;
  /** filter the rows which have to be updated */
  where: Imports_Bool_Exp;
};

/** columns and relationships of "integration_shopify_settings" */
export type Integration_Shopify_Settings = {
  created_at: Scalars["timestamptz"]["output"];
  id: Scalars["uuid"]["output"];
  /** An object relationship */
  integration: Integrations;
  integration_id: Scalars["uuid"]["output"];
  store_id: Scalars["String"]["output"];
  updated_at: Scalars["timestamptz"]["output"];
};

/** aggregated selection of "integration_shopify_settings" */
export type Integration_Shopify_Settings_Aggregate = {
  aggregate?: Maybe<Integration_Shopify_Settings_Aggregate_Fields>;
  nodes: Array<Integration_Shopify_Settings>;
};

export type Integration_Shopify_Settings_Aggregate_Bool_Exp = {
  count?: InputMaybe<Integration_Shopify_Settings_Aggregate_Bool_Exp_Count>;
};

export type Integration_Shopify_Settings_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Integration_Shopify_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Integration_Shopify_Settings_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "integration_shopify_settings" */
export type Integration_Shopify_Settings_Aggregate_Fields = {
  count: Scalars["Int"]["output"];
  max?: Maybe<Integration_Shopify_Settings_Max_Fields>;
  min?: Maybe<Integration_Shopify_Settings_Min_Fields>;
};

/** aggregate fields of "integration_shopify_settings" */
export type Integration_Shopify_Settings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Integration_Shopify_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "integration_shopify_settings" */
export type Integration_Shopify_Settings_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Integration_Shopify_Settings_Max_Order_By>;
  min?: InputMaybe<Integration_Shopify_Settings_Min_Order_By>;
};

/** input type for inserting array relation for remote table "integration_shopify_settings" */
export type Integration_Shopify_Settings_Arr_Rel_Insert_Input = {
  data: Array<Integration_Shopify_Settings_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Integration_Shopify_Settings_On_Conflict>;
};

/** Boolean expression to filter rows from the table "integration_shopify_settings". All fields are combined with a logical 'AND'. */
export type Integration_Shopify_Settings_Bool_Exp = {
  _and?: InputMaybe<Array<Integration_Shopify_Settings_Bool_Exp>>;
  _not?: InputMaybe<Integration_Shopify_Settings_Bool_Exp>;
  _or?: InputMaybe<Array<Integration_Shopify_Settings_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  integration?: InputMaybe<Integrations_Bool_Exp>;
  integration_id?: InputMaybe<Uuid_Comparison_Exp>;
  store_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "integration_shopify_settings" */
export enum Integration_Shopify_Settings_Constraint {
  /** unique or primary key constraint on columns "id" */
  IntegrationShopifySettingsPkey = "integration_shopify_settings_pkey",
}

/** input type for inserting data into table "integration_shopify_settings" */
export type Integration_Shopify_Settings_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  integration?: InputMaybe<Integrations_Obj_Rel_Insert_Input>;
  integration_id?: InputMaybe<Scalars["uuid"]["input"]>;
  store_id?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate max on columns */
export type Integration_Shopify_Settings_Max_Fields = {
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  integration_id?: Maybe<Scalars["uuid"]["output"]>;
  store_id?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by max() on columns of table "integration_shopify_settings" */
export type Integration_Shopify_Settings_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integration_id?: InputMaybe<Order_By>;
  store_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Integration_Shopify_Settings_Min_Fields = {
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  integration_id?: Maybe<Scalars["uuid"]["output"]>;
  store_id?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by min() on columns of table "integration_shopify_settings" */
export type Integration_Shopify_Settings_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integration_id?: InputMaybe<Order_By>;
  store_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "integration_shopify_settings" */
export type Integration_Shopify_Settings_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Integration_Shopify_Settings>;
};

/** on_conflict condition type for table "integration_shopify_settings" */
export type Integration_Shopify_Settings_On_Conflict = {
  constraint: Integration_Shopify_Settings_Constraint;
  update_columns?: Array<Integration_Shopify_Settings_Update_Column>;
  where?: InputMaybe<Integration_Shopify_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "integration_shopify_settings". */
export type Integration_Shopify_Settings_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integration?: InputMaybe<Integrations_Order_By>;
  integration_id?: InputMaybe<Order_By>;
  store_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: integration_shopify_settings */
export type Integration_Shopify_Settings_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "integration_shopify_settings" */
export enum Integration_Shopify_Settings_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IntegrationId = "integration_id",
  /** column name */
  StoreId = "store_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "integration_shopify_settings" */
export type Integration_Shopify_Settings_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  integration_id?: InputMaybe<Scalars["uuid"]["input"]>;
  store_id?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** Streaming cursor of the table "integration_shopify_settings" */
export type Integration_Shopify_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Integration_Shopify_Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Integration_Shopify_Settings_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  integration_id?: InputMaybe<Scalars["uuid"]["input"]>;
  store_id?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** update columns of table "integration_shopify_settings" */
export enum Integration_Shopify_Settings_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IntegrationId = "integration_id",
  /** column name */
  StoreId = "store_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Integration_Shopify_Settings_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Integration_Shopify_Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Integration_Shopify_Settings_Bool_Exp;
};

/** columns and relationships of "integration_yahoo_shopping_settings" */
export type Integration_Yahoo_Shopping_Settings = {
  created_at: Scalars["timestamptz"]["output"];
  id: Scalars["uuid"]["output"];
  /** An object relationship */
  integration: Integrations;
  integration_id: Scalars["uuid"]["output"];
  seller_id: Scalars["String"]["output"];
  updated_at: Scalars["timestamptz"]["output"];
};

/** aggregated selection of "integration_yahoo_shopping_settings" */
export type Integration_Yahoo_Shopping_Settings_Aggregate = {
  aggregate?: Maybe<Integration_Yahoo_Shopping_Settings_Aggregate_Fields>;
  nodes: Array<Integration_Yahoo_Shopping_Settings>;
};

export type Integration_Yahoo_Shopping_Settings_Aggregate_Bool_Exp = {
  count?: InputMaybe<Integration_Yahoo_Shopping_Settings_Aggregate_Bool_Exp_Count>;
};

export type Integration_Yahoo_Shopping_Settings_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Integration_Yahoo_Shopping_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Integration_Yahoo_Shopping_Settings_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "integration_yahoo_shopping_settings" */
export type Integration_Yahoo_Shopping_Settings_Aggregate_Fields = {
  count: Scalars["Int"]["output"];
  max?: Maybe<Integration_Yahoo_Shopping_Settings_Max_Fields>;
  min?: Maybe<Integration_Yahoo_Shopping_Settings_Min_Fields>;
};

/** aggregate fields of "integration_yahoo_shopping_settings" */
export type Integration_Yahoo_Shopping_Settings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Integration_Yahoo_Shopping_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "integration_yahoo_shopping_settings" */
export type Integration_Yahoo_Shopping_Settings_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Integration_Yahoo_Shopping_Settings_Max_Order_By>;
  min?: InputMaybe<Integration_Yahoo_Shopping_Settings_Min_Order_By>;
};

/** input type for inserting array relation for remote table "integration_yahoo_shopping_settings" */
export type Integration_Yahoo_Shopping_Settings_Arr_Rel_Insert_Input = {
  data: Array<Integration_Yahoo_Shopping_Settings_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Integration_Yahoo_Shopping_Settings_On_Conflict>;
};

/** Boolean expression to filter rows from the table "integration_yahoo_shopping_settings". All fields are combined with a logical 'AND'. */
export type Integration_Yahoo_Shopping_Settings_Bool_Exp = {
  _and?: InputMaybe<Array<Integration_Yahoo_Shopping_Settings_Bool_Exp>>;
  _not?: InputMaybe<Integration_Yahoo_Shopping_Settings_Bool_Exp>;
  _or?: InputMaybe<Array<Integration_Yahoo_Shopping_Settings_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  integration?: InputMaybe<Integrations_Bool_Exp>;
  integration_id?: InputMaybe<Uuid_Comparison_Exp>;
  seller_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "integration_yahoo_shopping_settings" */
export enum Integration_Yahoo_Shopping_Settings_Constraint {
  /** unique or primary key constraint on columns "id" */
  IntegrationYahooShoppingSettingsPkey = "integration_yahoo_shopping_settings_pkey",
}

/** input type for inserting data into table "integration_yahoo_shopping_settings" */
export type Integration_Yahoo_Shopping_Settings_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  integration?: InputMaybe<Integrations_Obj_Rel_Insert_Input>;
  integration_id?: InputMaybe<Scalars["uuid"]["input"]>;
  seller_id?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate max on columns */
export type Integration_Yahoo_Shopping_Settings_Max_Fields = {
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  integration_id?: Maybe<Scalars["uuid"]["output"]>;
  seller_id?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by max() on columns of table "integration_yahoo_shopping_settings" */
export type Integration_Yahoo_Shopping_Settings_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integration_id?: InputMaybe<Order_By>;
  seller_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Integration_Yahoo_Shopping_Settings_Min_Fields = {
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  integration_id?: Maybe<Scalars["uuid"]["output"]>;
  seller_id?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by min() on columns of table "integration_yahoo_shopping_settings" */
export type Integration_Yahoo_Shopping_Settings_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integration_id?: InputMaybe<Order_By>;
  seller_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "integration_yahoo_shopping_settings" */
export type Integration_Yahoo_Shopping_Settings_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Integration_Yahoo_Shopping_Settings>;
};

/** on_conflict condition type for table "integration_yahoo_shopping_settings" */
export type Integration_Yahoo_Shopping_Settings_On_Conflict = {
  constraint: Integration_Yahoo_Shopping_Settings_Constraint;
  update_columns?: Array<Integration_Yahoo_Shopping_Settings_Update_Column>;
  where?: InputMaybe<Integration_Yahoo_Shopping_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "integration_yahoo_shopping_settings". */
export type Integration_Yahoo_Shopping_Settings_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integration?: InputMaybe<Integrations_Order_By>;
  integration_id?: InputMaybe<Order_By>;
  seller_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: integration_yahoo_shopping_settings */
export type Integration_Yahoo_Shopping_Settings_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "integration_yahoo_shopping_settings" */
export enum Integration_Yahoo_Shopping_Settings_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IntegrationId = "integration_id",
  /** column name */
  SellerId = "seller_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "integration_yahoo_shopping_settings" */
export type Integration_Yahoo_Shopping_Settings_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  integration_id?: InputMaybe<Scalars["uuid"]["input"]>;
  seller_id?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** Streaming cursor of the table "integration_yahoo_shopping_settings" */
export type Integration_Yahoo_Shopping_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Integration_Yahoo_Shopping_Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Integration_Yahoo_Shopping_Settings_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  integration_id?: InputMaybe<Scalars["uuid"]["input"]>;
  seller_id?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** update columns of table "integration_yahoo_shopping_settings" */
export enum Integration_Yahoo_Shopping_Settings_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IntegrationId = "integration_id",
  /** column name */
  SellerId = "seller_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Integration_Yahoo_Shopping_Settings_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Integration_Yahoo_Shopping_Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Integration_Yahoo_Shopping_Settings_Bool_Exp;
};

/** ショップとプラットフォームの連携 */
export type Integrations = {
  account_name?: Maybe<Scalars["String"]["output"]>;
  api_key?: Maybe<Scalars["String"]["output"]>;
  created_at: Scalars["timestamptz"]["output"];
  disabled_at?: Maybe<Scalars["timestamptz"]["output"]>;
  encrypted_password?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["uuid"]["output"];
  oauth2_access_token?: Maybe<Scalars["String"]["output"]>;
  oauth2_access_token_expires_at?: Maybe<Scalars["timestamptz"]["output"]>;
  oauth2_refresh_token?: Maybe<Scalars["String"]["output"]>;
  platform: Scalars["String"]["output"];
  /** An object relationship */
  shop: Shops;
  shop_id: Scalars["uuid"]["output"];
  /** An array relationship */
  shopify_settings: Array<Integration_Shopify_Settings>;
  /** An aggregate relationship */
  shopify_settings_aggregate: Integration_Shopify_Settings_Aggregate;
  updated_at: Scalars["timestamptz"]["output"];
  /** An array relationship */
  yahoo_shopping_settings: Array<Integration_Yahoo_Shopping_Settings>;
  /** An aggregate relationship */
  yahoo_shopping_settings_aggregate: Integration_Yahoo_Shopping_Settings_Aggregate;
};

/** ショップとプラットフォームの連携 */
export type IntegrationsShopify_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Integration_Shopify_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Integration_Shopify_Settings_Order_By>>;
  where?: InputMaybe<Integration_Shopify_Settings_Bool_Exp>;
};

/** ショップとプラットフォームの連携 */
export type IntegrationsShopify_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Integration_Shopify_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Integration_Shopify_Settings_Order_By>>;
  where?: InputMaybe<Integration_Shopify_Settings_Bool_Exp>;
};

/** ショップとプラットフォームの連携 */
export type IntegrationsYahoo_Shopping_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Integration_Yahoo_Shopping_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Integration_Yahoo_Shopping_Settings_Order_By>>;
  where?: InputMaybe<Integration_Yahoo_Shopping_Settings_Bool_Exp>;
};

/** ショップとプラットフォームの連携 */
export type IntegrationsYahoo_Shopping_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Integration_Yahoo_Shopping_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Integration_Yahoo_Shopping_Settings_Order_By>>;
  where?: InputMaybe<Integration_Yahoo_Shopping_Settings_Bool_Exp>;
};

/** aggregated selection of "integrations" */
export type Integrations_Aggregate = {
  aggregate?: Maybe<Integrations_Aggregate_Fields>;
  nodes: Array<Integrations>;
};

/** aggregate fields of "integrations" */
export type Integrations_Aggregate_Fields = {
  count: Scalars["Int"]["output"];
  max?: Maybe<Integrations_Max_Fields>;
  min?: Maybe<Integrations_Min_Fields>;
};

/** aggregate fields of "integrations" */
export type Integrations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Integrations_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Boolean expression to filter rows from the table "integrations". All fields are combined with a logical 'AND'. */
export type Integrations_Bool_Exp = {
  _and?: InputMaybe<Array<Integrations_Bool_Exp>>;
  _not?: InputMaybe<Integrations_Bool_Exp>;
  _or?: InputMaybe<Array<Integrations_Bool_Exp>>;
  account_name?: InputMaybe<String_Comparison_Exp>;
  api_key?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  disabled_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  encrypted_password?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  oauth2_access_token?: InputMaybe<String_Comparison_Exp>;
  oauth2_access_token_expires_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  oauth2_refresh_token?: InputMaybe<String_Comparison_Exp>;
  platform?: InputMaybe<String_Comparison_Exp>;
  shop?: InputMaybe<Shops_Bool_Exp>;
  shop_id?: InputMaybe<Uuid_Comparison_Exp>;
  shopify_settings?: InputMaybe<Integration_Shopify_Settings_Bool_Exp>;
  shopify_settings_aggregate?: InputMaybe<Integration_Shopify_Settings_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  yahoo_shopping_settings?: InputMaybe<Integration_Yahoo_Shopping_Settings_Bool_Exp>;
  yahoo_shopping_settings_aggregate?: InputMaybe<Integration_Yahoo_Shopping_Settings_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "integrations" */
export enum Integrations_Constraint {
  /** unique or primary key constraint on columns "id" */
  IntegrationsPkey = "integrations_pkey",
  /** unique or primary key constraint on columns "shop_id", "platform" */
  IntegrationsShopIdPlatformKey = "integrations_shop_id_platform_key",
}

/** input type for inserting data into table "integrations" */
export type Integrations_Insert_Input = {
  account_name?: InputMaybe<Scalars["String"]["input"]>;
  api_key?: InputMaybe<Scalars["String"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  disabled_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  encrypted_password?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  oauth2_access_token?: InputMaybe<Scalars["String"]["input"]>;
  oauth2_access_token_expires_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  oauth2_refresh_token?: InputMaybe<Scalars["String"]["input"]>;
  platform?: InputMaybe<Scalars["String"]["input"]>;
  shop?: InputMaybe<Shops_Obj_Rel_Insert_Input>;
  shop_id?: InputMaybe<Scalars["uuid"]["input"]>;
  shopify_settings?: InputMaybe<Integration_Shopify_Settings_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  yahoo_shopping_settings?: InputMaybe<Integration_Yahoo_Shopping_Settings_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Integrations_Max_Fields = {
  account_name?: Maybe<Scalars["String"]["output"]>;
  api_key?: Maybe<Scalars["String"]["output"]>;
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  disabled_at?: Maybe<Scalars["timestamptz"]["output"]>;
  encrypted_password?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  oauth2_access_token?: Maybe<Scalars["String"]["output"]>;
  oauth2_access_token_expires_at?: Maybe<Scalars["timestamptz"]["output"]>;
  oauth2_refresh_token?: Maybe<Scalars["String"]["output"]>;
  platform?: Maybe<Scalars["String"]["output"]>;
  shop_id?: Maybe<Scalars["uuid"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** aggregate min on columns */
export type Integrations_Min_Fields = {
  account_name?: Maybe<Scalars["String"]["output"]>;
  api_key?: Maybe<Scalars["String"]["output"]>;
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  disabled_at?: Maybe<Scalars["timestamptz"]["output"]>;
  encrypted_password?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  oauth2_access_token?: Maybe<Scalars["String"]["output"]>;
  oauth2_access_token_expires_at?: Maybe<Scalars["timestamptz"]["output"]>;
  oauth2_refresh_token?: Maybe<Scalars["String"]["output"]>;
  platform?: Maybe<Scalars["String"]["output"]>;
  shop_id?: Maybe<Scalars["uuid"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** response of any mutation on the table "integrations" */
export type Integrations_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Integrations>;
};

/** input type for inserting object relation for remote table "integrations" */
export type Integrations_Obj_Rel_Insert_Input = {
  data: Integrations_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Integrations_On_Conflict>;
};

/** on_conflict condition type for table "integrations" */
export type Integrations_On_Conflict = {
  constraint: Integrations_Constraint;
  update_columns?: Array<Integrations_Update_Column>;
  where?: InputMaybe<Integrations_Bool_Exp>;
};

/** Ordering options when selecting data from "integrations". */
export type Integrations_Order_By = {
  account_name?: InputMaybe<Order_By>;
  api_key?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  disabled_at?: InputMaybe<Order_By>;
  encrypted_password?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  oauth2_access_token?: InputMaybe<Order_By>;
  oauth2_access_token_expires_at?: InputMaybe<Order_By>;
  oauth2_refresh_token?: InputMaybe<Order_By>;
  platform?: InputMaybe<Order_By>;
  shop?: InputMaybe<Shops_Order_By>;
  shop_id?: InputMaybe<Order_By>;
  shopify_settings_aggregate?: InputMaybe<Integration_Shopify_Settings_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  yahoo_shopping_settings_aggregate?: InputMaybe<Integration_Yahoo_Shopping_Settings_Aggregate_Order_By>;
};

/** primary key columns input for table: integrations */
export type Integrations_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "integrations" */
export enum Integrations_Select_Column {
  /** column name */
  AccountName = "account_name",
  /** column name */
  ApiKey = "api_key",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DisabledAt = "disabled_at",
  /** column name */
  EncryptedPassword = "encrypted_password",
  /** column name */
  Id = "id",
  /** column name */
  Oauth2AccessToken = "oauth2_access_token",
  /** column name */
  Oauth2AccessTokenExpiresAt = "oauth2_access_token_expires_at",
  /** column name */
  Oauth2RefreshToken = "oauth2_refresh_token",
  /** column name */
  Platform = "platform",
  /** column name */
  ShopId = "shop_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "integrations" */
export type Integrations_Set_Input = {
  account_name?: InputMaybe<Scalars["String"]["input"]>;
  api_key?: InputMaybe<Scalars["String"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  disabled_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  encrypted_password?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  oauth2_access_token?: InputMaybe<Scalars["String"]["input"]>;
  oauth2_access_token_expires_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  oauth2_refresh_token?: InputMaybe<Scalars["String"]["input"]>;
  platform?: InputMaybe<Scalars["String"]["input"]>;
  shop_id?: InputMaybe<Scalars["uuid"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** Streaming cursor of the table "integrations" */
export type Integrations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Integrations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Integrations_Stream_Cursor_Value_Input = {
  account_name?: InputMaybe<Scalars["String"]["input"]>;
  api_key?: InputMaybe<Scalars["String"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  disabled_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  encrypted_password?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  oauth2_access_token?: InputMaybe<Scalars["String"]["input"]>;
  oauth2_access_token_expires_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  oauth2_refresh_token?: InputMaybe<Scalars["String"]["input"]>;
  platform?: InputMaybe<Scalars["String"]["input"]>;
  shop_id?: InputMaybe<Scalars["uuid"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** update columns of table "integrations" */
export enum Integrations_Update_Column {
  /** column name */
  AccountName = "account_name",
  /** column name */
  ApiKey = "api_key",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DisabledAt = "disabled_at",
  /** column name */
  EncryptedPassword = "encrypted_password",
  /** column name */
  Id = "id",
  /** column name */
  Oauth2AccessToken = "oauth2_access_token",
  /** column name */
  Oauth2AccessTokenExpiresAt = "oauth2_access_token_expires_at",
  /** column name */
  Oauth2RefreshToken = "oauth2_refresh_token",
  /** column name */
  Platform = "platform",
  /** column name */
  ShopId = "shop_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Integrations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Integrations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Integrations_Bool_Exp;
};

/** 商品の状態 */
export type Item_Conditions = {
  key: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

/** aggregated selection of "item_conditions" */
export type Item_Conditions_Aggregate = {
  aggregate?: Maybe<Item_Conditions_Aggregate_Fields>;
  nodes: Array<Item_Conditions>;
};

/** aggregate fields of "item_conditions" */
export type Item_Conditions_Aggregate_Fields = {
  count: Scalars["Int"]["output"];
  max?: Maybe<Item_Conditions_Max_Fields>;
  min?: Maybe<Item_Conditions_Min_Fields>;
};

/** aggregate fields of "item_conditions" */
export type Item_Conditions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Item_Conditions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Boolean expression to filter rows from the table "item_conditions". All fields are combined with a logical 'AND'. */
export type Item_Conditions_Bool_Exp = {
  _and?: InputMaybe<Array<Item_Conditions_Bool_Exp>>;
  _not?: InputMaybe<Item_Conditions_Bool_Exp>;
  _or?: InputMaybe<Array<Item_Conditions_Bool_Exp>>;
  key?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "item_conditions" */
export enum Item_Conditions_Constraint {
  /** unique or primary key constraint on columns "key" */
  ItemConditionsPkey = "item_conditions_pkey",
}

/** input type for inserting data into table "item_conditions" */
export type Item_Conditions_Insert_Input = {
  key?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type Item_Conditions_Max_Fields = {
  key?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type Item_Conditions_Min_Fields = {
  key?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "item_conditions" */
export type Item_Conditions_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Item_Conditions>;
};

/** input type for inserting object relation for remote table "item_conditions" */
export type Item_Conditions_Obj_Rel_Insert_Input = {
  data: Item_Conditions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Item_Conditions_On_Conflict>;
};

/** on_conflict condition type for table "item_conditions" */
export type Item_Conditions_On_Conflict = {
  constraint: Item_Conditions_Constraint;
  update_columns?: Array<Item_Conditions_Update_Column>;
  where?: InputMaybe<Item_Conditions_Bool_Exp>;
};

/** Ordering options when selecting data from "item_conditions". */
export type Item_Conditions_Order_By = {
  key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: item_conditions */
export type Item_Conditions_Pk_Columns_Input = {
  key: Scalars["String"]["input"];
};

/** select columns of table "item_conditions" */
export enum Item_Conditions_Select_Column {
  /** column name */
  Key = "key",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "item_conditions" */
export type Item_Conditions_Set_Input = {
  key?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** Streaming cursor of the table "item_conditions" */
export type Item_Conditions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Item_Conditions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Item_Conditions_Stream_Cursor_Value_Input = {
  key?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** update columns of table "item_conditions" */
export enum Item_Conditions_Update_Column {
  /** column name */
  Key = "key",
  /** column name */
  Name = "name",
}

export type Item_Conditions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Item_Conditions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Item_Conditions_Bool_Exp;
};

/** 商品画像テーブル */
export type Item_Images = {
  created_at: Scalars["timestamptz"]["output"];
  id: Scalars["uuid"]["output"];
  /** An object relationship */
  item?: Maybe<Items>;
  item_id?: Maybe<Scalars["uuid"]["output"]>;
  mime_type: Scalars["String"]["output"];
  path: Scalars["String"]["output"];
  position?: Maybe<Scalars["Int"]["output"]>;
};

/** aggregated selection of "item_images" */
export type Item_Images_Aggregate = {
  aggregate?: Maybe<Item_Images_Aggregate_Fields>;
  nodes: Array<Item_Images>;
};

export type Item_Images_Aggregate_Bool_Exp = {
  count?: InputMaybe<Item_Images_Aggregate_Bool_Exp_Count>;
};

export type Item_Images_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Item_Images_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Item_Images_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "item_images" */
export type Item_Images_Aggregate_Fields = {
  avg?: Maybe<Item_Images_Avg_Fields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<Item_Images_Max_Fields>;
  min?: Maybe<Item_Images_Min_Fields>;
  stddev?: Maybe<Item_Images_Stddev_Fields>;
  stddev_pop?: Maybe<Item_Images_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Item_Images_Stddev_Samp_Fields>;
  sum?: Maybe<Item_Images_Sum_Fields>;
  var_pop?: Maybe<Item_Images_Var_Pop_Fields>;
  var_samp?: Maybe<Item_Images_Var_Samp_Fields>;
  variance?: Maybe<Item_Images_Variance_Fields>;
};

/** aggregate fields of "item_images" */
export type Item_Images_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Item_Images_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "item_images" */
export type Item_Images_Aggregate_Order_By = {
  avg?: InputMaybe<Item_Images_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Item_Images_Max_Order_By>;
  min?: InputMaybe<Item_Images_Min_Order_By>;
  stddev?: InputMaybe<Item_Images_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Item_Images_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Item_Images_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Item_Images_Sum_Order_By>;
  var_pop?: InputMaybe<Item_Images_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Item_Images_Var_Samp_Order_By>;
  variance?: InputMaybe<Item_Images_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "item_images" */
export type Item_Images_Arr_Rel_Insert_Input = {
  data: Array<Item_Images_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Item_Images_On_Conflict>;
};

/** aggregate avg on columns */
export type Item_Images_Avg_Fields = {
  position?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "item_images" */
export type Item_Images_Avg_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "item_images". All fields are combined with a logical 'AND'. */
export type Item_Images_Bool_Exp = {
  _and?: InputMaybe<Array<Item_Images_Bool_Exp>>;
  _not?: InputMaybe<Item_Images_Bool_Exp>;
  _or?: InputMaybe<Array<Item_Images_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  item?: InputMaybe<Items_Bool_Exp>;
  item_id?: InputMaybe<Uuid_Comparison_Exp>;
  mime_type?: InputMaybe<String_Comparison_Exp>;
  path?: InputMaybe<String_Comparison_Exp>;
  position?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "item_images" */
export enum Item_Images_Constraint {
  /** unique or primary key constraint on columns "id" */
  ItemImagesPkey = "item_images_pkey",
}

/** input type for incrementing numeric columns in table "item_images" */
export type Item_Images_Inc_Input = {
  position?: InputMaybe<Scalars["Int"]["input"]>;
};

/** input type for inserting data into table "item_images" */
export type Item_Images_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  item?: InputMaybe<Items_Obj_Rel_Insert_Input>;
  item_id?: InputMaybe<Scalars["uuid"]["input"]>;
  mime_type?: InputMaybe<Scalars["String"]["input"]>;
  path?: InputMaybe<Scalars["String"]["input"]>;
  position?: InputMaybe<Scalars["Int"]["input"]>;
};

/** aggregate max on columns */
export type Item_Images_Max_Fields = {
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  item_id?: Maybe<Scalars["uuid"]["output"]>;
  mime_type?: Maybe<Scalars["String"]["output"]>;
  path?: Maybe<Scalars["String"]["output"]>;
  position?: Maybe<Scalars["Int"]["output"]>;
};

/** order by max() on columns of table "item_images" */
export type Item_Images_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  mime_type?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Item_Images_Min_Fields = {
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  item_id?: Maybe<Scalars["uuid"]["output"]>;
  mime_type?: Maybe<Scalars["String"]["output"]>;
  path?: Maybe<Scalars["String"]["output"]>;
  position?: Maybe<Scalars["Int"]["output"]>;
};

/** order by min() on columns of table "item_images" */
export type Item_Images_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  mime_type?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "item_images" */
export type Item_Images_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Item_Images>;
};

/** on_conflict condition type for table "item_images" */
export type Item_Images_On_Conflict = {
  constraint: Item_Images_Constraint;
  update_columns?: Array<Item_Images_Update_Column>;
  where?: InputMaybe<Item_Images_Bool_Exp>;
};

/** Ordering options when selecting data from "item_images". */
export type Item_Images_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item?: InputMaybe<Items_Order_By>;
  item_id?: InputMaybe<Order_By>;
  mime_type?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
};

/** primary key columns input for table: item_images */
export type Item_Images_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "item_images" */
export enum Item_Images_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  ItemId = "item_id",
  /** column name */
  MimeType = "mime_type",
  /** column name */
  Path = "path",
  /** column name */
  Position = "position",
}

/** input type for updating data in table "item_images" */
export type Item_Images_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  item_id?: InputMaybe<Scalars["uuid"]["input"]>;
  mime_type?: InputMaybe<Scalars["String"]["input"]>;
  path?: InputMaybe<Scalars["String"]["input"]>;
  position?: InputMaybe<Scalars["Int"]["input"]>;
};

/** aggregate stddev on columns */
export type Item_Images_Stddev_Fields = {
  position?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "item_images" */
export type Item_Images_Stddev_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Item_Images_Stddev_Pop_Fields = {
  position?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev_pop() on columns of table "item_images" */
export type Item_Images_Stddev_Pop_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Item_Images_Stddev_Samp_Fields = {
  position?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev_samp() on columns of table "item_images" */
export type Item_Images_Stddev_Samp_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "item_images" */
export type Item_Images_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Item_Images_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Item_Images_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  item_id?: InputMaybe<Scalars["uuid"]["input"]>;
  mime_type?: InputMaybe<Scalars["String"]["input"]>;
  path?: InputMaybe<Scalars["String"]["input"]>;
  position?: InputMaybe<Scalars["Int"]["input"]>;
};

/** aggregate sum on columns */
export type Item_Images_Sum_Fields = {
  position?: Maybe<Scalars["Int"]["output"]>;
};

/** order by sum() on columns of table "item_images" */
export type Item_Images_Sum_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** update columns of table "item_images" */
export enum Item_Images_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  ItemId = "item_id",
  /** column name */
  MimeType = "mime_type",
  /** column name */
  Path = "path",
  /** column name */
  Position = "position",
}

export type Item_Images_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Item_Images_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Item_Images_Set_Input>;
  /** filter the rows which have to be updated */
  where: Item_Images_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Item_Images_Var_Pop_Fields = {
  position?: Maybe<Scalars["Float"]["output"]>;
};

/** order by var_pop() on columns of table "item_images" */
export type Item_Images_Var_Pop_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Item_Images_Var_Samp_Fields = {
  position?: Maybe<Scalars["Float"]["output"]>;
};

/** order by var_samp() on columns of table "item_images" */
export type Item_Images_Var_Samp_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Item_Images_Variance_Fields = {
  position?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "item_images" */
export type Item_Images_Variance_Order_By = {
  position?: InputMaybe<Order_By>;
};

/** 購入を検知した履歴 */
export type Item_Sold_Histories = {
  created_at: Scalars["timestamptz"]["output"];
  id: Scalars["uuid"]["output"];
  /** An object relationship */
  item: Items;
  item_id: Scalars["uuid"]["output"];
  /** An object relationship */
  item_variant?: Maybe<Item_Variants>;
  item_variant_id?: Maybe<Scalars["uuid"]["output"]>;
  platform: Scalars["String"]["output"];
  stock_quantity: Scalars["Int"]["output"];
  updated_at: Scalars["timestamptz"]["output"];
};

/** aggregated selection of "item_sold_histories" */
export type Item_Sold_Histories_Aggregate = {
  aggregate?: Maybe<Item_Sold_Histories_Aggregate_Fields>;
  nodes: Array<Item_Sold_Histories>;
};

export type Item_Sold_Histories_Aggregate_Bool_Exp = {
  count?: InputMaybe<Item_Sold_Histories_Aggregate_Bool_Exp_Count>;
};

export type Item_Sold_Histories_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Item_Sold_Histories_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Item_Sold_Histories_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "item_sold_histories" */
export type Item_Sold_Histories_Aggregate_Fields = {
  avg?: Maybe<Item_Sold_Histories_Avg_Fields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<Item_Sold_Histories_Max_Fields>;
  min?: Maybe<Item_Sold_Histories_Min_Fields>;
  stddev?: Maybe<Item_Sold_Histories_Stddev_Fields>;
  stddev_pop?: Maybe<Item_Sold_Histories_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Item_Sold_Histories_Stddev_Samp_Fields>;
  sum?: Maybe<Item_Sold_Histories_Sum_Fields>;
  var_pop?: Maybe<Item_Sold_Histories_Var_Pop_Fields>;
  var_samp?: Maybe<Item_Sold_Histories_Var_Samp_Fields>;
  variance?: Maybe<Item_Sold_Histories_Variance_Fields>;
};

/** aggregate fields of "item_sold_histories" */
export type Item_Sold_Histories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Item_Sold_Histories_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "item_sold_histories" */
export type Item_Sold_Histories_Aggregate_Order_By = {
  avg?: InputMaybe<Item_Sold_Histories_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Item_Sold_Histories_Max_Order_By>;
  min?: InputMaybe<Item_Sold_Histories_Min_Order_By>;
  stddev?: InputMaybe<Item_Sold_Histories_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Item_Sold_Histories_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Item_Sold_Histories_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Item_Sold_Histories_Sum_Order_By>;
  var_pop?: InputMaybe<Item_Sold_Histories_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Item_Sold_Histories_Var_Samp_Order_By>;
  variance?: InputMaybe<Item_Sold_Histories_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "item_sold_histories" */
export type Item_Sold_Histories_Arr_Rel_Insert_Input = {
  data: Array<Item_Sold_Histories_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Item_Sold_Histories_On_Conflict>;
};

/** aggregate avg on columns */
export type Item_Sold_Histories_Avg_Fields = {
  stock_quantity?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "item_sold_histories" */
export type Item_Sold_Histories_Avg_Order_By = {
  stock_quantity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "item_sold_histories". All fields are combined with a logical 'AND'. */
export type Item_Sold_Histories_Bool_Exp = {
  _and?: InputMaybe<Array<Item_Sold_Histories_Bool_Exp>>;
  _not?: InputMaybe<Item_Sold_Histories_Bool_Exp>;
  _or?: InputMaybe<Array<Item_Sold_Histories_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  item?: InputMaybe<Items_Bool_Exp>;
  item_id?: InputMaybe<Uuid_Comparison_Exp>;
  item_variant?: InputMaybe<Item_Variants_Bool_Exp>;
  item_variant_id?: InputMaybe<Uuid_Comparison_Exp>;
  platform?: InputMaybe<String_Comparison_Exp>;
  stock_quantity?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "item_sold_histories" */
export enum Item_Sold_Histories_Constraint {
  /** unique or primary key constraint on columns "id" */
  ItemSoldHistoriesPkey = "item_sold_histories_pkey",
}

/** input type for incrementing numeric columns in table "item_sold_histories" */
export type Item_Sold_Histories_Inc_Input = {
  stock_quantity?: InputMaybe<Scalars["Int"]["input"]>;
};

/** input type for inserting data into table "item_sold_histories" */
export type Item_Sold_Histories_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  item?: InputMaybe<Items_Obj_Rel_Insert_Input>;
  item_id?: InputMaybe<Scalars["uuid"]["input"]>;
  item_variant?: InputMaybe<Item_Variants_Obj_Rel_Insert_Input>;
  item_variant_id?: InputMaybe<Scalars["uuid"]["input"]>;
  platform?: InputMaybe<Scalars["String"]["input"]>;
  stock_quantity?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate max on columns */
export type Item_Sold_Histories_Max_Fields = {
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  item_id?: Maybe<Scalars["uuid"]["output"]>;
  item_variant_id?: Maybe<Scalars["uuid"]["output"]>;
  platform?: Maybe<Scalars["String"]["output"]>;
  stock_quantity?: Maybe<Scalars["Int"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by max() on columns of table "item_sold_histories" */
export type Item_Sold_Histories_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_variant_id?: InputMaybe<Order_By>;
  platform?: InputMaybe<Order_By>;
  stock_quantity?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Item_Sold_Histories_Min_Fields = {
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  item_id?: Maybe<Scalars["uuid"]["output"]>;
  item_variant_id?: Maybe<Scalars["uuid"]["output"]>;
  platform?: Maybe<Scalars["String"]["output"]>;
  stock_quantity?: Maybe<Scalars["Int"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by min() on columns of table "item_sold_histories" */
export type Item_Sold_Histories_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_variant_id?: InputMaybe<Order_By>;
  platform?: InputMaybe<Order_By>;
  stock_quantity?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "item_sold_histories" */
export type Item_Sold_Histories_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Item_Sold_Histories>;
};

/** on_conflict condition type for table "item_sold_histories" */
export type Item_Sold_Histories_On_Conflict = {
  constraint: Item_Sold_Histories_Constraint;
  update_columns?: Array<Item_Sold_Histories_Update_Column>;
  where?: InputMaybe<Item_Sold_Histories_Bool_Exp>;
};

/** Ordering options when selecting data from "item_sold_histories". */
export type Item_Sold_Histories_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item?: InputMaybe<Items_Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_variant?: InputMaybe<Item_Variants_Order_By>;
  item_variant_id?: InputMaybe<Order_By>;
  platform?: InputMaybe<Order_By>;
  stock_quantity?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: item_sold_histories */
export type Item_Sold_Histories_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "item_sold_histories" */
export enum Item_Sold_Histories_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  ItemId = "item_id",
  /** column name */
  ItemVariantId = "item_variant_id",
  /** column name */
  Platform = "platform",
  /** column name */
  StockQuantity = "stock_quantity",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "item_sold_histories" */
export type Item_Sold_Histories_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  item_id?: InputMaybe<Scalars["uuid"]["input"]>;
  item_variant_id?: InputMaybe<Scalars["uuid"]["input"]>;
  platform?: InputMaybe<Scalars["String"]["input"]>;
  stock_quantity?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate stddev on columns */
export type Item_Sold_Histories_Stddev_Fields = {
  stock_quantity?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "item_sold_histories" */
export type Item_Sold_Histories_Stddev_Order_By = {
  stock_quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Item_Sold_Histories_Stddev_Pop_Fields = {
  stock_quantity?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev_pop() on columns of table "item_sold_histories" */
export type Item_Sold_Histories_Stddev_Pop_Order_By = {
  stock_quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Item_Sold_Histories_Stddev_Samp_Fields = {
  stock_quantity?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev_samp() on columns of table "item_sold_histories" */
export type Item_Sold_Histories_Stddev_Samp_Order_By = {
  stock_quantity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "item_sold_histories" */
export type Item_Sold_Histories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Item_Sold_Histories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Item_Sold_Histories_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  item_id?: InputMaybe<Scalars["uuid"]["input"]>;
  item_variant_id?: InputMaybe<Scalars["uuid"]["input"]>;
  platform?: InputMaybe<Scalars["String"]["input"]>;
  stock_quantity?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate sum on columns */
export type Item_Sold_Histories_Sum_Fields = {
  stock_quantity?: Maybe<Scalars["Int"]["output"]>;
};

/** order by sum() on columns of table "item_sold_histories" */
export type Item_Sold_Histories_Sum_Order_By = {
  stock_quantity?: InputMaybe<Order_By>;
};

/** update columns of table "item_sold_histories" */
export enum Item_Sold_Histories_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  ItemId = "item_id",
  /** column name */
  ItemVariantId = "item_variant_id",
  /** column name */
  Platform = "platform",
  /** column name */
  StockQuantity = "stock_quantity",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Item_Sold_Histories_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Item_Sold_Histories_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Item_Sold_Histories_Set_Input>;
  /** filter the rows which have to be updated */
  where: Item_Sold_Histories_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Item_Sold_Histories_Var_Pop_Fields = {
  stock_quantity?: Maybe<Scalars["Float"]["output"]>;
};

/** order by var_pop() on columns of table "item_sold_histories" */
export type Item_Sold_Histories_Var_Pop_Order_By = {
  stock_quantity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Item_Sold_Histories_Var_Samp_Fields = {
  stock_quantity?: Maybe<Scalars["Float"]["output"]>;
};

/** order by var_samp() on columns of table "item_sold_histories" */
export type Item_Sold_Histories_Var_Samp_Order_By = {
  stock_quantity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Item_Sold_Histories_Variance_Fields = {
  stock_quantity?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "item_sold_histories" */
export type Item_Sold_Histories_Variance_Order_By = {
  stock_quantity?: InputMaybe<Order_By>;
};

/** 商品の出品状態 */
export type Item_Statuses = {
  key: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

/** aggregated selection of "item_statuses" */
export type Item_Statuses_Aggregate = {
  aggregate?: Maybe<Item_Statuses_Aggregate_Fields>;
  nodes: Array<Item_Statuses>;
};

/** aggregate fields of "item_statuses" */
export type Item_Statuses_Aggregate_Fields = {
  count: Scalars["Int"]["output"];
  max?: Maybe<Item_Statuses_Max_Fields>;
  min?: Maybe<Item_Statuses_Min_Fields>;
};

/** aggregate fields of "item_statuses" */
export type Item_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Item_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Boolean expression to filter rows from the table "item_statuses". All fields are combined with a logical 'AND'. */
export type Item_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Item_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Item_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Item_Statuses_Bool_Exp>>;
  key?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "item_statuses" */
export enum Item_Statuses_Constraint {
  /** unique or primary key constraint on columns "key" */
  ItemStatusesPkey = "item_statuses_pkey",
}

/** input type for inserting data into table "item_statuses" */
export type Item_Statuses_Insert_Input = {
  key?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type Item_Statuses_Max_Fields = {
  key?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type Item_Statuses_Min_Fields = {
  key?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "item_statuses" */
export type Item_Statuses_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Item_Statuses>;
};

/** input type for inserting object relation for remote table "item_statuses" */
export type Item_Statuses_Obj_Rel_Insert_Input = {
  data: Item_Statuses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Item_Statuses_On_Conflict>;
};

/** on_conflict condition type for table "item_statuses" */
export type Item_Statuses_On_Conflict = {
  constraint: Item_Statuses_Constraint;
  update_columns?: Array<Item_Statuses_Update_Column>;
  where?: InputMaybe<Item_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "item_statuses". */
export type Item_Statuses_Order_By = {
  key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: item_statuses */
export type Item_Statuses_Pk_Columns_Input = {
  key: Scalars["String"]["input"];
};

/** select columns of table "item_statuses" */
export enum Item_Statuses_Select_Column {
  /** column name */
  Key = "key",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "item_statuses" */
export type Item_Statuses_Set_Input = {
  key?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** Streaming cursor of the table "item_statuses" */
export type Item_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Item_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Item_Statuses_Stream_Cursor_Value_Input = {
  key?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** update columns of table "item_statuses" */
export enum Item_Statuses_Update_Column {
  /** column name */
  Key = "key",
  /** column name */
  Name = "name",
}

export type Item_Statuses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Item_Statuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Item_Statuses_Bool_Exp;
};

/** item_variantと各プラットフォームの出品情報の関連 */
export type Item_Variant_Exhibitions = {
  created_at: Scalars["timestamptz"]["output"];
  /** An object relationship */
  exhibition: Exhibitions;
  exhibition_id: Scalars["uuid"]["output"];
  id: Scalars["uuid"]["output"];
  /** An object relationship */
  item_variant: Item_Variants;
  item_variant_id: Scalars["uuid"]["output"];
  platform_variant_id: Scalars["String"]["output"];
  updated_at: Scalars["timestamptz"]["output"];
};

/** aggregated selection of "item_variant_exhibitions" */
export type Item_Variant_Exhibitions_Aggregate = {
  aggregate?: Maybe<Item_Variant_Exhibitions_Aggregate_Fields>;
  nodes: Array<Item_Variant_Exhibitions>;
};

export type Item_Variant_Exhibitions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Item_Variant_Exhibitions_Aggregate_Bool_Exp_Count>;
};

export type Item_Variant_Exhibitions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Item_Variant_Exhibitions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Item_Variant_Exhibitions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "item_variant_exhibitions" */
export type Item_Variant_Exhibitions_Aggregate_Fields = {
  count: Scalars["Int"]["output"];
  max?: Maybe<Item_Variant_Exhibitions_Max_Fields>;
  min?: Maybe<Item_Variant_Exhibitions_Min_Fields>;
};

/** aggregate fields of "item_variant_exhibitions" */
export type Item_Variant_Exhibitions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Item_Variant_Exhibitions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "item_variant_exhibitions" */
export type Item_Variant_Exhibitions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Item_Variant_Exhibitions_Max_Order_By>;
  min?: InputMaybe<Item_Variant_Exhibitions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "item_variant_exhibitions" */
export type Item_Variant_Exhibitions_Arr_Rel_Insert_Input = {
  data: Array<Item_Variant_Exhibitions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Item_Variant_Exhibitions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "item_variant_exhibitions". All fields are combined with a logical 'AND'. */
export type Item_Variant_Exhibitions_Bool_Exp = {
  _and?: InputMaybe<Array<Item_Variant_Exhibitions_Bool_Exp>>;
  _not?: InputMaybe<Item_Variant_Exhibitions_Bool_Exp>;
  _or?: InputMaybe<Array<Item_Variant_Exhibitions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  exhibition?: InputMaybe<Exhibitions_Bool_Exp>;
  exhibition_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  item_variant?: InputMaybe<Item_Variants_Bool_Exp>;
  item_variant_id?: InputMaybe<Uuid_Comparison_Exp>;
  platform_variant_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "item_variant_exhibitions" */
export enum Item_Variant_Exhibitions_Constraint {
  /** unique or primary key constraint on columns "exhibition_id", "item_variant_id" */
  ItemVariantExhibitionsItemVariantIdExhibitionIdKey = "item_variant_exhibitions_item_variant_id_exhibition_id_key",
  /** unique or primary key constraint on columns "id" */
  ItemVariantExhibitionsPkey = "item_variant_exhibitions_pkey",
}

/** input type for inserting data into table "item_variant_exhibitions" */
export type Item_Variant_Exhibitions_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  exhibition?: InputMaybe<Exhibitions_Obj_Rel_Insert_Input>;
  exhibition_id?: InputMaybe<Scalars["uuid"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  item_variant?: InputMaybe<Item_Variants_Obj_Rel_Insert_Input>;
  item_variant_id?: InputMaybe<Scalars["uuid"]["input"]>;
  platform_variant_id?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate max on columns */
export type Item_Variant_Exhibitions_Max_Fields = {
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  exhibition_id?: Maybe<Scalars["uuid"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  item_variant_id?: Maybe<Scalars["uuid"]["output"]>;
  platform_variant_id?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by max() on columns of table "item_variant_exhibitions" */
export type Item_Variant_Exhibitions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  exhibition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_variant_id?: InputMaybe<Order_By>;
  platform_variant_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Item_Variant_Exhibitions_Min_Fields = {
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  exhibition_id?: Maybe<Scalars["uuid"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  item_variant_id?: Maybe<Scalars["uuid"]["output"]>;
  platform_variant_id?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by min() on columns of table "item_variant_exhibitions" */
export type Item_Variant_Exhibitions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  exhibition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_variant_id?: InputMaybe<Order_By>;
  platform_variant_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "item_variant_exhibitions" */
export type Item_Variant_Exhibitions_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Item_Variant_Exhibitions>;
};

/** on_conflict condition type for table "item_variant_exhibitions" */
export type Item_Variant_Exhibitions_On_Conflict = {
  constraint: Item_Variant_Exhibitions_Constraint;
  update_columns?: Array<Item_Variant_Exhibitions_Update_Column>;
  where?: InputMaybe<Item_Variant_Exhibitions_Bool_Exp>;
};

/** Ordering options when selecting data from "item_variant_exhibitions". */
export type Item_Variant_Exhibitions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  exhibition?: InputMaybe<Exhibitions_Order_By>;
  exhibition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_variant?: InputMaybe<Item_Variants_Order_By>;
  item_variant_id?: InputMaybe<Order_By>;
  platform_variant_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: item_variant_exhibitions */
export type Item_Variant_Exhibitions_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "item_variant_exhibitions" */
export enum Item_Variant_Exhibitions_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExhibitionId = "exhibition_id",
  /** column name */
  Id = "id",
  /** column name */
  ItemVariantId = "item_variant_id",
  /** column name */
  PlatformVariantId = "platform_variant_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "item_variant_exhibitions" */
export type Item_Variant_Exhibitions_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  exhibition_id?: InputMaybe<Scalars["uuid"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  item_variant_id?: InputMaybe<Scalars["uuid"]["input"]>;
  platform_variant_id?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** Streaming cursor of the table "item_variant_exhibitions" */
export type Item_Variant_Exhibitions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Item_Variant_Exhibitions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Item_Variant_Exhibitions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  exhibition_id?: InputMaybe<Scalars["uuid"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  item_variant_id?: InputMaybe<Scalars["uuid"]["input"]>;
  platform_variant_id?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** update columns of table "item_variant_exhibitions" */
export enum Item_Variant_Exhibitions_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExhibitionId = "exhibition_id",
  /** column name */
  Id = "id",
  /** column name */
  ItemVariantId = "item_variant_id",
  /** column name */
  PlatformVariantId = "platform_variant_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Item_Variant_Exhibitions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Item_Variant_Exhibitions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Item_Variant_Exhibitions_Bool_Exp;
};

/** 商品の種別 */
export type Item_Variants = {
  created_at: Scalars["timestamptz"]["output"];
  /** An array relationship */
  exhibitions: Array<Item_Variant_Exhibitions>;
  /** An aggregate relationship */
  exhibitions_aggregate: Item_Variant_Exhibitions_Aggregate;
  id: Scalars["uuid"]["output"];
  /** An object relationship */
  item: Items;
  item_id: Scalars["uuid"]["output"];
  name: Scalars["String"]["output"];
  position: Scalars["Int"]["output"];
  sku: Scalars["String"]["output"];
  stock_quantity: Scalars["Int"]["output"];
  updated_at: Scalars["timestamptz"]["output"];
};

/** 商品の種別 */
export type Item_VariantsExhibitionsArgs = {
  distinct_on?: InputMaybe<Array<Item_Variant_Exhibitions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Item_Variant_Exhibitions_Order_By>>;
  where?: InputMaybe<Item_Variant_Exhibitions_Bool_Exp>;
};

/** 商品の種別 */
export type Item_VariantsExhibitions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Item_Variant_Exhibitions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Item_Variant_Exhibitions_Order_By>>;
  where?: InputMaybe<Item_Variant_Exhibitions_Bool_Exp>;
};

/** aggregated selection of "item_variants" */
export type Item_Variants_Aggregate = {
  aggregate?: Maybe<Item_Variants_Aggregate_Fields>;
  nodes: Array<Item_Variants>;
};

export type Item_Variants_Aggregate_Bool_Exp = {
  count?: InputMaybe<Item_Variants_Aggregate_Bool_Exp_Count>;
};

export type Item_Variants_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Item_Variants_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Item_Variants_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "item_variants" */
export type Item_Variants_Aggregate_Fields = {
  avg?: Maybe<Item_Variants_Avg_Fields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<Item_Variants_Max_Fields>;
  min?: Maybe<Item_Variants_Min_Fields>;
  stddev?: Maybe<Item_Variants_Stddev_Fields>;
  stddev_pop?: Maybe<Item_Variants_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Item_Variants_Stddev_Samp_Fields>;
  sum?: Maybe<Item_Variants_Sum_Fields>;
  var_pop?: Maybe<Item_Variants_Var_Pop_Fields>;
  var_samp?: Maybe<Item_Variants_Var_Samp_Fields>;
  variance?: Maybe<Item_Variants_Variance_Fields>;
};

/** aggregate fields of "item_variants" */
export type Item_Variants_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Item_Variants_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "item_variants" */
export type Item_Variants_Aggregate_Order_By = {
  avg?: InputMaybe<Item_Variants_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Item_Variants_Max_Order_By>;
  min?: InputMaybe<Item_Variants_Min_Order_By>;
  stddev?: InputMaybe<Item_Variants_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Item_Variants_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Item_Variants_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Item_Variants_Sum_Order_By>;
  var_pop?: InputMaybe<Item_Variants_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Item_Variants_Var_Samp_Order_By>;
  variance?: InputMaybe<Item_Variants_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "item_variants" */
export type Item_Variants_Arr_Rel_Insert_Input = {
  data: Array<Item_Variants_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Item_Variants_On_Conflict>;
};

/** aggregate avg on columns */
export type Item_Variants_Avg_Fields = {
  position?: Maybe<Scalars["Float"]["output"]>;
  stock_quantity?: Maybe<Scalars["Float"]["output"]>;
};

/** order by avg() on columns of table "item_variants" */
export type Item_Variants_Avg_Order_By = {
  position?: InputMaybe<Order_By>;
  stock_quantity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "item_variants". All fields are combined with a logical 'AND'. */
export type Item_Variants_Bool_Exp = {
  _and?: InputMaybe<Array<Item_Variants_Bool_Exp>>;
  _not?: InputMaybe<Item_Variants_Bool_Exp>;
  _or?: InputMaybe<Array<Item_Variants_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  exhibitions?: InputMaybe<Item_Variant_Exhibitions_Bool_Exp>;
  exhibitions_aggregate?: InputMaybe<Item_Variant_Exhibitions_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  item?: InputMaybe<Items_Bool_Exp>;
  item_id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  position?: InputMaybe<Int_Comparison_Exp>;
  sku?: InputMaybe<String_Comparison_Exp>;
  stock_quantity?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "item_variants" */
export enum Item_Variants_Constraint {
  /** unique or primary key constraint on columns "item_id", "name" */
  ItemVariantsItemIdNameKey = "item_variants_item_id_name_key",
  /** unique or primary key constraint on columns "id" */
  ItemVariantsPkey = "item_variants_pkey",
}

/** input type for incrementing numeric columns in table "item_variants" */
export type Item_Variants_Inc_Input = {
  position?: InputMaybe<Scalars["Int"]["input"]>;
  stock_quantity?: InputMaybe<Scalars["Int"]["input"]>;
};

/** input type for inserting data into table "item_variants" */
export type Item_Variants_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  exhibitions?: InputMaybe<Item_Variant_Exhibitions_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  item?: InputMaybe<Items_Obj_Rel_Insert_Input>;
  item_id?: InputMaybe<Scalars["uuid"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  position?: InputMaybe<Scalars["Int"]["input"]>;
  sku?: InputMaybe<Scalars["String"]["input"]>;
  stock_quantity?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate max on columns */
export type Item_Variants_Max_Fields = {
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  item_id?: Maybe<Scalars["uuid"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  position?: Maybe<Scalars["Int"]["output"]>;
  sku?: Maybe<Scalars["String"]["output"]>;
  stock_quantity?: Maybe<Scalars["Int"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by max() on columns of table "item_variants" */
export type Item_Variants_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  stock_quantity?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Item_Variants_Min_Fields = {
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  item_id?: Maybe<Scalars["uuid"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  position?: Maybe<Scalars["Int"]["output"]>;
  sku?: Maybe<Scalars["String"]["output"]>;
  stock_quantity?: Maybe<Scalars["Int"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by min() on columns of table "item_variants" */
export type Item_Variants_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  stock_quantity?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "item_variants" */
export type Item_Variants_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Item_Variants>;
};

/** input type for inserting object relation for remote table "item_variants" */
export type Item_Variants_Obj_Rel_Insert_Input = {
  data: Item_Variants_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Item_Variants_On_Conflict>;
};

/** on_conflict condition type for table "item_variants" */
export type Item_Variants_On_Conflict = {
  constraint: Item_Variants_Constraint;
  update_columns?: Array<Item_Variants_Update_Column>;
  where?: InputMaybe<Item_Variants_Bool_Exp>;
};

/** Ordering options when selecting data from "item_variants". */
export type Item_Variants_Order_By = {
  created_at?: InputMaybe<Order_By>;
  exhibitions_aggregate?: InputMaybe<Item_Variant_Exhibitions_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  item?: InputMaybe<Items_Order_By>;
  item_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  stock_quantity?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: item_variants */
export type Item_Variants_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "item_variants" */
export enum Item_Variants_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  ItemId = "item_id",
  /** column name */
  Name = "name",
  /** column name */
  Position = "position",
  /** column name */
  Sku = "sku",
  /** column name */
  StockQuantity = "stock_quantity",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "item_variants" */
export type Item_Variants_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  item_id?: InputMaybe<Scalars["uuid"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  position?: InputMaybe<Scalars["Int"]["input"]>;
  sku?: InputMaybe<Scalars["String"]["input"]>;
  stock_quantity?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate stddev on columns */
export type Item_Variants_Stddev_Fields = {
  position?: Maybe<Scalars["Float"]["output"]>;
  stock_quantity?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev() on columns of table "item_variants" */
export type Item_Variants_Stddev_Order_By = {
  position?: InputMaybe<Order_By>;
  stock_quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Item_Variants_Stddev_Pop_Fields = {
  position?: Maybe<Scalars["Float"]["output"]>;
  stock_quantity?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev_pop() on columns of table "item_variants" */
export type Item_Variants_Stddev_Pop_Order_By = {
  position?: InputMaybe<Order_By>;
  stock_quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Item_Variants_Stddev_Samp_Fields = {
  position?: Maybe<Scalars["Float"]["output"]>;
  stock_quantity?: Maybe<Scalars["Float"]["output"]>;
};

/** order by stddev_samp() on columns of table "item_variants" */
export type Item_Variants_Stddev_Samp_Order_By = {
  position?: InputMaybe<Order_By>;
  stock_quantity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "item_variants" */
export type Item_Variants_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Item_Variants_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Item_Variants_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  item_id?: InputMaybe<Scalars["uuid"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  position?: InputMaybe<Scalars["Int"]["input"]>;
  sku?: InputMaybe<Scalars["String"]["input"]>;
  stock_quantity?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate sum on columns */
export type Item_Variants_Sum_Fields = {
  position?: Maybe<Scalars["Int"]["output"]>;
  stock_quantity?: Maybe<Scalars["Int"]["output"]>;
};

/** order by sum() on columns of table "item_variants" */
export type Item_Variants_Sum_Order_By = {
  position?: InputMaybe<Order_By>;
  stock_quantity?: InputMaybe<Order_By>;
};

/** update columns of table "item_variants" */
export enum Item_Variants_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  ItemId = "item_id",
  /** column name */
  Name = "name",
  /** column name */
  Position = "position",
  /** column name */
  Sku = "sku",
  /** column name */
  StockQuantity = "stock_quantity",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Item_Variants_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Item_Variants_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Item_Variants_Set_Input>;
  /** filter the rows which have to be updated */
  where: Item_Variants_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Item_Variants_Var_Pop_Fields = {
  position?: Maybe<Scalars["Float"]["output"]>;
  stock_quantity?: Maybe<Scalars["Float"]["output"]>;
};

/** order by var_pop() on columns of table "item_variants" */
export type Item_Variants_Var_Pop_Order_By = {
  position?: InputMaybe<Order_By>;
  stock_quantity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Item_Variants_Var_Samp_Fields = {
  position?: Maybe<Scalars["Float"]["output"]>;
  stock_quantity?: Maybe<Scalars["Float"]["output"]>;
};

/** order by var_samp() on columns of table "item_variants" */
export type Item_Variants_Var_Samp_Order_By = {
  position?: InputMaybe<Order_By>;
  stock_quantity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Item_Variants_Variance_Fields = {
  position?: Maybe<Scalars["Float"]["output"]>;
  stock_quantity?: Maybe<Scalars["Float"]["output"]>;
};

/** order by variance() on columns of table "item_variants" */
export type Item_Variants_Variance_Order_By = {
  position?: InputMaybe<Order_By>;
  stock_quantity?: InputMaybe<Order_By>;
};

/** 商品テーブル */
export type Items = {
  /** An object relationship */
  brand?: Maybe<Brands>;
  brand_id?: Maybe<Scalars["uuid"]["output"]>;
  compare_at_price?: Maybe<Scalars["Int"]["output"]>;
  condition?: Maybe<Scalars["String"]["output"]>;
  cost?: Maybe<Scalars["Int"]["output"]>;
  created_at: Scalars["timestamptz"]["output"];
  description: Scalars["String"]["output"];
  /** An array relationship */
  exhibitions: Array<Exhibitions>;
  /** An aggregate relationship */
  exhibitions_aggregate: Exhibitions_Aggregate;
  id: Scalars["uuid"]["output"];
  /** An array relationship */
  images: Array<Item_Images>;
  /** An aggregate relationship */
  images_aggregate: Item_Images_Aggregate;
  /** An array relationship */
  import_exhibitions: Array<Import_Exhibitions>;
  /** An aggregate relationship */
  import_exhibitions_aggregate: Import_Exhibitions_Aggregate;
  /** An object relationship */
  item_condition?: Maybe<Item_Conditions>;
  /** An object relationship */
  item_status: Item_Statuses;
  name: Scalars["String"]["output"];
  price: Scalars["Int"]["output"];
  sale_starts_at?: Maybe<Scalars["timestamptz"]["output"]>;
  /** An object relationship */
  shop: Shops;
  shop_id: Scalars["uuid"]["output"];
  /** An array relationship */
  sold_histories: Array<Item_Sold_Histories>;
  /** An aggregate relationship */
  sold_histories_aggregate: Item_Sold_Histories_Aggregate;
  status: Scalars["String"]["output"];
  updated_at: Scalars["timestamptz"]["output"];
  /** An array relationship */
  variants: Array<Item_Variants>;
  /** An aggregate relationship */
  variants_aggregate: Item_Variants_Aggregate;
};

/** 商品テーブル */
export type ItemsExhibitionsArgs = {
  distinct_on?: InputMaybe<Array<Exhibitions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Exhibitions_Order_By>>;
  where?: InputMaybe<Exhibitions_Bool_Exp>;
};

/** 商品テーブル */
export type ItemsExhibitions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Exhibitions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Exhibitions_Order_By>>;
  where?: InputMaybe<Exhibitions_Bool_Exp>;
};

/** 商品テーブル */
export type ItemsImagesArgs = {
  distinct_on?: InputMaybe<Array<Item_Images_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Item_Images_Order_By>>;
  where?: InputMaybe<Item_Images_Bool_Exp>;
};

/** 商品テーブル */
export type ItemsImages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Item_Images_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Item_Images_Order_By>>;
  where?: InputMaybe<Item_Images_Bool_Exp>;
};

/** 商品テーブル */
export type ItemsImport_ExhibitionsArgs = {
  distinct_on?: InputMaybe<Array<Import_Exhibitions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Import_Exhibitions_Order_By>>;
  where?: InputMaybe<Import_Exhibitions_Bool_Exp>;
};

/** 商品テーブル */
export type ItemsImport_Exhibitions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Import_Exhibitions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Import_Exhibitions_Order_By>>;
  where?: InputMaybe<Import_Exhibitions_Bool_Exp>;
};

/** 商品テーブル */
export type ItemsSold_HistoriesArgs = {
  distinct_on?: InputMaybe<Array<Item_Sold_Histories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Item_Sold_Histories_Order_By>>;
  where?: InputMaybe<Item_Sold_Histories_Bool_Exp>;
};

/** 商品テーブル */
export type ItemsSold_Histories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Item_Sold_Histories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Item_Sold_Histories_Order_By>>;
  where?: InputMaybe<Item_Sold_Histories_Bool_Exp>;
};

/** 商品テーブル */
export type ItemsVariantsArgs = {
  distinct_on?: InputMaybe<Array<Item_Variants_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Item_Variants_Order_By>>;
  where?: InputMaybe<Item_Variants_Bool_Exp>;
};

/** 商品テーブル */
export type ItemsVariants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Item_Variants_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Item_Variants_Order_By>>;
  where?: InputMaybe<Item_Variants_Bool_Exp>;
};

/** aggregated selection of "items" */
export type Items_Aggregate = {
  aggregate?: Maybe<Items_Aggregate_Fields>;
  nodes: Array<Items>;
};

/** aggregate fields of "items" */
export type Items_Aggregate_Fields = {
  avg?: Maybe<Items_Avg_Fields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<Items_Max_Fields>;
  min?: Maybe<Items_Min_Fields>;
  stddev?: Maybe<Items_Stddev_Fields>;
  stddev_pop?: Maybe<Items_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Items_Stddev_Samp_Fields>;
  sum?: Maybe<Items_Sum_Fields>;
  var_pop?: Maybe<Items_Var_Pop_Fields>;
  var_samp?: Maybe<Items_Var_Samp_Fields>;
  variance?: Maybe<Items_Variance_Fields>;
};

/** aggregate fields of "items" */
export type Items_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Items_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type Items_Avg_Fields = {
  compare_at_price?: Maybe<Scalars["Float"]["output"]>;
  cost?: Maybe<Scalars["Float"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "items". All fields are combined with a logical 'AND'. */
export type Items_Bool_Exp = {
  _and?: InputMaybe<Array<Items_Bool_Exp>>;
  _not?: InputMaybe<Items_Bool_Exp>;
  _or?: InputMaybe<Array<Items_Bool_Exp>>;
  brand?: InputMaybe<Brands_Bool_Exp>;
  brand_id?: InputMaybe<Uuid_Comparison_Exp>;
  compare_at_price?: InputMaybe<Int_Comparison_Exp>;
  condition?: InputMaybe<String_Comparison_Exp>;
  cost?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  exhibitions?: InputMaybe<Exhibitions_Bool_Exp>;
  exhibitions_aggregate?: InputMaybe<Exhibitions_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  images?: InputMaybe<Item_Images_Bool_Exp>;
  images_aggregate?: InputMaybe<Item_Images_Aggregate_Bool_Exp>;
  import_exhibitions?: InputMaybe<Import_Exhibitions_Bool_Exp>;
  import_exhibitions_aggregate?: InputMaybe<Import_Exhibitions_Aggregate_Bool_Exp>;
  item_condition?: InputMaybe<Item_Conditions_Bool_Exp>;
  item_status?: InputMaybe<Item_Statuses_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  price?: InputMaybe<Int_Comparison_Exp>;
  sale_starts_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  shop?: InputMaybe<Shops_Bool_Exp>;
  shop_id?: InputMaybe<Uuid_Comparison_Exp>;
  sold_histories?: InputMaybe<Item_Sold_Histories_Bool_Exp>;
  sold_histories_aggregate?: InputMaybe<Item_Sold_Histories_Aggregate_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  variants?: InputMaybe<Item_Variants_Bool_Exp>;
  variants_aggregate?: InputMaybe<Item_Variants_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "items" */
export enum Items_Constraint {
  /** unique or primary key constraint on columns "id" */
  ItemsPkey = "items_pkey",
}

/** input type for incrementing numeric columns in table "items" */
export type Items_Inc_Input = {
  compare_at_price?: InputMaybe<Scalars["Int"]["input"]>;
  cost?: InputMaybe<Scalars["Int"]["input"]>;
  price?: InputMaybe<Scalars["Int"]["input"]>;
};

/** input type for inserting data into table "items" */
export type Items_Insert_Input = {
  brand?: InputMaybe<Brands_Obj_Rel_Insert_Input>;
  brand_id?: InputMaybe<Scalars["uuid"]["input"]>;
  compare_at_price?: InputMaybe<Scalars["Int"]["input"]>;
  condition?: InputMaybe<Scalars["String"]["input"]>;
  cost?: InputMaybe<Scalars["Int"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  exhibitions?: InputMaybe<Exhibitions_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  images?: InputMaybe<Item_Images_Arr_Rel_Insert_Input>;
  import_exhibitions?: InputMaybe<Import_Exhibitions_Arr_Rel_Insert_Input>;
  item_condition?: InputMaybe<Item_Conditions_Obj_Rel_Insert_Input>;
  item_status?: InputMaybe<Item_Statuses_Obj_Rel_Insert_Input>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  price?: InputMaybe<Scalars["Int"]["input"]>;
  sale_starts_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  shop?: InputMaybe<Shops_Obj_Rel_Insert_Input>;
  shop_id?: InputMaybe<Scalars["uuid"]["input"]>;
  sold_histories?: InputMaybe<Item_Sold_Histories_Arr_Rel_Insert_Input>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  variants?: InputMaybe<Item_Variants_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Items_Max_Fields = {
  brand_id?: Maybe<Scalars["uuid"]["output"]>;
  compare_at_price?: Maybe<Scalars["Int"]["output"]>;
  condition?: Maybe<Scalars["String"]["output"]>;
  cost?: Maybe<Scalars["Int"]["output"]>;
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<Scalars["Int"]["output"]>;
  sale_starts_at?: Maybe<Scalars["timestamptz"]["output"]>;
  shop_id?: Maybe<Scalars["uuid"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** aggregate min on columns */
export type Items_Min_Fields = {
  brand_id?: Maybe<Scalars["uuid"]["output"]>;
  compare_at_price?: Maybe<Scalars["Int"]["output"]>;
  condition?: Maybe<Scalars["String"]["output"]>;
  cost?: Maybe<Scalars["Int"]["output"]>;
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<Scalars["Int"]["output"]>;
  sale_starts_at?: Maybe<Scalars["timestamptz"]["output"]>;
  shop_id?: Maybe<Scalars["uuid"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** response of any mutation on the table "items" */
export type Items_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Items>;
};

/** input type for inserting object relation for remote table "items" */
export type Items_Obj_Rel_Insert_Input = {
  data: Items_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Items_On_Conflict>;
};

/** on_conflict condition type for table "items" */
export type Items_On_Conflict = {
  constraint: Items_Constraint;
  update_columns?: Array<Items_Update_Column>;
  where?: InputMaybe<Items_Bool_Exp>;
};

/** Ordering options when selecting data from "items". */
export type Items_Order_By = {
  brand?: InputMaybe<Brands_Order_By>;
  brand_id?: InputMaybe<Order_By>;
  compare_at_price?: InputMaybe<Order_By>;
  condition?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  exhibitions_aggregate?: InputMaybe<Exhibitions_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  images_aggregate?: InputMaybe<Item_Images_Aggregate_Order_By>;
  import_exhibitions_aggregate?: InputMaybe<Import_Exhibitions_Aggregate_Order_By>;
  item_condition?: InputMaybe<Item_Conditions_Order_By>;
  item_status?: InputMaybe<Item_Statuses_Order_By>;
  name?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  sale_starts_at?: InputMaybe<Order_By>;
  shop?: InputMaybe<Shops_Order_By>;
  shop_id?: InputMaybe<Order_By>;
  sold_histories_aggregate?: InputMaybe<Item_Sold_Histories_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  variants_aggregate?: InputMaybe<Item_Variants_Aggregate_Order_By>;
};

/** primary key columns input for table: items */
export type Items_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "items" */
export enum Items_Select_Column {
  /** column name */
  BrandId = "brand_id",
  /** column name */
  CompareAtPrice = "compare_at_price",
  /** column name */
  Condition = "condition",
  /** column name */
  Cost = "cost",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  Price = "price",
  /** column name */
  SaleStartsAt = "sale_starts_at",
  /** column name */
  ShopId = "shop_id",
  /** column name */
  Status = "status",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "items" */
export type Items_Set_Input = {
  brand_id?: InputMaybe<Scalars["uuid"]["input"]>;
  compare_at_price?: InputMaybe<Scalars["Int"]["input"]>;
  condition?: InputMaybe<Scalars["String"]["input"]>;
  cost?: InputMaybe<Scalars["Int"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  price?: InputMaybe<Scalars["Int"]["input"]>;
  sale_starts_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  shop_id?: InputMaybe<Scalars["uuid"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate stddev on columns */
export type Items_Stddev_Fields = {
  compare_at_price?: Maybe<Scalars["Float"]["output"]>;
  cost?: Maybe<Scalars["Float"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddev_pop on columns */
export type Items_Stddev_Pop_Fields = {
  compare_at_price?: Maybe<Scalars["Float"]["output"]>;
  cost?: Maybe<Scalars["Float"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddev_samp on columns */
export type Items_Stddev_Samp_Fields = {
  compare_at_price?: Maybe<Scalars["Float"]["output"]>;
  cost?: Maybe<Scalars["Float"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "items" */
export type Items_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Items_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Items_Stream_Cursor_Value_Input = {
  brand_id?: InputMaybe<Scalars["uuid"]["input"]>;
  compare_at_price?: InputMaybe<Scalars["Int"]["input"]>;
  condition?: InputMaybe<Scalars["String"]["input"]>;
  cost?: InputMaybe<Scalars["Int"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  price?: InputMaybe<Scalars["Int"]["input"]>;
  sale_starts_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  shop_id?: InputMaybe<Scalars["uuid"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate sum on columns */
export type Items_Sum_Fields = {
  compare_at_price?: Maybe<Scalars["Int"]["output"]>;
  cost?: Maybe<Scalars["Int"]["output"]>;
  price?: Maybe<Scalars["Int"]["output"]>;
};

/** update columns of table "items" */
export enum Items_Update_Column {
  /** column name */
  BrandId = "brand_id",
  /** column name */
  CompareAtPrice = "compare_at_price",
  /** column name */
  Condition = "condition",
  /** column name */
  Cost = "cost",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  Price = "price",
  /** column name */
  SaleStartsAt = "sale_starts_at",
  /** column name */
  ShopId = "shop_id",
  /** column name */
  Status = "status",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Items_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Items_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Items_Set_Input>;
  /** filter the rows which have to be updated */
  where: Items_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Items_Var_Pop_Fields = {
  compare_at_price?: Maybe<Scalars["Float"]["output"]>;
  cost?: Maybe<Scalars["Float"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate var_samp on columns */
export type Items_Var_Samp_Fields = {
  compare_at_price?: Maybe<Scalars["Float"]["output"]>;
  cost?: Maybe<Scalars["Float"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type Items_Variance_Fields = {
  compare_at_price?: Maybe<Scalars["Float"]["output"]>;
  cost?: Maybe<Scalars["Float"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars["jsonb"]["input"]>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars["jsonb"]["input"]>;
  _eq?: InputMaybe<Scalars["jsonb"]["input"]>;
  _gt?: InputMaybe<Scalars["jsonb"]["input"]>;
  _gte?: InputMaybe<Scalars["jsonb"]["input"]>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars["String"]["input"]>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars["String"]["input"]>>;
  _in?: InputMaybe<Array<Scalars["jsonb"]["input"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["jsonb"]["input"]>;
  _lte?: InputMaybe<Scalars["jsonb"]["input"]>;
  _neq?: InputMaybe<Scalars["jsonb"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["jsonb"]["input"]>>;
};

/** columns and relationships of "mercari_shops_categories" */
export type Mercari_Shops_Categories = {
  id: Scalars["String"]["output"];
  id_path: Scalars["String"]["output"];
  is_leaf: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  name_path: Scalars["String"]["output"];
  parent_id?: Maybe<Scalars["String"]["output"]>;
};

/** aggregated selection of "mercari_shops_categories" */
export type Mercari_Shops_Categories_Aggregate = {
  aggregate?: Maybe<Mercari_Shops_Categories_Aggregate_Fields>;
  nodes: Array<Mercari_Shops_Categories>;
};

/** aggregate fields of "mercari_shops_categories" */
export type Mercari_Shops_Categories_Aggregate_Fields = {
  count: Scalars["Int"]["output"];
  max?: Maybe<Mercari_Shops_Categories_Max_Fields>;
  min?: Maybe<Mercari_Shops_Categories_Min_Fields>;
};

/** aggregate fields of "mercari_shops_categories" */
export type Mercari_Shops_Categories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mercari_Shops_Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Boolean expression to filter rows from the table "mercari_shops_categories". All fields are combined with a logical 'AND'. */
export type Mercari_Shops_Categories_Bool_Exp = {
  _and?: InputMaybe<Array<Mercari_Shops_Categories_Bool_Exp>>;
  _not?: InputMaybe<Mercari_Shops_Categories_Bool_Exp>;
  _or?: InputMaybe<Array<Mercari_Shops_Categories_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  id_path?: InputMaybe<String_Comparison_Exp>;
  is_leaf?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  name_path?: InputMaybe<String_Comparison_Exp>;
  parent_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "mercari_shops_categories" */
export enum Mercari_Shops_Categories_Constraint {
  /** unique or primary key constraint on columns "id" */
  MercariShopsCategoriesPkey = "mercari_shops_categories_pkey",
}

/** input type for inserting data into table "mercari_shops_categories" */
export type Mercari_Shops_Categories_Insert_Input = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  id_path?: InputMaybe<Scalars["String"]["input"]>;
  is_leaf?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_path?: InputMaybe<Scalars["String"]["input"]>;
  parent_id?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type Mercari_Shops_Categories_Max_Fields = {
  id?: Maybe<Scalars["String"]["output"]>;
  id_path?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  name_path?: Maybe<Scalars["String"]["output"]>;
  parent_id?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type Mercari_Shops_Categories_Min_Fields = {
  id?: Maybe<Scalars["String"]["output"]>;
  id_path?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  name_path?: Maybe<Scalars["String"]["output"]>;
  parent_id?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "mercari_shops_categories" */
export type Mercari_Shops_Categories_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Mercari_Shops_Categories>;
};

/** on_conflict condition type for table "mercari_shops_categories" */
export type Mercari_Shops_Categories_On_Conflict = {
  constraint: Mercari_Shops_Categories_Constraint;
  update_columns?: Array<Mercari_Shops_Categories_Update_Column>;
  where?: InputMaybe<Mercari_Shops_Categories_Bool_Exp>;
};

/** Ordering options when selecting data from "mercari_shops_categories". */
export type Mercari_Shops_Categories_Order_By = {
  id?: InputMaybe<Order_By>;
  id_path?: InputMaybe<Order_By>;
  is_leaf?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  name_path?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mercari_shops_categories */
export type Mercari_Shops_Categories_Pk_Columns_Input = {
  id: Scalars["String"]["input"];
};

/** select columns of table "mercari_shops_categories" */
export enum Mercari_Shops_Categories_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  IdPath = "id_path",
  /** column name */
  IsLeaf = "is_leaf",
  /** column name */
  Name = "name",
  /** column name */
  NamePath = "name_path",
  /** column name */
  ParentId = "parent_id",
}

/** input type for updating data in table "mercari_shops_categories" */
export type Mercari_Shops_Categories_Set_Input = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  id_path?: InputMaybe<Scalars["String"]["input"]>;
  is_leaf?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_path?: InputMaybe<Scalars["String"]["input"]>;
  parent_id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Streaming cursor of the table "mercari_shops_categories" */
export type Mercari_Shops_Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mercari_Shops_Categories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mercari_Shops_Categories_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  id_path?: InputMaybe<Scalars["String"]["input"]>;
  is_leaf?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_path?: InputMaybe<Scalars["String"]["input"]>;
  parent_id?: InputMaybe<Scalars["String"]["input"]>;
};

/** update columns of table "mercari_shops_categories" */
export enum Mercari_Shops_Categories_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  IdPath = "id_path",
  /** column name */
  IsLeaf = "is_leaf",
  /** column name */
  Name = "name",
  /** column name */
  NamePath = "name_path",
  /** column name */
  ParentId = "parent_id",
}

export type Mercari_Shops_Categories_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mercari_Shops_Categories_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mercari_Shops_Categories_Bool_Exp;
};

/** mutation root */
export type Mutation_Root = {
  /** create_integration */
  createIntegration?: Maybe<CreateIntegrationOutput>;
  /** Create User and Payment */
  createUserAndPayment: CreateUserAndPaymentOutput;
  /** delete data from the table: "brand_mercari_shops_mappings" */
  delete_brand_mercari_shops_mappings?: Maybe<Brand_Mercari_Shops_Mappings_Mutation_Response>;
  /** delete single row from the table: "brand_mercari_shops_mappings" */
  delete_brand_mercari_shops_mappings_by_pk?: Maybe<Brand_Mercari_Shops_Mappings>;
  /** delete data from the table: "brands" */
  delete_brands?: Maybe<Brands_Mutation_Response>;
  /** delete single row from the table: "brands" */
  delete_brands_by_pk?: Maybe<Brands>;
  /** delete data from the table: "exhibition_base_categories" */
  delete_exhibition_base_categories?: Maybe<Exhibition_Base_Categories_Mutation_Response>;
  /** delete single row from the table: "exhibition_base_categories" */
  delete_exhibition_base_categories_by_pk?: Maybe<Exhibition_Base_Categories>;
  /** delete data from the table: "exhibition_mercari_shops_settings" */
  delete_exhibition_mercari_shops_settings?: Maybe<Exhibition_Mercari_Shops_Settings_Mutation_Response>;
  /** delete single row from the table: "exhibition_mercari_shops_settings" */
  delete_exhibition_mercari_shops_settings_by_pk?: Maybe<Exhibition_Mercari_Shops_Settings>;
  /** delete data from the table: "exhibition_shopify_settings" */
  delete_exhibition_shopify_settings?: Maybe<Exhibition_Shopify_Settings_Mutation_Response>;
  /** delete single row from the table: "exhibition_shopify_settings" */
  delete_exhibition_shopify_settings_by_pk?: Maybe<Exhibition_Shopify_Settings>;
  /** delete data from the table: "exhibition_statuses" */
  delete_exhibition_statuses?: Maybe<Exhibition_Statuses_Mutation_Response>;
  /** delete single row from the table: "exhibition_statuses" */
  delete_exhibition_statuses_by_pk?: Maybe<Exhibition_Statuses>;
  /** delete data from the table: "exhibition_stores_categories" */
  delete_exhibition_stores_categories?: Maybe<Exhibition_Stores_Categories_Mutation_Response>;
  /** delete single row from the table: "exhibition_stores_categories" */
  delete_exhibition_stores_categories_by_pk?: Maybe<Exhibition_Stores_Categories>;
  /** delete data from the table: "exhibition_yahoo_shopping_settings" */
  delete_exhibition_yahoo_shopping_settings?: Maybe<Exhibition_Yahoo_Shopping_Settings_Mutation_Response>;
  /** delete single row from the table: "exhibition_yahoo_shopping_settings" */
  delete_exhibition_yahoo_shopping_settings_by_pk?: Maybe<Exhibition_Yahoo_Shopping_Settings>;
  /** delete data from the table: "exhibitions" */
  delete_exhibitions?: Maybe<Exhibitions_Mutation_Response>;
  /** delete single row from the table: "exhibitions" */
  delete_exhibitions_by_pk?: Maybe<Exhibitions>;
  /** delete data from the table: "import_exhibitions" */
  delete_import_exhibitions?: Maybe<Import_Exhibitions_Mutation_Response>;
  /** delete single row from the table: "import_exhibitions" */
  delete_import_exhibitions_by_pk?: Maybe<Import_Exhibitions>;
  /** delete data from the table: "imports" */
  delete_imports?: Maybe<Imports_Mutation_Response>;
  /** delete single row from the table: "imports" */
  delete_imports_by_pk?: Maybe<Imports>;
  /** delete data from the table: "integration_shopify_settings" */
  delete_integration_shopify_settings?: Maybe<Integration_Shopify_Settings_Mutation_Response>;
  /** delete single row from the table: "integration_shopify_settings" */
  delete_integration_shopify_settings_by_pk?: Maybe<Integration_Shopify_Settings>;
  /** delete data from the table: "integration_yahoo_shopping_settings" */
  delete_integration_yahoo_shopping_settings?: Maybe<Integration_Yahoo_Shopping_Settings_Mutation_Response>;
  /** delete single row from the table: "integration_yahoo_shopping_settings" */
  delete_integration_yahoo_shopping_settings_by_pk?: Maybe<Integration_Yahoo_Shopping_Settings>;
  /** delete data from the table: "integrations" */
  delete_integrations?: Maybe<Integrations_Mutation_Response>;
  /** delete single row from the table: "integrations" */
  delete_integrations_by_pk?: Maybe<Integrations>;
  /** delete data from the table: "item_conditions" */
  delete_item_conditions?: Maybe<Item_Conditions_Mutation_Response>;
  /** delete single row from the table: "item_conditions" */
  delete_item_conditions_by_pk?: Maybe<Item_Conditions>;
  /** delete data from the table: "item_images" */
  delete_item_images?: Maybe<Item_Images_Mutation_Response>;
  /** delete single row from the table: "item_images" */
  delete_item_images_by_pk?: Maybe<Item_Images>;
  /** delete data from the table: "item_sold_histories" */
  delete_item_sold_histories?: Maybe<Item_Sold_Histories_Mutation_Response>;
  /** delete single row from the table: "item_sold_histories" */
  delete_item_sold_histories_by_pk?: Maybe<Item_Sold_Histories>;
  /** delete data from the table: "item_statuses" */
  delete_item_statuses?: Maybe<Item_Statuses_Mutation_Response>;
  /** delete single row from the table: "item_statuses" */
  delete_item_statuses_by_pk?: Maybe<Item_Statuses>;
  /** delete data from the table: "item_variant_exhibitions" */
  delete_item_variant_exhibitions?: Maybe<Item_Variant_Exhibitions_Mutation_Response>;
  /** delete single row from the table: "item_variant_exhibitions" */
  delete_item_variant_exhibitions_by_pk?: Maybe<Item_Variant_Exhibitions>;
  /** delete data from the table: "item_variants" */
  delete_item_variants?: Maybe<Item_Variants_Mutation_Response>;
  /** delete single row from the table: "item_variants" */
  delete_item_variants_by_pk?: Maybe<Item_Variants>;
  /** delete data from the table: "items" */
  delete_items?: Maybe<Items_Mutation_Response>;
  /** delete single row from the table: "items" */
  delete_items_by_pk?: Maybe<Items>;
  /** delete data from the table: "mercari_shops_categories" */
  delete_mercari_shops_categories?: Maybe<Mercari_Shops_Categories_Mutation_Response>;
  /** delete single row from the table: "mercari_shops_categories" */
  delete_mercari_shops_categories_by_pk?: Maybe<Mercari_Shops_Categories>;
  /** delete data from the table: "plans" */
  delete_plans?: Maybe<Plans_Mutation_Response>;
  /** delete single row from the table: "plans" */
  delete_plans_by_pk?: Maybe<Plans>;
  /** delete data from the table: "platforms" */
  delete_platforms?: Maybe<Platforms_Mutation_Response>;
  /** delete single row from the table: "platforms" */
  delete_platforms_by_pk?: Maybe<Platforms>;
  /** delete data from the table: "shop_mercari_shops_settings" */
  delete_shop_mercari_shops_settings?: Maybe<Shop_Mercari_Shops_Settings_Mutation_Response>;
  /** delete single row from the table: "shop_mercari_shops_settings" */
  delete_shop_mercari_shops_settings_by_pk?: Maybe<Shop_Mercari_Shops_Settings>;
  /** delete data from the table: "shop_staff" */
  delete_shop_staff?: Maybe<Shop_Staff_Mutation_Response>;
  /** delete single row from the table: "shop_staff" */
  delete_shop_staff_by_pk?: Maybe<Shop_Staff>;
  /** delete data from the table: "shop_staff_roles" */
  delete_shop_staff_roles?: Maybe<Shop_Staff_Roles_Mutation_Response>;
  /** delete single row from the table: "shop_staff_roles" */
  delete_shop_staff_roles_by_pk?: Maybe<Shop_Staff_Roles>;
  /** delete data from the table: "shop_statuses" */
  delete_shop_statuses?: Maybe<Shop_Statuses_Mutation_Response>;
  /** delete single row from the table: "shop_statuses" */
  delete_shop_statuses_by_pk?: Maybe<Shop_Statuses>;
  /** delete data from the table: "shop_yahoo_shopping_settings" */
  delete_shop_yahoo_shopping_settings?: Maybe<Shop_Yahoo_Shopping_Settings_Mutation_Response>;
  /** delete single row from the table: "shop_yahoo_shopping_settings" */
  delete_shop_yahoo_shopping_settings_by_pk?: Maybe<Shop_Yahoo_Shopping_Settings>;
  /** delete data from the table: "shops" */
  delete_shops?: Maybe<Shops_Mutation_Response>;
  /** delete single row from the table: "shops" */
  delete_shops_by_pk?: Maybe<Shops>;
  /** delete data from the table: "subscription_statuses" */
  delete_subscription_statuses?: Maybe<Subscription_Statuses_Mutation_Response>;
  /** delete single row from the table: "subscription_statuses" */
  delete_subscription_statuses_by_pk?: Maybe<Subscription_Statuses>;
  /** delete data from the table: "subscriptions" */
  delete_subscriptions?: Maybe<Subscriptions_Mutation_Response>;
  /** delete single row from the table: "subscriptions" */
  delete_subscriptions_by_pk?: Maybe<Subscriptions>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "yahoo_auction_categories" */
  delete_yahoo_auction_categories?: Maybe<Yahoo_Auction_Categories_Mutation_Response>;
  /** delete single row from the table: "yahoo_auction_categories" */
  delete_yahoo_auction_categories_by_pk?: Maybe<Yahoo_Auction_Categories>;
  fetch_base_orders: FetchBaseOrdersOutput;
  /** insert data into the table: "brand_mercari_shops_mappings" */
  insert_brand_mercari_shops_mappings?: Maybe<Brand_Mercari_Shops_Mappings_Mutation_Response>;
  /** insert a single row into the table: "brand_mercari_shops_mappings" */
  insert_brand_mercari_shops_mappings_one?: Maybe<Brand_Mercari_Shops_Mappings>;
  /** insert data into the table: "brands" */
  insert_brands?: Maybe<Brands_Mutation_Response>;
  /** insert a single row into the table: "brands" */
  insert_brands_one?: Maybe<Brands>;
  /** insert data into the table: "exhibition_base_categories" */
  insert_exhibition_base_categories?: Maybe<Exhibition_Base_Categories_Mutation_Response>;
  /** insert a single row into the table: "exhibition_base_categories" */
  insert_exhibition_base_categories_one?: Maybe<Exhibition_Base_Categories>;
  /** insert data into the table: "exhibition_mercari_shops_settings" */
  insert_exhibition_mercari_shops_settings?: Maybe<Exhibition_Mercari_Shops_Settings_Mutation_Response>;
  /** insert a single row into the table: "exhibition_mercari_shops_settings" */
  insert_exhibition_mercari_shops_settings_one?: Maybe<Exhibition_Mercari_Shops_Settings>;
  /** insert data into the table: "exhibition_shopify_settings" */
  insert_exhibition_shopify_settings?: Maybe<Exhibition_Shopify_Settings_Mutation_Response>;
  /** insert a single row into the table: "exhibition_shopify_settings" */
  insert_exhibition_shopify_settings_one?: Maybe<Exhibition_Shopify_Settings>;
  /** insert data into the table: "exhibition_statuses" */
  insert_exhibition_statuses?: Maybe<Exhibition_Statuses_Mutation_Response>;
  /** insert a single row into the table: "exhibition_statuses" */
  insert_exhibition_statuses_one?: Maybe<Exhibition_Statuses>;
  /** insert data into the table: "exhibition_stores_categories" */
  insert_exhibition_stores_categories?: Maybe<Exhibition_Stores_Categories_Mutation_Response>;
  /** insert a single row into the table: "exhibition_stores_categories" */
  insert_exhibition_stores_categories_one?: Maybe<Exhibition_Stores_Categories>;
  /** insert data into the table: "exhibition_yahoo_shopping_settings" */
  insert_exhibition_yahoo_shopping_settings?: Maybe<Exhibition_Yahoo_Shopping_Settings_Mutation_Response>;
  /** insert a single row into the table: "exhibition_yahoo_shopping_settings" */
  insert_exhibition_yahoo_shopping_settings_one?: Maybe<Exhibition_Yahoo_Shopping_Settings>;
  /** insert data into the table: "exhibitions" */
  insert_exhibitions?: Maybe<Exhibitions_Mutation_Response>;
  /** insert a single row into the table: "exhibitions" */
  insert_exhibitions_one?: Maybe<Exhibitions>;
  /** insert data into the table: "import_exhibitions" */
  insert_import_exhibitions?: Maybe<Import_Exhibitions_Mutation_Response>;
  /** insert a single row into the table: "import_exhibitions" */
  insert_import_exhibitions_one?: Maybe<Import_Exhibitions>;
  /** insert data into the table: "imports" */
  insert_imports?: Maybe<Imports_Mutation_Response>;
  /** insert a single row into the table: "imports" */
  insert_imports_one?: Maybe<Imports>;
  /** insert data into the table: "integration_shopify_settings" */
  insert_integration_shopify_settings?: Maybe<Integration_Shopify_Settings_Mutation_Response>;
  /** insert a single row into the table: "integration_shopify_settings" */
  insert_integration_shopify_settings_one?: Maybe<Integration_Shopify_Settings>;
  /** insert data into the table: "integration_yahoo_shopping_settings" */
  insert_integration_yahoo_shopping_settings?: Maybe<Integration_Yahoo_Shopping_Settings_Mutation_Response>;
  /** insert a single row into the table: "integration_yahoo_shopping_settings" */
  insert_integration_yahoo_shopping_settings_one?: Maybe<Integration_Yahoo_Shopping_Settings>;
  /** insert data into the table: "integrations" */
  insert_integrations?: Maybe<Integrations_Mutation_Response>;
  /** insert a single row into the table: "integrations" */
  insert_integrations_one?: Maybe<Integrations>;
  /** insert data into the table: "item_conditions" */
  insert_item_conditions?: Maybe<Item_Conditions_Mutation_Response>;
  /** insert a single row into the table: "item_conditions" */
  insert_item_conditions_one?: Maybe<Item_Conditions>;
  /** insert data into the table: "item_images" */
  insert_item_images?: Maybe<Item_Images_Mutation_Response>;
  /** insert a single row into the table: "item_images" */
  insert_item_images_one?: Maybe<Item_Images>;
  /** insert data into the table: "item_sold_histories" */
  insert_item_sold_histories?: Maybe<Item_Sold_Histories_Mutation_Response>;
  /** insert a single row into the table: "item_sold_histories" */
  insert_item_sold_histories_one?: Maybe<Item_Sold_Histories>;
  /** insert data into the table: "item_statuses" */
  insert_item_statuses?: Maybe<Item_Statuses_Mutation_Response>;
  /** insert a single row into the table: "item_statuses" */
  insert_item_statuses_one?: Maybe<Item_Statuses>;
  /** insert data into the table: "item_variant_exhibitions" */
  insert_item_variant_exhibitions?: Maybe<Item_Variant_Exhibitions_Mutation_Response>;
  /** insert a single row into the table: "item_variant_exhibitions" */
  insert_item_variant_exhibitions_one?: Maybe<Item_Variant_Exhibitions>;
  /** insert data into the table: "item_variants" */
  insert_item_variants?: Maybe<Item_Variants_Mutation_Response>;
  /** insert a single row into the table: "item_variants" */
  insert_item_variants_one?: Maybe<Item_Variants>;
  /** insert data into the table: "items" */
  insert_items?: Maybe<Items_Mutation_Response>;
  /** insert a single row into the table: "items" */
  insert_items_one?: Maybe<Items>;
  /** insert data into the table: "mercari_shops_categories" */
  insert_mercari_shops_categories?: Maybe<Mercari_Shops_Categories_Mutation_Response>;
  /** insert a single row into the table: "mercari_shops_categories" */
  insert_mercari_shops_categories_one?: Maybe<Mercari_Shops_Categories>;
  /** insert data into the table: "plans" */
  insert_plans?: Maybe<Plans_Mutation_Response>;
  /** insert a single row into the table: "plans" */
  insert_plans_one?: Maybe<Plans>;
  /** insert data into the table: "platforms" */
  insert_platforms?: Maybe<Platforms_Mutation_Response>;
  /** insert a single row into the table: "platforms" */
  insert_platforms_one?: Maybe<Platforms>;
  /** insert data into the table: "shop_mercari_shops_settings" */
  insert_shop_mercari_shops_settings?: Maybe<Shop_Mercari_Shops_Settings_Mutation_Response>;
  /** insert a single row into the table: "shop_mercari_shops_settings" */
  insert_shop_mercari_shops_settings_one?: Maybe<Shop_Mercari_Shops_Settings>;
  /** insert data into the table: "shop_staff" */
  insert_shop_staff?: Maybe<Shop_Staff_Mutation_Response>;
  /** insert a single row into the table: "shop_staff" */
  insert_shop_staff_one?: Maybe<Shop_Staff>;
  /** insert data into the table: "shop_staff_roles" */
  insert_shop_staff_roles?: Maybe<Shop_Staff_Roles_Mutation_Response>;
  /** insert a single row into the table: "shop_staff_roles" */
  insert_shop_staff_roles_one?: Maybe<Shop_Staff_Roles>;
  /** insert data into the table: "shop_statuses" */
  insert_shop_statuses?: Maybe<Shop_Statuses_Mutation_Response>;
  /** insert a single row into the table: "shop_statuses" */
  insert_shop_statuses_one?: Maybe<Shop_Statuses>;
  /** insert data into the table: "shop_yahoo_shopping_settings" */
  insert_shop_yahoo_shopping_settings?: Maybe<Shop_Yahoo_Shopping_Settings_Mutation_Response>;
  /** insert a single row into the table: "shop_yahoo_shopping_settings" */
  insert_shop_yahoo_shopping_settings_one?: Maybe<Shop_Yahoo_Shopping_Settings>;
  /** insert data into the table: "shops" */
  insert_shops?: Maybe<Shops_Mutation_Response>;
  /** insert a single row into the table: "shops" */
  insert_shops_one?: Maybe<Shops>;
  /** insert data into the table: "subscription_statuses" */
  insert_subscription_statuses?: Maybe<Subscription_Statuses_Mutation_Response>;
  /** insert a single row into the table: "subscription_statuses" */
  insert_subscription_statuses_one?: Maybe<Subscription_Statuses>;
  /** insert data into the table: "subscriptions" */
  insert_subscriptions?: Maybe<Subscriptions_Mutation_Response>;
  /** insert a single row into the table: "subscriptions" */
  insert_subscriptions_one?: Maybe<Subscriptions>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "yahoo_auction_categories" */
  insert_yahoo_auction_categories?: Maybe<Yahoo_Auction_Categories_Mutation_Response>;
  /** insert a single row into the table: "yahoo_auction_categories" */
  insert_yahoo_auction_categories_one?: Maybe<Yahoo_Auction_Categories>;
  /** update data of the table: "brand_mercari_shops_mappings" */
  update_brand_mercari_shops_mappings?: Maybe<Brand_Mercari_Shops_Mappings_Mutation_Response>;
  /** update single row of the table: "brand_mercari_shops_mappings" */
  update_brand_mercari_shops_mappings_by_pk?: Maybe<Brand_Mercari_Shops_Mappings>;
  /** update multiples rows of table: "brand_mercari_shops_mappings" */
  update_brand_mercari_shops_mappings_many?: Maybe<Array<Maybe<Brand_Mercari_Shops_Mappings_Mutation_Response>>>;
  /** update data of the table: "brands" */
  update_brands?: Maybe<Brands_Mutation_Response>;
  /** update single row of the table: "brands" */
  update_brands_by_pk?: Maybe<Brands>;
  /** update multiples rows of table: "brands" */
  update_brands_many?: Maybe<Array<Maybe<Brands_Mutation_Response>>>;
  /** update data of the table: "exhibition_base_categories" */
  update_exhibition_base_categories?: Maybe<Exhibition_Base_Categories_Mutation_Response>;
  /** update single row of the table: "exhibition_base_categories" */
  update_exhibition_base_categories_by_pk?: Maybe<Exhibition_Base_Categories>;
  /** update multiples rows of table: "exhibition_base_categories" */
  update_exhibition_base_categories_many?: Maybe<Array<Maybe<Exhibition_Base_Categories_Mutation_Response>>>;
  /** update data of the table: "exhibition_mercari_shops_settings" */
  update_exhibition_mercari_shops_settings?: Maybe<Exhibition_Mercari_Shops_Settings_Mutation_Response>;
  /** update single row of the table: "exhibition_mercari_shops_settings" */
  update_exhibition_mercari_shops_settings_by_pk?: Maybe<Exhibition_Mercari_Shops_Settings>;
  /** update multiples rows of table: "exhibition_mercari_shops_settings" */
  update_exhibition_mercari_shops_settings_many?: Maybe<
    Array<Maybe<Exhibition_Mercari_Shops_Settings_Mutation_Response>>
  >;
  /** update data of the table: "exhibition_shopify_settings" */
  update_exhibition_shopify_settings?: Maybe<Exhibition_Shopify_Settings_Mutation_Response>;
  /** update single row of the table: "exhibition_shopify_settings" */
  update_exhibition_shopify_settings_by_pk?: Maybe<Exhibition_Shopify_Settings>;
  /** update multiples rows of table: "exhibition_shopify_settings" */
  update_exhibition_shopify_settings_many?: Maybe<Array<Maybe<Exhibition_Shopify_Settings_Mutation_Response>>>;
  /** update data of the table: "exhibition_statuses" */
  update_exhibition_statuses?: Maybe<Exhibition_Statuses_Mutation_Response>;
  /** update single row of the table: "exhibition_statuses" */
  update_exhibition_statuses_by_pk?: Maybe<Exhibition_Statuses>;
  /** update multiples rows of table: "exhibition_statuses" */
  update_exhibition_statuses_many?: Maybe<Array<Maybe<Exhibition_Statuses_Mutation_Response>>>;
  /** update data of the table: "exhibition_stores_categories" */
  update_exhibition_stores_categories?: Maybe<Exhibition_Stores_Categories_Mutation_Response>;
  /** update single row of the table: "exhibition_stores_categories" */
  update_exhibition_stores_categories_by_pk?: Maybe<Exhibition_Stores_Categories>;
  /** update multiples rows of table: "exhibition_stores_categories" */
  update_exhibition_stores_categories_many?: Maybe<Array<Maybe<Exhibition_Stores_Categories_Mutation_Response>>>;
  /** update data of the table: "exhibition_yahoo_shopping_settings" */
  update_exhibition_yahoo_shopping_settings?: Maybe<Exhibition_Yahoo_Shopping_Settings_Mutation_Response>;
  /** update single row of the table: "exhibition_yahoo_shopping_settings" */
  update_exhibition_yahoo_shopping_settings_by_pk?: Maybe<Exhibition_Yahoo_Shopping_Settings>;
  /** update multiples rows of table: "exhibition_yahoo_shopping_settings" */
  update_exhibition_yahoo_shopping_settings_many?: Maybe<
    Array<Maybe<Exhibition_Yahoo_Shopping_Settings_Mutation_Response>>
  >;
  /** update data of the table: "exhibitions" */
  update_exhibitions?: Maybe<Exhibitions_Mutation_Response>;
  /** update single row of the table: "exhibitions" */
  update_exhibitions_by_pk?: Maybe<Exhibitions>;
  /** update multiples rows of table: "exhibitions" */
  update_exhibitions_many?: Maybe<Array<Maybe<Exhibitions_Mutation_Response>>>;
  /** update data of the table: "import_exhibitions" */
  update_import_exhibitions?: Maybe<Import_Exhibitions_Mutation_Response>;
  /** update single row of the table: "import_exhibitions" */
  update_import_exhibitions_by_pk?: Maybe<Import_Exhibitions>;
  /** update multiples rows of table: "import_exhibitions" */
  update_import_exhibitions_many?: Maybe<Array<Maybe<Import_Exhibitions_Mutation_Response>>>;
  /** update data of the table: "imports" */
  update_imports?: Maybe<Imports_Mutation_Response>;
  /** update single row of the table: "imports" */
  update_imports_by_pk?: Maybe<Imports>;
  /** update multiples rows of table: "imports" */
  update_imports_many?: Maybe<Array<Maybe<Imports_Mutation_Response>>>;
  /** update data of the table: "integration_shopify_settings" */
  update_integration_shopify_settings?: Maybe<Integration_Shopify_Settings_Mutation_Response>;
  /** update single row of the table: "integration_shopify_settings" */
  update_integration_shopify_settings_by_pk?: Maybe<Integration_Shopify_Settings>;
  /** update multiples rows of table: "integration_shopify_settings" */
  update_integration_shopify_settings_many?: Maybe<Array<Maybe<Integration_Shopify_Settings_Mutation_Response>>>;
  /** update data of the table: "integration_yahoo_shopping_settings" */
  update_integration_yahoo_shopping_settings?: Maybe<Integration_Yahoo_Shopping_Settings_Mutation_Response>;
  /** update single row of the table: "integration_yahoo_shopping_settings" */
  update_integration_yahoo_shopping_settings_by_pk?: Maybe<Integration_Yahoo_Shopping_Settings>;
  /** update multiples rows of table: "integration_yahoo_shopping_settings" */
  update_integration_yahoo_shopping_settings_many?: Maybe<
    Array<Maybe<Integration_Yahoo_Shopping_Settings_Mutation_Response>>
  >;
  /** update data of the table: "integrations" */
  update_integrations?: Maybe<Integrations_Mutation_Response>;
  /** update single row of the table: "integrations" */
  update_integrations_by_pk?: Maybe<Integrations>;
  /** update multiples rows of table: "integrations" */
  update_integrations_many?: Maybe<Array<Maybe<Integrations_Mutation_Response>>>;
  /** update data of the table: "item_conditions" */
  update_item_conditions?: Maybe<Item_Conditions_Mutation_Response>;
  /** update single row of the table: "item_conditions" */
  update_item_conditions_by_pk?: Maybe<Item_Conditions>;
  /** update multiples rows of table: "item_conditions" */
  update_item_conditions_many?: Maybe<Array<Maybe<Item_Conditions_Mutation_Response>>>;
  /** update data of the table: "item_images" */
  update_item_images?: Maybe<Item_Images_Mutation_Response>;
  /** update single row of the table: "item_images" */
  update_item_images_by_pk?: Maybe<Item_Images>;
  /** update multiples rows of table: "item_images" */
  update_item_images_many?: Maybe<Array<Maybe<Item_Images_Mutation_Response>>>;
  /** update data of the table: "item_sold_histories" */
  update_item_sold_histories?: Maybe<Item_Sold_Histories_Mutation_Response>;
  /** update single row of the table: "item_sold_histories" */
  update_item_sold_histories_by_pk?: Maybe<Item_Sold_Histories>;
  /** update multiples rows of table: "item_sold_histories" */
  update_item_sold_histories_many?: Maybe<Array<Maybe<Item_Sold_Histories_Mutation_Response>>>;
  /** update data of the table: "item_statuses" */
  update_item_statuses?: Maybe<Item_Statuses_Mutation_Response>;
  /** update single row of the table: "item_statuses" */
  update_item_statuses_by_pk?: Maybe<Item_Statuses>;
  /** update multiples rows of table: "item_statuses" */
  update_item_statuses_many?: Maybe<Array<Maybe<Item_Statuses_Mutation_Response>>>;
  /** update data of the table: "item_variant_exhibitions" */
  update_item_variant_exhibitions?: Maybe<Item_Variant_Exhibitions_Mutation_Response>;
  /** update single row of the table: "item_variant_exhibitions" */
  update_item_variant_exhibitions_by_pk?: Maybe<Item_Variant_Exhibitions>;
  /** update multiples rows of table: "item_variant_exhibitions" */
  update_item_variant_exhibitions_many?: Maybe<Array<Maybe<Item_Variant_Exhibitions_Mutation_Response>>>;
  /** update data of the table: "item_variants" */
  update_item_variants?: Maybe<Item_Variants_Mutation_Response>;
  /** update single row of the table: "item_variants" */
  update_item_variants_by_pk?: Maybe<Item_Variants>;
  /** update multiples rows of table: "item_variants" */
  update_item_variants_many?: Maybe<Array<Maybe<Item_Variants_Mutation_Response>>>;
  /** update data of the table: "items" */
  update_items?: Maybe<Items_Mutation_Response>;
  /** update single row of the table: "items" */
  update_items_by_pk?: Maybe<Items>;
  /** update multiples rows of table: "items" */
  update_items_many?: Maybe<Array<Maybe<Items_Mutation_Response>>>;
  /** update data of the table: "mercari_shops_categories" */
  update_mercari_shops_categories?: Maybe<Mercari_Shops_Categories_Mutation_Response>;
  /** update single row of the table: "mercari_shops_categories" */
  update_mercari_shops_categories_by_pk?: Maybe<Mercari_Shops_Categories>;
  /** update multiples rows of table: "mercari_shops_categories" */
  update_mercari_shops_categories_many?: Maybe<Array<Maybe<Mercari_Shops_Categories_Mutation_Response>>>;
  /** update data of the table: "plans" */
  update_plans?: Maybe<Plans_Mutation_Response>;
  /** update single row of the table: "plans" */
  update_plans_by_pk?: Maybe<Plans>;
  /** update multiples rows of table: "plans" */
  update_plans_many?: Maybe<Array<Maybe<Plans_Mutation_Response>>>;
  /** update data of the table: "platforms" */
  update_platforms?: Maybe<Platforms_Mutation_Response>;
  /** update single row of the table: "platforms" */
  update_platforms_by_pk?: Maybe<Platforms>;
  /** update multiples rows of table: "platforms" */
  update_platforms_many?: Maybe<Array<Maybe<Platforms_Mutation_Response>>>;
  /** update data of the table: "shop_mercari_shops_settings" */
  update_shop_mercari_shops_settings?: Maybe<Shop_Mercari_Shops_Settings_Mutation_Response>;
  /** update single row of the table: "shop_mercari_shops_settings" */
  update_shop_mercari_shops_settings_by_pk?: Maybe<Shop_Mercari_Shops_Settings>;
  /** update multiples rows of table: "shop_mercari_shops_settings" */
  update_shop_mercari_shops_settings_many?: Maybe<Array<Maybe<Shop_Mercari_Shops_Settings_Mutation_Response>>>;
  /** update data of the table: "shop_staff" */
  update_shop_staff?: Maybe<Shop_Staff_Mutation_Response>;
  /** update single row of the table: "shop_staff" */
  update_shop_staff_by_pk?: Maybe<Shop_Staff>;
  /** update multiples rows of table: "shop_staff" */
  update_shop_staff_many?: Maybe<Array<Maybe<Shop_Staff_Mutation_Response>>>;
  /** update data of the table: "shop_staff_roles" */
  update_shop_staff_roles?: Maybe<Shop_Staff_Roles_Mutation_Response>;
  /** update single row of the table: "shop_staff_roles" */
  update_shop_staff_roles_by_pk?: Maybe<Shop_Staff_Roles>;
  /** update multiples rows of table: "shop_staff_roles" */
  update_shop_staff_roles_many?: Maybe<Array<Maybe<Shop_Staff_Roles_Mutation_Response>>>;
  /** update data of the table: "shop_statuses" */
  update_shop_statuses?: Maybe<Shop_Statuses_Mutation_Response>;
  /** update single row of the table: "shop_statuses" */
  update_shop_statuses_by_pk?: Maybe<Shop_Statuses>;
  /** update multiples rows of table: "shop_statuses" */
  update_shop_statuses_many?: Maybe<Array<Maybe<Shop_Statuses_Mutation_Response>>>;
  /** update data of the table: "shop_yahoo_shopping_settings" */
  update_shop_yahoo_shopping_settings?: Maybe<Shop_Yahoo_Shopping_Settings_Mutation_Response>;
  /** update single row of the table: "shop_yahoo_shopping_settings" */
  update_shop_yahoo_shopping_settings_by_pk?: Maybe<Shop_Yahoo_Shopping_Settings>;
  /** update multiples rows of table: "shop_yahoo_shopping_settings" */
  update_shop_yahoo_shopping_settings_many?: Maybe<Array<Maybe<Shop_Yahoo_Shopping_Settings_Mutation_Response>>>;
  /** update data of the table: "shops" */
  update_shops?: Maybe<Shops_Mutation_Response>;
  /** update single row of the table: "shops" */
  update_shops_by_pk?: Maybe<Shops>;
  /** update multiples rows of table: "shops" */
  update_shops_many?: Maybe<Array<Maybe<Shops_Mutation_Response>>>;
  /** update data of the table: "subscription_statuses" */
  update_subscription_statuses?: Maybe<Subscription_Statuses_Mutation_Response>;
  /** update single row of the table: "subscription_statuses" */
  update_subscription_statuses_by_pk?: Maybe<Subscription_Statuses>;
  /** update multiples rows of table: "subscription_statuses" */
  update_subscription_statuses_many?: Maybe<Array<Maybe<Subscription_Statuses_Mutation_Response>>>;
  /** update data of the table: "subscriptions" */
  update_subscriptions?: Maybe<Subscriptions_Mutation_Response>;
  /** update single row of the table: "subscriptions" */
  update_subscriptions_by_pk?: Maybe<Subscriptions>;
  /** update multiples rows of table: "subscriptions" */
  update_subscriptions_many?: Maybe<Array<Maybe<Subscriptions_Mutation_Response>>>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
  /** update data of the table: "yahoo_auction_categories" */
  update_yahoo_auction_categories?: Maybe<Yahoo_Auction_Categories_Mutation_Response>;
  /** update single row of the table: "yahoo_auction_categories" */
  update_yahoo_auction_categories_by_pk?: Maybe<Yahoo_Auction_Categories>;
  /** update multiples rows of table: "yahoo_auction_categories" */
  update_yahoo_auction_categories_many?: Maybe<Array<Maybe<Yahoo_Auction_Categories_Mutation_Response>>>;
  /** upload_image */
  upload_image?: Maybe<FileOutput>;
};

/** mutation root */
export type Mutation_RootCreateIntegrationArgs = {
  input: CreateIntegrationInput;
};

/** mutation root */
export type Mutation_RootCreateUserAndPaymentArgs = {
  input: CreateUserAndPaymentInput;
};

/** mutation root */
export type Mutation_RootDelete_Brand_Mercari_Shops_MappingsArgs = {
  where: Brand_Mercari_Shops_Mappings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Brand_Mercari_Shops_Mappings_By_PkArgs = {
  brand_id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_BrandsArgs = {
  where: Brands_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Brands_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Exhibition_Base_CategoriesArgs = {
  where: Exhibition_Base_Categories_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Exhibition_Base_Categories_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Exhibition_Mercari_Shops_SettingsArgs = {
  where: Exhibition_Mercari_Shops_Settings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Exhibition_Mercari_Shops_Settings_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Exhibition_Shopify_SettingsArgs = {
  where: Exhibition_Shopify_Settings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Exhibition_Shopify_Settings_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Exhibition_StatusesArgs = {
  where: Exhibition_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Exhibition_Statuses_By_PkArgs = {
  key: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Exhibition_Stores_CategoriesArgs = {
  where: Exhibition_Stores_Categories_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Exhibition_Stores_Categories_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Exhibition_Yahoo_Shopping_SettingsArgs = {
  where: Exhibition_Yahoo_Shopping_Settings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Exhibition_Yahoo_Shopping_Settings_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_ExhibitionsArgs = {
  where: Exhibitions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Exhibitions_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Import_ExhibitionsArgs = {
  where: Import_Exhibitions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Import_Exhibitions_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_ImportsArgs = {
  where: Imports_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Imports_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Integration_Shopify_SettingsArgs = {
  where: Integration_Shopify_Settings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Integration_Shopify_Settings_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Integration_Yahoo_Shopping_SettingsArgs = {
  where: Integration_Yahoo_Shopping_Settings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Integration_Yahoo_Shopping_Settings_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_IntegrationsArgs = {
  where: Integrations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Integrations_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Item_ConditionsArgs = {
  where: Item_Conditions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Item_Conditions_By_PkArgs = {
  key: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Item_ImagesArgs = {
  where: Item_Images_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Item_Images_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Item_Sold_HistoriesArgs = {
  where: Item_Sold_Histories_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Item_Sold_Histories_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Item_StatusesArgs = {
  where: Item_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Item_Statuses_By_PkArgs = {
  key: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Item_Variant_ExhibitionsArgs = {
  where: Item_Variant_Exhibitions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Item_Variant_Exhibitions_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Item_VariantsArgs = {
  where: Item_Variants_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Item_Variants_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_ItemsArgs = {
  where: Items_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Items_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Mercari_Shops_CategoriesArgs = {
  where: Mercari_Shops_Categories_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Mercari_Shops_Categories_By_PkArgs = {
  id: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_PlansArgs = {
  where: Plans_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Plans_By_PkArgs = {
  key: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_PlatformsArgs = {
  where: Platforms_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Platforms_By_PkArgs = {
  key: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Shop_Mercari_Shops_SettingsArgs = {
  where: Shop_Mercari_Shops_Settings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Shop_Mercari_Shops_Settings_By_PkArgs = {
  shop_id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Shop_StaffArgs = {
  where: Shop_Staff_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Shop_Staff_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Shop_Staff_RolesArgs = {
  where: Shop_Staff_Roles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Shop_Staff_Roles_By_PkArgs = {
  key: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Shop_StatusesArgs = {
  where: Shop_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Shop_Statuses_By_PkArgs = {
  key: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Shop_Yahoo_Shopping_SettingsArgs = {
  where: Shop_Yahoo_Shopping_Settings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Shop_Yahoo_Shopping_Settings_By_PkArgs = {
  shop_id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_ShopsArgs = {
  where: Shops_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Shops_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Subscription_StatusesArgs = {
  where: Subscription_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Subscription_Statuses_By_PkArgs = {
  key: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_SubscriptionsArgs = {
  where: Subscriptions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Subscriptions_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Yahoo_Auction_CategoriesArgs = {
  where: Yahoo_Auction_Categories_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Yahoo_Auction_Categories_By_PkArgs = {
  id: Scalars["Int"]["input"];
};

/** mutation root */
export type Mutation_RootFetch_Base_OrdersArgs = {
  input: FetchBaseOrdersInput;
};

/** mutation root */
export type Mutation_RootInsert_Brand_Mercari_Shops_MappingsArgs = {
  objects: Array<Brand_Mercari_Shops_Mappings_Insert_Input>;
  on_conflict?: InputMaybe<Brand_Mercari_Shops_Mappings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Brand_Mercari_Shops_Mappings_OneArgs = {
  object: Brand_Mercari_Shops_Mappings_Insert_Input;
  on_conflict?: InputMaybe<Brand_Mercari_Shops_Mappings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_BrandsArgs = {
  objects: Array<Brands_Insert_Input>;
  on_conflict?: InputMaybe<Brands_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Brands_OneArgs = {
  object: Brands_Insert_Input;
  on_conflict?: InputMaybe<Brands_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Exhibition_Base_CategoriesArgs = {
  objects: Array<Exhibition_Base_Categories_Insert_Input>;
  on_conflict?: InputMaybe<Exhibition_Base_Categories_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Exhibition_Base_Categories_OneArgs = {
  object: Exhibition_Base_Categories_Insert_Input;
  on_conflict?: InputMaybe<Exhibition_Base_Categories_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Exhibition_Mercari_Shops_SettingsArgs = {
  objects: Array<Exhibition_Mercari_Shops_Settings_Insert_Input>;
  on_conflict?: InputMaybe<Exhibition_Mercari_Shops_Settings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Exhibition_Mercari_Shops_Settings_OneArgs = {
  object: Exhibition_Mercari_Shops_Settings_Insert_Input;
  on_conflict?: InputMaybe<Exhibition_Mercari_Shops_Settings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Exhibition_Shopify_SettingsArgs = {
  objects: Array<Exhibition_Shopify_Settings_Insert_Input>;
  on_conflict?: InputMaybe<Exhibition_Shopify_Settings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Exhibition_Shopify_Settings_OneArgs = {
  object: Exhibition_Shopify_Settings_Insert_Input;
  on_conflict?: InputMaybe<Exhibition_Shopify_Settings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Exhibition_StatusesArgs = {
  objects: Array<Exhibition_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Exhibition_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Exhibition_Statuses_OneArgs = {
  object: Exhibition_Statuses_Insert_Input;
  on_conflict?: InputMaybe<Exhibition_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Exhibition_Stores_CategoriesArgs = {
  objects: Array<Exhibition_Stores_Categories_Insert_Input>;
  on_conflict?: InputMaybe<Exhibition_Stores_Categories_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Exhibition_Stores_Categories_OneArgs = {
  object: Exhibition_Stores_Categories_Insert_Input;
  on_conflict?: InputMaybe<Exhibition_Stores_Categories_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Exhibition_Yahoo_Shopping_SettingsArgs = {
  objects: Array<Exhibition_Yahoo_Shopping_Settings_Insert_Input>;
  on_conflict?: InputMaybe<Exhibition_Yahoo_Shopping_Settings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Exhibition_Yahoo_Shopping_Settings_OneArgs = {
  object: Exhibition_Yahoo_Shopping_Settings_Insert_Input;
  on_conflict?: InputMaybe<Exhibition_Yahoo_Shopping_Settings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ExhibitionsArgs = {
  objects: Array<Exhibitions_Insert_Input>;
  on_conflict?: InputMaybe<Exhibitions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Exhibitions_OneArgs = {
  object: Exhibitions_Insert_Input;
  on_conflict?: InputMaybe<Exhibitions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Import_ExhibitionsArgs = {
  objects: Array<Import_Exhibitions_Insert_Input>;
  on_conflict?: InputMaybe<Import_Exhibitions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Import_Exhibitions_OneArgs = {
  object: Import_Exhibitions_Insert_Input;
  on_conflict?: InputMaybe<Import_Exhibitions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ImportsArgs = {
  objects: Array<Imports_Insert_Input>;
  on_conflict?: InputMaybe<Imports_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Imports_OneArgs = {
  object: Imports_Insert_Input;
  on_conflict?: InputMaybe<Imports_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Integration_Shopify_SettingsArgs = {
  objects: Array<Integration_Shopify_Settings_Insert_Input>;
  on_conflict?: InputMaybe<Integration_Shopify_Settings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Integration_Shopify_Settings_OneArgs = {
  object: Integration_Shopify_Settings_Insert_Input;
  on_conflict?: InputMaybe<Integration_Shopify_Settings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Integration_Yahoo_Shopping_SettingsArgs = {
  objects: Array<Integration_Yahoo_Shopping_Settings_Insert_Input>;
  on_conflict?: InputMaybe<Integration_Yahoo_Shopping_Settings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Integration_Yahoo_Shopping_Settings_OneArgs = {
  object: Integration_Yahoo_Shopping_Settings_Insert_Input;
  on_conflict?: InputMaybe<Integration_Yahoo_Shopping_Settings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_IntegrationsArgs = {
  objects: Array<Integrations_Insert_Input>;
  on_conflict?: InputMaybe<Integrations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Integrations_OneArgs = {
  object: Integrations_Insert_Input;
  on_conflict?: InputMaybe<Integrations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Item_ConditionsArgs = {
  objects: Array<Item_Conditions_Insert_Input>;
  on_conflict?: InputMaybe<Item_Conditions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Item_Conditions_OneArgs = {
  object: Item_Conditions_Insert_Input;
  on_conflict?: InputMaybe<Item_Conditions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Item_ImagesArgs = {
  objects: Array<Item_Images_Insert_Input>;
  on_conflict?: InputMaybe<Item_Images_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Item_Images_OneArgs = {
  object: Item_Images_Insert_Input;
  on_conflict?: InputMaybe<Item_Images_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Item_Sold_HistoriesArgs = {
  objects: Array<Item_Sold_Histories_Insert_Input>;
  on_conflict?: InputMaybe<Item_Sold_Histories_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Item_Sold_Histories_OneArgs = {
  object: Item_Sold_Histories_Insert_Input;
  on_conflict?: InputMaybe<Item_Sold_Histories_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Item_StatusesArgs = {
  objects: Array<Item_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Item_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Item_Statuses_OneArgs = {
  object: Item_Statuses_Insert_Input;
  on_conflict?: InputMaybe<Item_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Item_Variant_ExhibitionsArgs = {
  objects: Array<Item_Variant_Exhibitions_Insert_Input>;
  on_conflict?: InputMaybe<Item_Variant_Exhibitions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Item_Variant_Exhibitions_OneArgs = {
  object: Item_Variant_Exhibitions_Insert_Input;
  on_conflict?: InputMaybe<Item_Variant_Exhibitions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Item_VariantsArgs = {
  objects: Array<Item_Variants_Insert_Input>;
  on_conflict?: InputMaybe<Item_Variants_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Item_Variants_OneArgs = {
  object: Item_Variants_Insert_Input;
  on_conflict?: InputMaybe<Item_Variants_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ItemsArgs = {
  objects: Array<Items_Insert_Input>;
  on_conflict?: InputMaybe<Items_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Items_OneArgs = {
  object: Items_Insert_Input;
  on_conflict?: InputMaybe<Items_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Mercari_Shops_CategoriesArgs = {
  objects: Array<Mercari_Shops_Categories_Insert_Input>;
  on_conflict?: InputMaybe<Mercari_Shops_Categories_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Mercari_Shops_Categories_OneArgs = {
  object: Mercari_Shops_Categories_Insert_Input;
  on_conflict?: InputMaybe<Mercari_Shops_Categories_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_PlansArgs = {
  objects: Array<Plans_Insert_Input>;
  on_conflict?: InputMaybe<Plans_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Plans_OneArgs = {
  object: Plans_Insert_Input;
  on_conflict?: InputMaybe<Plans_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_PlatformsArgs = {
  objects: Array<Platforms_Insert_Input>;
  on_conflict?: InputMaybe<Platforms_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Platforms_OneArgs = {
  object: Platforms_Insert_Input;
  on_conflict?: InputMaybe<Platforms_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Shop_Mercari_Shops_SettingsArgs = {
  objects: Array<Shop_Mercari_Shops_Settings_Insert_Input>;
  on_conflict?: InputMaybe<Shop_Mercari_Shops_Settings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Shop_Mercari_Shops_Settings_OneArgs = {
  object: Shop_Mercari_Shops_Settings_Insert_Input;
  on_conflict?: InputMaybe<Shop_Mercari_Shops_Settings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Shop_StaffArgs = {
  objects: Array<Shop_Staff_Insert_Input>;
  on_conflict?: InputMaybe<Shop_Staff_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Shop_Staff_OneArgs = {
  object: Shop_Staff_Insert_Input;
  on_conflict?: InputMaybe<Shop_Staff_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Shop_Staff_RolesArgs = {
  objects: Array<Shop_Staff_Roles_Insert_Input>;
  on_conflict?: InputMaybe<Shop_Staff_Roles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Shop_Staff_Roles_OneArgs = {
  object: Shop_Staff_Roles_Insert_Input;
  on_conflict?: InputMaybe<Shop_Staff_Roles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Shop_StatusesArgs = {
  objects: Array<Shop_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Shop_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Shop_Statuses_OneArgs = {
  object: Shop_Statuses_Insert_Input;
  on_conflict?: InputMaybe<Shop_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Shop_Yahoo_Shopping_SettingsArgs = {
  objects: Array<Shop_Yahoo_Shopping_Settings_Insert_Input>;
  on_conflict?: InputMaybe<Shop_Yahoo_Shopping_Settings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Shop_Yahoo_Shopping_Settings_OneArgs = {
  object: Shop_Yahoo_Shopping_Settings_Insert_Input;
  on_conflict?: InputMaybe<Shop_Yahoo_Shopping_Settings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ShopsArgs = {
  objects: Array<Shops_Insert_Input>;
  on_conflict?: InputMaybe<Shops_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Shops_OneArgs = {
  object: Shops_Insert_Input;
  on_conflict?: InputMaybe<Shops_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Subscription_StatusesArgs = {
  objects: Array<Subscription_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Subscription_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Subscription_Statuses_OneArgs = {
  object: Subscription_Statuses_Insert_Input;
  on_conflict?: InputMaybe<Subscription_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SubscriptionsArgs = {
  objects: Array<Subscriptions_Insert_Input>;
  on_conflict?: InputMaybe<Subscriptions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Subscriptions_OneArgs = {
  object: Subscriptions_Insert_Input;
  on_conflict?: InputMaybe<Subscriptions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Yahoo_Auction_CategoriesArgs = {
  objects: Array<Yahoo_Auction_Categories_Insert_Input>;
  on_conflict?: InputMaybe<Yahoo_Auction_Categories_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Yahoo_Auction_Categories_OneArgs = {
  object: Yahoo_Auction_Categories_Insert_Input;
  on_conflict?: InputMaybe<Yahoo_Auction_Categories_On_Conflict>;
};

/** mutation root */
export type Mutation_RootUpdate_Brand_Mercari_Shops_MappingsArgs = {
  _set?: InputMaybe<Brand_Mercari_Shops_Mappings_Set_Input>;
  where: Brand_Mercari_Shops_Mappings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Brand_Mercari_Shops_Mappings_By_PkArgs = {
  _set?: InputMaybe<Brand_Mercari_Shops_Mappings_Set_Input>;
  pk_columns: Brand_Mercari_Shops_Mappings_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Brand_Mercari_Shops_Mappings_ManyArgs = {
  updates: Array<Brand_Mercari_Shops_Mappings_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_BrandsArgs = {
  _set?: InputMaybe<Brands_Set_Input>;
  where: Brands_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Brands_By_PkArgs = {
  _set?: InputMaybe<Brands_Set_Input>;
  pk_columns: Brands_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Brands_ManyArgs = {
  updates: Array<Brands_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Exhibition_Base_CategoriesArgs = {
  _inc?: InputMaybe<Exhibition_Base_Categories_Inc_Input>;
  _set?: InputMaybe<Exhibition_Base_Categories_Set_Input>;
  where: Exhibition_Base_Categories_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Exhibition_Base_Categories_By_PkArgs = {
  _inc?: InputMaybe<Exhibition_Base_Categories_Inc_Input>;
  _set?: InputMaybe<Exhibition_Base_Categories_Set_Input>;
  pk_columns: Exhibition_Base_Categories_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Exhibition_Base_Categories_ManyArgs = {
  updates: Array<Exhibition_Base_Categories_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Exhibition_Mercari_Shops_SettingsArgs = {
  _set?: InputMaybe<Exhibition_Mercari_Shops_Settings_Set_Input>;
  where: Exhibition_Mercari_Shops_Settings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Exhibition_Mercari_Shops_Settings_By_PkArgs = {
  _set?: InputMaybe<Exhibition_Mercari_Shops_Settings_Set_Input>;
  pk_columns: Exhibition_Mercari_Shops_Settings_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Exhibition_Mercari_Shops_Settings_ManyArgs = {
  updates: Array<Exhibition_Mercari_Shops_Settings_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Exhibition_Shopify_SettingsArgs = {
  _set?: InputMaybe<Exhibition_Shopify_Settings_Set_Input>;
  where: Exhibition_Shopify_Settings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Exhibition_Shopify_Settings_By_PkArgs = {
  _set?: InputMaybe<Exhibition_Shopify_Settings_Set_Input>;
  pk_columns: Exhibition_Shopify_Settings_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Exhibition_Shopify_Settings_ManyArgs = {
  updates: Array<Exhibition_Shopify_Settings_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Exhibition_StatusesArgs = {
  _set?: InputMaybe<Exhibition_Statuses_Set_Input>;
  where: Exhibition_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Exhibition_Statuses_By_PkArgs = {
  _set?: InputMaybe<Exhibition_Statuses_Set_Input>;
  pk_columns: Exhibition_Statuses_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Exhibition_Statuses_ManyArgs = {
  updates: Array<Exhibition_Statuses_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Exhibition_Stores_CategoriesArgs = {
  _set?: InputMaybe<Exhibition_Stores_Categories_Set_Input>;
  where: Exhibition_Stores_Categories_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Exhibition_Stores_Categories_By_PkArgs = {
  _set?: InputMaybe<Exhibition_Stores_Categories_Set_Input>;
  pk_columns: Exhibition_Stores_Categories_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Exhibition_Stores_Categories_ManyArgs = {
  updates: Array<Exhibition_Stores_Categories_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Exhibition_Yahoo_Shopping_SettingsArgs = {
  _inc?: InputMaybe<Exhibition_Yahoo_Shopping_Settings_Inc_Input>;
  _set?: InputMaybe<Exhibition_Yahoo_Shopping_Settings_Set_Input>;
  where: Exhibition_Yahoo_Shopping_Settings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Exhibition_Yahoo_Shopping_Settings_By_PkArgs = {
  _inc?: InputMaybe<Exhibition_Yahoo_Shopping_Settings_Inc_Input>;
  _set?: InputMaybe<Exhibition_Yahoo_Shopping_Settings_Set_Input>;
  pk_columns: Exhibition_Yahoo_Shopping_Settings_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Exhibition_Yahoo_Shopping_Settings_ManyArgs = {
  updates: Array<Exhibition_Yahoo_Shopping_Settings_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ExhibitionsArgs = {
  _set?: InputMaybe<Exhibitions_Set_Input>;
  where: Exhibitions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Exhibitions_By_PkArgs = {
  _set?: InputMaybe<Exhibitions_Set_Input>;
  pk_columns: Exhibitions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Exhibitions_ManyArgs = {
  updates: Array<Exhibitions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Import_ExhibitionsArgs = {
  _inc?: InputMaybe<Import_Exhibitions_Inc_Input>;
  _set?: InputMaybe<Import_Exhibitions_Set_Input>;
  where: Import_Exhibitions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Import_Exhibitions_By_PkArgs = {
  _inc?: InputMaybe<Import_Exhibitions_Inc_Input>;
  _set?: InputMaybe<Import_Exhibitions_Set_Input>;
  pk_columns: Import_Exhibitions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Import_Exhibitions_ManyArgs = {
  updates: Array<Import_Exhibitions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ImportsArgs = {
  _set?: InputMaybe<Imports_Set_Input>;
  where: Imports_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Imports_By_PkArgs = {
  _set?: InputMaybe<Imports_Set_Input>;
  pk_columns: Imports_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Imports_ManyArgs = {
  updates: Array<Imports_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Integration_Shopify_SettingsArgs = {
  _set?: InputMaybe<Integration_Shopify_Settings_Set_Input>;
  where: Integration_Shopify_Settings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Integration_Shopify_Settings_By_PkArgs = {
  _set?: InputMaybe<Integration_Shopify_Settings_Set_Input>;
  pk_columns: Integration_Shopify_Settings_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Integration_Shopify_Settings_ManyArgs = {
  updates: Array<Integration_Shopify_Settings_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Integration_Yahoo_Shopping_SettingsArgs = {
  _set?: InputMaybe<Integration_Yahoo_Shopping_Settings_Set_Input>;
  where: Integration_Yahoo_Shopping_Settings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Integration_Yahoo_Shopping_Settings_By_PkArgs = {
  _set?: InputMaybe<Integration_Yahoo_Shopping_Settings_Set_Input>;
  pk_columns: Integration_Yahoo_Shopping_Settings_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Integration_Yahoo_Shopping_Settings_ManyArgs = {
  updates: Array<Integration_Yahoo_Shopping_Settings_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_IntegrationsArgs = {
  _set?: InputMaybe<Integrations_Set_Input>;
  where: Integrations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Integrations_By_PkArgs = {
  _set?: InputMaybe<Integrations_Set_Input>;
  pk_columns: Integrations_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Integrations_ManyArgs = {
  updates: Array<Integrations_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Item_ConditionsArgs = {
  _set?: InputMaybe<Item_Conditions_Set_Input>;
  where: Item_Conditions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Item_Conditions_By_PkArgs = {
  _set?: InputMaybe<Item_Conditions_Set_Input>;
  pk_columns: Item_Conditions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Item_Conditions_ManyArgs = {
  updates: Array<Item_Conditions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Item_ImagesArgs = {
  _inc?: InputMaybe<Item_Images_Inc_Input>;
  _set?: InputMaybe<Item_Images_Set_Input>;
  where: Item_Images_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Item_Images_By_PkArgs = {
  _inc?: InputMaybe<Item_Images_Inc_Input>;
  _set?: InputMaybe<Item_Images_Set_Input>;
  pk_columns: Item_Images_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Item_Images_ManyArgs = {
  updates: Array<Item_Images_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Item_Sold_HistoriesArgs = {
  _inc?: InputMaybe<Item_Sold_Histories_Inc_Input>;
  _set?: InputMaybe<Item_Sold_Histories_Set_Input>;
  where: Item_Sold_Histories_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Item_Sold_Histories_By_PkArgs = {
  _inc?: InputMaybe<Item_Sold_Histories_Inc_Input>;
  _set?: InputMaybe<Item_Sold_Histories_Set_Input>;
  pk_columns: Item_Sold_Histories_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Item_Sold_Histories_ManyArgs = {
  updates: Array<Item_Sold_Histories_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Item_StatusesArgs = {
  _set?: InputMaybe<Item_Statuses_Set_Input>;
  where: Item_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Item_Statuses_By_PkArgs = {
  _set?: InputMaybe<Item_Statuses_Set_Input>;
  pk_columns: Item_Statuses_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Item_Statuses_ManyArgs = {
  updates: Array<Item_Statuses_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Item_Variant_ExhibitionsArgs = {
  _set?: InputMaybe<Item_Variant_Exhibitions_Set_Input>;
  where: Item_Variant_Exhibitions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Item_Variant_Exhibitions_By_PkArgs = {
  _set?: InputMaybe<Item_Variant_Exhibitions_Set_Input>;
  pk_columns: Item_Variant_Exhibitions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Item_Variant_Exhibitions_ManyArgs = {
  updates: Array<Item_Variant_Exhibitions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Item_VariantsArgs = {
  _inc?: InputMaybe<Item_Variants_Inc_Input>;
  _set?: InputMaybe<Item_Variants_Set_Input>;
  where: Item_Variants_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Item_Variants_By_PkArgs = {
  _inc?: InputMaybe<Item_Variants_Inc_Input>;
  _set?: InputMaybe<Item_Variants_Set_Input>;
  pk_columns: Item_Variants_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Item_Variants_ManyArgs = {
  updates: Array<Item_Variants_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ItemsArgs = {
  _inc?: InputMaybe<Items_Inc_Input>;
  _set?: InputMaybe<Items_Set_Input>;
  where: Items_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Items_By_PkArgs = {
  _inc?: InputMaybe<Items_Inc_Input>;
  _set?: InputMaybe<Items_Set_Input>;
  pk_columns: Items_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Items_ManyArgs = {
  updates: Array<Items_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Mercari_Shops_CategoriesArgs = {
  _set?: InputMaybe<Mercari_Shops_Categories_Set_Input>;
  where: Mercari_Shops_Categories_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Mercari_Shops_Categories_By_PkArgs = {
  _set?: InputMaybe<Mercari_Shops_Categories_Set_Input>;
  pk_columns: Mercari_Shops_Categories_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Mercari_Shops_Categories_ManyArgs = {
  updates: Array<Mercari_Shops_Categories_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_PlansArgs = {
  _set?: InputMaybe<Plans_Set_Input>;
  where: Plans_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Plans_By_PkArgs = {
  _set?: InputMaybe<Plans_Set_Input>;
  pk_columns: Plans_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Plans_ManyArgs = {
  updates: Array<Plans_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_PlatformsArgs = {
  _set?: InputMaybe<Platforms_Set_Input>;
  where: Platforms_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Platforms_By_PkArgs = {
  _set?: InputMaybe<Platforms_Set_Input>;
  pk_columns: Platforms_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Platforms_ManyArgs = {
  updates: Array<Platforms_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Shop_Mercari_Shops_SettingsArgs = {
  _set?: InputMaybe<Shop_Mercari_Shops_Settings_Set_Input>;
  where: Shop_Mercari_Shops_Settings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Shop_Mercari_Shops_Settings_By_PkArgs = {
  _set?: InputMaybe<Shop_Mercari_Shops_Settings_Set_Input>;
  pk_columns: Shop_Mercari_Shops_Settings_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Shop_Mercari_Shops_Settings_ManyArgs = {
  updates: Array<Shop_Mercari_Shops_Settings_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Shop_StaffArgs = {
  _set?: InputMaybe<Shop_Staff_Set_Input>;
  where: Shop_Staff_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Shop_Staff_By_PkArgs = {
  _set?: InputMaybe<Shop_Staff_Set_Input>;
  pk_columns: Shop_Staff_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Shop_Staff_ManyArgs = {
  updates: Array<Shop_Staff_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Shop_Staff_RolesArgs = {
  _set?: InputMaybe<Shop_Staff_Roles_Set_Input>;
  where: Shop_Staff_Roles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Shop_Staff_Roles_By_PkArgs = {
  _set?: InputMaybe<Shop_Staff_Roles_Set_Input>;
  pk_columns: Shop_Staff_Roles_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Shop_Staff_Roles_ManyArgs = {
  updates: Array<Shop_Staff_Roles_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Shop_StatusesArgs = {
  _set?: InputMaybe<Shop_Statuses_Set_Input>;
  where: Shop_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Shop_Statuses_By_PkArgs = {
  _set?: InputMaybe<Shop_Statuses_Set_Input>;
  pk_columns: Shop_Statuses_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Shop_Statuses_ManyArgs = {
  updates: Array<Shop_Statuses_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Shop_Yahoo_Shopping_SettingsArgs = {
  _append?: InputMaybe<Shop_Yahoo_Shopping_Settings_Append_Input>;
  _delete_at_path?: InputMaybe<Shop_Yahoo_Shopping_Settings_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Shop_Yahoo_Shopping_Settings_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Shop_Yahoo_Shopping_Settings_Delete_Key_Input>;
  _prepend?: InputMaybe<Shop_Yahoo_Shopping_Settings_Prepend_Input>;
  _set?: InputMaybe<Shop_Yahoo_Shopping_Settings_Set_Input>;
  where: Shop_Yahoo_Shopping_Settings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Shop_Yahoo_Shopping_Settings_By_PkArgs = {
  _append?: InputMaybe<Shop_Yahoo_Shopping_Settings_Append_Input>;
  _delete_at_path?: InputMaybe<Shop_Yahoo_Shopping_Settings_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Shop_Yahoo_Shopping_Settings_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Shop_Yahoo_Shopping_Settings_Delete_Key_Input>;
  _prepend?: InputMaybe<Shop_Yahoo_Shopping_Settings_Prepend_Input>;
  _set?: InputMaybe<Shop_Yahoo_Shopping_Settings_Set_Input>;
  pk_columns: Shop_Yahoo_Shopping_Settings_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Shop_Yahoo_Shopping_Settings_ManyArgs = {
  updates: Array<Shop_Yahoo_Shopping_Settings_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ShopsArgs = {
  _set?: InputMaybe<Shops_Set_Input>;
  where: Shops_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Shops_By_PkArgs = {
  _set?: InputMaybe<Shops_Set_Input>;
  pk_columns: Shops_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Shops_ManyArgs = {
  updates: Array<Shops_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_StatusesArgs = {
  _set?: InputMaybe<Subscription_Statuses_Set_Input>;
  where: Subscription_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_Statuses_By_PkArgs = {
  _set?: InputMaybe<Subscription_Statuses_Set_Input>;
  pk_columns: Subscription_Statuses_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Subscription_Statuses_ManyArgs = {
  updates: Array<Subscription_Statuses_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_SubscriptionsArgs = {
  _set?: InputMaybe<Subscriptions_Set_Input>;
  where: Subscriptions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Subscriptions_By_PkArgs = {
  _set?: InputMaybe<Subscriptions_Set_Input>;
  pk_columns: Subscriptions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Subscriptions_ManyArgs = {
  updates: Array<Subscriptions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Yahoo_Auction_CategoriesArgs = {
  _inc?: InputMaybe<Yahoo_Auction_Categories_Inc_Input>;
  _set?: InputMaybe<Yahoo_Auction_Categories_Set_Input>;
  where: Yahoo_Auction_Categories_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Yahoo_Auction_Categories_By_PkArgs = {
  _inc?: InputMaybe<Yahoo_Auction_Categories_Inc_Input>;
  _set?: InputMaybe<Yahoo_Auction_Categories_Set_Input>;
  pk_columns: Yahoo_Auction_Categories_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Yahoo_Auction_Categories_ManyArgs = {
  updates: Array<Yahoo_Auction_Categories_Updates>;
};

/** mutation root */
export type Mutation_RootUpload_ImageArgs = {
  base64str: Scalars["String"]["input"];
  type: Scalars["String"]["input"];
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = "asc",
  /** in ascending order, nulls first */
  AscNullsFirst = "asc_nulls_first",
  /** in ascending order, nulls last */
  AscNullsLast = "asc_nulls_last",
  /** in descending order, nulls first */
  Desc = "desc",
  /** in descending order, nulls first */
  DescNullsFirst = "desc_nulls_first",
  /** in descending order, nulls last */
  DescNullsLast = "desc_nulls_last",
}

/** columns and relationships of "plans" */
export type Plans = {
  key: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

/** aggregated selection of "plans" */
export type Plans_Aggregate = {
  aggregate?: Maybe<Plans_Aggregate_Fields>;
  nodes: Array<Plans>;
};

/** aggregate fields of "plans" */
export type Plans_Aggregate_Fields = {
  count: Scalars["Int"]["output"];
  max?: Maybe<Plans_Max_Fields>;
  min?: Maybe<Plans_Min_Fields>;
};

/** aggregate fields of "plans" */
export type Plans_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Plans_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Boolean expression to filter rows from the table "plans". All fields are combined with a logical 'AND'. */
export type Plans_Bool_Exp = {
  _and?: InputMaybe<Array<Plans_Bool_Exp>>;
  _not?: InputMaybe<Plans_Bool_Exp>;
  _or?: InputMaybe<Array<Plans_Bool_Exp>>;
  key?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "plans" */
export enum Plans_Constraint {
  /** unique or primary key constraint on columns "key" */
  PlansPkey = "plans_pkey",
}

/** input type for inserting data into table "plans" */
export type Plans_Insert_Input = {
  key?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type Plans_Max_Fields = {
  key?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type Plans_Min_Fields = {
  key?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "plans" */
export type Plans_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Plans>;
};

/** on_conflict condition type for table "plans" */
export type Plans_On_Conflict = {
  constraint: Plans_Constraint;
  update_columns?: Array<Plans_Update_Column>;
  where?: InputMaybe<Plans_Bool_Exp>;
};

/** Ordering options when selecting data from "plans". */
export type Plans_Order_By = {
  key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: plans */
export type Plans_Pk_Columns_Input = {
  key: Scalars["String"]["input"];
};

/** select columns of table "plans" */
export enum Plans_Select_Column {
  /** column name */
  Key = "key",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "plans" */
export type Plans_Set_Input = {
  key?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** Streaming cursor of the table "plans" */
export type Plans_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Plans_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Plans_Stream_Cursor_Value_Input = {
  key?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** update columns of table "plans" */
export enum Plans_Update_Column {
  /** column name */
  Key = "key",
  /** column name */
  Name = "name",
}

export type Plans_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Plans_Set_Input>;
  /** filter the rows which have to be updated */
  where: Plans_Bool_Exp;
};

/** ECプラットフォームを管理するテーブル */
export type Platforms = {
  icon_url?: Maybe<Scalars["String"]["output"]>;
  key: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

/** aggregated selection of "platforms" */
export type Platforms_Aggregate = {
  aggregate?: Maybe<Platforms_Aggregate_Fields>;
  nodes: Array<Platforms>;
};

/** aggregate fields of "platforms" */
export type Platforms_Aggregate_Fields = {
  count: Scalars["Int"]["output"];
  max?: Maybe<Platforms_Max_Fields>;
  min?: Maybe<Platforms_Min_Fields>;
};

/** aggregate fields of "platforms" */
export type Platforms_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Platforms_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Boolean expression to filter rows from the table "platforms". All fields are combined with a logical 'AND'. */
export type Platforms_Bool_Exp = {
  _and?: InputMaybe<Array<Platforms_Bool_Exp>>;
  _not?: InputMaybe<Platforms_Bool_Exp>;
  _or?: InputMaybe<Array<Platforms_Bool_Exp>>;
  icon_url?: InputMaybe<String_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "platforms" */
export enum Platforms_Constraint {
  /** unique or primary key constraint on columns "key" */
  PlatformsPkey = "platforms_pkey",
}

/** input type for inserting data into table "platforms" */
export type Platforms_Insert_Input = {
  icon_url?: InputMaybe<Scalars["String"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type Platforms_Max_Fields = {
  icon_url?: Maybe<Scalars["String"]["output"]>;
  key?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type Platforms_Min_Fields = {
  icon_url?: Maybe<Scalars["String"]["output"]>;
  key?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "platforms" */
export type Platforms_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Platforms>;
};

/** on_conflict condition type for table "platforms" */
export type Platforms_On_Conflict = {
  constraint: Platforms_Constraint;
  update_columns?: Array<Platforms_Update_Column>;
  where?: InputMaybe<Platforms_Bool_Exp>;
};

/** Ordering options when selecting data from "platforms". */
export type Platforms_Order_By = {
  icon_url?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: platforms */
export type Platforms_Pk_Columns_Input = {
  key: Scalars["String"]["input"];
};

/** select columns of table "platforms" */
export enum Platforms_Select_Column {
  /** column name */
  IconUrl = "icon_url",
  /** column name */
  Key = "key",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "platforms" */
export type Platforms_Set_Input = {
  icon_url?: InputMaybe<Scalars["String"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** Streaming cursor of the table "platforms" */
export type Platforms_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Platforms_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Platforms_Stream_Cursor_Value_Input = {
  icon_url?: InputMaybe<Scalars["String"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** update columns of table "platforms" */
export enum Platforms_Update_Column {
  /** column name */
  IconUrl = "icon_url",
  /** column name */
  Key = "key",
  /** column name */
  Name = "name",
}

export type Platforms_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Platforms_Set_Input>;
  /** filter the rows which have to be updated */
  where: Platforms_Bool_Exp;
};

export type Query_Root = {
  /** fetch data from the table: "brand_mercari_shops_mappings" */
  brand_mercari_shops_mappings: Array<Brand_Mercari_Shops_Mappings>;
  /** fetch aggregated fields from the table: "brand_mercari_shops_mappings" */
  brand_mercari_shops_mappings_aggregate: Brand_Mercari_Shops_Mappings_Aggregate;
  /** fetch data from the table: "brand_mercari_shops_mappings" using primary key columns */
  brand_mercari_shops_mappings_by_pk?: Maybe<Brand_Mercari_Shops_Mappings>;
  /** fetch data from the table: "brands" */
  brands: Array<Brands>;
  /** fetch aggregated fields from the table: "brands" */
  brands_aggregate: Brands_Aggregate;
  /** fetch data from the table: "brands" using primary key columns */
  brands_by_pk?: Maybe<Brands>;
  /** fetch data from the table: "exhibition_base_categories" */
  exhibition_base_categories: Array<Exhibition_Base_Categories>;
  /** fetch aggregated fields from the table: "exhibition_base_categories" */
  exhibition_base_categories_aggregate: Exhibition_Base_Categories_Aggregate;
  /** fetch data from the table: "exhibition_base_categories" using primary key columns */
  exhibition_base_categories_by_pk?: Maybe<Exhibition_Base_Categories>;
  /** fetch data from the table: "exhibition_mercari_shops_settings" */
  exhibition_mercari_shops_settings: Array<Exhibition_Mercari_Shops_Settings>;
  /** fetch aggregated fields from the table: "exhibition_mercari_shops_settings" */
  exhibition_mercari_shops_settings_aggregate: Exhibition_Mercari_Shops_Settings_Aggregate;
  /** fetch data from the table: "exhibition_mercari_shops_settings" using primary key columns */
  exhibition_mercari_shops_settings_by_pk?: Maybe<Exhibition_Mercari_Shops_Settings>;
  /** fetch data from the table: "exhibition_shopify_settings" */
  exhibition_shopify_settings: Array<Exhibition_Shopify_Settings>;
  /** fetch aggregated fields from the table: "exhibition_shopify_settings" */
  exhibition_shopify_settings_aggregate: Exhibition_Shopify_Settings_Aggregate;
  /** fetch data from the table: "exhibition_shopify_settings" using primary key columns */
  exhibition_shopify_settings_by_pk?: Maybe<Exhibition_Shopify_Settings>;
  /** fetch data from the table: "exhibition_statuses" */
  exhibition_statuses: Array<Exhibition_Statuses>;
  /** fetch aggregated fields from the table: "exhibition_statuses" */
  exhibition_statuses_aggregate: Exhibition_Statuses_Aggregate;
  /** fetch data from the table: "exhibition_statuses" using primary key columns */
  exhibition_statuses_by_pk?: Maybe<Exhibition_Statuses>;
  /** fetch data from the table: "exhibition_stores_categories" */
  exhibition_stores_categories: Array<Exhibition_Stores_Categories>;
  /** fetch aggregated fields from the table: "exhibition_stores_categories" */
  exhibition_stores_categories_aggregate: Exhibition_Stores_Categories_Aggregate;
  /** fetch data from the table: "exhibition_stores_categories" using primary key columns */
  exhibition_stores_categories_by_pk?: Maybe<Exhibition_Stores_Categories>;
  /** fetch data from the table: "exhibition_yahoo_shopping_settings" */
  exhibition_yahoo_shopping_settings: Array<Exhibition_Yahoo_Shopping_Settings>;
  /** fetch aggregated fields from the table: "exhibition_yahoo_shopping_settings" */
  exhibition_yahoo_shopping_settings_aggregate: Exhibition_Yahoo_Shopping_Settings_Aggregate;
  /** fetch data from the table: "exhibition_yahoo_shopping_settings" using primary key columns */
  exhibition_yahoo_shopping_settings_by_pk?: Maybe<Exhibition_Yahoo_Shopping_Settings>;
  /** An array relationship */
  exhibitions: Array<Exhibitions>;
  /** An aggregate relationship */
  exhibitions_aggregate: Exhibitions_Aggregate;
  /** fetch data from the table: "exhibitions" using primary key columns */
  exhibitions_by_pk?: Maybe<Exhibitions>;
  /** getMercariShopsShopId */
  getMercariShopsShopId?: Maybe<GetMercariShopsShopIdOutput>;
  /** An array relationship */
  import_exhibitions: Array<Import_Exhibitions>;
  /** An aggregate relationship */
  import_exhibitions_aggregate: Import_Exhibitions_Aggregate;
  /** fetch data from the table: "import_exhibitions" using primary key columns */
  import_exhibitions_by_pk?: Maybe<Import_Exhibitions>;
  /** fetch data from the table: "imports" */
  imports: Array<Imports>;
  /** fetch aggregated fields from the table: "imports" */
  imports_aggregate: Imports_Aggregate;
  /** fetch data from the table: "imports" using primary key columns */
  imports_by_pk?: Maybe<Imports>;
  /** fetch data from the table: "integration_shopify_settings" */
  integration_shopify_settings: Array<Integration_Shopify_Settings>;
  /** fetch aggregated fields from the table: "integration_shopify_settings" */
  integration_shopify_settings_aggregate: Integration_Shopify_Settings_Aggregate;
  /** fetch data from the table: "integration_shopify_settings" using primary key columns */
  integration_shopify_settings_by_pk?: Maybe<Integration_Shopify_Settings>;
  /** fetch data from the table: "integration_yahoo_shopping_settings" */
  integration_yahoo_shopping_settings: Array<Integration_Yahoo_Shopping_Settings>;
  /** fetch aggregated fields from the table: "integration_yahoo_shopping_settings" */
  integration_yahoo_shopping_settings_aggregate: Integration_Yahoo_Shopping_Settings_Aggregate;
  /** fetch data from the table: "integration_yahoo_shopping_settings" using primary key columns */
  integration_yahoo_shopping_settings_by_pk?: Maybe<Integration_Yahoo_Shopping_Settings>;
  /** fetch data from the table: "integrations" */
  integrations: Array<Integrations>;
  /** fetch aggregated fields from the table: "integrations" */
  integrations_aggregate: Integrations_Aggregate;
  /** fetch data from the table: "integrations" using primary key columns */
  integrations_by_pk?: Maybe<Integrations>;
  /** fetch data from the table: "item_conditions" */
  item_conditions: Array<Item_Conditions>;
  /** fetch aggregated fields from the table: "item_conditions" */
  item_conditions_aggregate: Item_Conditions_Aggregate;
  /** fetch data from the table: "item_conditions" using primary key columns */
  item_conditions_by_pk?: Maybe<Item_Conditions>;
  /** fetch data from the table: "item_images" */
  item_images: Array<Item_Images>;
  /** fetch aggregated fields from the table: "item_images" */
  item_images_aggregate: Item_Images_Aggregate;
  /** fetch data from the table: "item_images" using primary key columns */
  item_images_by_pk?: Maybe<Item_Images>;
  /** fetch data from the table: "item_sold_histories" */
  item_sold_histories: Array<Item_Sold_Histories>;
  /** fetch aggregated fields from the table: "item_sold_histories" */
  item_sold_histories_aggregate: Item_Sold_Histories_Aggregate;
  /** fetch data from the table: "item_sold_histories" using primary key columns */
  item_sold_histories_by_pk?: Maybe<Item_Sold_Histories>;
  /** fetch data from the table: "item_statuses" */
  item_statuses: Array<Item_Statuses>;
  /** fetch aggregated fields from the table: "item_statuses" */
  item_statuses_aggregate: Item_Statuses_Aggregate;
  /** fetch data from the table: "item_statuses" using primary key columns */
  item_statuses_by_pk?: Maybe<Item_Statuses>;
  /** fetch data from the table: "item_variant_exhibitions" */
  item_variant_exhibitions: Array<Item_Variant_Exhibitions>;
  /** fetch aggregated fields from the table: "item_variant_exhibitions" */
  item_variant_exhibitions_aggregate: Item_Variant_Exhibitions_Aggregate;
  /** fetch data from the table: "item_variant_exhibitions" using primary key columns */
  item_variant_exhibitions_by_pk?: Maybe<Item_Variant_Exhibitions>;
  /** fetch data from the table: "item_variants" */
  item_variants: Array<Item_Variants>;
  /** fetch aggregated fields from the table: "item_variants" */
  item_variants_aggregate: Item_Variants_Aggregate;
  /** fetch data from the table: "item_variants" using primary key columns */
  item_variants_by_pk?: Maybe<Item_Variants>;
  /** fetch data from the table: "items" */
  items: Array<Items>;
  /** fetch aggregated fields from the table: "items" */
  items_aggregate: Items_Aggregate;
  /** fetch data from the table: "items" using primary key columns */
  items_by_pk?: Maybe<Items>;
  listYahooShoppingCategories: ListYahooShoppingCategoriesOutput;
  /** listYahooStoreCategories */
  listYahooStoreCategories: ListYahooStoreCategoriesOutput;
  /** fetch data from the table: "mercari_shops_categories" */
  mercari_shops_categories: Array<Mercari_Shops_Categories>;
  /** fetch aggregated fields from the table: "mercari_shops_categories" */
  mercari_shops_categories_aggregate: Mercari_Shops_Categories_Aggregate;
  /** fetch data from the table: "mercari_shops_categories" using primary key columns */
  mercari_shops_categories_by_pk?: Maybe<Mercari_Shops_Categories>;
  /** ping */
  ping?: Maybe<PingOutput>;
  /** fetch data from the table: "plans" */
  plans: Array<Plans>;
  /** fetch aggregated fields from the table: "plans" */
  plans_aggregate: Plans_Aggregate;
  /** fetch data from the table: "plans" using primary key columns */
  plans_by_pk?: Maybe<Plans>;
  /** Get categories via platforms */
  platform_categories?: Maybe<PlatformCategoriesOutput>;
  /** fetch data from the table: "platforms" */
  platforms: Array<Platforms>;
  /** fetch aggregated fields from the table: "platforms" */
  platforms_aggregate: Platforms_Aggregate;
  /** fetch data from the table: "platforms" using primary key columns */
  platforms_by_pk?: Maybe<Platforms>;
  predictCategory?: Maybe<PredictCategoryOutput>;
  searchYahooShoppingCategories: SearchYahooShoppingCategoriesOutput;
  /** fetch data from the table: "shop_mercari_shops_settings" */
  shop_mercari_shops_settings: Array<Shop_Mercari_Shops_Settings>;
  /** fetch aggregated fields from the table: "shop_mercari_shops_settings" */
  shop_mercari_shops_settings_aggregate: Shop_Mercari_Shops_Settings_Aggregate;
  /** fetch data from the table: "shop_mercari_shops_settings" using primary key columns */
  shop_mercari_shops_settings_by_pk?: Maybe<Shop_Mercari_Shops_Settings>;
  /** fetch data from the table: "shop_staff" */
  shop_staff: Array<Shop_Staff>;
  /** fetch aggregated fields from the table: "shop_staff" */
  shop_staff_aggregate: Shop_Staff_Aggregate;
  /** fetch data from the table: "shop_staff" using primary key columns */
  shop_staff_by_pk?: Maybe<Shop_Staff>;
  /** fetch data from the table: "shop_staff_roles" */
  shop_staff_roles: Array<Shop_Staff_Roles>;
  /** fetch aggregated fields from the table: "shop_staff_roles" */
  shop_staff_roles_aggregate: Shop_Staff_Roles_Aggregate;
  /** fetch data from the table: "shop_staff_roles" using primary key columns */
  shop_staff_roles_by_pk?: Maybe<Shop_Staff_Roles>;
  /** fetch data from the table: "shop_statuses" */
  shop_statuses: Array<Shop_Statuses>;
  /** fetch aggregated fields from the table: "shop_statuses" */
  shop_statuses_aggregate: Shop_Statuses_Aggregate;
  /** fetch data from the table: "shop_statuses" using primary key columns */
  shop_statuses_by_pk?: Maybe<Shop_Statuses>;
  /** fetch data from the table: "shop_yahoo_shopping_settings" */
  shop_yahoo_shopping_settings: Array<Shop_Yahoo_Shopping_Settings>;
  /** fetch aggregated fields from the table: "shop_yahoo_shopping_settings" */
  shop_yahoo_shopping_settings_aggregate: Shop_Yahoo_Shopping_Settings_Aggregate;
  /** fetch data from the table: "shop_yahoo_shopping_settings" using primary key columns */
  shop_yahoo_shopping_settings_by_pk?: Maybe<Shop_Yahoo_Shopping_Settings>;
  /** fetch data from the table: "shops" */
  shops: Array<Shops>;
  /** fetch aggregated fields from the table: "shops" */
  shops_aggregate: Shops_Aggregate;
  /** fetch data from the table: "shops" using primary key columns */
  shops_by_pk?: Maybe<Shops>;
  /** fetch data from the table: "subscription_statuses" */
  subscription_statuses: Array<Subscription_Statuses>;
  /** fetch aggregated fields from the table: "subscription_statuses" */
  subscription_statuses_aggregate: Subscription_Statuses_Aggregate;
  /** fetch data from the table: "subscription_statuses" using primary key columns */
  subscription_statuses_by_pk?: Maybe<Subscription_Statuses>;
  /** An array relationship */
  subscriptions: Array<Subscriptions>;
  /** An aggregate relationship */
  subscriptions_aggregate: Subscriptions_Aggregate;
  /** fetch data from the table: "subscriptions" using primary key columns */
  subscriptions_by_pk?: Maybe<Subscriptions>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "yahoo_auction_categories" */
  yahoo_auction_categories: Array<Yahoo_Auction_Categories>;
  /** fetch aggregated fields from the table: "yahoo_auction_categories" */
  yahoo_auction_categories_aggregate: Yahoo_Auction_Categories_Aggregate;
  /** fetch data from the table: "yahoo_auction_categories" using primary key columns */
  yahoo_auction_categories_by_pk?: Maybe<Yahoo_Auction_Categories>;
};

export type Query_RootBrand_Mercari_Shops_MappingsArgs = {
  distinct_on?: InputMaybe<Array<Brand_Mercari_Shops_Mappings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Brand_Mercari_Shops_Mappings_Order_By>>;
  where?: InputMaybe<Brand_Mercari_Shops_Mappings_Bool_Exp>;
};

export type Query_RootBrand_Mercari_Shops_Mappings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Brand_Mercari_Shops_Mappings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Brand_Mercari_Shops_Mappings_Order_By>>;
  where?: InputMaybe<Brand_Mercari_Shops_Mappings_Bool_Exp>;
};

export type Query_RootBrand_Mercari_Shops_Mappings_By_PkArgs = {
  brand_id: Scalars["uuid"]["input"];
};

export type Query_RootBrandsArgs = {
  distinct_on?: InputMaybe<Array<Brands_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Brands_Order_By>>;
  where?: InputMaybe<Brands_Bool_Exp>;
};

export type Query_RootBrands_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Brands_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Brands_Order_By>>;
  where?: InputMaybe<Brands_Bool_Exp>;
};

export type Query_RootBrands_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootExhibition_Base_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Exhibition_Base_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Exhibition_Base_Categories_Order_By>>;
  where?: InputMaybe<Exhibition_Base_Categories_Bool_Exp>;
};

export type Query_RootExhibition_Base_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Exhibition_Base_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Exhibition_Base_Categories_Order_By>>;
  where?: InputMaybe<Exhibition_Base_Categories_Bool_Exp>;
};

export type Query_RootExhibition_Base_Categories_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootExhibition_Mercari_Shops_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Exhibition_Mercari_Shops_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Exhibition_Mercari_Shops_Settings_Order_By>>;
  where?: InputMaybe<Exhibition_Mercari_Shops_Settings_Bool_Exp>;
};

export type Query_RootExhibition_Mercari_Shops_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Exhibition_Mercari_Shops_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Exhibition_Mercari_Shops_Settings_Order_By>>;
  where?: InputMaybe<Exhibition_Mercari_Shops_Settings_Bool_Exp>;
};

export type Query_RootExhibition_Mercari_Shops_Settings_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootExhibition_Shopify_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Exhibition_Shopify_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Exhibition_Shopify_Settings_Order_By>>;
  where?: InputMaybe<Exhibition_Shopify_Settings_Bool_Exp>;
};

export type Query_RootExhibition_Shopify_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Exhibition_Shopify_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Exhibition_Shopify_Settings_Order_By>>;
  where?: InputMaybe<Exhibition_Shopify_Settings_Bool_Exp>;
};

export type Query_RootExhibition_Shopify_Settings_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootExhibition_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Exhibition_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Exhibition_Statuses_Order_By>>;
  where?: InputMaybe<Exhibition_Statuses_Bool_Exp>;
};

export type Query_RootExhibition_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Exhibition_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Exhibition_Statuses_Order_By>>;
  where?: InputMaybe<Exhibition_Statuses_Bool_Exp>;
};

export type Query_RootExhibition_Statuses_By_PkArgs = {
  key: Scalars["String"]["input"];
};

export type Query_RootExhibition_Stores_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Exhibition_Stores_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Exhibition_Stores_Categories_Order_By>>;
  where?: InputMaybe<Exhibition_Stores_Categories_Bool_Exp>;
};

export type Query_RootExhibition_Stores_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Exhibition_Stores_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Exhibition_Stores_Categories_Order_By>>;
  where?: InputMaybe<Exhibition_Stores_Categories_Bool_Exp>;
};

export type Query_RootExhibition_Stores_Categories_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootExhibition_Yahoo_Shopping_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Exhibition_Yahoo_Shopping_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Exhibition_Yahoo_Shopping_Settings_Order_By>>;
  where?: InputMaybe<Exhibition_Yahoo_Shopping_Settings_Bool_Exp>;
};

export type Query_RootExhibition_Yahoo_Shopping_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Exhibition_Yahoo_Shopping_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Exhibition_Yahoo_Shopping_Settings_Order_By>>;
  where?: InputMaybe<Exhibition_Yahoo_Shopping_Settings_Bool_Exp>;
};

export type Query_RootExhibition_Yahoo_Shopping_Settings_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootExhibitionsArgs = {
  distinct_on?: InputMaybe<Array<Exhibitions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Exhibitions_Order_By>>;
  where?: InputMaybe<Exhibitions_Bool_Exp>;
};

export type Query_RootExhibitions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Exhibitions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Exhibitions_Order_By>>;
  where?: InputMaybe<Exhibitions_Bool_Exp>;
};

export type Query_RootExhibitions_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootGetMercariShopsShopIdArgs = {
  input: GetMercariShopsShopIdInput;
};

export type Query_RootImport_ExhibitionsArgs = {
  distinct_on?: InputMaybe<Array<Import_Exhibitions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Import_Exhibitions_Order_By>>;
  where?: InputMaybe<Import_Exhibitions_Bool_Exp>;
};

export type Query_RootImport_Exhibitions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Import_Exhibitions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Import_Exhibitions_Order_By>>;
  where?: InputMaybe<Import_Exhibitions_Bool_Exp>;
};

export type Query_RootImport_Exhibitions_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootImportsArgs = {
  distinct_on?: InputMaybe<Array<Imports_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Imports_Order_By>>;
  where?: InputMaybe<Imports_Bool_Exp>;
};

export type Query_RootImports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Imports_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Imports_Order_By>>;
  where?: InputMaybe<Imports_Bool_Exp>;
};

export type Query_RootImports_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootIntegration_Shopify_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Integration_Shopify_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Integration_Shopify_Settings_Order_By>>;
  where?: InputMaybe<Integration_Shopify_Settings_Bool_Exp>;
};

export type Query_RootIntegration_Shopify_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Integration_Shopify_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Integration_Shopify_Settings_Order_By>>;
  where?: InputMaybe<Integration_Shopify_Settings_Bool_Exp>;
};

export type Query_RootIntegration_Shopify_Settings_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootIntegration_Yahoo_Shopping_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Integration_Yahoo_Shopping_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Integration_Yahoo_Shopping_Settings_Order_By>>;
  where?: InputMaybe<Integration_Yahoo_Shopping_Settings_Bool_Exp>;
};

export type Query_RootIntegration_Yahoo_Shopping_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Integration_Yahoo_Shopping_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Integration_Yahoo_Shopping_Settings_Order_By>>;
  where?: InputMaybe<Integration_Yahoo_Shopping_Settings_Bool_Exp>;
};

export type Query_RootIntegration_Yahoo_Shopping_Settings_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootIntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Integrations_Order_By>>;
  where?: InputMaybe<Integrations_Bool_Exp>;
};

export type Query_RootIntegrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Integrations_Order_By>>;
  where?: InputMaybe<Integrations_Bool_Exp>;
};

export type Query_RootIntegrations_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootItem_ConditionsArgs = {
  distinct_on?: InputMaybe<Array<Item_Conditions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Item_Conditions_Order_By>>;
  where?: InputMaybe<Item_Conditions_Bool_Exp>;
};

export type Query_RootItem_Conditions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Item_Conditions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Item_Conditions_Order_By>>;
  where?: InputMaybe<Item_Conditions_Bool_Exp>;
};

export type Query_RootItem_Conditions_By_PkArgs = {
  key: Scalars["String"]["input"];
};

export type Query_RootItem_ImagesArgs = {
  distinct_on?: InputMaybe<Array<Item_Images_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Item_Images_Order_By>>;
  where?: InputMaybe<Item_Images_Bool_Exp>;
};

export type Query_RootItem_Images_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Item_Images_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Item_Images_Order_By>>;
  where?: InputMaybe<Item_Images_Bool_Exp>;
};

export type Query_RootItem_Images_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootItem_Sold_HistoriesArgs = {
  distinct_on?: InputMaybe<Array<Item_Sold_Histories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Item_Sold_Histories_Order_By>>;
  where?: InputMaybe<Item_Sold_Histories_Bool_Exp>;
};

export type Query_RootItem_Sold_Histories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Item_Sold_Histories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Item_Sold_Histories_Order_By>>;
  where?: InputMaybe<Item_Sold_Histories_Bool_Exp>;
};

export type Query_RootItem_Sold_Histories_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootItem_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Item_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Item_Statuses_Order_By>>;
  where?: InputMaybe<Item_Statuses_Bool_Exp>;
};

export type Query_RootItem_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Item_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Item_Statuses_Order_By>>;
  where?: InputMaybe<Item_Statuses_Bool_Exp>;
};

export type Query_RootItem_Statuses_By_PkArgs = {
  key: Scalars["String"]["input"];
};

export type Query_RootItem_Variant_ExhibitionsArgs = {
  distinct_on?: InputMaybe<Array<Item_Variant_Exhibitions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Item_Variant_Exhibitions_Order_By>>;
  where?: InputMaybe<Item_Variant_Exhibitions_Bool_Exp>;
};

export type Query_RootItem_Variant_Exhibitions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Item_Variant_Exhibitions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Item_Variant_Exhibitions_Order_By>>;
  where?: InputMaybe<Item_Variant_Exhibitions_Bool_Exp>;
};

export type Query_RootItem_Variant_Exhibitions_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootItem_VariantsArgs = {
  distinct_on?: InputMaybe<Array<Item_Variants_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Item_Variants_Order_By>>;
  where?: InputMaybe<Item_Variants_Bool_Exp>;
};

export type Query_RootItem_Variants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Item_Variants_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Item_Variants_Order_By>>;
  where?: InputMaybe<Item_Variants_Bool_Exp>;
};

export type Query_RootItem_Variants_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootItemsArgs = {
  distinct_on?: InputMaybe<Array<Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Items_Order_By>>;
  where?: InputMaybe<Items_Bool_Exp>;
};

export type Query_RootItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Items_Order_By>>;
  where?: InputMaybe<Items_Bool_Exp>;
};

export type Query_RootItems_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootListYahooShoppingCategoriesArgs = {
  input?: InputMaybe<ListYahooShoppingCategoriesInput>;
};

export type Query_RootListYahooStoreCategoriesArgs = {
  input?: InputMaybe<ListYahooStoreCategoriesInput>;
};

export type Query_RootMercari_Shops_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Mercari_Shops_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Mercari_Shops_Categories_Order_By>>;
  where?: InputMaybe<Mercari_Shops_Categories_Bool_Exp>;
};

export type Query_RootMercari_Shops_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mercari_Shops_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Mercari_Shops_Categories_Order_By>>;
  where?: InputMaybe<Mercari_Shops_Categories_Bool_Exp>;
};

export type Query_RootMercari_Shops_Categories_By_PkArgs = {
  id: Scalars["String"]["input"];
};

export type Query_RootPingArgs = {
  input: PingInput;
};

export type Query_RootPlansArgs = {
  distinct_on?: InputMaybe<Array<Plans_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Plans_Order_By>>;
  where?: InputMaybe<Plans_Bool_Exp>;
};

export type Query_RootPlans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plans_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Plans_Order_By>>;
  where?: InputMaybe<Plans_Bool_Exp>;
};

export type Query_RootPlans_By_PkArgs = {
  key: Scalars["String"]["input"];
};

export type Query_RootPlatform_CategoriesArgs = {
  input: PlatformCategoriesInput;
};

export type Query_RootPlatformsArgs = {
  distinct_on?: InputMaybe<Array<Platforms_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Platforms_Order_By>>;
  where?: InputMaybe<Platforms_Bool_Exp>;
};

export type Query_RootPlatforms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Platforms_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Platforms_Order_By>>;
  where?: InputMaybe<Platforms_Bool_Exp>;
};

export type Query_RootPlatforms_By_PkArgs = {
  key: Scalars["String"]["input"];
};

export type Query_RootPredictCategoryArgs = {
  input: PredictCategoryInput;
};

export type Query_RootSearchYahooShoppingCategoriesArgs = {
  input?: InputMaybe<SearchYahooShoppingCategoriesInput>;
};

export type Query_RootShop_Mercari_Shops_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Shop_Mercari_Shops_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Shop_Mercari_Shops_Settings_Order_By>>;
  where?: InputMaybe<Shop_Mercari_Shops_Settings_Bool_Exp>;
};

export type Query_RootShop_Mercari_Shops_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Shop_Mercari_Shops_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Shop_Mercari_Shops_Settings_Order_By>>;
  where?: InputMaybe<Shop_Mercari_Shops_Settings_Bool_Exp>;
};

export type Query_RootShop_Mercari_Shops_Settings_By_PkArgs = {
  shop_id: Scalars["uuid"]["input"];
};

export type Query_RootShop_StaffArgs = {
  distinct_on?: InputMaybe<Array<Shop_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Shop_Staff_Order_By>>;
  where?: InputMaybe<Shop_Staff_Bool_Exp>;
};

export type Query_RootShop_Staff_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Shop_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Shop_Staff_Order_By>>;
  where?: InputMaybe<Shop_Staff_Bool_Exp>;
};

export type Query_RootShop_Staff_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootShop_Staff_RolesArgs = {
  distinct_on?: InputMaybe<Array<Shop_Staff_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Shop_Staff_Roles_Order_By>>;
  where?: InputMaybe<Shop_Staff_Roles_Bool_Exp>;
};

export type Query_RootShop_Staff_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Shop_Staff_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Shop_Staff_Roles_Order_By>>;
  where?: InputMaybe<Shop_Staff_Roles_Bool_Exp>;
};

export type Query_RootShop_Staff_Roles_By_PkArgs = {
  key: Scalars["String"]["input"];
};

export type Query_RootShop_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Shop_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Shop_Statuses_Order_By>>;
  where?: InputMaybe<Shop_Statuses_Bool_Exp>;
};

export type Query_RootShop_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Shop_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Shop_Statuses_Order_By>>;
  where?: InputMaybe<Shop_Statuses_Bool_Exp>;
};

export type Query_RootShop_Statuses_By_PkArgs = {
  key: Scalars["String"]["input"];
};

export type Query_RootShop_Yahoo_Shopping_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Shop_Yahoo_Shopping_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Shop_Yahoo_Shopping_Settings_Order_By>>;
  where?: InputMaybe<Shop_Yahoo_Shopping_Settings_Bool_Exp>;
};

export type Query_RootShop_Yahoo_Shopping_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Shop_Yahoo_Shopping_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Shop_Yahoo_Shopping_Settings_Order_By>>;
  where?: InputMaybe<Shop_Yahoo_Shopping_Settings_Bool_Exp>;
};

export type Query_RootShop_Yahoo_Shopping_Settings_By_PkArgs = {
  shop_id: Scalars["uuid"]["input"];
};

export type Query_RootShopsArgs = {
  distinct_on?: InputMaybe<Array<Shops_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Shops_Order_By>>;
  where?: InputMaybe<Shops_Bool_Exp>;
};

export type Query_RootShops_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Shops_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Shops_Order_By>>;
  where?: InputMaybe<Shops_Bool_Exp>;
};

export type Query_RootShops_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootSubscription_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Subscription_Statuses_Order_By>>;
  where?: InputMaybe<Subscription_Statuses_Bool_Exp>;
};

export type Query_RootSubscription_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Subscription_Statuses_Order_By>>;
  where?: InputMaybe<Subscription_Statuses_Bool_Exp>;
};

export type Query_RootSubscription_Statuses_By_PkArgs = {
  key: Scalars["String"]["input"];
};

export type Query_RootSubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};

export type Query_RootSubscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};

export type Query_RootSubscriptions_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Query_RootUsers_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootYahoo_Auction_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Yahoo_Auction_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Yahoo_Auction_Categories_Order_By>>;
  where?: InputMaybe<Yahoo_Auction_Categories_Bool_Exp>;
};

export type Query_RootYahoo_Auction_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Yahoo_Auction_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Yahoo_Auction_Categories_Order_By>>;
  where?: InputMaybe<Yahoo_Auction_Categories_Bool_Exp>;
};

export type Query_RootYahoo_Auction_Categories_By_PkArgs = {
  id: Scalars["Int"]["input"];
};

/** columns and relationships of "shop_mercari_shops_settings" */
export type Shop_Mercari_Shops_Settings = {
  default_condition: Scalars["String"]["output"];
  default_shipping_duration: Scalars["String"]["output"];
  default_shipping_from_state_id: Scalars["String"]["output"];
  default_shipping_method: Scalars["String"]["output"];
  default_shipping_payer: Scalars["String"]["output"];
  /** An object relationship */
  shop: Shops;
  shop_id: Scalars["uuid"]["output"];
};

/** aggregated selection of "shop_mercari_shops_settings" */
export type Shop_Mercari_Shops_Settings_Aggregate = {
  aggregate?: Maybe<Shop_Mercari_Shops_Settings_Aggregate_Fields>;
  nodes: Array<Shop_Mercari_Shops_Settings>;
};

/** aggregate fields of "shop_mercari_shops_settings" */
export type Shop_Mercari_Shops_Settings_Aggregate_Fields = {
  count: Scalars["Int"]["output"];
  max?: Maybe<Shop_Mercari_Shops_Settings_Max_Fields>;
  min?: Maybe<Shop_Mercari_Shops_Settings_Min_Fields>;
};

/** aggregate fields of "shop_mercari_shops_settings" */
export type Shop_Mercari_Shops_Settings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Shop_Mercari_Shops_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Boolean expression to filter rows from the table "shop_mercari_shops_settings". All fields are combined with a logical 'AND'. */
export type Shop_Mercari_Shops_Settings_Bool_Exp = {
  _and?: InputMaybe<Array<Shop_Mercari_Shops_Settings_Bool_Exp>>;
  _not?: InputMaybe<Shop_Mercari_Shops_Settings_Bool_Exp>;
  _or?: InputMaybe<Array<Shop_Mercari_Shops_Settings_Bool_Exp>>;
  default_condition?: InputMaybe<String_Comparison_Exp>;
  default_shipping_duration?: InputMaybe<String_Comparison_Exp>;
  default_shipping_from_state_id?: InputMaybe<String_Comparison_Exp>;
  default_shipping_method?: InputMaybe<String_Comparison_Exp>;
  default_shipping_payer?: InputMaybe<String_Comparison_Exp>;
  shop?: InputMaybe<Shops_Bool_Exp>;
  shop_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "shop_mercari_shops_settings" */
export enum Shop_Mercari_Shops_Settings_Constraint {
  /** unique or primary key constraint on columns "shop_id" */
  ShopMercariShopsSettingsPkey = "shop_mercari_shops_settings_pkey",
}

/** input type for inserting data into table "shop_mercari_shops_settings" */
export type Shop_Mercari_Shops_Settings_Insert_Input = {
  default_condition?: InputMaybe<Scalars["String"]["input"]>;
  default_shipping_duration?: InputMaybe<Scalars["String"]["input"]>;
  default_shipping_from_state_id?: InputMaybe<Scalars["String"]["input"]>;
  default_shipping_method?: InputMaybe<Scalars["String"]["input"]>;
  default_shipping_payer?: InputMaybe<Scalars["String"]["input"]>;
  shop?: InputMaybe<Shops_Obj_Rel_Insert_Input>;
  shop_id?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** aggregate max on columns */
export type Shop_Mercari_Shops_Settings_Max_Fields = {
  default_condition?: Maybe<Scalars["String"]["output"]>;
  default_shipping_duration?: Maybe<Scalars["String"]["output"]>;
  default_shipping_from_state_id?: Maybe<Scalars["String"]["output"]>;
  default_shipping_method?: Maybe<Scalars["String"]["output"]>;
  default_shipping_payer?: Maybe<Scalars["String"]["output"]>;
  shop_id?: Maybe<Scalars["uuid"]["output"]>;
};

/** aggregate min on columns */
export type Shop_Mercari_Shops_Settings_Min_Fields = {
  default_condition?: Maybe<Scalars["String"]["output"]>;
  default_shipping_duration?: Maybe<Scalars["String"]["output"]>;
  default_shipping_from_state_id?: Maybe<Scalars["String"]["output"]>;
  default_shipping_method?: Maybe<Scalars["String"]["output"]>;
  default_shipping_payer?: Maybe<Scalars["String"]["output"]>;
  shop_id?: Maybe<Scalars["uuid"]["output"]>;
};

/** response of any mutation on the table "shop_mercari_shops_settings" */
export type Shop_Mercari_Shops_Settings_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Shop_Mercari_Shops_Settings>;
};

/** on_conflict condition type for table "shop_mercari_shops_settings" */
export type Shop_Mercari_Shops_Settings_On_Conflict = {
  constraint: Shop_Mercari_Shops_Settings_Constraint;
  update_columns?: Array<Shop_Mercari_Shops_Settings_Update_Column>;
  where?: InputMaybe<Shop_Mercari_Shops_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "shop_mercari_shops_settings". */
export type Shop_Mercari_Shops_Settings_Order_By = {
  default_condition?: InputMaybe<Order_By>;
  default_shipping_duration?: InputMaybe<Order_By>;
  default_shipping_from_state_id?: InputMaybe<Order_By>;
  default_shipping_method?: InputMaybe<Order_By>;
  default_shipping_payer?: InputMaybe<Order_By>;
  shop?: InputMaybe<Shops_Order_By>;
  shop_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: shop_mercari_shops_settings */
export type Shop_Mercari_Shops_Settings_Pk_Columns_Input = {
  shop_id: Scalars["uuid"]["input"];
};

/** select columns of table "shop_mercari_shops_settings" */
export enum Shop_Mercari_Shops_Settings_Select_Column {
  /** column name */
  DefaultCondition = "default_condition",
  /** column name */
  DefaultShippingDuration = "default_shipping_duration",
  /** column name */
  DefaultShippingFromStateId = "default_shipping_from_state_id",
  /** column name */
  DefaultShippingMethod = "default_shipping_method",
  /** column name */
  DefaultShippingPayer = "default_shipping_payer",
  /** column name */
  ShopId = "shop_id",
}

/** input type for updating data in table "shop_mercari_shops_settings" */
export type Shop_Mercari_Shops_Settings_Set_Input = {
  default_condition?: InputMaybe<Scalars["String"]["input"]>;
  default_shipping_duration?: InputMaybe<Scalars["String"]["input"]>;
  default_shipping_from_state_id?: InputMaybe<Scalars["String"]["input"]>;
  default_shipping_method?: InputMaybe<Scalars["String"]["input"]>;
  default_shipping_payer?: InputMaybe<Scalars["String"]["input"]>;
  shop_id?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** Streaming cursor of the table "shop_mercari_shops_settings" */
export type Shop_Mercari_Shops_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shop_Mercari_Shops_Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Shop_Mercari_Shops_Settings_Stream_Cursor_Value_Input = {
  default_condition?: InputMaybe<Scalars["String"]["input"]>;
  default_shipping_duration?: InputMaybe<Scalars["String"]["input"]>;
  default_shipping_from_state_id?: InputMaybe<Scalars["String"]["input"]>;
  default_shipping_method?: InputMaybe<Scalars["String"]["input"]>;
  default_shipping_payer?: InputMaybe<Scalars["String"]["input"]>;
  shop_id?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** update columns of table "shop_mercari_shops_settings" */
export enum Shop_Mercari_Shops_Settings_Update_Column {
  /** column name */
  DefaultCondition = "default_condition",
  /** column name */
  DefaultShippingDuration = "default_shipping_duration",
  /** column name */
  DefaultShippingFromStateId = "default_shipping_from_state_id",
  /** column name */
  DefaultShippingMethod = "default_shipping_method",
  /** column name */
  DefaultShippingPayer = "default_shipping_payer",
  /** column name */
  ShopId = "shop_id",
}

export type Shop_Mercari_Shops_Settings_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Shop_Mercari_Shops_Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Shop_Mercari_Shops_Settings_Bool_Exp;
};

/** columns and relationships of "shop_staff" */
export type Shop_Staff = {
  created_at: Scalars["timestamptz"]["output"];
  id: Scalars["uuid"]["output"];
  name: Scalars["String"]["output"];
  role: Scalars["String"]["output"];
  /** An object relationship */
  shop: Shops;
  shop_id: Scalars["uuid"]["output"];
  updated_at: Scalars["timestamptz"]["output"];
  /** An object relationship */
  user: Users;
  user_id: Scalars["uuid"]["output"];
};

/** aggregated selection of "shop_staff" */
export type Shop_Staff_Aggregate = {
  aggregate?: Maybe<Shop_Staff_Aggregate_Fields>;
  nodes: Array<Shop_Staff>;
};

export type Shop_Staff_Aggregate_Bool_Exp = {
  count?: InputMaybe<Shop_Staff_Aggregate_Bool_Exp_Count>;
};

export type Shop_Staff_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Shop_Staff_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Shop_Staff_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "shop_staff" */
export type Shop_Staff_Aggregate_Fields = {
  count: Scalars["Int"]["output"];
  max?: Maybe<Shop_Staff_Max_Fields>;
  min?: Maybe<Shop_Staff_Min_Fields>;
};

/** aggregate fields of "shop_staff" */
export type Shop_Staff_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Shop_Staff_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "shop_staff" */
export type Shop_Staff_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Shop_Staff_Max_Order_By>;
  min?: InputMaybe<Shop_Staff_Min_Order_By>;
};

/** input type for inserting array relation for remote table "shop_staff" */
export type Shop_Staff_Arr_Rel_Insert_Input = {
  data: Array<Shop_Staff_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Shop_Staff_On_Conflict>;
};

/** Boolean expression to filter rows from the table "shop_staff". All fields are combined with a logical 'AND'. */
export type Shop_Staff_Bool_Exp = {
  _and?: InputMaybe<Array<Shop_Staff_Bool_Exp>>;
  _not?: InputMaybe<Shop_Staff_Bool_Exp>;
  _or?: InputMaybe<Array<Shop_Staff_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  shop?: InputMaybe<Shops_Bool_Exp>;
  shop_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "shop_staff" */
export enum Shop_Staff_Constraint {
  /** unique or primary key constraint on columns "id" */
  ShopStaffPkey = "shop_staff_pkey",
  /** unique or primary key constraint on columns "user_id", "shop_id" */
  ShopStaffShopIdUserIdKey = "shop_staff_shop_id_user_id_key",
}

/** input type for inserting data into table "shop_staff" */
export type Shop_Staff_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  role?: InputMaybe<Scalars["String"]["input"]>;
  shop?: InputMaybe<Shops_Obj_Rel_Insert_Input>;
  shop_id?: InputMaybe<Scalars["uuid"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** aggregate max on columns */
export type Shop_Staff_Max_Fields = {
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  role?: Maybe<Scalars["String"]["output"]>;
  shop_id?: Maybe<Scalars["uuid"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
  user_id?: Maybe<Scalars["uuid"]["output"]>;
};

/** order by max() on columns of table "shop_staff" */
export type Shop_Staff_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  shop_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Shop_Staff_Min_Fields = {
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  role?: Maybe<Scalars["String"]["output"]>;
  shop_id?: Maybe<Scalars["uuid"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
  user_id?: Maybe<Scalars["uuid"]["output"]>;
};

/** order by min() on columns of table "shop_staff" */
export type Shop_Staff_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  shop_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "shop_staff" */
export type Shop_Staff_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Shop_Staff>;
};

/** on_conflict condition type for table "shop_staff" */
export type Shop_Staff_On_Conflict = {
  constraint: Shop_Staff_Constraint;
  update_columns?: Array<Shop_Staff_Update_Column>;
  where?: InputMaybe<Shop_Staff_Bool_Exp>;
};

/** Ordering options when selecting data from "shop_staff". */
export type Shop_Staff_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  shop?: InputMaybe<Shops_Order_By>;
  shop_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: shop_staff */
export type Shop_Staff_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** columns and relationships of "shop_staff_roles" */
export type Shop_Staff_Roles = {
  key: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

/** aggregated selection of "shop_staff_roles" */
export type Shop_Staff_Roles_Aggregate = {
  aggregate?: Maybe<Shop_Staff_Roles_Aggregate_Fields>;
  nodes: Array<Shop_Staff_Roles>;
};

/** aggregate fields of "shop_staff_roles" */
export type Shop_Staff_Roles_Aggregate_Fields = {
  count: Scalars["Int"]["output"];
  max?: Maybe<Shop_Staff_Roles_Max_Fields>;
  min?: Maybe<Shop_Staff_Roles_Min_Fields>;
};

/** aggregate fields of "shop_staff_roles" */
export type Shop_Staff_Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Shop_Staff_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Boolean expression to filter rows from the table "shop_staff_roles". All fields are combined with a logical 'AND'. */
export type Shop_Staff_Roles_Bool_Exp = {
  _and?: InputMaybe<Array<Shop_Staff_Roles_Bool_Exp>>;
  _not?: InputMaybe<Shop_Staff_Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Shop_Staff_Roles_Bool_Exp>>;
  key?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "shop_staff_roles" */
export enum Shop_Staff_Roles_Constraint {
  /** unique or primary key constraint on columns "key" */
  ShopStaffRolesPkey = "shop_staff_roles_pkey",
}

/** input type for inserting data into table "shop_staff_roles" */
export type Shop_Staff_Roles_Insert_Input = {
  key?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type Shop_Staff_Roles_Max_Fields = {
  key?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type Shop_Staff_Roles_Min_Fields = {
  key?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "shop_staff_roles" */
export type Shop_Staff_Roles_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Shop_Staff_Roles>;
};

/** on_conflict condition type for table "shop_staff_roles" */
export type Shop_Staff_Roles_On_Conflict = {
  constraint: Shop_Staff_Roles_Constraint;
  update_columns?: Array<Shop_Staff_Roles_Update_Column>;
  where?: InputMaybe<Shop_Staff_Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "shop_staff_roles". */
export type Shop_Staff_Roles_Order_By = {
  key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: shop_staff_roles */
export type Shop_Staff_Roles_Pk_Columns_Input = {
  key: Scalars["String"]["input"];
};

/** select columns of table "shop_staff_roles" */
export enum Shop_Staff_Roles_Select_Column {
  /** column name */
  Key = "key",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "shop_staff_roles" */
export type Shop_Staff_Roles_Set_Input = {
  key?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** Streaming cursor of the table "shop_staff_roles" */
export type Shop_Staff_Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shop_Staff_Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Shop_Staff_Roles_Stream_Cursor_Value_Input = {
  key?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** update columns of table "shop_staff_roles" */
export enum Shop_Staff_Roles_Update_Column {
  /** column name */
  Key = "key",
  /** column name */
  Name = "name",
}

export type Shop_Staff_Roles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Shop_Staff_Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Shop_Staff_Roles_Bool_Exp;
};

/** select columns of table "shop_staff" */
export enum Shop_Staff_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  Role = "role",
  /** column name */
  ShopId = "shop_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "shop_staff" */
export type Shop_Staff_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  role?: InputMaybe<Scalars["String"]["input"]>;
  shop_id?: InputMaybe<Scalars["uuid"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  user_id?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** Streaming cursor of the table "shop_staff" */
export type Shop_Staff_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shop_Staff_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Shop_Staff_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  role?: InputMaybe<Scalars["String"]["input"]>;
  shop_id?: InputMaybe<Scalars["uuid"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  user_id?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** update columns of table "shop_staff" */
export enum Shop_Staff_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  Role = "role",
  /** column name */
  ShopId = "shop_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UserId = "user_id",
}

export type Shop_Staff_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Shop_Staff_Set_Input>;
  /** filter the rows which have to be updated */
  where: Shop_Staff_Bool_Exp;
};

/** columns and relationships of "shop_statuses" */
export type Shop_Statuses = {
  key: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

/** aggregated selection of "shop_statuses" */
export type Shop_Statuses_Aggregate = {
  aggregate?: Maybe<Shop_Statuses_Aggregate_Fields>;
  nodes: Array<Shop_Statuses>;
};

/** aggregate fields of "shop_statuses" */
export type Shop_Statuses_Aggregate_Fields = {
  count: Scalars["Int"]["output"];
  max?: Maybe<Shop_Statuses_Max_Fields>;
  min?: Maybe<Shop_Statuses_Min_Fields>;
};

/** aggregate fields of "shop_statuses" */
export type Shop_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Shop_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Boolean expression to filter rows from the table "shop_statuses". All fields are combined with a logical 'AND'. */
export type Shop_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Shop_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Shop_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Shop_Statuses_Bool_Exp>>;
  key?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "shop_statuses" */
export enum Shop_Statuses_Constraint {
  /** unique or primary key constraint on columns "key" */
  ShopStatusesPkey = "shop_statuses_pkey",
}

/** input type for inserting data into table "shop_statuses" */
export type Shop_Statuses_Insert_Input = {
  key?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type Shop_Statuses_Max_Fields = {
  key?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type Shop_Statuses_Min_Fields = {
  key?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "shop_statuses" */
export type Shop_Statuses_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Shop_Statuses>;
};

/** on_conflict condition type for table "shop_statuses" */
export type Shop_Statuses_On_Conflict = {
  constraint: Shop_Statuses_Constraint;
  update_columns?: Array<Shop_Statuses_Update_Column>;
  where?: InputMaybe<Shop_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "shop_statuses". */
export type Shop_Statuses_Order_By = {
  key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: shop_statuses */
export type Shop_Statuses_Pk_Columns_Input = {
  key: Scalars["String"]["input"];
};

/** select columns of table "shop_statuses" */
export enum Shop_Statuses_Select_Column {
  /** column name */
  Key = "key",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "shop_statuses" */
export type Shop_Statuses_Set_Input = {
  key?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** Streaming cursor of the table "shop_statuses" */
export type Shop_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shop_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Shop_Statuses_Stream_Cursor_Value_Input = {
  key?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** update columns of table "shop_statuses" */
export enum Shop_Statuses_Update_Column {
  /** column name */
  Key = "key",
  /** column name */
  Name = "name",
}

export type Shop_Statuses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Shop_Statuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Shop_Statuses_Bool_Exp;
};

/** columns and relationships of "shop_yahoo_shopping_settings" */
export type Shop_Yahoo_Shopping_Settings = {
  lead_times: Scalars["jsonb"]["output"];
  postage_sets: Scalars["jsonb"]["output"];
  /** An object relationship */
  shop: Shops;
  shop_id: Scalars["uuid"]["output"];
};

/** columns and relationships of "shop_yahoo_shopping_settings" */
export type Shop_Yahoo_Shopping_SettingsLead_TimesArgs = {
  path?: InputMaybe<Scalars["String"]["input"]>;
};

/** columns and relationships of "shop_yahoo_shopping_settings" */
export type Shop_Yahoo_Shopping_SettingsPostage_SetsArgs = {
  path?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregated selection of "shop_yahoo_shopping_settings" */
export type Shop_Yahoo_Shopping_Settings_Aggregate = {
  aggregate?: Maybe<Shop_Yahoo_Shopping_Settings_Aggregate_Fields>;
  nodes: Array<Shop_Yahoo_Shopping_Settings>;
};

export type Shop_Yahoo_Shopping_Settings_Aggregate_Bool_Exp = {
  count?: InputMaybe<Shop_Yahoo_Shopping_Settings_Aggregate_Bool_Exp_Count>;
};

export type Shop_Yahoo_Shopping_Settings_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Shop_Yahoo_Shopping_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Shop_Yahoo_Shopping_Settings_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "shop_yahoo_shopping_settings" */
export type Shop_Yahoo_Shopping_Settings_Aggregate_Fields = {
  count: Scalars["Int"]["output"];
  max?: Maybe<Shop_Yahoo_Shopping_Settings_Max_Fields>;
  min?: Maybe<Shop_Yahoo_Shopping_Settings_Min_Fields>;
};

/** aggregate fields of "shop_yahoo_shopping_settings" */
export type Shop_Yahoo_Shopping_Settings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Shop_Yahoo_Shopping_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "shop_yahoo_shopping_settings" */
export type Shop_Yahoo_Shopping_Settings_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Shop_Yahoo_Shopping_Settings_Max_Order_By>;
  min?: InputMaybe<Shop_Yahoo_Shopping_Settings_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Shop_Yahoo_Shopping_Settings_Append_Input = {
  lead_times?: InputMaybe<Scalars["jsonb"]["input"]>;
  postage_sets?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** input type for inserting array relation for remote table "shop_yahoo_shopping_settings" */
export type Shop_Yahoo_Shopping_Settings_Arr_Rel_Insert_Input = {
  data: Array<Shop_Yahoo_Shopping_Settings_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Shop_Yahoo_Shopping_Settings_On_Conflict>;
};

/** Boolean expression to filter rows from the table "shop_yahoo_shopping_settings". All fields are combined with a logical 'AND'. */
export type Shop_Yahoo_Shopping_Settings_Bool_Exp = {
  _and?: InputMaybe<Array<Shop_Yahoo_Shopping_Settings_Bool_Exp>>;
  _not?: InputMaybe<Shop_Yahoo_Shopping_Settings_Bool_Exp>;
  _or?: InputMaybe<Array<Shop_Yahoo_Shopping_Settings_Bool_Exp>>;
  lead_times?: InputMaybe<Jsonb_Comparison_Exp>;
  postage_sets?: InputMaybe<Jsonb_Comparison_Exp>;
  shop?: InputMaybe<Shops_Bool_Exp>;
  shop_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "shop_yahoo_shopping_settings" */
export enum Shop_Yahoo_Shopping_Settings_Constraint {
  /** unique or primary key constraint on columns "shop_id" */
  ShopYahooShoppingSettingsPkey = "shop_yahoo_shopping_settings_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Shop_Yahoo_Shopping_Settings_Delete_At_Path_Input = {
  lead_times?: InputMaybe<Array<Scalars["String"]["input"]>>;
  postage_sets?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Shop_Yahoo_Shopping_Settings_Delete_Elem_Input = {
  lead_times?: InputMaybe<Scalars["Int"]["input"]>;
  postage_sets?: InputMaybe<Scalars["Int"]["input"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Shop_Yahoo_Shopping_Settings_Delete_Key_Input = {
  lead_times?: InputMaybe<Scalars["String"]["input"]>;
  postage_sets?: InputMaybe<Scalars["String"]["input"]>;
};

/** input type for inserting data into table "shop_yahoo_shopping_settings" */
export type Shop_Yahoo_Shopping_Settings_Insert_Input = {
  lead_times?: InputMaybe<Scalars["jsonb"]["input"]>;
  postage_sets?: InputMaybe<Scalars["jsonb"]["input"]>;
  shop?: InputMaybe<Shops_Obj_Rel_Insert_Input>;
  shop_id?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** aggregate max on columns */
export type Shop_Yahoo_Shopping_Settings_Max_Fields = {
  shop_id?: Maybe<Scalars["uuid"]["output"]>;
};

/** order by max() on columns of table "shop_yahoo_shopping_settings" */
export type Shop_Yahoo_Shopping_Settings_Max_Order_By = {
  shop_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Shop_Yahoo_Shopping_Settings_Min_Fields = {
  shop_id?: Maybe<Scalars["uuid"]["output"]>;
};

/** order by min() on columns of table "shop_yahoo_shopping_settings" */
export type Shop_Yahoo_Shopping_Settings_Min_Order_By = {
  shop_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "shop_yahoo_shopping_settings" */
export type Shop_Yahoo_Shopping_Settings_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Shop_Yahoo_Shopping_Settings>;
};

/** on_conflict condition type for table "shop_yahoo_shopping_settings" */
export type Shop_Yahoo_Shopping_Settings_On_Conflict = {
  constraint: Shop_Yahoo_Shopping_Settings_Constraint;
  update_columns?: Array<Shop_Yahoo_Shopping_Settings_Update_Column>;
  where?: InputMaybe<Shop_Yahoo_Shopping_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "shop_yahoo_shopping_settings". */
export type Shop_Yahoo_Shopping_Settings_Order_By = {
  lead_times?: InputMaybe<Order_By>;
  postage_sets?: InputMaybe<Order_By>;
  shop?: InputMaybe<Shops_Order_By>;
  shop_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: shop_yahoo_shopping_settings */
export type Shop_Yahoo_Shopping_Settings_Pk_Columns_Input = {
  shop_id: Scalars["uuid"]["input"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Shop_Yahoo_Shopping_Settings_Prepend_Input = {
  lead_times?: InputMaybe<Scalars["jsonb"]["input"]>;
  postage_sets?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** select columns of table "shop_yahoo_shopping_settings" */
export enum Shop_Yahoo_Shopping_Settings_Select_Column {
  /** column name */
  LeadTimes = "lead_times",
  /** column name */
  PostageSets = "postage_sets",
  /** column name */
  ShopId = "shop_id",
}

/** input type for updating data in table "shop_yahoo_shopping_settings" */
export type Shop_Yahoo_Shopping_Settings_Set_Input = {
  lead_times?: InputMaybe<Scalars["jsonb"]["input"]>;
  postage_sets?: InputMaybe<Scalars["jsonb"]["input"]>;
  shop_id?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** Streaming cursor of the table "shop_yahoo_shopping_settings" */
export type Shop_Yahoo_Shopping_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shop_Yahoo_Shopping_Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Shop_Yahoo_Shopping_Settings_Stream_Cursor_Value_Input = {
  lead_times?: InputMaybe<Scalars["jsonb"]["input"]>;
  postage_sets?: InputMaybe<Scalars["jsonb"]["input"]>;
  shop_id?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** update columns of table "shop_yahoo_shopping_settings" */
export enum Shop_Yahoo_Shopping_Settings_Update_Column {
  /** column name */
  LeadTimes = "lead_times",
  /** column name */
  PostageSets = "postage_sets",
  /** column name */
  ShopId = "shop_id",
}

export type Shop_Yahoo_Shopping_Settings_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Shop_Yahoo_Shopping_Settings_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Shop_Yahoo_Shopping_Settings_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Shop_Yahoo_Shopping_Settings_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Shop_Yahoo_Shopping_Settings_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Shop_Yahoo_Shopping_Settings_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Shop_Yahoo_Shopping_Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Shop_Yahoo_Shopping_Settings_Bool_Exp;
};

/** ショップを管理するテーブル */
export type Shops = {
  created_at: Scalars["timestamptz"]["output"];
  id: Scalars["uuid"]["output"];
  name: Scalars["String"]["output"];
  /** An array relationship */
  staff: Array<Shop_Staff>;
  /** An aggregate relationship */
  staff_aggregate: Shop_Staff_Aggregate;
  status: Scalars["String"]["output"];
  subscription_id?: Maybe<Scalars["uuid"]["output"]>;
  /** An array relationship */
  subscriptions: Array<Subscriptions>;
  /** An aggregate relationship */
  subscriptions_aggregate: Subscriptions_Aggregate;
  updated_at: Scalars["timestamptz"]["output"];
  /** An array relationship */
  yahoo_shopping_settings: Array<Shop_Yahoo_Shopping_Settings>;
  /** An aggregate relationship */
  yahoo_shopping_settings_aggregate: Shop_Yahoo_Shopping_Settings_Aggregate;
};

/** ショップを管理するテーブル */
export type ShopsStaffArgs = {
  distinct_on?: InputMaybe<Array<Shop_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Shop_Staff_Order_By>>;
  where?: InputMaybe<Shop_Staff_Bool_Exp>;
};

/** ショップを管理するテーブル */
export type ShopsStaff_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Shop_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Shop_Staff_Order_By>>;
  where?: InputMaybe<Shop_Staff_Bool_Exp>;
};

/** ショップを管理するテーブル */
export type ShopsSubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};

/** ショップを管理するテーブル */
export type ShopsSubscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};

/** ショップを管理するテーブル */
export type ShopsYahoo_Shopping_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Shop_Yahoo_Shopping_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Shop_Yahoo_Shopping_Settings_Order_By>>;
  where?: InputMaybe<Shop_Yahoo_Shopping_Settings_Bool_Exp>;
};

/** ショップを管理するテーブル */
export type ShopsYahoo_Shopping_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Shop_Yahoo_Shopping_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Shop_Yahoo_Shopping_Settings_Order_By>>;
  where?: InputMaybe<Shop_Yahoo_Shopping_Settings_Bool_Exp>;
};

/** aggregated selection of "shops" */
export type Shops_Aggregate = {
  aggregate?: Maybe<Shops_Aggregate_Fields>;
  nodes: Array<Shops>;
};

/** aggregate fields of "shops" */
export type Shops_Aggregate_Fields = {
  count: Scalars["Int"]["output"];
  max?: Maybe<Shops_Max_Fields>;
  min?: Maybe<Shops_Min_Fields>;
};

/** aggregate fields of "shops" */
export type Shops_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Shops_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Boolean expression to filter rows from the table "shops". All fields are combined with a logical 'AND'. */
export type Shops_Bool_Exp = {
  _and?: InputMaybe<Array<Shops_Bool_Exp>>;
  _not?: InputMaybe<Shops_Bool_Exp>;
  _or?: InputMaybe<Array<Shops_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  staff?: InputMaybe<Shop_Staff_Bool_Exp>;
  staff_aggregate?: InputMaybe<Shop_Staff_Aggregate_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  subscription_id?: InputMaybe<Uuid_Comparison_Exp>;
  subscriptions?: InputMaybe<Subscriptions_Bool_Exp>;
  subscriptions_aggregate?: InputMaybe<Subscriptions_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  yahoo_shopping_settings?: InputMaybe<Shop_Yahoo_Shopping_Settings_Bool_Exp>;
  yahoo_shopping_settings_aggregate?: InputMaybe<Shop_Yahoo_Shopping_Settings_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "shops" */
export enum Shops_Constraint {
  /** unique or primary key constraint on columns "id" */
  ShopsPkey = "shops_pkey",
}

/** input type for inserting data into table "shops" */
export type Shops_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  staff?: InputMaybe<Shop_Staff_Arr_Rel_Insert_Input>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  subscription_id?: InputMaybe<Scalars["uuid"]["input"]>;
  subscriptions?: InputMaybe<Subscriptions_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  yahoo_shopping_settings?: InputMaybe<Shop_Yahoo_Shopping_Settings_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Shops_Max_Fields = {
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  subscription_id?: Maybe<Scalars["uuid"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** aggregate min on columns */
export type Shops_Min_Fields = {
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  subscription_id?: Maybe<Scalars["uuid"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** response of any mutation on the table "shops" */
export type Shops_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Shops>;
};

/** input type for inserting object relation for remote table "shops" */
export type Shops_Obj_Rel_Insert_Input = {
  data: Shops_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Shops_On_Conflict>;
};

/** on_conflict condition type for table "shops" */
export type Shops_On_Conflict = {
  constraint: Shops_Constraint;
  update_columns?: Array<Shops_Update_Column>;
  where?: InputMaybe<Shops_Bool_Exp>;
};

/** Ordering options when selecting data from "shops". */
export type Shops_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  staff_aggregate?: InputMaybe<Shop_Staff_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  subscriptions_aggregate?: InputMaybe<Subscriptions_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  yahoo_shopping_settings_aggregate?: InputMaybe<Shop_Yahoo_Shopping_Settings_Aggregate_Order_By>;
};

/** primary key columns input for table: shops */
export type Shops_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "shops" */
export enum Shops_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  Status = "status",
  /** column name */
  SubscriptionId = "subscription_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "shops" */
export type Shops_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  subscription_id?: InputMaybe<Scalars["uuid"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** Streaming cursor of the table "shops" */
export type Shops_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shops_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Shops_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  subscription_id?: InputMaybe<Scalars["uuid"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** update columns of table "shops" */
export enum Shops_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  Status = "status",
  /** column name */
  SubscriptionId = "subscription_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Shops_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Shops_Set_Input>;
  /** filter the rows which have to be updated */
  where: Shops_Bool_Exp;
};

export type Subscription_Root = {
  /** fetch data from the table: "brand_mercari_shops_mappings" */
  brand_mercari_shops_mappings: Array<Brand_Mercari_Shops_Mappings>;
  /** fetch aggregated fields from the table: "brand_mercari_shops_mappings" */
  brand_mercari_shops_mappings_aggregate: Brand_Mercari_Shops_Mappings_Aggregate;
  /** fetch data from the table: "brand_mercari_shops_mappings" using primary key columns */
  brand_mercari_shops_mappings_by_pk?: Maybe<Brand_Mercari_Shops_Mappings>;
  /** fetch data from the table in a streaming manner: "brand_mercari_shops_mappings" */
  brand_mercari_shops_mappings_stream: Array<Brand_Mercari_Shops_Mappings>;
  /** fetch data from the table: "brands" */
  brands: Array<Brands>;
  /** fetch aggregated fields from the table: "brands" */
  brands_aggregate: Brands_Aggregate;
  /** fetch data from the table: "brands" using primary key columns */
  brands_by_pk?: Maybe<Brands>;
  /** fetch data from the table in a streaming manner: "brands" */
  brands_stream: Array<Brands>;
  /** fetch data from the table: "exhibition_base_categories" */
  exhibition_base_categories: Array<Exhibition_Base_Categories>;
  /** fetch aggregated fields from the table: "exhibition_base_categories" */
  exhibition_base_categories_aggregate: Exhibition_Base_Categories_Aggregate;
  /** fetch data from the table: "exhibition_base_categories" using primary key columns */
  exhibition_base_categories_by_pk?: Maybe<Exhibition_Base_Categories>;
  /** fetch data from the table in a streaming manner: "exhibition_base_categories" */
  exhibition_base_categories_stream: Array<Exhibition_Base_Categories>;
  /** fetch data from the table: "exhibition_mercari_shops_settings" */
  exhibition_mercari_shops_settings: Array<Exhibition_Mercari_Shops_Settings>;
  /** fetch aggregated fields from the table: "exhibition_mercari_shops_settings" */
  exhibition_mercari_shops_settings_aggregate: Exhibition_Mercari_Shops_Settings_Aggregate;
  /** fetch data from the table: "exhibition_mercari_shops_settings" using primary key columns */
  exhibition_mercari_shops_settings_by_pk?: Maybe<Exhibition_Mercari_Shops_Settings>;
  /** fetch data from the table in a streaming manner: "exhibition_mercari_shops_settings" */
  exhibition_mercari_shops_settings_stream: Array<Exhibition_Mercari_Shops_Settings>;
  /** fetch data from the table: "exhibition_shopify_settings" */
  exhibition_shopify_settings: Array<Exhibition_Shopify_Settings>;
  /** fetch aggregated fields from the table: "exhibition_shopify_settings" */
  exhibition_shopify_settings_aggregate: Exhibition_Shopify_Settings_Aggregate;
  /** fetch data from the table: "exhibition_shopify_settings" using primary key columns */
  exhibition_shopify_settings_by_pk?: Maybe<Exhibition_Shopify_Settings>;
  /** fetch data from the table in a streaming manner: "exhibition_shopify_settings" */
  exhibition_shopify_settings_stream: Array<Exhibition_Shopify_Settings>;
  /** fetch data from the table: "exhibition_statuses" */
  exhibition_statuses: Array<Exhibition_Statuses>;
  /** fetch aggregated fields from the table: "exhibition_statuses" */
  exhibition_statuses_aggregate: Exhibition_Statuses_Aggregate;
  /** fetch data from the table: "exhibition_statuses" using primary key columns */
  exhibition_statuses_by_pk?: Maybe<Exhibition_Statuses>;
  /** fetch data from the table in a streaming manner: "exhibition_statuses" */
  exhibition_statuses_stream: Array<Exhibition_Statuses>;
  /** fetch data from the table: "exhibition_stores_categories" */
  exhibition_stores_categories: Array<Exhibition_Stores_Categories>;
  /** fetch aggregated fields from the table: "exhibition_stores_categories" */
  exhibition_stores_categories_aggregate: Exhibition_Stores_Categories_Aggregate;
  /** fetch data from the table: "exhibition_stores_categories" using primary key columns */
  exhibition_stores_categories_by_pk?: Maybe<Exhibition_Stores_Categories>;
  /** fetch data from the table in a streaming manner: "exhibition_stores_categories" */
  exhibition_stores_categories_stream: Array<Exhibition_Stores_Categories>;
  /** fetch data from the table: "exhibition_yahoo_shopping_settings" */
  exhibition_yahoo_shopping_settings: Array<Exhibition_Yahoo_Shopping_Settings>;
  /** fetch aggregated fields from the table: "exhibition_yahoo_shopping_settings" */
  exhibition_yahoo_shopping_settings_aggregate: Exhibition_Yahoo_Shopping_Settings_Aggregate;
  /** fetch data from the table: "exhibition_yahoo_shopping_settings" using primary key columns */
  exhibition_yahoo_shopping_settings_by_pk?: Maybe<Exhibition_Yahoo_Shopping_Settings>;
  /** fetch data from the table in a streaming manner: "exhibition_yahoo_shopping_settings" */
  exhibition_yahoo_shopping_settings_stream: Array<Exhibition_Yahoo_Shopping_Settings>;
  /** An array relationship */
  exhibitions: Array<Exhibitions>;
  /** An aggregate relationship */
  exhibitions_aggregate: Exhibitions_Aggregate;
  /** fetch data from the table: "exhibitions" using primary key columns */
  exhibitions_by_pk?: Maybe<Exhibitions>;
  /** fetch data from the table in a streaming manner: "exhibitions" */
  exhibitions_stream: Array<Exhibitions>;
  /** An array relationship */
  import_exhibitions: Array<Import_Exhibitions>;
  /** An aggregate relationship */
  import_exhibitions_aggregate: Import_Exhibitions_Aggregate;
  /** fetch data from the table: "import_exhibitions" using primary key columns */
  import_exhibitions_by_pk?: Maybe<Import_Exhibitions>;
  /** fetch data from the table in a streaming manner: "import_exhibitions" */
  import_exhibitions_stream: Array<Import_Exhibitions>;
  /** fetch data from the table: "imports" */
  imports: Array<Imports>;
  /** fetch aggregated fields from the table: "imports" */
  imports_aggregate: Imports_Aggregate;
  /** fetch data from the table: "imports" using primary key columns */
  imports_by_pk?: Maybe<Imports>;
  /** fetch data from the table in a streaming manner: "imports" */
  imports_stream: Array<Imports>;
  /** fetch data from the table: "integration_shopify_settings" */
  integration_shopify_settings: Array<Integration_Shopify_Settings>;
  /** fetch aggregated fields from the table: "integration_shopify_settings" */
  integration_shopify_settings_aggregate: Integration_Shopify_Settings_Aggregate;
  /** fetch data from the table: "integration_shopify_settings" using primary key columns */
  integration_shopify_settings_by_pk?: Maybe<Integration_Shopify_Settings>;
  /** fetch data from the table in a streaming manner: "integration_shopify_settings" */
  integration_shopify_settings_stream: Array<Integration_Shopify_Settings>;
  /** fetch data from the table: "integration_yahoo_shopping_settings" */
  integration_yahoo_shopping_settings: Array<Integration_Yahoo_Shopping_Settings>;
  /** fetch aggregated fields from the table: "integration_yahoo_shopping_settings" */
  integration_yahoo_shopping_settings_aggregate: Integration_Yahoo_Shopping_Settings_Aggregate;
  /** fetch data from the table: "integration_yahoo_shopping_settings" using primary key columns */
  integration_yahoo_shopping_settings_by_pk?: Maybe<Integration_Yahoo_Shopping_Settings>;
  /** fetch data from the table in a streaming manner: "integration_yahoo_shopping_settings" */
  integration_yahoo_shopping_settings_stream: Array<Integration_Yahoo_Shopping_Settings>;
  /** fetch data from the table: "integrations" */
  integrations: Array<Integrations>;
  /** fetch aggregated fields from the table: "integrations" */
  integrations_aggregate: Integrations_Aggregate;
  /** fetch data from the table: "integrations" using primary key columns */
  integrations_by_pk?: Maybe<Integrations>;
  /** fetch data from the table in a streaming manner: "integrations" */
  integrations_stream: Array<Integrations>;
  /** fetch data from the table: "item_conditions" */
  item_conditions: Array<Item_Conditions>;
  /** fetch aggregated fields from the table: "item_conditions" */
  item_conditions_aggregate: Item_Conditions_Aggregate;
  /** fetch data from the table: "item_conditions" using primary key columns */
  item_conditions_by_pk?: Maybe<Item_Conditions>;
  /** fetch data from the table in a streaming manner: "item_conditions" */
  item_conditions_stream: Array<Item_Conditions>;
  /** fetch data from the table: "item_images" */
  item_images: Array<Item_Images>;
  /** fetch aggregated fields from the table: "item_images" */
  item_images_aggregate: Item_Images_Aggregate;
  /** fetch data from the table: "item_images" using primary key columns */
  item_images_by_pk?: Maybe<Item_Images>;
  /** fetch data from the table in a streaming manner: "item_images" */
  item_images_stream: Array<Item_Images>;
  /** fetch data from the table: "item_sold_histories" */
  item_sold_histories: Array<Item_Sold_Histories>;
  /** fetch aggregated fields from the table: "item_sold_histories" */
  item_sold_histories_aggregate: Item_Sold_Histories_Aggregate;
  /** fetch data from the table: "item_sold_histories" using primary key columns */
  item_sold_histories_by_pk?: Maybe<Item_Sold_Histories>;
  /** fetch data from the table in a streaming manner: "item_sold_histories" */
  item_sold_histories_stream: Array<Item_Sold_Histories>;
  /** fetch data from the table: "item_statuses" */
  item_statuses: Array<Item_Statuses>;
  /** fetch aggregated fields from the table: "item_statuses" */
  item_statuses_aggregate: Item_Statuses_Aggregate;
  /** fetch data from the table: "item_statuses" using primary key columns */
  item_statuses_by_pk?: Maybe<Item_Statuses>;
  /** fetch data from the table in a streaming manner: "item_statuses" */
  item_statuses_stream: Array<Item_Statuses>;
  /** fetch data from the table: "item_variant_exhibitions" */
  item_variant_exhibitions: Array<Item_Variant_Exhibitions>;
  /** fetch aggregated fields from the table: "item_variant_exhibitions" */
  item_variant_exhibitions_aggregate: Item_Variant_Exhibitions_Aggregate;
  /** fetch data from the table: "item_variant_exhibitions" using primary key columns */
  item_variant_exhibitions_by_pk?: Maybe<Item_Variant_Exhibitions>;
  /** fetch data from the table in a streaming manner: "item_variant_exhibitions" */
  item_variant_exhibitions_stream: Array<Item_Variant_Exhibitions>;
  /** fetch data from the table: "item_variants" */
  item_variants: Array<Item_Variants>;
  /** fetch aggregated fields from the table: "item_variants" */
  item_variants_aggregate: Item_Variants_Aggregate;
  /** fetch data from the table: "item_variants" using primary key columns */
  item_variants_by_pk?: Maybe<Item_Variants>;
  /** fetch data from the table in a streaming manner: "item_variants" */
  item_variants_stream: Array<Item_Variants>;
  /** fetch data from the table: "items" */
  items: Array<Items>;
  /** fetch aggregated fields from the table: "items" */
  items_aggregate: Items_Aggregate;
  /** fetch data from the table: "items" using primary key columns */
  items_by_pk?: Maybe<Items>;
  /** fetch data from the table in a streaming manner: "items" */
  items_stream: Array<Items>;
  /** fetch data from the table: "mercari_shops_categories" */
  mercari_shops_categories: Array<Mercari_Shops_Categories>;
  /** fetch aggregated fields from the table: "mercari_shops_categories" */
  mercari_shops_categories_aggregate: Mercari_Shops_Categories_Aggregate;
  /** fetch data from the table: "mercari_shops_categories" using primary key columns */
  mercari_shops_categories_by_pk?: Maybe<Mercari_Shops_Categories>;
  /** fetch data from the table in a streaming manner: "mercari_shops_categories" */
  mercari_shops_categories_stream: Array<Mercari_Shops_Categories>;
  /** fetch data from the table: "plans" */
  plans: Array<Plans>;
  /** fetch aggregated fields from the table: "plans" */
  plans_aggregate: Plans_Aggregate;
  /** fetch data from the table: "plans" using primary key columns */
  plans_by_pk?: Maybe<Plans>;
  /** fetch data from the table in a streaming manner: "plans" */
  plans_stream: Array<Plans>;
  /** fetch data from the table: "platforms" */
  platforms: Array<Platforms>;
  /** fetch aggregated fields from the table: "platforms" */
  platforms_aggregate: Platforms_Aggregate;
  /** fetch data from the table: "platforms" using primary key columns */
  platforms_by_pk?: Maybe<Platforms>;
  /** fetch data from the table in a streaming manner: "platforms" */
  platforms_stream: Array<Platforms>;
  /** fetch data from the table: "shop_mercari_shops_settings" */
  shop_mercari_shops_settings: Array<Shop_Mercari_Shops_Settings>;
  /** fetch aggregated fields from the table: "shop_mercari_shops_settings" */
  shop_mercari_shops_settings_aggregate: Shop_Mercari_Shops_Settings_Aggregate;
  /** fetch data from the table: "shop_mercari_shops_settings" using primary key columns */
  shop_mercari_shops_settings_by_pk?: Maybe<Shop_Mercari_Shops_Settings>;
  /** fetch data from the table in a streaming manner: "shop_mercari_shops_settings" */
  shop_mercari_shops_settings_stream: Array<Shop_Mercari_Shops_Settings>;
  /** fetch data from the table: "shop_staff" */
  shop_staff: Array<Shop_Staff>;
  /** fetch aggregated fields from the table: "shop_staff" */
  shop_staff_aggregate: Shop_Staff_Aggregate;
  /** fetch data from the table: "shop_staff" using primary key columns */
  shop_staff_by_pk?: Maybe<Shop_Staff>;
  /** fetch data from the table: "shop_staff_roles" */
  shop_staff_roles: Array<Shop_Staff_Roles>;
  /** fetch aggregated fields from the table: "shop_staff_roles" */
  shop_staff_roles_aggregate: Shop_Staff_Roles_Aggregate;
  /** fetch data from the table: "shop_staff_roles" using primary key columns */
  shop_staff_roles_by_pk?: Maybe<Shop_Staff_Roles>;
  /** fetch data from the table in a streaming manner: "shop_staff_roles" */
  shop_staff_roles_stream: Array<Shop_Staff_Roles>;
  /** fetch data from the table in a streaming manner: "shop_staff" */
  shop_staff_stream: Array<Shop_Staff>;
  /** fetch data from the table: "shop_statuses" */
  shop_statuses: Array<Shop_Statuses>;
  /** fetch aggregated fields from the table: "shop_statuses" */
  shop_statuses_aggregate: Shop_Statuses_Aggregate;
  /** fetch data from the table: "shop_statuses" using primary key columns */
  shop_statuses_by_pk?: Maybe<Shop_Statuses>;
  /** fetch data from the table in a streaming manner: "shop_statuses" */
  shop_statuses_stream: Array<Shop_Statuses>;
  /** fetch data from the table: "shop_yahoo_shopping_settings" */
  shop_yahoo_shopping_settings: Array<Shop_Yahoo_Shopping_Settings>;
  /** fetch aggregated fields from the table: "shop_yahoo_shopping_settings" */
  shop_yahoo_shopping_settings_aggregate: Shop_Yahoo_Shopping_Settings_Aggregate;
  /** fetch data from the table: "shop_yahoo_shopping_settings" using primary key columns */
  shop_yahoo_shopping_settings_by_pk?: Maybe<Shop_Yahoo_Shopping_Settings>;
  /** fetch data from the table in a streaming manner: "shop_yahoo_shopping_settings" */
  shop_yahoo_shopping_settings_stream: Array<Shop_Yahoo_Shopping_Settings>;
  /** fetch data from the table: "shops" */
  shops: Array<Shops>;
  /** fetch aggregated fields from the table: "shops" */
  shops_aggregate: Shops_Aggregate;
  /** fetch data from the table: "shops" using primary key columns */
  shops_by_pk?: Maybe<Shops>;
  /** fetch data from the table in a streaming manner: "shops" */
  shops_stream: Array<Shops>;
  /** fetch data from the table: "subscription_statuses" */
  subscription_statuses: Array<Subscription_Statuses>;
  /** fetch aggregated fields from the table: "subscription_statuses" */
  subscription_statuses_aggregate: Subscription_Statuses_Aggregate;
  /** fetch data from the table: "subscription_statuses" using primary key columns */
  subscription_statuses_by_pk?: Maybe<Subscription_Statuses>;
  /** fetch data from the table in a streaming manner: "subscription_statuses" */
  subscription_statuses_stream: Array<Subscription_Statuses>;
  /** An array relationship */
  subscriptions: Array<Subscriptions>;
  /** An aggregate relationship */
  subscriptions_aggregate: Subscriptions_Aggregate;
  /** fetch data from the table: "subscriptions" using primary key columns */
  subscriptions_by_pk?: Maybe<Subscriptions>;
  /** fetch data from the table in a streaming manner: "subscriptions" */
  subscriptions_stream: Array<Subscriptions>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
  /** fetch data from the table: "yahoo_auction_categories" */
  yahoo_auction_categories: Array<Yahoo_Auction_Categories>;
  /** fetch aggregated fields from the table: "yahoo_auction_categories" */
  yahoo_auction_categories_aggregate: Yahoo_Auction_Categories_Aggregate;
  /** fetch data from the table: "yahoo_auction_categories" using primary key columns */
  yahoo_auction_categories_by_pk?: Maybe<Yahoo_Auction_Categories>;
  /** fetch data from the table in a streaming manner: "yahoo_auction_categories" */
  yahoo_auction_categories_stream: Array<Yahoo_Auction_Categories>;
};

export type Subscription_RootBrand_Mercari_Shops_MappingsArgs = {
  distinct_on?: InputMaybe<Array<Brand_Mercari_Shops_Mappings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Brand_Mercari_Shops_Mappings_Order_By>>;
  where?: InputMaybe<Brand_Mercari_Shops_Mappings_Bool_Exp>;
};

export type Subscription_RootBrand_Mercari_Shops_Mappings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Brand_Mercari_Shops_Mappings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Brand_Mercari_Shops_Mappings_Order_By>>;
  where?: InputMaybe<Brand_Mercari_Shops_Mappings_Bool_Exp>;
};

export type Subscription_RootBrand_Mercari_Shops_Mappings_By_PkArgs = {
  brand_id: Scalars["uuid"]["input"];
};

export type Subscription_RootBrand_Mercari_Shops_Mappings_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Brand_Mercari_Shops_Mappings_Stream_Cursor_Input>>;
  where?: InputMaybe<Brand_Mercari_Shops_Mappings_Bool_Exp>;
};

export type Subscription_RootBrandsArgs = {
  distinct_on?: InputMaybe<Array<Brands_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Brands_Order_By>>;
  where?: InputMaybe<Brands_Bool_Exp>;
};

export type Subscription_RootBrands_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Brands_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Brands_Order_By>>;
  where?: InputMaybe<Brands_Bool_Exp>;
};

export type Subscription_RootBrands_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootBrands_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Brands_Stream_Cursor_Input>>;
  where?: InputMaybe<Brands_Bool_Exp>;
};

export type Subscription_RootExhibition_Base_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Exhibition_Base_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Exhibition_Base_Categories_Order_By>>;
  where?: InputMaybe<Exhibition_Base_Categories_Bool_Exp>;
};

export type Subscription_RootExhibition_Base_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Exhibition_Base_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Exhibition_Base_Categories_Order_By>>;
  where?: InputMaybe<Exhibition_Base_Categories_Bool_Exp>;
};

export type Subscription_RootExhibition_Base_Categories_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootExhibition_Base_Categories_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Exhibition_Base_Categories_Stream_Cursor_Input>>;
  where?: InputMaybe<Exhibition_Base_Categories_Bool_Exp>;
};

export type Subscription_RootExhibition_Mercari_Shops_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Exhibition_Mercari_Shops_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Exhibition_Mercari_Shops_Settings_Order_By>>;
  where?: InputMaybe<Exhibition_Mercari_Shops_Settings_Bool_Exp>;
};

export type Subscription_RootExhibition_Mercari_Shops_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Exhibition_Mercari_Shops_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Exhibition_Mercari_Shops_Settings_Order_By>>;
  where?: InputMaybe<Exhibition_Mercari_Shops_Settings_Bool_Exp>;
};

export type Subscription_RootExhibition_Mercari_Shops_Settings_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootExhibition_Mercari_Shops_Settings_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Exhibition_Mercari_Shops_Settings_Stream_Cursor_Input>>;
  where?: InputMaybe<Exhibition_Mercari_Shops_Settings_Bool_Exp>;
};

export type Subscription_RootExhibition_Shopify_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Exhibition_Shopify_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Exhibition_Shopify_Settings_Order_By>>;
  where?: InputMaybe<Exhibition_Shopify_Settings_Bool_Exp>;
};

export type Subscription_RootExhibition_Shopify_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Exhibition_Shopify_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Exhibition_Shopify_Settings_Order_By>>;
  where?: InputMaybe<Exhibition_Shopify_Settings_Bool_Exp>;
};

export type Subscription_RootExhibition_Shopify_Settings_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootExhibition_Shopify_Settings_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Exhibition_Shopify_Settings_Stream_Cursor_Input>>;
  where?: InputMaybe<Exhibition_Shopify_Settings_Bool_Exp>;
};

export type Subscription_RootExhibition_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Exhibition_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Exhibition_Statuses_Order_By>>;
  where?: InputMaybe<Exhibition_Statuses_Bool_Exp>;
};

export type Subscription_RootExhibition_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Exhibition_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Exhibition_Statuses_Order_By>>;
  where?: InputMaybe<Exhibition_Statuses_Bool_Exp>;
};

export type Subscription_RootExhibition_Statuses_By_PkArgs = {
  key: Scalars["String"]["input"];
};

export type Subscription_RootExhibition_Statuses_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Exhibition_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Exhibition_Statuses_Bool_Exp>;
};

export type Subscription_RootExhibition_Stores_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Exhibition_Stores_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Exhibition_Stores_Categories_Order_By>>;
  where?: InputMaybe<Exhibition_Stores_Categories_Bool_Exp>;
};

export type Subscription_RootExhibition_Stores_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Exhibition_Stores_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Exhibition_Stores_Categories_Order_By>>;
  where?: InputMaybe<Exhibition_Stores_Categories_Bool_Exp>;
};

export type Subscription_RootExhibition_Stores_Categories_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootExhibition_Stores_Categories_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Exhibition_Stores_Categories_Stream_Cursor_Input>>;
  where?: InputMaybe<Exhibition_Stores_Categories_Bool_Exp>;
};

export type Subscription_RootExhibition_Yahoo_Shopping_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Exhibition_Yahoo_Shopping_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Exhibition_Yahoo_Shopping_Settings_Order_By>>;
  where?: InputMaybe<Exhibition_Yahoo_Shopping_Settings_Bool_Exp>;
};

export type Subscription_RootExhibition_Yahoo_Shopping_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Exhibition_Yahoo_Shopping_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Exhibition_Yahoo_Shopping_Settings_Order_By>>;
  where?: InputMaybe<Exhibition_Yahoo_Shopping_Settings_Bool_Exp>;
};

export type Subscription_RootExhibition_Yahoo_Shopping_Settings_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootExhibition_Yahoo_Shopping_Settings_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Exhibition_Yahoo_Shopping_Settings_Stream_Cursor_Input>>;
  where?: InputMaybe<Exhibition_Yahoo_Shopping_Settings_Bool_Exp>;
};

export type Subscription_RootExhibitionsArgs = {
  distinct_on?: InputMaybe<Array<Exhibitions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Exhibitions_Order_By>>;
  where?: InputMaybe<Exhibitions_Bool_Exp>;
};

export type Subscription_RootExhibitions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Exhibitions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Exhibitions_Order_By>>;
  where?: InputMaybe<Exhibitions_Bool_Exp>;
};

export type Subscription_RootExhibitions_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootExhibitions_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Exhibitions_Stream_Cursor_Input>>;
  where?: InputMaybe<Exhibitions_Bool_Exp>;
};

export type Subscription_RootImport_ExhibitionsArgs = {
  distinct_on?: InputMaybe<Array<Import_Exhibitions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Import_Exhibitions_Order_By>>;
  where?: InputMaybe<Import_Exhibitions_Bool_Exp>;
};

export type Subscription_RootImport_Exhibitions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Import_Exhibitions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Import_Exhibitions_Order_By>>;
  where?: InputMaybe<Import_Exhibitions_Bool_Exp>;
};

export type Subscription_RootImport_Exhibitions_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootImport_Exhibitions_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Import_Exhibitions_Stream_Cursor_Input>>;
  where?: InputMaybe<Import_Exhibitions_Bool_Exp>;
};

export type Subscription_RootImportsArgs = {
  distinct_on?: InputMaybe<Array<Imports_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Imports_Order_By>>;
  where?: InputMaybe<Imports_Bool_Exp>;
};

export type Subscription_RootImports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Imports_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Imports_Order_By>>;
  where?: InputMaybe<Imports_Bool_Exp>;
};

export type Subscription_RootImports_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootImports_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Imports_Stream_Cursor_Input>>;
  where?: InputMaybe<Imports_Bool_Exp>;
};

export type Subscription_RootIntegration_Shopify_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Integration_Shopify_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Integration_Shopify_Settings_Order_By>>;
  where?: InputMaybe<Integration_Shopify_Settings_Bool_Exp>;
};

export type Subscription_RootIntegration_Shopify_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Integration_Shopify_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Integration_Shopify_Settings_Order_By>>;
  where?: InputMaybe<Integration_Shopify_Settings_Bool_Exp>;
};

export type Subscription_RootIntegration_Shopify_Settings_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootIntegration_Shopify_Settings_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Integration_Shopify_Settings_Stream_Cursor_Input>>;
  where?: InputMaybe<Integration_Shopify_Settings_Bool_Exp>;
};

export type Subscription_RootIntegration_Yahoo_Shopping_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Integration_Yahoo_Shopping_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Integration_Yahoo_Shopping_Settings_Order_By>>;
  where?: InputMaybe<Integration_Yahoo_Shopping_Settings_Bool_Exp>;
};

export type Subscription_RootIntegration_Yahoo_Shopping_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Integration_Yahoo_Shopping_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Integration_Yahoo_Shopping_Settings_Order_By>>;
  where?: InputMaybe<Integration_Yahoo_Shopping_Settings_Bool_Exp>;
};

export type Subscription_RootIntegration_Yahoo_Shopping_Settings_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootIntegration_Yahoo_Shopping_Settings_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Integration_Yahoo_Shopping_Settings_Stream_Cursor_Input>>;
  where?: InputMaybe<Integration_Yahoo_Shopping_Settings_Bool_Exp>;
};

export type Subscription_RootIntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Integrations_Order_By>>;
  where?: InputMaybe<Integrations_Bool_Exp>;
};

export type Subscription_RootIntegrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Integrations_Order_By>>;
  where?: InputMaybe<Integrations_Bool_Exp>;
};

export type Subscription_RootIntegrations_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootIntegrations_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Integrations_Stream_Cursor_Input>>;
  where?: InputMaybe<Integrations_Bool_Exp>;
};

export type Subscription_RootItem_ConditionsArgs = {
  distinct_on?: InputMaybe<Array<Item_Conditions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Item_Conditions_Order_By>>;
  where?: InputMaybe<Item_Conditions_Bool_Exp>;
};

export type Subscription_RootItem_Conditions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Item_Conditions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Item_Conditions_Order_By>>;
  where?: InputMaybe<Item_Conditions_Bool_Exp>;
};

export type Subscription_RootItem_Conditions_By_PkArgs = {
  key: Scalars["String"]["input"];
};

export type Subscription_RootItem_Conditions_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Item_Conditions_Stream_Cursor_Input>>;
  where?: InputMaybe<Item_Conditions_Bool_Exp>;
};

export type Subscription_RootItem_ImagesArgs = {
  distinct_on?: InputMaybe<Array<Item_Images_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Item_Images_Order_By>>;
  where?: InputMaybe<Item_Images_Bool_Exp>;
};

export type Subscription_RootItem_Images_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Item_Images_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Item_Images_Order_By>>;
  where?: InputMaybe<Item_Images_Bool_Exp>;
};

export type Subscription_RootItem_Images_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootItem_Images_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Item_Images_Stream_Cursor_Input>>;
  where?: InputMaybe<Item_Images_Bool_Exp>;
};

export type Subscription_RootItem_Sold_HistoriesArgs = {
  distinct_on?: InputMaybe<Array<Item_Sold_Histories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Item_Sold_Histories_Order_By>>;
  where?: InputMaybe<Item_Sold_Histories_Bool_Exp>;
};

export type Subscription_RootItem_Sold_Histories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Item_Sold_Histories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Item_Sold_Histories_Order_By>>;
  where?: InputMaybe<Item_Sold_Histories_Bool_Exp>;
};

export type Subscription_RootItem_Sold_Histories_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootItem_Sold_Histories_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Item_Sold_Histories_Stream_Cursor_Input>>;
  where?: InputMaybe<Item_Sold_Histories_Bool_Exp>;
};

export type Subscription_RootItem_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Item_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Item_Statuses_Order_By>>;
  where?: InputMaybe<Item_Statuses_Bool_Exp>;
};

export type Subscription_RootItem_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Item_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Item_Statuses_Order_By>>;
  where?: InputMaybe<Item_Statuses_Bool_Exp>;
};

export type Subscription_RootItem_Statuses_By_PkArgs = {
  key: Scalars["String"]["input"];
};

export type Subscription_RootItem_Statuses_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Item_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Item_Statuses_Bool_Exp>;
};

export type Subscription_RootItem_Variant_ExhibitionsArgs = {
  distinct_on?: InputMaybe<Array<Item_Variant_Exhibitions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Item_Variant_Exhibitions_Order_By>>;
  where?: InputMaybe<Item_Variant_Exhibitions_Bool_Exp>;
};

export type Subscription_RootItem_Variant_Exhibitions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Item_Variant_Exhibitions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Item_Variant_Exhibitions_Order_By>>;
  where?: InputMaybe<Item_Variant_Exhibitions_Bool_Exp>;
};

export type Subscription_RootItem_Variant_Exhibitions_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootItem_Variant_Exhibitions_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Item_Variant_Exhibitions_Stream_Cursor_Input>>;
  where?: InputMaybe<Item_Variant_Exhibitions_Bool_Exp>;
};

export type Subscription_RootItem_VariantsArgs = {
  distinct_on?: InputMaybe<Array<Item_Variants_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Item_Variants_Order_By>>;
  where?: InputMaybe<Item_Variants_Bool_Exp>;
};

export type Subscription_RootItem_Variants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Item_Variants_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Item_Variants_Order_By>>;
  where?: InputMaybe<Item_Variants_Bool_Exp>;
};

export type Subscription_RootItem_Variants_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootItem_Variants_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Item_Variants_Stream_Cursor_Input>>;
  where?: InputMaybe<Item_Variants_Bool_Exp>;
};

export type Subscription_RootItemsArgs = {
  distinct_on?: InputMaybe<Array<Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Items_Order_By>>;
  where?: InputMaybe<Items_Bool_Exp>;
};

export type Subscription_RootItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Items_Order_By>>;
  where?: InputMaybe<Items_Bool_Exp>;
};

export type Subscription_RootItems_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootItems_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Items_Stream_Cursor_Input>>;
  where?: InputMaybe<Items_Bool_Exp>;
};

export type Subscription_RootMercari_Shops_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Mercari_Shops_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Mercari_Shops_Categories_Order_By>>;
  where?: InputMaybe<Mercari_Shops_Categories_Bool_Exp>;
};

export type Subscription_RootMercari_Shops_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mercari_Shops_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Mercari_Shops_Categories_Order_By>>;
  where?: InputMaybe<Mercari_Shops_Categories_Bool_Exp>;
};

export type Subscription_RootMercari_Shops_Categories_By_PkArgs = {
  id: Scalars["String"]["input"];
};

export type Subscription_RootMercari_Shops_Categories_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Mercari_Shops_Categories_Stream_Cursor_Input>>;
  where?: InputMaybe<Mercari_Shops_Categories_Bool_Exp>;
};

export type Subscription_RootPlansArgs = {
  distinct_on?: InputMaybe<Array<Plans_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Plans_Order_By>>;
  where?: InputMaybe<Plans_Bool_Exp>;
};

export type Subscription_RootPlans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plans_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Plans_Order_By>>;
  where?: InputMaybe<Plans_Bool_Exp>;
};

export type Subscription_RootPlans_By_PkArgs = {
  key: Scalars["String"]["input"];
};

export type Subscription_RootPlans_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Plans_Stream_Cursor_Input>>;
  where?: InputMaybe<Plans_Bool_Exp>;
};

export type Subscription_RootPlatformsArgs = {
  distinct_on?: InputMaybe<Array<Platforms_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Platforms_Order_By>>;
  where?: InputMaybe<Platforms_Bool_Exp>;
};

export type Subscription_RootPlatforms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Platforms_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Platforms_Order_By>>;
  where?: InputMaybe<Platforms_Bool_Exp>;
};

export type Subscription_RootPlatforms_By_PkArgs = {
  key: Scalars["String"]["input"];
};

export type Subscription_RootPlatforms_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Platforms_Stream_Cursor_Input>>;
  where?: InputMaybe<Platforms_Bool_Exp>;
};

export type Subscription_RootShop_Mercari_Shops_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Shop_Mercari_Shops_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Shop_Mercari_Shops_Settings_Order_By>>;
  where?: InputMaybe<Shop_Mercari_Shops_Settings_Bool_Exp>;
};

export type Subscription_RootShop_Mercari_Shops_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Shop_Mercari_Shops_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Shop_Mercari_Shops_Settings_Order_By>>;
  where?: InputMaybe<Shop_Mercari_Shops_Settings_Bool_Exp>;
};

export type Subscription_RootShop_Mercari_Shops_Settings_By_PkArgs = {
  shop_id: Scalars["uuid"]["input"];
};

export type Subscription_RootShop_Mercari_Shops_Settings_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Shop_Mercari_Shops_Settings_Stream_Cursor_Input>>;
  where?: InputMaybe<Shop_Mercari_Shops_Settings_Bool_Exp>;
};

export type Subscription_RootShop_StaffArgs = {
  distinct_on?: InputMaybe<Array<Shop_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Shop_Staff_Order_By>>;
  where?: InputMaybe<Shop_Staff_Bool_Exp>;
};

export type Subscription_RootShop_Staff_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Shop_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Shop_Staff_Order_By>>;
  where?: InputMaybe<Shop_Staff_Bool_Exp>;
};

export type Subscription_RootShop_Staff_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootShop_Staff_RolesArgs = {
  distinct_on?: InputMaybe<Array<Shop_Staff_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Shop_Staff_Roles_Order_By>>;
  where?: InputMaybe<Shop_Staff_Roles_Bool_Exp>;
};

export type Subscription_RootShop_Staff_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Shop_Staff_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Shop_Staff_Roles_Order_By>>;
  where?: InputMaybe<Shop_Staff_Roles_Bool_Exp>;
};

export type Subscription_RootShop_Staff_Roles_By_PkArgs = {
  key: Scalars["String"]["input"];
};

export type Subscription_RootShop_Staff_Roles_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Shop_Staff_Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<Shop_Staff_Roles_Bool_Exp>;
};

export type Subscription_RootShop_Staff_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Shop_Staff_Stream_Cursor_Input>>;
  where?: InputMaybe<Shop_Staff_Bool_Exp>;
};

export type Subscription_RootShop_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Shop_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Shop_Statuses_Order_By>>;
  where?: InputMaybe<Shop_Statuses_Bool_Exp>;
};

export type Subscription_RootShop_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Shop_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Shop_Statuses_Order_By>>;
  where?: InputMaybe<Shop_Statuses_Bool_Exp>;
};

export type Subscription_RootShop_Statuses_By_PkArgs = {
  key: Scalars["String"]["input"];
};

export type Subscription_RootShop_Statuses_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Shop_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Shop_Statuses_Bool_Exp>;
};

export type Subscription_RootShop_Yahoo_Shopping_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Shop_Yahoo_Shopping_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Shop_Yahoo_Shopping_Settings_Order_By>>;
  where?: InputMaybe<Shop_Yahoo_Shopping_Settings_Bool_Exp>;
};

export type Subscription_RootShop_Yahoo_Shopping_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Shop_Yahoo_Shopping_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Shop_Yahoo_Shopping_Settings_Order_By>>;
  where?: InputMaybe<Shop_Yahoo_Shopping_Settings_Bool_Exp>;
};

export type Subscription_RootShop_Yahoo_Shopping_Settings_By_PkArgs = {
  shop_id: Scalars["uuid"]["input"];
};

export type Subscription_RootShop_Yahoo_Shopping_Settings_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Shop_Yahoo_Shopping_Settings_Stream_Cursor_Input>>;
  where?: InputMaybe<Shop_Yahoo_Shopping_Settings_Bool_Exp>;
};

export type Subscription_RootShopsArgs = {
  distinct_on?: InputMaybe<Array<Shops_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Shops_Order_By>>;
  where?: InputMaybe<Shops_Bool_Exp>;
};

export type Subscription_RootShops_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Shops_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Shops_Order_By>>;
  where?: InputMaybe<Shops_Bool_Exp>;
};

export type Subscription_RootShops_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootShops_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Shops_Stream_Cursor_Input>>;
  where?: InputMaybe<Shops_Bool_Exp>;
};

export type Subscription_RootSubscription_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Subscription_Statuses_Order_By>>;
  where?: InputMaybe<Subscription_Statuses_Bool_Exp>;
};

export type Subscription_RootSubscription_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Subscription_Statuses_Order_By>>;
  where?: InputMaybe<Subscription_Statuses_Bool_Exp>;
};

export type Subscription_RootSubscription_Statuses_By_PkArgs = {
  key: Scalars["String"]["input"];
};

export type Subscription_RootSubscription_Statuses_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Subscription_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Subscription_Statuses_Bool_Exp>;
};

export type Subscription_RootSubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};

export type Subscription_RootSubscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};

export type Subscription_RootSubscriptions_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootSubscriptions_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Subscriptions_Stream_Cursor_Input>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};

export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootYahoo_Auction_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Yahoo_Auction_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Yahoo_Auction_Categories_Order_By>>;
  where?: InputMaybe<Yahoo_Auction_Categories_Bool_Exp>;
};

export type Subscription_RootYahoo_Auction_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Yahoo_Auction_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Yahoo_Auction_Categories_Order_By>>;
  where?: InputMaybe<Yahoo_Auction_Categories_Bool_Exp>;
};

export type Subscription_RootYahoo_Auction_Categories_By_PkArgs = {
  id: Scalars["Int"]["input"];
};

export type Subscription_RootYahoo_Auction_Categories_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Yahoo_Auction_Categories_Stream_Cursor_Input>>;
  where?: InputMaybe<Yahoo_Auction_Categories_Bool_Exp>;
};

/** columns and relationships of "subscription_statuses" */
export type Subscription_Statuses = {
  key: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

/** aggregated selection of "subscription_statuses" */
export type Subscription_Statuses_Aggregate = {
  aggregate?: Maybe<Subscription_Statuses_Aggregate_Fields>;
  nodes: Array<Subscription_Statuses>;
};

/** aggregate fields of "subscription_statuses" */
export type Subscription_Statuses_Aggregate_Fields = {
  count: Scalars["Int"]["output"];
  max?: Maybe<Subscription_Statuses_Max_Fields>;
  min?: Maybe<Subscription_Statuses_Min_Fields>;
};

/** aggregate fields of "subscription_statuses" */
export type Subscription_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Subscription_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Boolean expression to filter rows from the table "subscription_statuses". All fields are combined with a logical 'AND'. */
export type Subscription_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Subscription_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Subscription_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Subscription_Statuses_Bool_Exp>>;
  key?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "subscription_statuses" */
export enum Subscription_Statuses_Constraint {
  /** unique or primary key constraint on columns "key" */
  SubscriptionStatusesPkey = "subscription_statuses_pkey",
}

/** input type for inserting data into table "subscription_statuses" */
export type Subscription_Statuses_Insert_Input = {
  key?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type Subscription_Statuses_Max_Fields = {
  key?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type Subscription_Statuses_Min_Fields = {
  key?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "subscription_statuses" */
export type Subscription_Statuses_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Subscription_Statuses>;
};

/** on_conflict condition type for table "subscription_statuses" */
export type Subscription_Statuses_On_Conflict = {
  constraint: Subscription_Statuses_Constraint;
  update_columns?: Array<Subscription_Statuses_Update_Column>;
  where?: InputMaybe<Subscription_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "subscription_statuses". */
export type Subscription_Statuses_Order_By = {
  key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: subscription_statuses */
export type Subscription_Statuses_Pk_Columns_Input = {
  key: Scalars["String"]["input"];
};

/** select columns of table "subscription_statuses" */
export enum Subscription_Statuses_Select_Column {
  /** column name */
  Key = "key",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "subscription_statuses" */
export type Subscription_Statuses_Set_Input = {
  key?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** Streaming cursor of the table "subscription_statuses" */
export type Subscription_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Subscription_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Subscription_Statuses_Stream_Cursor_Value_Input = {
  key?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** update columns of table "subscription_statuses" */
export enum Subscription_Statuses_Update_Column {
  /** column name */
  Key = "key",
  /** column name */
  Name = "name",
}

export type Subscription_Statuses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Subscription_Statuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Subscription_Statuses_Bool_Exp;
};

/** columns and relationships of "subscriptions" */
export type Subscriptions = {
  cancelled_at?: Maybe<Scalars["timestamptz"]["output"]>;
  created_at: Scalars["timestamptz"]["output"];
  fincode_plan_id?: Maybe<Scalars["String"]["output"]>;
  fincode_subscription_id: Scalars["String"]["output"];
  id: Scalars["uuid"]["output"];
  last_paid_at: Scalars["timestamptz"]["output"];
  shop_id: Scalars["uuid"]["output"];
  status: Scalars["String"]["output"];
  updated_at: Scalars["timestamptz"]["output"];
};

/** aggregated selection of "subscriptions" */
export type Subscriptions_Aggregate = {
  aggregate?: Maybe<Subscriptions_Aggregate_Fields>;
  nodes: Array<Subscriptions>;
};

export type Subscriptions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Subscriptions_Aggregate_Bool_Exp_Count>;
};

export type Subscriptions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Subscriptions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Subscriptions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "subscriptions" */
export type Subscriptions_Aggregate_Fields = {
  count: Scalars["Int"]["output"];
  max?: Maybe<Subscriptions_Max_Fields>;
  min?: Maybe<Subscriptions_Min_Fields>;
};

/** aggregate fields of "subscriptions" */
export type Subscriptions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Subscriptions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "subscriptions" */
export type Subscriptions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Subscriptions_Max_Order_By>;
  min?: InputMaybe<Subscriptions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "subscriptions" */
export type Subscriptions_Arr_Rel_Insert_Input = {
  data: Array<Subscriptions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Subscriptions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "subscriptions". All fields are combined with a logical 'AND'. */
export type Subscriptions_Bool_Exp = {
  _and?: InputMaybe<Array<Subscriptions_Bool_Exp>>;
  _not?: InputMaybe<Subscriptions_Bool_Exp>;
  _or?: InputMaybe<Array<Subscriptions_Bool_Exp>>;
  cancelled_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  fincode_plan_id?: InputMaybe<String_Comparison_Exp>;
  fincode_subscription_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  last_paid_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  shop_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "subscriptions" */
export enum Subscriptions_Constraint {
  /** unique or primary key constraint on columns "id" */
  SubscriptionsPkey = "subscriptions_pkey",
}

/** input type for inserting data into table "subscriptions" */
export type Subscriptions_Insert_Input = {
  cancelled_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  fincode_plan_id?: InputMaybe<Scalars["String"]["input"]>;
  fincode_subscription_id?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  last_paid_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  shop_id?: InputMaybe<Scalars["uuid"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate max on columns */
export type Subscriptions_Max_Fields = {
  cancelled_at?: Maybe<Scalars["timestamptz"]["output"]>;
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  fincode_plan_id?: Maybe<Scalars["String"]["output"]>;
  fincode_subscription_id?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  last_paid_at?: Maybe<Scalars["timestamptz"]["output"]>;
  shop_id?: Maybe<Scalars["uuid"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by max() on columns of table "subscriptions" */
export type Subscriptions_Max_Order_By = {
  cancelled_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  fincode_plan_id?: InputMaybe<Order_By>;
  fincode_subscription_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_paid_at?: InputMaybe<Order_By>;
  shop_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Subscriptions_Min_Fields = {
  cancelled_at?: Maybe<Scalars["timestamptz"]["output"]>;
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  fincode_plan_id?: Maybe<Scalars["String"]["output"]>;
  fincode_subscription_id?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  last_paid_at?: Maybe<Scalars["timestamptz"]["output"]>;
  shop_id?: Maybe<Scalars["uuid"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by min() on columns of table "subscriptions" */
export type Subscriptions_Min_Order_By = {
  cancelled_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  fincode_plan_id?: InputMaybe<Order_By>;
  fincode_subscription_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_paid_at?: InputMaybe<Order_By>;
  shop_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "subscriptions" */
export type Subscriptions_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Subscriptions>;
};

/** on_conflict condition type for table "subscriptions" */
export type Subscriptions_On_Conflict = {
  constraint: Subscriptions_Constraint;
  update_columns?: Array<Subscriptions_Update_Column>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};

/** Ordering options when selecting data from "subscriptions". */
export type Subscriptions_Order_By = {
  cancelled_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  fincode_plan_id?: InputMaybe<Order_By>;
  fincode_subscription_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_paid_at?: InputMaybe<Order_By>;
  shop_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: subscriptions */
export type Subscriptions_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "subscriptions" */
export enum Subscriptions_Select_Column {
  /** column name */
  CancelledAt = "cancelled_at",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FincodePlanId = "fincode_plan_id",
  /** column name */
  FincodeSubscriptionId = "fincode_subscription_id",
  /** column name */
  Id = "id",
  /** column name */
  LastPaidAt = "last_paid_at",
  /** column name */
  ShopId = "shop_id",
  /** column name */
  Status = "status",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "subscriptions" */
export type Subscriptions_Set_Input = {
  cancelled_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  fincode_plan_id?: InputMaybe<Scalars["String"]["input"]>;
  fincode_subscription_id?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  last_paid_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  shop_id?: InputMaybe<Scalars["uuid"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** Streaming cursor of the table "subscriptions" */
export type Subscriptions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Subscriptions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Subscriptions_Stream_Cursor_Value_Input = {
  cancelled_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  fincode_plan_id?: InputMaybe<Scalars["String"]["input"]>;
  fincode_subscription_id?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  last_paid_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  shop_id?: InputMaybe<Scalars["uuid"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** update columns of table "subscriptions" */
export enum Subscriptions_Update_Column {
  /** column name */
  CancelledAt = "cancelled_at",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FincodePlanId = "fincode_plan_id",
  /** column name */
  FincodeSubscriptionId = "fincode_subscription_id",
  /** column name */
  Id = "id",
  /** column name */
  LastPaidAt = "last_paid_at",
  /** column name */
  ShopId = "shop_id",
  /** column name */
  Status = "status",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Subscriptions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Subscriptions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Subscriptions_Bool_Exp;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["timestamptz"]["input"]>;
  _gt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  _gte?: InputMaybe<Scalars["timestamptz"]["input"]>;
  _in?: InputMaybe<Array<Scalars["timestamptz"]["input"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  _lte?: InputMaybe<Scalars["timestamptz"]["input"]>;
  _neq?: InputMaybe<Scalars["timestamptz"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["timestamptz"]["input"]>>;
};

/** columns and relationships of "users" */
export type Users = {
  created_at: Scalars["timestamptz"]["output"];
  email: Scalars["String"]["output"];
  fincode_customer_id?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["uuid"]["output"];
  /** An array relationship */
  shops: Array<Shop_Staff>;
  /** An aggregate relationship */
  shops_aggregate: Shop_Staff_Aggregate;
  updated_at: Scalars["timestamptz"]["output"];
};

/** columns and relationships of "users" */
export type UsersShopsArgs = {
  distinct_on?: InputMaybe<Array<Shop_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Shop_Staff_Order_By>>;
  where?: InputMaybe<Shop_Staff_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersShops_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Shop_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Shop_Staff_Order_By>>;
  where?: InputMaybe<Shop_Staff_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  count: Scalars["Int"]["output"];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  fincode_customer_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  shops?: InputMaybe<Shop_Staff_Bool_Exp>;
  shops_aggregate?: InputMaybe<Shop_Staff_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "email" */
  UsersEmailKey = "users_email_key",
  /** unique or primary key constraint on columns "id" */
  UsersPkey = "users_pkey",
}

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fincode_customer_id?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  shops?: InputMaybe<Shop_Staff_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  fincode_customer_id?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  fincode_customer_id?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  fincode_customer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  shops_aggregate?: InputMaybe<Shop_Staff_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Email = "email",
  /** column name */
  FincodeCustomerId = "fincode_customer_id",
  /** column name */
  Id = "id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fincode_customer_id?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fincode_customer_id?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Email = "email",
  /** column name */
  FincodeCustomerId = "fincode_customer_id",
  /** column name */
  Id = "id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["uuid"]["input"]>;
  _gt?: InputMaybe<Scalars["uuid"]["input"]>;
  _gte?: InputMaybe<Scalars["uuid"]["input"]>;
  _in?: InputMaybe<Array<Scalars["uuid"]["input"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["uuid"]["input"]>;
  _lte?: InputMaybe<Scalars["uuid"]["input"]>;
  _neq?: InputMaybe<Scalars["uuid"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["uuid"]["input"]>>;
};

/** columns and relationships of "yahoo_auction_categories" */
export type Yahoo_Auction_Categories = {
  id: Scalars["Int"]["output"];
  id_path: Scalars["String"]["output"];
  is_leaf: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  name_path: Scalars["String"]["output"];
  parent_id?: Maybe<Scalars["Int"]["output"]>;
};

/** aggregated selection of "yahoo_auction_categories" */
export type Yahoo_Auction_Categories_Aggregate = {
  aggregate?: Maybe<Yahoo_Auction_Categories_Aggregate_Fields>;
  nodes: Array<Yahoo_Auction_Categories>;
};

/** aggregate fields of "yahoo_auction_categories" */
export type Yahoo_Auction_Categories_Aggregate_Fields = {
  avg?: Maybe<Yahoo_Auction_Categories_Avg_Fields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<Yahoo_Auction_Categories_Max_Fields>;
  min?: Maybe<Yahoo_Auction_Categories_Min_Fields>;
  stddev?: Maybe<Yahoo_Auction_Categories_Stddev_Fields>;
  stddev_pop?: Maybe<Yahoo_Auction_Categories_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Yahoo_Auction_Categories_Stddev_Samp_Fields>;
  sum?: Maybe<Yahoo_Auction_Categories_Sum_Fields>;
  var_pop?: Maybe<Yahoo_Auction_Categories_Var_Pop_Fields>;
  var_samp?: Maybe<Yahoo_Auction_Categories_Var_Samp_Fields>;
  variance?: Maybe<Yahoo_Auction_Categories_Variance_Fields>;
};

/** aggregate fields of "yahoo_auction_categories" */
export type Yahoo_Auction_Categories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Yahoo_Auction_Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type Yahoo_Auction_Categories_Avg_Fields = {
  id?: Maybe<Scalars["Float"]["output"]>;
  parent_id?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "yahoo_auction_categories". All fields are combined with a logical 'AND'. */
export type Yahoo_Auction_Categories_Bool_Exp = {
  _and?: InputMaybe<Array<Yahoo_Auction_Categories_Bool_Exp>>;
  _not?: InputMaybe<Yahoo_Auction_Categories_Bool_Exp>;
  _or?: InputMaybe<Array<Yahoo_Auction_Categories_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  id_path?: InputMaybe<String_Comparison_Exp>;
  is_leaf?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  name_path?: InputMaybe<String_Comparison_Exp>;
  parent_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "yahoo_auction_categories" */
export enum Yahoo_Auction_Categories_Constraint {
  /** unique or primary key constraint on columns "id" */
  YahooAuctionCategoriesPkey = "yahoo_auction_categories_pkey",
}

/** input type for incrementing numeric columns in table "yahoo_auction_categories" */
export type Yahoo_Auction_Categories_Inc_Input = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  parent_id?: InputMaybe<Scalars["Int"]["input"]>;
};

/** input type for inserting data into table "yahoo_auction_categories" */
export type Yahoo_Auction_Categories_Insert_Input = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_path?: InputMaybe<Scalars["String"]["input"]>;
  is_leaf?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_path?: InputMaybe<Scalars["String"]["input"]>;
  parent_id?: InputMaybe<Scalars["Int"]["input"]>;
};

/** aggregate max on columns */
export type Yahoo_Auction_Categories_Max_Fields = {
  id?: Maybe<Scalars["Int"]["output"]>;
  id_path?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  name_path?: Maybe<Scalars["String"]["output"]>;
  parent_id?: Maybe<Scalars["Int"]["output"]>;
};

/** aggregate min on columns */
export type Yahoo_Auction_Categories_Min_Fields = {
  id?: Maybe<Scalars["Int"]["output"]>;
  id_path?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  name_path?: Maybe<Scalars["String"]["output"]>;
  parent_id?: Maybe<Scalars["Int"]["output"]>;
};

/** response of any mutation on the table "yahoo_auction_categories" */
export type Yahoo_Auction_Categories_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Yahoo_Auction_Categories>;
};

/** on_conflict condition type for table "yahoo_auction_categories" */
export type Yahoo_Auction_Categories_On_Conflict = {
  constraint: Yahoo_Auction_Categories_Constraint;
  update_columns?: Array<Yahoo_Auction_Categories_Update_Column>;
  where?: InputMaybe<Yahoo_Auction_Categories_Bool_Exp>;
};

/** Ordering options when selecting data from "yahoo_auction_categories". */
export type Yahoo_Auction_Categories_Order_By = {
  id?: InputMaybe<Order_By>;
  id_path?: InputMaybe<Order_By>;
  is_leaf?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  name_path?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: yahoo_auction_categories */
export type Yahoo_Auction_Categories_Pk_Columns_Input = {
  id: Scalars["Int"]["input"];
};

/** select columns of table "yahoo_auction_categories" */
export enum Yahoo_Auction_Categories_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  IdPath = "id_path",
  /** column name */
  IsLeaf = "is_leaf",
  /** column name */
  Name = "name",
  /** column name */
  NamePath = "name_path",
  /** column name */
  ParentId = "parent_id",
}

/** input type for updating data in table "yahoo_auction_categories" */
export type Yahoo_Auction_Categories_Set_Input = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_path?: InputMaybe<Scalars["String"]["input"]>;
  is_leaf?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_path?: InputMaybe<Scalars["String"]["input"]>;
  parent_id?: InputMaybe<Scalars["Int"]["input"]>;
};

/** aggregate stddev on columns */
export type Yahoo_Auction_Categories_Stddev_Fields = {
  id?: Maybe<Scalars["Float"]["output"]>;
  parent_id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddev_pop on columns */
export type Yahoo_Auction_Categories_Stddev_Pop_Fields = {
  id?: Maybe<Scalars["Float"]["output"]>;
  parent_id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddev_samp on columns */
export type Yahoo_Auction_Categories_Stddev_Samp_Fields = {
  id?: Maybe<Scalars["Float"]["output"]>;
  parent_id?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "yahoo_auction_categories" */
export type Yahoo_Auction_Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Yahoo_Auction_Categories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Yahoo_Auction_Categories_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_path?: InputMaybe<Scalars["String"]["input"]>;
  is_leaf?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_path?: InputMaybe<Scalars["String"]["input"]>;
  parent_id?: InputMaybe<Scalars["Int"]["input"]>;
};

/** aggregate sum on columns */
export type Yahoo_Auction_Categories_Sum_Fields = {
  id?: Maybe<Scalars["Int"]["output"]>;
  parent_id?: Maybe<Scalars["Int"]["output"]>;
};

/** update columns of table "yahoo_auction_categories" */
export enum Yahoo_Auction_Categories_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  IdPath = "id_path",
  /** column name */
  IsLeaf = "is_leaf",
  /** column name */
  Name = "name",
  /** column name */
  NamePath = "name_path",
  /** column name */
  ParentId = "parent_id",
}

export type Yahoo_Auction_Categories_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Yahoo_Auction_Categories_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Yahoo_Auction_Categories_Set_Input>;
  /** filter the rows which have to be updated */
  where: Yahoo_Auction_Categories_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Yahoo_Auction_Categories_Var_Pop_Fields = {
  id?: Maybe<Scalars["Float"]["output"]>;
  parent_id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate var_samp on columns */
export type Yahoo_Auction_Categories_Var_Samp_Fields = {
  id?: Maybe<Scalars["Float"]["output"]>;
  parent_id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type Yahoo_Auction_Categories_Variance_Fields = {
  id?: Maybe<Scalars["Float"]["output"]>;
  parent_id?: Maybe<Scalars["Float"]["output"]>;
};

export type InitializeQueryVariables = Exact<{ [key: string]: never }>;

export type InitializeQuery = {
  brands: Array<{ id: any; name: string; mercari_shops_mapping?: { platform_mercari_shops_brand_id: string } | null }>;
  item_conditions: Array<{ key: string; name: string }>;
  item_statuses: Array<{ key: string; name: string }>;
  exhibition_statuses: Array<{ key: string; name: string }>;
  platforms: Array<{ key: string; name: string; icon_url?: string | null }>;
  shop_staff: Array<{
    id: any;
    name: string;
    role: string;
    created_at: any;
    updated_at: any;
    user: { id: any; email: string };
    shop: { id: any; name: string; status: string };
  }>;
};

export type InitializeByShopQueryVariables = Exact<{
  shop_id: Scalars["uuid"]["input"];
  order_by?: InputMaybe<Array<Items_Order_By> | Items_Order_By>;
}>;

export type InitializeByShopQuery = {
  integrations: Array<{
    id: any;
    shop_id: any;
    platform: string;
    account_name?: string | null;
    disabled_at?: any | null;
    yahoo_shopping_settings: Array<{ id: any; integration_id: any; seller_id: string }>;
  }>;
  base_categories?: { categories: Array<{ id: string; name: string; path: string }> } | null;
  stores_categories?: { categories: Array<{ id: string; name: string; path: string }> } | null;
};

export type ListIntegrationsByShopQueryVariables = Exact<{
  shop_id?: InputMaybe<Scalars["uuid"]["input"]>;
}>;

export type ListIntegrationsByShopQuery = {
  integrations: Array<{
    id: any;
    shop_id: any;
    platform: string;
    account_name?: string | null;
    disabled_at?: any | null;
    yahoo_shopping_settings: Array<{ id: any; integration_id: any; seller_id: string }>;
  }>;
};

export type GetItemQueryVariables = Exact<{
  id: Scalars["uuid"]["input"];
}>;

export type GetItemQuery = {
  items_by_pk?: {
    id: any;
    condition?: string | null;
    description: string;
    name: string;
    price: number;
    compare_at_price?: number | null;
    cost?: number | null;
    status: string;
    sale_starts_at?: any | null;
    created_at: any;
    updated_at: any;
    brand?: {
      id: any;
      name: string;
      mercari_shops_mapping?: { platform_mercari_shops_brand_id: string } | null;
    } | null;
    exhibitions: Array<{
      id: any;
      platform: string;
      platform_item_id?: string | null;
      status: string;
      error_message?: string | null;
      base_categories: Array<{ platform_base_category_id: number }>;
      stores_categories: Array<{ platform_stores_category_id: string }>;
      mercari_shops_setting?: {
        brand_id?: string | null;
        category_id: string;
        condition: string;
        shipping_duration: string;
        shipping_from_state_id: string;
        shipping_method: string;
        shipping_payer: string;
      } | null;
      yahoo_shopping_setting?: {
        store_category_hierarchies: any;
        product_category: number;
        shopping_category_hierarchy: any;
        brand_code?: number | null;
        condition?: number | null;
        postage_set?: number | null;
        lead_time_instock?: number | null;
        lead_time_outstock?: number | null;
        auction_category?: number | null;
        auction_shipping_from_prefecture?: string | null;
      } | null;
      shopify_setting?: { category: string; product_type: string; vendor: string; tags: any } | null;
    }>;
    variants: Array<{ id: any; name: string; sku: string; stock_quantity: number; position: number }>;
    images: Array<{ id: any; mime_type: string; path: string; created_at: any }>;
    item_condition?: { key: string; name: string } | null;
    item_status: { key: string; name: string };
    shop: { id: any; name: string; status: string };
    sold_histories: Array<{ id: any; item_id: any; platform: string; created_at: any }>;
  } | null;
};

export type GetPlatformCategoryQueryVariables = Exact<{
  input: PlatformCategoriesInput;
}>;

export type GetPlatformCategoryQuery = {
  platform_categories?: { categories: Array<{ id: string; name: string; path: string }> } | null;
};

export type ListYahooShoppingCategoriesQueryVariables = Exact<{
  input: ListYahooShoppingCategoriesInput;
}>;

export type ListYahooShoppingCategoriesQuery = {
  listYahooShoppingCategories: {
    yahooShoppingCategories?: Array<{ code: number; name: string; hasChild: boolean } | null> | null;
  };
};

export type ListYahooStoreCategoriesQueryVariables = Exact<{
  input: ListYahooStoreCategoriesInput;
}>;

export type ListYahooStoreCategoriesQuery = {
  listYahooStoreCategories: {
    yahooStoreCategories?: Array<{ pageKey: string; name: string; isHidden: boolean } | null> | null;
  };
};

export type GetSoldItemsQueryVariables = Exact<{
  shop_id: Scalars["uuid"]["input"];
}>;

export type GetSoldItemsQuery = {
  item_sold_histories: Array<{
    id: any;
    item_id: any;
    item_variant_id?: any | null;
    platform: string;
    created_at: any;
    item: {
      name: string;
      price: number;
      id: any;
      shop_id: any;
      images: Array<{ path: string; mime_type: string }>;
      shop: { name: string };
    };
    item_variant?: { name: string } | null;
  }>;
};

export type GetMercariShopsShopIdQueryVariables = Exact<{
  shop_id: Scalars["uuid"]["input"];
}>;

export type GetMercariShopsShopIdQuery = { getMercariShopsShopId?: { shopId?: string | null } | null };

export type CreateImageMutationVariables = Exact<{
  base64str?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type CreateImageMutation = {
  upload_image?: { id: string; path: string; mime_type?: string | null; created_at?: any | null } | null;
};

export type CreateItemMutationVariables = Exact<{
  object: Items_Insert_Input;
}>;

export type CreateItemMutation = {
  insert_items_one?: {
    id: any;
    condition?: string | null;
    description: string;
    name: string;
    price: number;
    compare_at_price?: number | null;
    cost?: number | null;
    status: string;
    sale_starts_at?: any | null;
    created_at: any;
    updated_at: any;
    brand?: {
      id: any;
      name: string;
      mercari_shops_mapping?: { platform_mercari_shops_brand_id: string } | null;
    } | null;
    exhibitions: Array<{
      id: any;
      platform: string;
      platform_item_id?: string | null;
      status: string;
      error_message?: string | null;
      base_categories: Array<{ platform_base_category_id: number }>;
      stores_categories: Array<{ platform_stores_category_id: string }>;
      mercari_shops_setting?: {
        brand_id?: string | null;
        category_id: string;
        condition: string;
        shipping_duration: string;
        shipping_from_state_id: string;
        shipping_method: string;
        shipping_payer: string;
      } | null;
      yahoo_shopping_setting?: {
        store_category_hierarchies: any;
        product_category: number;
        shopping_category_hierarchy: any;
        brand_code?: number | null;
        condition?: number | null;
        postage_set?: number | null;
        lead_time_instock?: number | null;
        lead_time_outstock?: number | null;
        auction_category?: number | null;
        auction_shipping_from_prefecture?: string | null;
      } | null;
      shopify_setting?: { category: string; product_type: string; vendor: string; tags: any } | null;
    }>;
    variants: Array<{ id: any; name: string; sku: string; stock_quantity: number; position: number }>;
    images: Array<{ id: any; mime_type: string; path: string; created_at: any }>;
    item_condition?: { key: string; name: string } | null;
    item_status: { key: string; name: string };
    shop: { id: any; name: string; status: string };
    sold_histories: Array<{ id: any; item_id: any; platform: string; created_at: any }>;
  } | null;
};

export type UpdateItemMutationVariables = Exact<{
  object: Items_Insert_Input;
  item_id: Scalars["uuid"]["input"];
  item_variant_ids?: InputMaybe<Array<Scalars["uuid"]["input"]> | Scalars["uuid"]["input"]>;
  active_item_image_ids?: InputMaybe<Array<Scalars["uuid"]["input"]> | Scalars["uuid"]["input"]>;
  selected_platforms?: InputMaybe<Array<Scalars["String"]["input"]> | Scalars["String"]["input"]>;
  platform_base_category_ids?: InputMaybe<Array<Scalars["Int"]["input"]> | Scalars["Int"]["input"]>;
  platform_stores_category_ids?: InputMaybe<Array<Scalars["String"]["input"]> | Scalars["String"]["input"]>;
}>;

export type UpdateItemMutation = {
  delete_item_images?: { returning: Array<{ id: any; mime_type: string; path: string; created_at: any }> } | null;
  delete_exhibitions?: {
    returning: Array<{
      id: any;
      platform: string;
      platform_item_id?: string | null;
      status: string;
      error_message?: string | null;
      base_categories: Array<{ platform_base_category_id: number }>;
      stores_categories: Array<{ platform_stores_category_id: string }>;
      mercari_shops_setting?: {
        brand_id?: string | null;
        category_id: string;
        condition: string;
        shipping_duration: string;
        shipping_from_state_id: string;
        shipping_method: string;
        shipping_payer: string;
      } | null;
      yahoo_shopping_setting?: {
        store_category_hierarchies: any;
        product_category: number;
        shopping_category_hierarchy: any;
        brand_code?: number | null;
        condition?: number | null;
        postage_set?: number | null;
        lead_time_instock?: number | null;
        lead_time_outstock?: number | null;
        auction_category?: number | null;
        auction_shipping_from_prefecture?: string | null;
      } | null;
      shopify_setting?: { category: string; product_type: string; vendor: string; tags: any } | null;
    }>;
  } | null;
  delete_item_variants?: {
    returning: Array<{ id: any; name: string; sku: string; stock_quantity: number; position: number }>;
  } | null;
  delete_exhibition_base_categories?: { returning: Array<{ platform_base_category_id: number }> } | null;
  delete_exhibition_stores_categories?: { returning: Array<{ platform_stores_category_id: string }> } | null;
  insert_items_one?: {
    id: any;
    condition?: string | null;
    description: string;
    name: string;
    price: number;
    compare_at_price?: number | null;
    cost?: number | null;
    status: string;
    sale_starts_at?: any | null;
    created_at: any;
    updated_at: any;
    brand?: {
      id: any;
      name: string;
      mercari_shops_mapping?: { platform_mercari_shops_brand_id: string } | null;
    } | null;
    exhibitions: Array<{
      id: any;
      platform: string;
      platform_item_id?: string | null;
      status: string;
      error_message?: string | null;
      base_categories: Array<{ platform_base_category_id: number }>;
      stores_categories: Array<{ platform_stores_category_id: string }>;
      mercari_shops_setting?: {
        brand_id?: string | null;
        category_id: string;
        condition: string;
        shipping_duration: string;
        shipping_from_state_id: string;
        shipping_method: string;
        shipping_payer: string;
      } | null;
      yahoo_shopping_setting?: {
        store_category_hierarchies: any;
        product_category: number;
        shopping_category_hierarchy: any;
        brand_code?: number | null;
        condition?: number | null;
        postage_set?: number | null;
        lead_time_instock?: number | null;
        lead_time_outstock?: number | null;
        auction_category?: number | null;
        auction_shipping_from_prefecture?: string | null;
      } | null;
      shopify_setting?: { category: string; product_type: string; vendor: string; tags: any } | null;
    }>;
    variants: Array<{ id: any; name: string; sku: string; stock_quantity: number; position: number }>;
    images: Array<{ id: any; mime_type: string; path: string; created_at: any }>;
    item_condition?: { key: string; name: string } | null;
    item_status: { key: string; name: string };
    shop: { id: any; name: string; status: string };
    sold_histories: Array<{ id: any; item_id: any; platform: string; created_at: any }>;
  } | null;
};

export type DeleteItemMutationVariables = Exact<{
  id: Scalars["uuid"]["input"];
}>;

export type DeleteItemMutation = { delete_items_by_pk?: { id: any } | null };

export type CreateTemporaryItemImageMutationVariables = Exact<{
  mime_type: Scalars["String"]["input"];
  path: Scalars["String"]["input"];
}>;

export type CreateTemporaryItemImageMutation = { insert_item_images_one?: { id: any } | null };

export type CopyItemMutationVariables = Exact<{
  object: Items_Insert_Input;
  temporaryImageIds: Array<Scalars["uuid"]["input"]> | Scalars["uuid"]["input"];
  newItemId: Scalars["uuid"]["input"];
}>;

export type CopyItemMutation = {
  insert_items_one?: { id: any } | null;
  update_item_images?: { affected_rows: number } | null;
};

export type CreateIntegrationMutationVariables = Exact<{
  input: CreateIntegrationInput;
}>;

export type CreateIntegrationMutation = { createIntegration?: { integrationId: any } | null };

export type DeleteIntegrationMutationVariables = Exact<{
  shop_id: Scalars["uuid"]["input"];
  platform: Scalars["String"]["input"];
}>;

export type DeleteIntegrationMutation = { delete_integrations?: { returning: Array<{ id: any }> } | null };

export type CreateIntegrationYahooShoppingSettingMutationVariables = Exact<{
  input?: InputMaybe<Integration_Yahoo_Shopping_Settings_Insert_Input>;
}>;

export type CreateIntegrationYahooShoppingSettingMutation = {
  insert_integration_yahoo_shopping_settings_one?: { id: any; integration_id: any; seller_id: string } | null;
};

export type CreateUserAndPaymentMutationVariables = Exact<{
  input: CreateUserAndPaymentInput;
}>;

export type CreateUserAndPaymentMutation = { createUserAndPayment: { userId: string } };

export type ItemFragment = {
  id: any;
  condition?: string | null;
  description: string;
  name: string;
  price: number;
  compare_at_price?: number | null;
  cost?: number | null;
  status: string;
  sale_starts_at?: any | null;
  created_at: any;
  updated_at: any;
  brand?: { id: any; name: string; mercari_shops_mapping?: { platform_mercari_shops_brand_id: string } | null } | null;
  exhibitions: Array<{
    id: any;
    platform: string;
    platform_item_id?: string | null;
    status: string;
    error_message?: string | null;
    base_categories: Array<{ platform_base_category_id: number }>;
    stores_categories: Array<{ platform_stores_category_id: string }>;
    mercari_shops_setting?: {
      brand_id?: string | null;
      category_id: string;
      condition: string;
      shipping_duration: string;
      shipping_from_state_id: string;
      shipping_method: string;
      shipping_payer: string;
    } | null;
    yahoo_shopping_setting?: {
      store_category_hierarchies: any;
      product_category: number;
      shopping_category_hierarchy: any;
      brand_code?: number | null;
      condition?: number | null;
      postage_set?: number | null;
      lead_time_instock?: number | null;
      lead_time_outstock?: number | null;
      auction_category?: number | null;
      auction_shipping_from_prefecture?: string | null;
    } | null;
    shopify_setting?: { category: string; product_type: string; vendor: string; tags: any } | null;
  }>;
  variants: Array<{ id: any; name: string; sku: string; stock_quantity: number; position: number }>;
  images: Array<{ id: any; mime_type: string; path: string; created_at: any }>;
  item_condition?: { key: string; name: string } | null;
  item_status: { key: string; name: string };
  shop: { id: any; name: string; status: string };
  sold_histories: Array<{ id: any; item_id: any; platform: string; created_at: any }>;
};

export type ShopFragment = { id: any; name: string; status: string };

export type BrandFragment = {
  id: any;
  name: string;
  mercari_shops_mapping?: { platform_mercari_shops_brand_id: string } | null;
};

export type ItemConditionFragment = { key: string; name: string };

export type ItemStatusFragment = { key: string; name: string };

export type ExhibitionFragment = {
  id: any;
  platform: string;
  platform_item_id?: string | null;
  status: string;
  error_message?: string | null;
  base_categories: Array<{ platform_base_category_id: number }>;
  stores_categories: Array<{ platform_stores_category_id: string }>;
  mercari_shops_setting?: {
    brand_id?: string | null;
    category_id: string;
    condition: string;
    shipping_duration: string;
    shipping_from_state_id: string;
    shipping_method: string;
    shipping_payer: string;
  } | null;
  yahoo_shopping_setting?: {
    store_category_hierarchies: any;
    product_category: number;
    shopping_category_hierarchy: any;
    brand_code?: number | null;
    condition?: number | null;
    postage_set?: number | null;
    lead_time_instock?: number | null;
    lead_time_outstock?: number | null;
    auction_category?: number | null;
    auction_shipping_from_prefecture?: string | null;
  } | null;
  shopify_setting?: { category: string; product_type: string; vendor: string; tags: any } | null;
};

export type ExhibitionStatusFragment = { key: string; name: string };

export type ExhibitionBaseCategoriesFragment = { platform_base_category_id: number };

export type ExhibitionStoresCategoriesFragment = { platform_stores_category_id: string };

export type ItemImageFragment = { id: any; mime_type: string; path: string; created_at: any };

export type PlatformFragment = { key: string; name: string; icon_url?: string | null };

export type IntegrationFragment = {
  id: any;
  shop_id: any;
  platform: string;
  account_name?: string | null;
  disabled_at?: any | null;
  yahoo_shopping_settings: Array<{ id: any; integration_id: any; seller_id: string }>;
};

export type UserFragment = { id: any; email: string };

export type ShopStaffFragment = {
  id: any;
  name: string;
  role: string;
  created_at: any;
  updated_at: any;
  user: { id: any; email: string };
  shop: { id: any; name: string; status: string };
};

export type ItemVariantFragment = { id: any; name: string; sku: string; stock_quantity: number; position: number };

export type ItemSoldHistoryFragment = { id: any; item_id: any; platform: string; created_at: any };

export type ExhibitionMercariShopsSettingFragment = {
  brand_id?: string | null;
  category_id: string;
  condition: string;
  shipping_duration: string;
  shipping_from_state_id: string;
  shipping_method: string;
  shipping_payer: string;
};

export type ExhibitionYahooShoppingSettingFragment = {
  store_category_hierarchies: any;
  product_category: number;
  shopping_category_hierarchy: any;
  brand_code?: number | null;
  condition?: number | null;
  postage_set?: number | null;
  lead_time_instock?: number | null;
  lead_time_outstock?: number | null;
  auction_category?: number | null;
  auction_shipping_from_prefecture?: string | null;
};

export type ExhibitionShopifySettingFragment = { category: string; product_type: string; vendor: string; tags: any };

export type ListExhibitionsByItemIdQueryVariables = Exact<{
  item_id: Scalars["uuid"]["input"];
}>;

export type ListExhibitionsByItemIdQuery = {
  exhibitions: Array<{ platform: string; status: string; error_message?: string | null }>;
};

export type ExhibitionStatusIndicator_ExhibitionFragment = {
  platform: string;
  status: string;
  error_message?: string | null;
};

export type PredictCategoryQueryVariables = Exact<{
  input: PredictCategoryInput;
}>;

export type PredictCategoryQuery = {
  predictCategory?: {
    results: Array<{
      target: string;
      predictedCategories: Array<{ id: string; namePath: string; isLeaf: boolean; score: number }>;
    }>;
  } | null;
};

export type ItemForm_PredictCategoryOutputFragment = {
  results: Array<{
    target: string;
    predictedCategories: Array<{ id: string; namePath: string; isLeaf: boolean; score: number }>;
  }>;
};

export type ItemsQueryVariables = Exact<{
  shop_id: Scalars["uuid"]["input"];
  cond?: InputMaybe<Array<Items_Bool_Exp> | Items_Bool_Exp>;
  order_by?: InputMaybe<Array<Items_Order_By> | Items_Order_By>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type ItemsQuery = {
  items: Array<{
    id: any;
    name: string;
    price: number;
    status: string;
    created_at: any;
    updated_at: any;
    exhibitions: Array<{
      id: any;
      platform: string;
      platform_item_id?: string | null;
      status: string;
      error_message?: string | null;
    }>;
    variants: Array<{ id: any; name: string; stock_quantity: number; position: number }>;
    images: Array<{ id: any; mime_type: string; path: string; created_at: any }>;
    item_status: { name: string };
  }>;
  items_aggregate: { aggregate?: { count: number } | null };
};

export type ListedItemsTab_ItemFragment = {
  id: any;
  name: string;
  price: number;
  status: string;
  created_at: any;
  updated_at: any;
  exhibitions: Array<{
    id: any;
    platform: string;
    platform_item_id?: string | null;
    status: string;
    error_message?: string | null;
  }>;
  variants: Array<{ id: any; name: string; stock_quantity: number; position: number }>;
  images: Array<{ id: any; mime_type: string; path: string; created_at: any }>;
  item_status: { name: string };
};

export type ListingCard_ItemFragment = {
  id: any;
  name: string;
  price: number;
  status: string;
  images: Array<{ path: string; mime_type: string }>;
  item_status: { name: string };
  exhibitions: Array<{ platform: string; status: string; error_message?: string | null }>;
  variants: Array<{ id: any; name: string; stock_quantity: number; position: number }>;
};

export type GetMercariShopsSettingQueryVariables = Exact<{
  shop_id: Scalars["uuid"]["input"];
}>;

export type GetMercariShopsSettingQuery = {
  shop_mercari_shops_settings_by_pk?: {
    default_condition: string;
    default_shipping_duration: string;
    default_shipping_from_state_id: string;
    default_shipping_method: string;
  } | null;
  exhibition_mercari_shops_settings: Array<{
    brand_id?: string | null;
    category_id: string;
    condition: string;
    shipping_duration: string;
    shipping_from_state_id: string;
    shipping_method: string;
    shipping_payer: string;
  }>;
};

export type MercariShopsExhibitionSettingsForm_ExhibitionMercariShopsSettingFragment = {
  brand_id?: string | null;
  category_id: string;
  condition: string;
  shipping_duration: string;
  shipping_from_state_id: string;
  shipping_method: string;
  shipping_payer: string;
};

export type MercariShopsExhibitionSettingsForm_ShopMercariShopsSettingFragment = {
  default_condition: string;
  default_shipping_duration: string;
  default_shipping_from_state_id: string;
  default_shipping_method: string;
};

export type ListPlatformCategoriesQueryVariables = Exact<{
  input: PlatformCategoriesInput;
}>;

export type ListPlatformCategoriesQuery = {
  platform_categories?: { categories: Array<{ id: string; name: string; path: string }> } | null;
};

export type PlatformCategoryTree_CategoryFragment = { id: string; name: string; path: string };

export type PlatformSettingsForm_ShopMercariShopsSettingFragment = {
  default_condition: string;
  default_shipping_duration: string;
  default_shipping_from_state_id: string;
  default_shipping_method: string;
};

export type PlatformSettingsForm_ShopYahooShoppingSettingFragment = { postage_sets: any; lead_times: any };

export type ListPorductTypeHistoriesQueryVariables = Exact<{
  shopId: Scalars["uuid"]["input"];
}>;

export type ListPorductTypeHistoriesQuery = { exhibition_shopify_settings: Array<{ product_type: string }> };

export type ListVendorHistoriesQueryVariables = Exact<{
  shopId: Scalars["uuid"]["input"];
}>;

export type ListVendorHistoriesQuery = { exhibition_shopify_settings: Array<{ vendor: string }> };

export type ShopifyExhibitionSettingForm_ShopifySettingFragment = {
  category: string;
  product_type: string;
  vendor: string;
  tags: any;
};

export type SearchYahooShoppingCategoriesQueryVariables = Exact<{
  input: SearchYahooShoppingCategoriesInput;
}>;

export type SearchYahooShoppingCategoriesQuery = {
  searchYahooShoppingCategories: {
    yahooShoppingCategories?: Array<{ code: number; name: string; namePath: string } | null> | null;
  };
};

export type GetYahooAuctionCategoryQueryVariables = Exact<{
  id: Scalars["Int"]["input"];
}>;

export type GetYahooAuctionCategoryQuery = {
  yahoo_auction_categories_by_pk?: { id: number; name: string; name_path: string } | null;
};

export type SearchYahooAuctionCategoryQueryVariables = Exact<{
  cond: Yahoo_Auction_Categories_Bool_Exp;
}>;

export type SearchYahooAuctionCategoryQuery = {
  yahoo_auction_categories: Array<{ id: number; name: string; name_path: string }>;
};

export type GetYahooShoppingPlatformSettingQueryVariables = Exact<{
  shop_id: Scalars["uuid"]["input"];
}>;

export type GetYahooShoppingPlatformSettingQuery = {
  shop_yahoo_shopping_settings_by_pk?: { postage_sets: any; lead_times: any } | null;
};

export type YahooShoppingCategorySelect_YahooAuctionCategoryFragment = { code: number; name: string; namePath: string };

export type YahooAuctionCategorySelect_YahooAuctionCategoryFragment = { id: number; name: string; name_path: string };

export type PlatformSettingsQueryVariables = Exact<{
  shop_id: Scalars["uuid"]["input"];
}>;

export type PlatformSettingsQuery = {
  shop_mercari_shops_settings_by_pk?: {
    default_condition: string;
    default_shipping_duration: string;
    default_shipping_from_state_id: string;
    default_shipping_method: string;
  } | null;
  shop_yahoo_shopping_settings_by_pk?: { postage_sets: any; lead_times: any } | null;
};

export type UpdatePlatformSettingsMutationVariables = Exact<{
  mercari_shop_input: Shop_Mercari_Shops_Settings_Insert_Input;
  yahoo_shopping_input: Shop_Yahoo_Shopping_Settings_Insert_Input;
}>;

export type UpdatePlatformSettingsMutation = {
  insert_shop_mercari_shops_settings_one?: {
    default_condition: string;
    default_shipping_duration: string;
    default_shipping_from_state_id: string;
    default_shipping_method: string;
  } | null;
  insert_shop_yahoo_shopping_settings_one?: { lead_times: any; postage_sets: any } | null;
};

export const BrandFragmentDoc = gql`
    fragment Brand on brands {
  id
  name
  mercari_shops_mapping {
    platform_mercari_shops_brand_id
  }
}
    `;
export const ExhibitionBaseCategoriesFragmentDoc = gql`
    fragment ExhibitionBaseCategories on exhibition_base_categories {
  platform_base_category_id
}
    `;
export const ExhibitionStoresCategoriesFragmentDoc = gql`
    fragment ExhibitionStoresCategories on exhibition_stores_categories {
  platform_stores_category_id
}
    `;
export const ExhibitionMercariShopsSettingFragmentDoc = gql`
    fragment ExhibitionMercariShopsSetting on exhibition_mercari_shops_settings {
  brand_id
  category_id
  condition
  shipping_duration
  shipping_from_state_id
  shipping_method
  shipping_payer
}
    `;
export const ExhibitionYahooShoppingSettingFragmentDoc = gql`
    fragment ExhibitionYahooShoppingSetting on exhibition_yahoo_shopping_settings {
  store_category_hierarchies
  product_category
  shopping_category_hierarchy
  brand_code
  condition
  postage_set
  lead_time_instock
  lead_time_outstock
  auction_category
  auction_shipping_from_prefecture
}
    `;
export const ExhibitionShopifySettingFragmentDoc = gql`
    fragment ExhibitionShopifySetting on exhibition_shopify_settings {
  category
  product_type
  vendor
  tags
}
    `;
export const ExhibitionFragmentDoc = gql`
    fragment Exhibition on exhibitions {
  id
  platform
  platform_item_id
  status
  error_message
  base_categories {
    ...ExhibitionBaseCategories
  }
  stores_categories {
    ...ExhibitionStoresCategories
  }
  mercari_shops_setting {
    ...ExhibitionMercariShopsSetting
  }
  yahoo_shopping_setting {
    ...ExhibitionYahooShoppingSetting
  }
  shopify_setting {
    ...ExhibitionShopifySetting
  }
}
    ${ExhibitionBaseCategoriesFragmentDoc}
${ExhibitionStoresCategoriesFragmentDoc}
${ExhibitionMercariShopsSettingFragmentDoc}
${ExhibitionYahooShoppingSettingFragmentDoc}
${ExhibitionShopifySettingFragmentDoc}`;
export const ItemVariantFragmentDoc = gql`
    fragment ItemVariant on item_variants {
  id
  name
  sku
  stock_quantity
  position
}
    `;
export const ItemImageFragmentDoc = gql`
    fragment ItemImage on item_images {
  id
  mime_type
  path
  created_at
}
    `;
export const ItemConditionFragmentDoc = gql`
    fragment ItemCondition on item_conditions {
  key
  name
}
    `;
export const ItemStatusFragmentDoc = gql`
    fragment ItemStatus on item_statuses {
  key
  name
}
    `;
export const ShopFragmentDoc = gql`
    fragment Shop on shops {
  id
  name
  status
}
    `;
export const ItemSoldHistoryFragmentDoc = gql`
    fragment ItemSoldHistory on item_sold_histories {
  id
  item_id
  platform
  created_at
}
    `;
export const ItemFragmentDoc = gql`
    fragment Item on items {
  id
  brand {
    ...Brand
  }
  condition
  description
  exhibitions {
    ...Exhibition
  }
  variants(order_by: {position: asc}) {
    ...ItemVariant
  }
  images(order_by: [{position: asc}, {created_at: asc}]) {
    ...ItemImage
  }
  item_condition {
    ...ItemCondition
  }
  item_status {
    ...ItemStatus
  }
  name
  price
  compare_at_price
  cost
  shop {
    ...Shop
  }
  sold_histories {
    ...ItemSoldHistory
  }
  status
  sale_starts_at
  created_at
  updated_at
}
    ${BrandFragmentDoc}
${ExhibitionFragmentDoc}
${ItemVariantFragmentDoc}
${ItemImageFragmentDoc}
${ItemConditionFragmentDoc}
${ItemStatusFragmentDoc}
${ShopFragmentDoc}
${ItemSoldHistoryFragmentDoc}`;
export const ExhibitionStatusFragmentDoc = gql`
    fragment ExhibitionStatus on exhibition_statuses {
  key
  name
}
    `;
export const PlatformFragmentDoc = gql`
    fragment Platform on platforms {
  key
  name
  icon_url
}
    `;
export const IntegrationFragmentDoc = gql`
    fragment Integration on integrations {
  id
  shop_id
  platform
  account_name
  disabled_at
  yahoo_shopping_settings {
    id
    integration_id
    seller_id
  }
}
    `;
export const UserFragmentDoc = gql`
    fragment User on users {
  id
  email
}
    `;
export const ShopStaffFragmentDoc = gql`
    fragment ShopStaff on shop_staff {
  id
  name
  role
  user {
    ...User
  }
  shop {
    ...Shop
  }
  created_at
  updated_at
}
    ${UserFragmentDoc}
${ShopFragmentDoc}`;
export const ExhibitionStatusIndicator_ExhibitionFragmentDoc = gql`
    fragment ExhibitionStatusIndicator_Exhibition on exhibitions {
  platform
  status
  error_message
}
    `;
export const ItemForm_PredictCategoryOutputFragmentDoc = gql`
    fragment ItemForm_PredictCategoryOutput on PredictCategoryOutput {
  results {
    target
    predictedCategories {
      id
      namePath
      isLeaf
      score
    }
  }
}
    `;
export const ListedItemsTab_ItemFragmentDoc = gql`
    fragment ListedItemsTab_Item on items {
  id
  name
  price
  exhibitions {
    id
    platform
    platform_item_id
    status
    error_message
  }
  variants(order_by: {position: asc}) {
    id
    name
    stock_quantity
    position
  }
  images(order_by: [{position: asc}, {created_at: asc}]) {
    id
    mime_type
    path
    created_at
  }
  item_status {
    name
  }
  status
  created_at
  updated_at
}
    `;
export const ListingCard_ItemFragmentDoc = gql`
    fragment ListingCard_Item on items {
  id
  name
  price
  images {
    path
    mime_type
  }
  status
  item_status {
    name
  }
  exhibitions {
    platform
    status
    error_message
  }
  variants {
    id
    name
    stock_quantity
    position
  }
}
    `;
export const MercariShopsExhibitionSettingsForm_ExhibitionMercariShopsSettingFragmentDoc = gql`
    fragment MercariShopsExhibitionSettingsForm_ExhibitionMercariShopsSetting on exhibition_mercari_shops_settings {
  brand_id
  category_id
  condition
  shipping_duration
  shipping_from_state_id
  shipping_method
  shipping_payer
}
    `;
export const MercariShopsExhibitionSettingsForm_ShopMercariShopsSettingFragmentDoc = gql`
    fragment MercariShopsExhibitionSettingsForm_ShopMercariShopsSetting on shop_mercari_shops_settings {
  default_condition
  default_shipping_duration
  default_shipping_from_state_id
  default_shipping_method
}
    `;
export const PlatformCategoryTree_CategoryFragmentDoc = gql`
    fragment PlatformCategoryTree_Category on Category {
  id
  name
  path
}
    `;
export const PlatformSettingsForm_ShopMercariShopsSettingFragmentDoc = gql`
    fragment PlatformSettingsForm_ShopMercariShopsSetting on shop_mercari_shops_settings {
  default_condition
  default_shipping_duration
  default_shipping_from_state_id
  default_shipping_method
}
    `;
export const PlatformSettingsForm_ShopYahooShoppingSettingFragmentDoc = gql`
    fragment PlatformSettingsForm_ShopYahooShoppingSetting on shop_yahoo_shopping_settings {
  postage_sets
  lead_times
}
    `;
export const ShopifyExhibitionSettingForm_ShopifySettingFragmentDoc = gql`
    fragment ShopifyExhibitionSettingForm_shopifySetting on exhibition_shopify_settings {
  category
  product_type
  vendor
  tags
}
    `;
export const YahooShoppingCategorySelect_YahooAuctionCategoryFragmentDoc = gql`
    fragment YahooShoppingCategorySelect_YahooAuctionCategory on SearchedYahooShoppingCategory {
  code
  name
  namePath
}
    `;
export const YahooAuctionCategorySelect_YahooAuctionCategoryFragmentDoc = gql`
    fragment YahooAuctionCategorySelect_YahooAuctionCategory on yahoo_auction_categories {
  id
  name
  name_path
}
    `;
export const InitializeDocument = gql`
    query Initialize {
  brands {
    ...Brand
  }
  item_conditions {
    ...ItemCondition
  }
  item_statuses {
    ...ItemStatus
  }
  exhibition_statuses {
    ...ExhibitionStatus
  }
  platforms {
    ...Platform
  }
  shop_staff {
    ...ShopStaff
  }
}
    ${BrandFragmentDoc}
${ItemConditionFragmentDoc}
${ItemStatusFragmentDoc}
${ExhibitionStatusFragmentDoc}
${PlatformFragmentDoc}
${ShopStaffFragmentDoc}`;

/**
 * __useInitializeQuery__
 *
 * To run a query within a React component, call `useInitializeQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitializeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitializeQuery({
 *   variables: {
 *   },
 * });
 */
export function useInitializeQuery(baseOptions?: Apollo.QueryHookOptions<InitializeQuery, InitializeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InitializeQuery, InitializeQueryVariables>(InitializeDocument, options);
}
export function useInitializeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InitializeQuery, InitializeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InitializeQuery, InitializeQueryVariables>(InitializeDocument, options);
}
export function useInitializeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<InitializeQuery, InitializeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<InitializeQuery, InitializeQueryVariables>(InitializeDocument, options);
}
export type InitializeQueryHookResult = ReturnType<typeof useInitializeQuery>;
export type InitializeLazyQueryHookResult = ReturnType<typeof useInitializeLazyQuery>;
export type InitializeSuspenseQueryHookResult = ReturnType<typeof useInitializeSuspenseQuery>;
export type InitializeQueryResult = Apollo.QueryResult<InitializeQuery, InitializeQueryVariables>;
export const InitializeByShopDocument = gql`
    query InitializeByShop($shop_id: uuid!, $order_by: [items_order_by!] = {created_at: desc}) {
  integrations(where: {shop_id: {_eq: $shop_id}}) {
    ...Integration
  }
  base_categories: platform_categories(
    input: {shopId: $shop_id, platform: "BASE"}
  ) {
    categories {
      id
      name
      path
    }
  }
  stores_categories: platform_categories(
    input: {shopId: $shop_id, platform: "STORES"}
  ) {
    categories {
      id
      name
      path
    }
  }
}
    ${IntegrationFragmentDoc}`;

/**
 * __useInitializeByShopQuery__
 *
 * To run a query within a React component, call `useInitializeByShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitializeByShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitializeByShopQuery({
 *   variables: {
 *      shop_id: // value for 'shop_id'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useInitializeByShopQuery(
  baseOptions: Apollo.QueryHookOptions<InitializeByShopQuery, InitializeByShopQueryVariables> &
    ({ variables: InitializeByShopQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InitializeByShopQuery, InitializeByShopQueryVariables>(InitializeByShopDocument, options);
}
export function useInitializeByShopLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InitializeByShopQuery, InitializeByShopQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InitializeByShopQuery, InitializeByShopQueryVariables>(InitializeByShopDocument, options);
}
export function useInitializeByShopSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<InitializeByShopQuery, InitializeByShopQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<InitializeByShopQuery, InitializeByShopQueryVariables>(
    InitializeByShopDocument,
    options,
  );
}
export type InitializeByShopQueryHookResult = ReturnType<typeof useInitializeByShopQuery>;
export type InitializeByShopLazyQueryHookResult = ReturnType<typeof useInitializeByShopLazyQuery>;
export type InitializeByShopSuspenseQueryHookResult = ReturnType<typeof useInitializeByShopSuspenseQuery>;
export type InitializeByShopQueryResult = Apollo.QueryResult<InitializeByShopQuery, InitializeByShopQueryVariables>;
export const ListIntegrationsByShopDocument = gql`
    query ListIntegrationsByShop($shop_id: uuid = "shop_id") {
  integrations(where: {shop_id: {_eq: $shop_id}}) {
    ...Integration
  }
}
    ${IntegrationFragmentDoc}`;

/**
 * __useListIntegrationsByShopQuery__
 *
 * To run a query within a React component, call `useListIntegrationsByShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useListIntegrationsByShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListIntegrationsByShopQuery({
 *   variables: {
 *      shop_id: // value for 'shop_id'
 *   },
 * });
 */
export function useListIntegrationsByShopQuery(
  baseOptions?: Apollo.QueryHookOptions<ListIntegrationsByShopQuery, ListIntegrationsByShopQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListIntegrationsByShopQuery, ListIntegrationsByShopQueryVariables>(
    ListIntegrationsByShopDocument,
    options,
  );
}
export function useListIntegrationsByShopLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListIntegrationsByShopQuery, ListIntegrationsByShopQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListIntegrationsByShopQuery, ListIntegrationsByShopQueryVariables>(
    ListIntegrationsByShopDocument,
    options,
  );
}
export function useListIntegrationsByShopSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ListIntegrationsByShopQuery, ListIntegrationsByShopQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListIntegrationsByShopQuery, ListIntegrationsByShopQueryVariables>(
    ListIntegrationsByShopDocument,
    options,
  );
}
export type ListIntegrationsByShopQueryHookResult = ReturnType<typeof useListIntegrationsByShopQuery>;
export type ListIntegrationsByShopLazyQueryHookResult = ReturnType<typeof useListIntegrationsByShopLazyQuery>;
export type ListIntegrationsByShopSuspenseQueryHookResult = ReturnType<typeof useListIntegrationsByShopSuspenseQuery>;
export type ListIntegrationsByShopQueryResult = Apollo.QueryResult<
  ListIntegrationsByShopQuery,
  ListIntegrationsByShopQueryVariables
>;
export const GetItemDocument = gql`
    query GetItem($id: uuid!) {
  items_by_pk(id: $id) {
    ...Item
  }
}
    ${ItemFragmentDoc}`;

/**
 * __useGetItemQuery__
 *
 * To run a query within a React component, call `useGetItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetItemQuery(
  baseOptions: Apollo.QueryHookOptions<GetItemQuery, GetItemQueryVariables> &
    ({ variables: GetItemQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetItemQuery, GetItemQueryVariables>(GetItemDocument, options);
}
export function useGetItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetItemQuery, GetItemQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetItemQuery, GetItemQueryVariables>(GetItemDocument, options);
}
export function useGetItemSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetItemQuery, GetItemQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetItemQuery, GetItemQueryVariables>(GetItemDocument, options);
}
export type GetItemQueryHookResult = ReturnType<typeof useGetItemQuery>;
export type GetItemLazyQueryHookResult = ReturnType<typeof useGetItemLazyQuery>;
export type GetItemSuspenseQueryHookResult = ReturnType<typeof useGetItemSuspenseQuery>;
export type GetItemQueryResult = Apollo.QueryResult<GetItemQuery, GetItemQueryVariables>;
export const GetPlatformCategoryDocument = gql`
    query GetPlatformCategory($input: PlatformCategoriesInput!) {
  platform_categories(input: $input) {
    categories {
      id
      name
      path
    }
  }
}
    `;

/**
 * __useGetPlatformCategoryQuery__
 *
 * To run a query within a React component, call `useGetPlatformCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlatformCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlatformCategoryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPlatformCategoryQuery(
  baseOptions: Apollo.QueryHookOptions<GetPlatformCategoryQuery, GetPlatformCategoryQueryVariables> &
    ({ variables: GetPlatformCategoryQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlatformCategoryQuery, GetPlatformCategoryQueryVariables>(
    GetPlatformCategoryDocument,
    options,
  );
}
export function useGetPlatformCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPlatformCategoryQuery, GetPlatformCategoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPlatformCategoryQuery, GetPlatformCategoryQueryVariables>(
    GetPlatformCategoryDocument,
    options,
  );
}
export function useGetPlatformCategorySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetPlatformCategoryQuery, GetPlatformCategoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPlatformCategoryQuery, GetPlatformCategoryQueryVariables>(
    GetPlatformCategoryDocument,
    options,
  );
}
export type GetPlatformCategoryQueryHookResult = ReturnType<typeof useGetPlatformCategoryQuery>;
export type GetPlatformCategoryLazyQueryHookResult = ReturnType<typeof useGetPlatformCategoryLazyQuery>;
export type GetPlatformCategorySuspenseQueryHookResult = ReturnType<typeof useGetPlatformCategorySuspenseQuery>;
export type GetPlatformCategoryQueryResult = Apollo.QueryResult<
  GetPlatformCategoryQuery,
  GetPlatformCategoryQueryVariables
>;
export const ListYahooShoppingCategoriesDocument = gql`
    query ListYahooShoppingCategories($input: ListYahooShoppingCategoriesInput!) {
  listYahooShoppingCategories(input: $input) {
    yahooShoppingCategories {
      code
      name
      hasChild
    }
  }
}
    `;

/**
 * __useListYahooShoppingCategoriesQuery__
 *
 * To run a query within a React component, call `useListYahooShoppingCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListYahooShoppingCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListYahooShoppingCategoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListYahooShoppingCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<ListYahooShoppingCategoriesQuery, ListYahooShoppingCategoriesQueryVariables> &
    ({ variables: ListYahooShoppingCategoriesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListYahooShoppingCategoriesQuery, ListYahooShoppingCategoriesQueryVariables>(
    ListYahooShoppingCategoriesDocument,
    options,
  );
}
export function useListYahooShoppingCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListYahooShoppingCategoriesQuery,
    ListYahooShoppingCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListYahooShoppingCategoriesQuery, ListYahooShoppingCategoriesQueryVariables>(
    ListYahooShoppingCategoriesDocument,
    options,
  );
}
export function useListYahooShoppingCategoriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListYahooShoppingCategoriesQuery,
    ListYahooShoppingCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListYahooShoppingCategoriesQuery, ListYahooShoppingCategoriesQueryVariables>(
    ListYahooShoppingCategoriesDocument,
    options,
  );
}
export type ListYahooShoppingCategoriesQueryHookResult = ReturnType<typeof useListYahooShoppingCategoriesQuery>;
export type ListYahooShoppingCategoriesLazyQueryHookResult = ReturnType<typeof useListYahooShoppingCategoriesLazyQuery>;
export type ListYahooShoppingCategoriesSuspenseQueryHookResult = ReturnType<
  typeof useListYahooShoppingCategoriesSuspenseQuery
>;
export type ListYahooShoppingCategoriesQueryResult = Apollo.QueryResult<
  ListYahooShoppingCategoriesQuery,
  ListYahooShoppingCategoriesQueryVariables
>;
export const ListYahooStoreCategoriesDocument = gql`
    query ListYahooStoreCategories($input: ListYahooStoreCategoriesInput!) {
  listYahooStoreCategories(input: $input) {
    yahooStoreCategories {
      pageKey
      name
      isHidden
    }
  }
}
    `;

/**
 * __useListYahooStoreCategoriesQuery__
 *
 * To run a query within a React component, call `useListYahooStoreCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListYahooStoreCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListYahooStoreCategoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListYahooStoreCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<ListYahooStoreCategoriesQuery, ListYahooStoreCategoriesQueryVariables> &
    ({ variables: ListYahooStoreCategoriesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListYahooStoreCategoriesQuery, ListYahooStoreCategoriesQueryVariables>(
    ListYahooStoreCategoriesDocument,
    options,
  );
}
export function useListYahooStoreCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListYahooStoreCategoriesQuery, ListYahooStoreCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListYahooStoreCategoriesQuery, ListYahooStoreCategoriesQueryVariables>(
    ListYahooStoreCategoriesDocument,
    options,
  );
}
export function useListYahooStoreCategoriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ListYahooStoreCategoriesQuery, ListYahooStoreCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListYahooStoreCategoriesQuery, ListYahooStoreCategoriesQueryVariables>(
    ListYahooStoreCategoriesDocument,
    options,
  );
}
export type ListYahooStoreCategoriesQueryHookResult = ReturnType<typeof useListYahooStoreCategoriesQuery>;
export type ListYahooStoreCategoriesLazyQueryHookResult = ReturnType<typeof useListYahooStoreCategoriesLazyQuery>;
export type ListYahooStoreCategoriesSuspenseQueryHookResult = ReturnType<
  typeof useListYahooStoreCategoriesSuspenseQuery
>;
export type ListYahooStoreCategoriesQueryResult = Apollo.QueryResult<
  ListYahooStoreCategoriesQuery,
  ListYahooStoreCategoriesQueryVariables
>;
export const GetSoldItemsDocument = gql`
    query GetSoldItems($shop_id: uuid!) {
  item_sold_histories(
    where: {item: {shop_id: {_eq: $shop_id}}}
    order_by: {created_at: desc}
  ) {
    id
    item_id
    item_variant_id
    platform
    created_at
    item {
      name
      price
      id
      shop_id
      images {
        path
        mime_type
      }
      shop {
        name
      }
    }
    item_variant {
      name
    }
  }
}
    `;

/**
 * __useGetSoldItemsQuery__
 *
 * To run a query within a React component, call `useGetSoldItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSoldItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSoldItemsQuery({
 *   variables: {
 *      shop_id: // value for 'shop_id'
 *   },
 * });
 */
export function useGetSoldItemsQuery(
  baseOptions: Apollo.QueryHookOptions<GetSoldItemsQuery, GetSoldItemsQueryVariables> &
    ({ variables: GetSoldItemsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSoldItemsQuery, GetSoldItemsQueryVariables>(GetSoldItemsDocument, options);
}
export function useGetSoldItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSoldItemsQuery, GetSoldItemsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSoldItemsQuery, GetSoldItemsQueryVariables>(GetSoldItemsDocument, options);
}
export function useGetSoldItemsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetSoldItemsQuery, GetSoldItemsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetSoldItemsQuery, GetSoldItemsQueryVariables>(GetSoldItemsDocument, options);
}
export type GetSoldItemsQueryHookResult = ReturnType<typeof useGetSoldItemsQuery>;
export type GetSoldItemsLazyQueryHookResult = ReturnType<typeof useGetSoldItemsLazyQuery>;
export type GetSoldItemsSuspenseQueryHookResult = ReturnType<typeof useGetSoldItemsSuspenseQuery>;
export type GetSoldItemsQueryResult = Apollo.QueryResult<GetSoldItemsQuery, GetSoldItemsQueryVariables>;
export const GetMercariShopsShopIdDocument = gql`
    query GetMercariShopsShopId($shop_id: uuid!) {
  getMercariShopsShopId(input: {shopId: $shop_id}) {
    shopId
  }
}
    `;

/**
 * __useGetMercariShopsShopIdQuery__
 *
 * To run a query within a React component, call `useGetMercariShopsShopIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMercariShopsShopIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMercariShopsShopIdQuery({
 *   variables: {
 *      shop_id: // value for 'shop_id'
 *   },
 * });
 */
export function useGetMercariShopsShopIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMercariShopsShopIdQuery, GetMercariShopsShopIdQueryVariables> &
    ({ variables: GetMercariShopsShopIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMercariShopsShopIdQuery, GetMercariShopsShopIdQueryVariables>(
    GetMercariShopsShopIdDocument,
    options,
  );
}
export function useGetMercariShopsShopIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMercariShopsShopIdQuery, GetMercariShopsShopIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMercariShopsShopIdQuery, GetMercariShopsShopIdQueryVariables>(
    GetMercariShopsShopIdDocument,
    options,
  );
}
export function useGetMercariShopsShopIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetMercariShopsShopIdQuery, GetMercariShopsShopIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetMercariShopsShopIdQuery, GetMercariShopsShopIdQueryVariables>(
    GetMercariShopsShopIdDocument,
    options,
  );
}
export type GetMercariShopsShopIdQueryHookResult = ReturnType<typeof useGetMercariShopsShopIdQuery>;
export type GetMercariShopsShopIdLazyQueryHookResult = ReturnType<typeof useGetMercariShopsShopIdLazyQuery>;
export type GetMercariShopsShopIdSuspenseQueryHookResult = ReturnType<typeof useGetMercariShopsShopIdSuspenseQuery>;
export type GetMercariShopsShopIdQueryResult = Apollo.QueryResult<
  GetMercariShopsShopIdQuery,
  GetMercariShopsShopIdQueryVariables
>;
export const CreateImageDocument = gql`
    mutation CreateImage($base64str: String = "", $type: String = "") {
  upload_image(base64str: $base64str, type: $type) {
    id
    path
    mime_type
    created_at
  }
}
    `;
export type CreateImageMutationFn = Apollo.MutationFunction<CreateImageMutation, CreateImageMutationVariables>;

/**
 * __useCreateImageMutation__
 *
 * To run a mutation, you first call `useCreateImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createImageMutation, { data, loading, error }] = useCreateImageMutation({
 *   variables: {
 *      base64str: // value for 'base64str'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCreateImageMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateImageMutation, CreateImageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateImageMutation, CreateImageMutationVariables>(CreateImageDocument, options);
}
export type CreateImageMutationHookResult = ReturnType<typeof useCreateImageMutation>;
export type CreateImageMutationResult = Apollo.MutationResult<CreateImageMutation>;
export type CreateImageMutationOptions = Apollo.BaseMutationOptions<CreateImageMutation, CreateImageMutationVariables>;
export const CreateItemDocument = gql`
    mutation CreateItem($object: items_insert_input!) {
  insert_items_one(object: $object) {
    ...Item
  }
}
    ${ItemFragmentDoc}`;
export type CreateItemMutationFn = Apollo.MutationFunction<CreateItemMutation, CreateItemMutationVariables>;

/**
 * __useCreateItemMutation__
 *
 * To run a mutation, you first call `useCreateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createItemMutation, { data, loading, error }] = useCreateItemMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useCreateItemMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateItemMutation, CreateItemMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateItemMutation, CreateItemMutationVariables>(CreateItemDocument, options);
}
export type CreateItemMutationHookResult = ReturnType<typeof useCreateItemMutation>;
export type CreateItemMutationResult = Apollo.MutationResult<CreateItemMutation>;
export type CreateItemMutationOptions = Apollo.BaseMutationOptions<CreateItemMutation, CreateItemMutationVariables>;
export const UpdateItemDocument = gql`
    mutation UpdateItem($object: items_insert_input!, $item_id: uuid!, $item_variant_ids: [uuid!], $active_item_image_ids: [uuid!], $selected_platforms: [String!], $platform_base_category_ids: [Int!], $platform_stores_category_ids: [String!]) {
  delete_item_images(
    where: {item_id: {_eq: $item_id}, id: {_nin: $active_item_image_ids}}
  ) {
    returning {
      ...ItemImage
    }
  }
  delete_exhibitions(
    where: {item_id: {_eq: $item_id}, platform: {_nin: $selected_platforms}}
  ) {
    returning {
      ...Exhibition
    }
  }
  delete_item_variants(
    where: {item_id: {_eq: $item_id}, id: {_nin: $item_variant_ids}}
  ) {
    returning {
      ...ItemVariant
    }
  }
  delete_exhibition_base_categories(
    where: {exhibition: {item_id: {_eq: $item_id}, platform: {_eq: "BASE"}}, platform_base_category_id: {_nin: $platform_base_category_ids}}
  ) {
    returning {
      ...ExhibitionBaseCategories
    }
  }
  delete_exhibition_stores_categories(
    where: {exhibition: {item_id: {_eq: $item_id}, platform: {_eq: "STORES"}}, platform_stores_category_id: {_nin: $platform_stores_category_ids}}
  ) {
    returning {
      ...ExhibitionStoresCategories
    }
  }
  insert_items_one(
    object: $object
    on_conflict: {constraint: items_pkey, update_columns: [name, description, price, compare_at_price, cost, sale_starts_at, condition, brand_id, status]}
  ) {
    ...Item
  }
}
    ${ItemImageFragmentDoc}
${ExhibitionFragmentDoc}
${ItemVariantFragmentDoc}
${ExhibitionBaseCategoriesFragmentDoc}
${ExhibitionStoresCategoriesFragmentDoc}
${ItemFragmentDoc}`;
export type UpdateItemMutationFn = Apollo.MutationFunction<UpdateItemMutation, UpdateItemMutationVariables>;

/**
 * __useUpdateItemMutation__
 *
 * To run a mutation, you first call `useUpdateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemMutation, { data, loading, error }] = useUpdateItemMutation({
 *   variables: {
 *      object: // value for 'object'
 *      item_id: // value for 'item_id'
 *      item_variant_ids: // value for 'item_variant_ids'
 *      active_item_image_ids: // value for 'active_item_image_ids'
 *      selected_platforms: // value for 'selected_platforms'
 *      platform_base_category_ids: // value for 'platform_base_category_ids'
 *      platform_stores_category_ids: // value for 'platform_stores_category_ids'
 *   },
 * });
 */
export function useUpdateItemMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateItemMutation, UpdateItemMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateItemMutation, UpdateItemMutationVariables>(UpdateItemDocument, options);
}
export type UpdateItemMutationHookResult = ReturnType<typeof useUpdateItemMutation>;
export type UpdateItemMutationResult = Apollo.MutationResult<UpdateItemMutation>;
export type UpdateItemMutationOptions = Apollo.BaseMutationOptions<UpdateItemMutation, UpdateItemMutationVariables>;
export const DeleteItemDocument = gql`
    mutation DeleteItem($id: uuid!) {
  delete_items_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteItemMutationFn = Apollo.MutationFunction<DeleteItemMutation, DeleteItemMutationVariables>;

/**
 * __useDeleteItemMutation__
 *
 * To run a mutation, you first call `useDeleteItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteItemMutation, { data, loading, error }] = useDeleteItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteItemMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteItemMutation, DeleteItemMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteItemMutation, DeleteItemMutationVariables>(DeleteItemDocument, options);
}
export type DeleteItemMutationHookResult = ReturnType<typeof useDeleteItemMutation>;
export type DeleteItemMutationResult = Apollo.MutationResult<DeleteItemMutation>;
export type DeleteItemMutationOptions = Apollo.BaseMutationOptions<DeleteItemMutation, DeleteItemMutationVariables>;
export const CreateTemporaryItemImageDocument = gql`
    mutation CreateTemporaryItemImage($mime_type: String!, $path: String!) {
  insert_item_images_one(object: {path: $path, mime_type: $mime_type}) {
    id
  }
}
    `;
export type CreateTemporaryItemImageMutationFn = Apollo.MutationFunction<
  CreateTemporaryItemImageMutation,
  CreateTemporaryItemImageMutationVariables
>;

/**
 * __useCreateTemporaryItemImageMutation__
 *
 * To run a mutation, you first call `useCreateTemporaryItemImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemporaryItemImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemporaryItemImageMutation, { data, loading, error }] = useCreateTemporaryItemImageMutation({
 *   variables: {
 *      mime_type: // value for 'mime_type'
 *      path: // value for 'path'
 *   },
 * });
 */
export function useCreateTemporaryItemImageMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTemporaryItemImageMutation, CreateTemporaryItemImageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTemporaryItemImageMutation, CreateTemporaryItemImageMutationVariables>(
    CreateTemporaryItemImageDocument,
    options,
  );
}
export type CreateTemporaryItemImageMutationHookResult = ReturnType<typeof useCreateTemporaryItemImageMutation>;
export type CreateTemporaryItemImageMutationResult = Apollo.MutationResult<CreateTemporaryItemImageMutation>;
export type CreateTemporaryItemImageMutationOptions = Apollo.BaseMutationOptions<
  CreateTemporaryItemImageMutation,
  CreateTemporaryItemImageMutationVariables
>;
export const CopyItemDocument = gql`
    mutation CopyItem($object: items_insert_input!, $temporaryImageIds: [uuid!]!, $newItemId: uuid!) {
  insert_items_one(object: $object) {
    id
  }
  update_item_images(
    where: {id: {_in: $temporaryImageIds}}
    _set: {item_id: $newItemId}
  ) {
    affected_rows
  }
}
    `;
export type CopyItemMutationFn = Apollo.MutationFunction<CopyItemMutation, CopyItemMutationVariables>;

/**
 * __useCopyItemMutation__
 *
 * To run a mutation, you first call `useCopyItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyItemMutation, { data, loading, error }] = useCopyItemMutation({
 *   variables: {
 *      object: // value for 'object'
 *      temporaryImageIds: // value for 'temporaryImageIds'
 *      newItemId: // value for 'newItemId'
 *   },
 * });
 */
export function useCopyItemMutation(
  baseOptions?: Apollo.MutationHookOptions<CopyItemMutation, CopyItemMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CopyItemMutation, CopyItemMutationVariables>(CopyItemDocument, options);
}
export type CopyItemMutationHookResult = ReturnType<typeof useCopyItemMutation>;
export type CopyItemMutationResult = Apollo.MutationResult<CopyItemMutation>;
export type CopyItemMutationOptions = Apollo.BaseMutationOptions<CopyItemMutation, CopyItemMutationVariables>;
export const CreateIntegrationDocument = gql`
    mutation CreateIntegration($input: CreateIntegrationInput!) {
  createIntegration(input: $input) {
    integrationId
  }
}
    `;
export type CreateIntegrationMutationFn = Apollo.MutationFunction<
  CreateIntegrationMutation,
  CreateIntegrationMutationVariables
>;

/**
 * __useCreateIntegrationMutation__
 *
 * To run a mutation, you first call `useCreateIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIntegrationMutation, { data, loading, error }] = useCreateIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateIntegrationMutation, CreateIntegrationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateIntegrationMutation, CreateIntegrationMutationVariables>(
    CreateIntegrationDocument,
    options,
  );
}
export type CreateIntegrationMutationHookResult = ReturnType<typeof useCreateIntegrationMutation>;
export type CreateIntegrationMutationResult = Apollo.MutationResult<CreateIntegrationMutation>;
export type CreateIntegrationMutationOptions = Apollo.BaseMutationOptions<
  CreateIntegrationMutation,
  CreateIntegrationMutationVariables
>;
export const DeleteIntegrationDocument = gql`
    mutation DeleteIntegration($shop_id: uuid!, $platform: String!) {
  delete_integrations(
    where: {_and: {shop_id: {_eq: $shop_id}, platform: {_eq: $platform}}}
  ) {
    returning {
      id
    }
  }
}
    `;
export type DeleteIntegrationMutationFn = Apollo.MutationFunction<
  DeleteIntegrationMutation,
  DeleteIntegrationMutationVariables
>;

/**
 * __useDeleteIntegrationMutation__
 *
 * To run a mutation, you first call `useDeleteIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIntegrationMutation, { data, loading, error }] = useDeleteIntegrationMutation({
 *   variables: {
 *      shop_id: // value for 'shop_id'
 *      platform: // value for 'platform'
 *   },
 * });
 */
export function useDeleteIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteIntegrationMutation, DeleteIntegrationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteIntegrationMutation, DeleteIntegrationMutationVariables>(
    DeleteIntegrationDocument,
    options,
  );
}
export type DeleteIntegrationMutationHookResult = ReturnType<typeof useDeleteIntegrationMutation>;
export type DeleteIntegrationMutationResult = Apollo.MutationResult<DeleteIntegrationMutation>;
export type DeleteIntegrationMutationOptions = Apollo.BaseMutationOptions<
  DeleteIntegrationMutation,
  DeleteIntegrationMutationVariables
>;
export const CreateIntegrationYahooShoppingSettingDocument = gql`
    mutation CreateIntegrationYahooShoppingSetting($input: integration_yahoo_shopping_settings_insert_input = {}) {
  insert_integration_yahoo_shopping_settings_one(object: $input) {
    id
    integration_id
    seller_id
  }
}
    `;
export type CreateIntegrationYahooShoppingSettingMutationFn = Apollo.MutationFunction<
  CreateIntegrationYahooShoppingSettingMutation,
  CreateIntegrationYahooShoppingSettingMutationVariables
>;

/**
 * __useCreateIntegrationYahooShoppingSettingMutation__
 *
 * To run a mutation, you first call `useCreateIntegrationYahooShoppingSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIntegrationYahooShoppingSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIntegrationYahooShoppingSettingMutation, { data, loading, error }] = useCreateIntegrationYahooShoppingSettingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateIntegrationYahooShoppingSettingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateIntegrationYahooShoppingSettingMutation,
    CreateIntegrationYahooShoppingSettingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateIntegrationYahooShoppingSettingMutation,
    CreateIntegrationYahooShoppingSettingMutationVariables
  >(CreateIntegrationYahooShoppingSettingDocument, options);
}
export type CreateIntegrationYahooShoppingSettingMutationHookResult = ReturnType<
  typeof useCreateIntegrationYahooShoppingSettingMutation
>;
export type CreateIntegrationYahooShoppingSettingMutationResult =
  Apollo.MutationResult<CreateIntegrationYahooShoppingSettingMutation>;
export type CreateIntegrationYahooShoppingSettingMutationOptions = Apollo.BaseMutationOptions<
  CreateIntegrationYahooShoppingSettingMutation,
  CreateIntegrationYahooShoppingSettingMutationVariables
>;
export const CreateUserAndPaymentDocument = gql`
    mutation CreateUserAndPayment($input: CreateUserAndPaymentInput!) {
  createUserAndPayment(input: $input) {
    userId
  }
}
    `;
export type CreateUserAndPaymentMutationFn = Apollo.MutationFunction<
  CreateUserAndPaymentMutation,
  CreateUserAndPaymentMutationVariables
>;

/**
 * __useCreateUserAndPaymentMutation__
 *
 * To run a mutation, you first call `useCreateUserAndPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserAndPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserAndPaymentMutation, { data, loading, error }] = useCreateUserAndPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserAndPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUserAndPaymentMutation, CreateUserAndPaymentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserAndPaymentMutation, CreateUserAndPaymentMutationVariables>(
    CreateUserAndPaymentDocument,
    options,
  );
}
export type CreateUserAndPaymentMutationHookResult = ReturnType<typeof useCreateUserAndPaymentMutation>;
export type CreateUserAndPaymentMutationResult = Apollo.MutationResult<CreateUserAndPaymentMutation>;
export type CreateUserAndPaymentMutationOptions = Apollo.BaseMutationOptions<
  CreateUserAndPaymentMutation,
  CreateUserAndPaymentMutationVariables
>;
export const ListExhibitionsByItemIdDocument = gql`
    query ListExhibitionsByItemId($item_id: uuid!) {
  exhibitions(where: {item_id: {_eq: $item_id}}) {
    ...ExhibitionStatusIndicator_Exhibition
  }
}
    ${ExhibitionStatusIndicator_ExhibitionFragmentDoc}`;

/**
 * __useListExhibitionsByItemIdQuery__
 *
 * To run a query within a React component, call `useListExhibitionsByItemIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useListExhibitionsByItemIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListExhibitionsByItemIdQuery({
 *   variables: {
 *      item_id: // value for 'item_id'
 *   },
 * });
 */
export function useListExhibitionsByItemIdQuery(
  baseOptions: Apollo.QueryHookOptions<ListExhibitionsByItemIdQuery, ListExhibitionsByItemIdQueryVariables> &
    ({ variables: ListExhibitionsByItemIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListExhibitionsByItemIdQuery, ListExhibitionsByItemIdQueryVariables>(
    ListExhibitionsByItemIdDocument,
    options,
  );
}
export function useListExhibitionsByItemIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListExhibitionsByItemIdQuery, ListExhibitionsByItemIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListExhibitionsByItemIdQuery, ListExhibitionsByItemIdQueryVariables>(
    ListExhibitionsByItemIdDocument,
    options,
  );
}
export function useListExhibitionsByItemIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ListExhibitionsByItemIdQuery, ListExhibitionsByItemIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListExhibitionsByItemIdQuery, ListExhibitionsByItemIdQueryVariables>(
    ListExhibitionsByItemIdDocument,
    options,
  );
}
export type ListExhibitionsByItemIdQueryHookResult = ReturnType<typeof useListExhibitionsByItemIdQuery>;
export type ListExhibitionsByItemIdLazyQueryHookResult = ReturnType<typeof useListExhibitionsByItemIdLazyQuery>;
export type ListExhibitionsByItemIdSuspenseQueryHookResult = ReturnType<typeof useListExhibitionsByItemIdSuspenseQuery>;
export type ListExhibitionsByItemIdQueryResult = Apollo.QueryResult<
  ListExhibitionsByItemIdQuery,
  ListExhibitionsByItemIdQueryVariables
>;
export const PredictCategoryDocument = gql`
    query PredictCategory($input: PredictCategoryInput!) {
  predictCategory(input: $input) {
    ...ItemForm_PredictCategoryOutput
  }
}
    ${ItemForm_PredictCategoryOutputFragmentDoc}`;

/**
 * __usePredictCategoryQuery__
 *
 * To run a query within a React component, call `usePredictCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePredictCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePredictCategoryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePredictCategoryQuery(
  baseOptions: Apollo.QueryHookOptions<PredictCategoryQuery, PredictCategoryQueryVariables> &
    ({ variables: PredictCategoryQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PredictCategoryQuery, PredictCategoryQueryVariables>(PredictCategoryDocument, options);
}
export function usePredictCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PredictCategoryQuery, PredictCategoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PredictCategoryQuery, PredictCategoryQueryVariables>(PredictCategoryDocument, options);
}
export function usePredictCategorySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PredictCategoryQuery, PredictCategoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PredictCategoryQuery, PredictCategoryQueryVariables>(PredictCategoryDocument, options);
}
export type PredictCategoryQueryHookResult = ReturnType<typeof usePredictCategoryQuery>;
export type PredictCategoryLazyQueryHookResult = ReturnType<typeof usePredictCategoryLazyQuery>;
export type PredictCategorySuspenseQueryHookResult = ReturnType<typeof usePredictCategorySuspenseQuery>;
export type PredictCategoryQueryResult = Apollo.QueryResult<PredictCategoryQuery, PredictCategoryQueryVariables>;
export const ItemsDocument = gql`
    query Items($shop_id: uuid!, $cond: [items_bool_exp!] = {}, $order_by: [items_order_by!] = {created_at: desc}, $limit: Int = 200, $offset: Int = 0) {
  items(
    where: {shop_id: {_eq: $shop_id}, _and: $cond}
    order_by: $order_by
    limit: $limit
    offset: $offset
  ) {
    ...ListedItemsTab_Item
  }
  items_aggregate(where: {shop_id: {_eq: $shop_id}, _and: $cond}) {
    aggregate {
      count
    }
  }
}
    ${ListedItemsTab_ItemFragmentDoc}`;

/**
 * __useItemsQuery__
 *
 * To run a query within a React component, call `useItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemsQuery({
 *   variables: {
 *      shop_id: // value for 'shop_id'
 *      cond: // value for 'cond'
 *      order_by: // value for 'order_by'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useItemsQuery(
  baseOptions: Apollo.QueryHookOptions<ItemsQuery, ItemsQueryVariables> &
    ({ variables: ItemsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ItemsQuery, ItemsQueryVariables>(ItemsDocument, options);
}
export function useItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemsQuery, ItemsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ItemsQuery, ItemsQueryVariables>(ItemsDocument, options);
}
export function useItemsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ItemsQuery, ItemsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ItemsQuery, ItemsQueryVariables>(ItemsDocument, options);
}
export type ItemsQueryHookResult = ReturnType<typeof useItemsQuery>;
export type ItemsLazyQueryHookResult = ReturnType<typeof useItemsLazyQuery>;
export type ItemsSuspenseQueryHookResult = ReturnType<typeof useItemsSuspenseQuery>;
export type ItemsQueryResult = Apollo.QueryResult<ItemsQuery, ItemsQueryVariables>;
export const GetMercariShopsSettingDocument = gql`
    query GetMercariShopsSetting($shop_id: uuid!) {
  shop_mercari_shops_settings_by_pk(shop_id: $shop_id) {
    ...MercariShopsExhibitionSettingsForm_ShopMercariShopsSetting
  }
  exhibition_mercari_shops_settings(
    where: {exhibition: {shop_id: {_eq: $shop_id}}}
    order_by: {updated_at: desc}
    limit: 1
  ) {
    ...MercariShopsExhibitionSettingsForm_ExhibitionMercariShopsSetting
  }
}
    ${MercariShopsExhibitionSettingsForm_ShopMercariShopsSettingFragmentDoc}
${MercariShopsExhibitionSettingsForm_ExhibitionMercariShopsSettingFragmentDoc}`;

/**
 * __useGetMercariShopsSettingQuery__
 *
 * To run a query within a React component, call `useGetMercariShopsSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMercariShopsSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMercariShopsSettingQuery({
 *   variables: {
 *      shop_id: // value for 'shop_id'
 *   },
 * });
 */
export function useGetMercariShopsSettingQuery(
  baseOptions: Apollo.QueryHookOptions<GetMercariShopsSettingQuery, GetMercariShopsSettingQueryVariables> &
    ({ variables: GetMercariShopsSettingQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMercariShopsSettingQuery, GetMercariShopsSettingQueryVariables>(
    GetMercariShopsSettingDocument,
    options,
  );
}
export function useGetMercariShopsSettingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMercariShopsSettingQuery, GetMercariShopsSettingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMercariShopsSettingQuery, GetMercariShopsSettingQueryVariables>(
    GetMercariShopsSettingDocument,
    options,
  );
}
export function useGetMercariShopsSettingSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetMercariShopsSettingQuery, GetMercariShopsSettingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetMercariShopsSettingQuery, GetMercariShopsSettingQueryVariables>(
    GetMercariShopsSettingDocument,
    options,
  );
}
export type GetMercariShopsSettingQueryHookResult = ReturnType<typeof useGetMercariShopsSettingQuery>;
export type GetMercariShopsSettingLazyQueryHookResult = ReturnType<typeof useGetMercariShopsSettingLazyQuery>;
export type GetMercariShopsSettingSuspenseQueryHookResult = ReturnType<typeof useGetMercariShopsSettingSuspenseQuery>;
export type GetMercariShopsSettingQueryResult = Apollo.QueryResult<
  GetMercariShopsSettingQuery,
  GetMercariShopsSettingQueryVariables
>;
export const ListPlatformCategoriesDocument = gql`
    query ListPlatformCategories($input: PlatformCategoriesInput!) {
  platform_categories(input: $input) {
    categories {
      ...PlatformCategoryTree_Category
    }
  }
}
    ${PlatformCategoryTree_CategoryFragmentDoc}`;

/**
 * __useListPlatformCategoriesQuery__
 *
 * To run a query within a React component, call `useListPlatformCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPlatformCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPlatformCategoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListPlatformCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<ListPlatformCategoriesQuery, ListPlatformCategoriesQueryVariables> &
    ({ variables: ListPlatformCategoriesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListPlatformCategoriesQuery, ListPlatformCategoriesQueryVariables>(
    ListPlatformCategoriesDocument,
    options,
  );
}
export function useListPlatformCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListPlatformCategoriesQuery, ListPlatformCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListPlatformCategoriesQuery, ListPlatformCategoriesQueryVariables>(
    ListPlatformCategoriesDocument,
    options,
  );
}
export function useListPlatformCategoriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ListPlatformCategoriesQuery, ListPlatformCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListPlatformCategoriesQuery, ListPlatformCategoriesQueryVariables>(
    ListPlatformCategoriesDocument,
    options,
  );
}
export type ListPlatformCategoriesQueryHookResult = ReturnType<typeof useListPlatformCategoriesQuery>;
export type ListPlatformCategoriesLazyQueryHookResult = ReturnType<typeof useListPlatformCategoriesLazyQuery>;
export type ListPlatformCategoriesSuspenseQueryHookResult = ReturnType<typeof useListPlatformCategoriesSuspenseQuery>;
export type ListPlatformCategoriesQueryResult = Apollo.QueryResult<
  ListPlatformCategoriesQuery,
  ListPlatformCategoriesQueryVariables
>;
export const ListPorductTypeHistoriesDocument = gql`
    query ListPorductTypeHistories($shopId: uuid!) {
  exhibition_shopify_settings(
    distinct_on: product_type
    where: {exhibition: {shop_id: {_eq: $shopId}}}
  ) {
    product_type
  }
}
    `;

/**
 * __useListPorductTypeHistoriesQuery__
 *
 * To run a query within a React component, call `useListPorductTypeHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPorductTypeHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPorductTypeHistoriesQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useListPorductTypeHistoriesQuery(
  baseOptions: Apollo.QueryHookOptions<ListPorductTypeHistoriesQuery, ListPorductTypeHistoriesQueryVariables> &
    ({ variables: ListPorductTypeHistoriesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListPorductTypeHistoriesQuery, ListPorductTypeHistoriesQueryVariables>(
    ListPorductTypeHistoriesDocument,
    options,
  );
}
export function useListPorductTypeHistoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListPorductTypeHistoriesQuery, ListPorductTypeHistoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListPorductTypeHistoriesQuery, ListPorductTypeHistoriesQueryVariables>(
    ListPorductTypeHistoriesDocument,
    options,
  );
}
export function useListPorductTypeHistoriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ListPorductTypeHistoriesQuery, ListPorductTypeHistoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListPorductTypeHistoriesQuery, ListPorductTypeHistoriesQueryVariables>(
    ListPorductTypeHistoriesDocument,
    options,
  );
}
export type ListPorductTypeHistoriesQueryHookResult = ReturnType<typeof useListPorductTypeHistoriesQuery>;
export type ListPorductTypeHistoriesLazyQueryHookResult = ReturnType<typeof useListPorductTypeHistoriesLazyQuery>;
export type ListPorductTypeHistoriesSuspenseQueryHookResult = ReturnType<
  typeof useListPorductTypeHistoriesSuspenseQuery
>;
export type ListPorductTypeHistoriesQueryResult = Apollo.QueryResult<
  ListPorductTypeHistoriesQuery,
  ListPorductTypeHistoriesQueryVariables
>;
export const ListVendorHistoriesDocument = gql`
    query ListVendorHistories($shopId: uuid!) {
  exhibition_shopify_settings(
    distinct_on: vendor
    where: {exhibition: {shop_id: {_eq: $shopId}}}
  ) {
    vendor
  }
}
    `;

/**
 * __useListVendorHistoriesQuery__
 *
 * To run a query within a React component, call `useListVendorHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListVendorHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListVendorHistoriesQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useListVendorHistoriesQuery(
  baseOptions: Apollo.QueryHookOptions<ListVendorHistoriesQuery, ListVendorHistoriesQueryVariables> &
    ({ variables: ListVendorHistoriesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListVendorHistoriesQuery, ListVendorHistoriesQueryVariables>(
    ListVendorHistoriesDocument,
    options,
  );
}
export function useListVendorHistoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListVendorHistoriesQuery, ListVendorHistoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListVendorHistoriesQuery, ListVendorHistoriesQueryVariables>(
    ListVendorHistoriesDocument,
    options,
  );
}
export function useListVendorHistoriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ListVendorHistoriesQuery, ListVendorHistoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListVendorHistoriesQuery, ListVendorHistoriesQueryVariables>(
    ListVendorHistoriesDocument,
    options,
  );
}
export type ListVendorHistoriesQueryHookResult = ReturnType<typeof useListVendorHistoriesQuery>;
export type ListVendorHistoriesLazyQueryHookResult = ReturnType<typeof useListVendorHistoriesLazyQuery>;
export type ListVendorHistoriesSuspenseQueryHookResult = ReturnType<typeof useListVendorHistoriesSuspenseQuery>;
export type ListVendorHistoriesQueryResult = Apollo.QueryResult<
  ListVendorHistoriesQuery,
  ListVendorHistoriesQueryVariables
>;
export const SearchYahooShoppingCategoriesDocument = gql`
    query SearchYahooShoppingCategories($input: SearchYahooShoppingCategoriesInput!) {
  searchYahooShoppingCategories(input: $input) {
    yahooShoppingCategories {
      code
      name
      namePath
    }
  }
}
    `;

/**
 * __useSearchYahooShoppingCategoriesQuery__
 *
 * To run a query within a React component, call `useSearchYahooShoppingCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchYahooShoppingCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchYahooShoppingCategoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchYahooShoppingCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchYahooShoppingCategoriesQuery,
    SearchYahooShoppingCategoriesQueryVariables
  > &
    ({ variables: SearchYahooShoppingCategoriesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchYahooShoppingCategoriesQuery, SearchYahooShoppingCategoriesQueryVariables>(
    SearchYahooShoppingCategoriesDocument,
    options,
  );
}
export function useSearchYahooShoppingCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchYahooShoppingCategoriesQuery,
    SearchYahooShoppingCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchYahooShoppingCategoriesQuery, SearchYahooShoppingCategoriesQueryVariables>(
    SearchYahooShoppingCategoriesDocument,
    options,
  );
}
export function useSearchYahooShoppingCategoriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SearchYahooShoppingCategoriesQuery,
    SearchYahooShoppingCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SearchYahooShoppingCategoriesQuery, SearchYahooShoppingCategoriesQueryVariables>(
    SearchYahooShoppingCategoriesDocument,
    options,
  );
}
export type SearchYahooShoppingCategoriesQueryHookResult = ReturnType<typeof useSearchYahooShoppingCategoriesQuery>;
export type SearchYahooShoppingCategoriesLazyQueryHookResult = ReturnType<
  typeof useSearchYahooShoppingCategoriesLazyQuery
>;
export type SearchYahooShoppingCategoriesSuspenseQueryHookResult = ReturnType<
  typeof useSearchYahooShoppingCategoriesSuspenseQuery
>;
export type SearchYahooShoppingCategoriesQueryResult = Apollo.QueryResult<
  SearchYahooShoppingCategoriesQuery,
  SearchYahooShoppingCategoriesQueryVariables
>;
export const GetYahooAuctionCategoryDocument = gql`
    query GetYahooAuctionCategory($id: Int!) {
  yahoo_auction_categories_by_pk(id: $id) {
    ...YahooAuctionCategorySelect_YahooAuctionCategory
  }
}
    ${YahooAuctionCategorySelect_YahooAuctionCategoryFragmentDoc}`;

/**
 * __useGetYahooAuctionCategoryQuery__
 *
 * To run a query within a React component, call `useGetYahooAuctionCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetYahooAuctionCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetYahooAuctionCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetYahooAuctionCategoryQuery(
  baseOptions: Apollo.QueryHookOptions<GetYahooAuctionCategoryQuery, GetYahooAuctionCategoryQueryVariables> &
    ({ variables: GetYahooAuctionCategoryQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetYahooAuctionCategoryQuery, GetYahooAuctionCategoryQueryVariables>(
    GetYahooAuctionCategoryDocument,
    options,
  );
}
export function useGetYahooAuctionCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetYahooAuctionCategoryQuery, GetYahooAuctionCategoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetYahooAuctionCategoryQuery, GetYahooAuctionCategoryQueryVariables>(
    GetYahooAuctionCategoryDocument,
    options,
  );
}
export function useGetYahooAuctionCategorySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetYahooAuctionCategoryQuery, GetYahooAuctionCategoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetYahooAuctionCategoryQuery, GetYahooAuctionCategoryQueryVariables>(
    GetYahooAuctionCategoryDocument,
    options,
  );
}
export type GetYahooAuctionCategoryQueryHookResult = ReturnType<typeof useGetYahooAuctionCategoryQuery>;
export type GetYahooAuctionCategoryLazyQueryHookResult = ReturnType<typeof useGetYahooAuctionCategoryLazyQuery>;
export type GetYahooAuctionCategorySuspenseQueryHookResult = ReturnType<typeof useGetYahooAuctionCategorySuspenseQuery>;
export type GetYahooAuctionCategoryQueryResult = Apollo.QueryResult<
  GetYahooAuctionCategoryQuery,
  GetYahooAuctionCategoryQueryVariables
>;
export const SearchYahooAuctionCategoryDocument = gql`
    query SearchYahooAuctionCategory($cond: yahoo_auction_categories_bool_exp!) {
  yahoo_auction_categories(where: $cond) {
    ...YahooAuctionCategorySelect_YahooAuctionCategory
  }
}
    ${YahooAuctionCategorySelect_YahooAuctionCategoryFragmentDoc}`;

/**
 * __useSearchYahooAuctionCategoryQuery__
 *
 * To run a query within a React component, call `useSearchYahooAuctionCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchYahooAuctionCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchYahooAuctionCategoryQuery({
 *   variables: {
 *      cond: // value for 'cond'
 *   },
 * });
 */
export function useSearchYahooAuctionCategoryQuery(
  baseOptions: Apollo.QueryHookOptions<SearchYahooAuctionCategoryQuery, SearchYahooAuctionCategoryQueryVariables> &
    ({ variables: SearchYahooAuctionCategoryQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchYahooAuctionCategoryQuery, SearchYahooAuctionCategoryQueryVariables>(
    SearchYahooAuctionCategoryDocument,
    options,
  );
}
export function useSearchYahooAuctionCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchYahooAuctionCategoryQuery, SearchYahooAuctionCategoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchYahooAuctionCategoryQuery, SearchYahooAuctionCategoryQueryVariables>(
    SearchYahooAuctionCategoryDocument,
    options,
  );
}
export function useSearchYahooAuctionCategorySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SearchYahooAuctionCategoryQuery,
    SearchYahooAuctionCategoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SearchYahooAuctionCategoryQuery, SearchYahooAuctionCategoryQueryVariables>(
    SearchYahooAuctionCategoryDocument,
    options,
  );
}
export type SearchYahooAuctionCategoryQueryHookResult = ReturnType<typeof useSearchYahooAuctionCategoryQuery>;
export type SearchYahooAuctionCategoryLazyQueryHookResult = ReturnType<typeof useSearchYahooAuctionCategoryLazyQuery>;
export type SearchYahooAuctionCategorySuspenseQueryHookResult = ReturnType<
  typeof useSearchYahooAuctionCategorySuspenseQuery
>;
export type SearchYahooAuctionCategoryQueryResult = Apollo.QueryResult<
  SearchYahooAuctionCategoryQuery,
  SearchYahooAuctionCategoryQueryVariables
>;
export const GetYahooShoppingPlatformSettingDocument = gql`
    query GetYahooShoppingPlatformSetting($shop_id: uuid!) {
  shop_yahoo_shopping_settings_by_pk(shop_id: $shop_id) {
    postage_sets
    lead_times
  }
}
    `;

/**
 * __useGetYahooShoppingPlatformSettingQuery__
 *
 * To run a query within a React component, call `useGetYahooShoppingPlatformSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetYahooShoppingPlatformSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetYahooShoppingPlatformSettingQuery({
 *   variables: {
 *      shop_id: // value for 'shop_id'
 *   },
 * });
 */
export function useGetYahooShoppingPlatformSettingQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetYahooShoppingPlatformSettingQuery,
    GetYahooShoppingPlatformSettingQueryVariables
  > &
    ({ variables: GetYahooShoppingPlatformSettingQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetYahooShoppingPlatformSettingQuery, GetYahooShoppingPlatformSettingQueryVariables>(
    GetYahooShoppingPlatformSettingDocument,
    options,
  );
}
export function useGetYahooShoppingPlatformSettingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetYahooShoppingPlatformSettingQuery,
    GetYahooShoppingPlatformSettingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetYahooShoppingPlatformSettingQuery, GetYahooShoppingPlatformSettingQueryVariables>(
    GetYahooShoppingPlatformSettingDocument,
    options,
  );
}
export function useGetYahooShoppingPlatformSettingSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetYahooShoppingPlatformSettingQuery,
    GetYahooShoppingPlatformSettingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetYahooShoppingPlatformSettingQuery, GetYahooShoppingPlatformSettingQueryVariables>(
    GetYahooShoppingPlatformSettingDocument,
    options,
  );
}
export type GetYahooShoppingPlatformSettingQueryHookResult = ReturnType<typeof useGetYahooShoppingPlatformSettingQuery>;
export type GetYahooShoppingPlatformSettingLazyQueryHookResult = ReturnType<
  typeof useGetYahooShoppingPlatformSettingLazyQuery
>;
export type GetYahooShoppingPlatformSettingSuspenseQueryHookResult = ReturnType<
  typeof useGetYahooShoppingPlatformSettingSuspenseQuery
>;
export type GetYahooShoppingPlatformSettingQueryResult = Apollo.QueryResult<
  GetYahooShoppingPlatformSettingQuery,
  GetYahooShoppingPlatformSettingQueryVariables
>;
export const PlatformSettingsDocument = gql`
    query PlatformSettings($shop_id: uuid!) {
  shop_mercari_shops_settings_by_pk(shop_id: $shop_id) {
    default_condition
    default_shipping_duration
    default_shipping_from_state_id
    default_shipping_method
  }
  shop_yahoo_shopping_settings_by_pk(shop_id: $shop_id) {
    postage_sets
    lead_times
  }
}
    `;

/**
 * __usePlatformSettingsQuery__
 *
 * To run a query within a React component, call `usePlatformSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlatformSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlatformSettingsQuery({
 *   variables: {
 *      shop_id: // value for 'shop_id'
 *   },
 * });
 */
export function usePlatformSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<PlatformSettingsQuery, PlatformSettingsQueryVariables> &
    ({ variables: PlatformSettingsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PlatformSettingsQuery, PlatformSettingsQueryVariables>(PlatformSettingsDocument, options);
}
export function usePlatformSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PlatformSettingsQuery, PlatformSettingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PlatformSettingsQuery, PlatformSettingsQueryVariables>(PlatformSettingsDocument, options);
}
export function usePlatformSettingsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PlatformSettingsQuery, PlatformSettingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PlatformSettingsQuery, PlatformSettingsQueryVariables>(
    PlatformSettingsDocument,
    options,
  );
}
export type PlatformSettingsQueryHookResult = ReturnType<typeof usePlatformSettingsQuery>;
export type PlatformSettingsLazyQueryHookResult = ReturnType<typeof usePlatformSettingsLazyQuery>;
export type PlatformSettingsSuspenseQueryHookResult = ReturnType<typeof usePlatformSettingsSuspenseQuery>;
export type PlatformSettingsQueryResult = Apollo.QueryResult<PlatformSettingsQuery, PlatformSettingsQueryVariables>;
export const UpdatePlatformSettingsDocument = gql`
    mutation UpdatePlatformSettings($mercari_shop_input: shop_mercari_shops_settings_insert_input!, $yahoo_shopping_input: shop_yahoo_shopping_settings_insert_input!) {
  insert_shop_mercari_shops_settings_one(
    object: $mercari_shop_input
    on_conflict: {constraint: shop_mercari_shops_settings_pkey, update_columns: [default_condition, default_shipping_duration, default_shipping_from_state_id, default_shipping_method]}
  ) {
    default_condition
    default_shipping_duration
    default_shipping_from_state_id
    default_shipping_method
  }
  insert_shop_yahoo_shopping_settings_one(
    object: $yahoo_shopping_input
    on_conflict: {constraint: shop_yahoo_shopping_settings_pkey, update_columns: [postage_sets, lead_times]}
  ) {
    lead_times
    postage_sets
  }
}
    `;
export type UpdatePlatformSettingsMutationFn = Apollo.MutationFunction<
  UpdatePlatformSettingsMutation,
  UpdatePlatformSettingsMutationVariables
>;

/**
 * __useUpdatePlatformSettingsMutation__
 *
 * To run a mutation, you first call `useUpdatePlatformSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlatformSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlatformSettingsMutation, { data, loading, error }] = useUpdatePlatformSettingsMutation({
 *   variables: {
 *      mercari_shop_input: // value for 'mercari_shop_input'
 *      yahoo_shopping_input: // value for 'yahoo_shopping_input'
 *   },
 * });
 */
export function useUpdatePlatformSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePlatformSettingsMutation, UpdatePlatformSettingsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePlatformSettingsMutation, UpdatePlatformSettingsMutationVariables>(
    UpdatePlatformSettingsDocument,
    options,
  );
}
export type UpdatePlatformSettingsMutationHookResult = ReturnType<typeof useUpdatePlatformSettingsMutation>;
export type UpdatePlatformSettingsMutationResult = Apollo.MutationResult<UpdatePlatformSettingsMutation>;
export type UpdatePlatformSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdatePlatformSettingsMutation,
  UpdatePlatformSettingsMutationVariables
>;
