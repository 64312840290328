import { gql, useApolloClient } from "@apollo/client";
import { InfoIcon, Search2Icon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  CloseButton,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputRightElement,
  ListItem,
  NumberInput,
  NumberInputField,
  Select,
  Text,
  Textarea,
  Tooltip,
  UnorderedList,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../auth";
import { DeleteConfirmModal } from "../components/DeleteConfirmModal";
import { DeleteFinishedModal } from "../components/DeleteFinishedModal";
import { ImageUploader, type ItemImage } from "../components/ImageUploader";
import { ListingConfirmModal } from "../components/ListingConfirmModal";
import { ListingFinishedModal } from "../components/ListingFinishedModal";
import {
  type BrandFragment,
  type ExhibitionMercariShopsSettingFragment,
  type ExhibitionShopifySettingFragment,
  type ExhibitionYahooShoppingSettingFragment,
  Exhibition_Base_Categories_Constraint,
  Exhibition_Mercari_Shops_Settings_Constraint,
  Exhibition_Mercari_Shops_Settings_Update_Column,
  Exhibition_Shopify_Settings_Constraint,
  Exhibition_Shopify_Settings_Update_Column,
  Exhibition_Stores_Categories_Constraint,
  Exhibition_Yahoo_Shopping_Settings_Constraint,
  Exhibition_Yahoo_Shopping_Settings_Update_Column,
  Exhibitions_Constraint,
  Exhibitions_Update_Column,
  ItemFragmentDoc,
  Item_Images_Constraint,
  Item_Images_Update_Column,
  Item_Variants_Constraint,
  Item_Variants_Update_Column,
  useCreateImageMutation,
  useCreateItemMutation,
  useDeleteItemMutation,
  useGetItemQuery,
  useInitializeByShopQuery,
  useInitializeQuery,
  usePredictCategoryLazyQuery,
  useUpdateItemMutation,
} from "../graphql/generated/apollo";
import { useShopContext } from "../shop";
import { pad2dArray } from "../utils/array";
import { localDateTimeToJSTTimestamp, timestampToDateTime } from "../utils/date";
import { isFeatureEnabled } from "../utils/featureFlag";
import { type ValidationWarning, validate } from "../utils/item";
import { isRealNumber } from "../utils/number";
import { platformKeyToName } from "../utils/platform";
import { isUuid } from "../utils/uuid";
import { GeneralModal } from "./GeneralModal";
import { OptionalTag } from "./ItemForm/OptionalTag";
import { PlatformExhibitionSettingsForm } from "./PlatformExhibitionSettingsForm";
import { VariantsForm } from "./VariantsForm";

export type ItemType = {
  id: string;
  name: string;
  price: number;
  compare_at_price?: number | null;
  cost?: number | null;
  description?: string | null;
  images?: ItemImage[] | null;
  condition?: string | null;
  item_status?: { name: string } | null;
  sale_starts_at?: string | null;
  brand?: BrandFragment | null;
  status: string;
  exhibitions?:
    | {
        platform: string;
        status: string;
        base_categories?: {
          platform_base_category_id: number;
        }[];
        stores_categories?: {
          platform_stores_category_id: string;
        }[];
        mercari_shops_setting?: {
          brand_id?: string | null;
          category_id: string;
          condition: string;
          shipping_duration: string;
          shipping_from_state_id: string;
          shipping_method: string;
          shipping_payer: string;
        } | null;
        yahoo_shopping_setting?: {
          store_category_hierarchies: string[][];
          product_category: number;
          shopping_category_hierarchy: number[];
          brand_code?: number | null;
        } | null;
        shopify_setting?: {
          category: string;
          product_type: string;
          vendor: string;
          tags: string[];
        } | null;
      }[]
    | null;
  variants?: { id: string; name: string; stock_quantity: string | number; sku: string }[] | null;
};

export type ItemFormProps = {
  initialData?: ItemType;
  onFinished?: () => void;
};

gql`
  query PredictCategory($input: PredictCategoryInput!) {
    predictCategory(input: $input) {
      ...ItemForm_PredictCategoryOutput
    }
  }
  fragment ItemForm_PredictCategoryOutput on PredictCategoryOutput {
    results {
      target
      predictedCategories {
        id
        namePath
        isLeaf
        score
      }
    }
  }
`;

export function ItemForm({ initialData, onFinished }: ItemFormProps) {
  const { currentShopId, isActiveShop } = useShopContext();
  if (!currentShopId) return <p>ショップが選択されていません</p>;
  const { user } = useAuthContext();
  const toast = useToast();

  const [itemStatus, setItemStatus] = useState(initialData?.status ?? "INITIAL");
  const [itemName, setItemName] = useState(initialData?.name || "");
  const [itemPrice, setItemPrice] = useState(initialData?.price ?? undefined);
  const [itemCompareAtPrice, setItemCompareAtPrice] = useState(initialData?.compare_at_price ?? undefined);
  const [itemCost, setItemCost] = useState(initialData?.cost ?? undefined);
  const [enableCompareAtPrice, setEnableCompareAtPrice] = useState(!!initialData?.compare_at_price);
  const [itemDescription, setItemDescription] = useState(initialData?.description || "");
  const [itemBrand, setItemBrand] = useState<BrandFragment | null | undefined>(initialData?.brand || null);
  const [itemCondition, setItemCondition] = useState<string | null | undefined>(initialData?.condition || null);
  const [saleStartsAt, setSaleStartsAt] = useState<string | null | undefined>(
    initialData?.sale_starts_at ? timestampToDateTime(initialData.sale_starts_at) : null,
  );
  const [variants, setVariants] = useState<{ id: string; name: string; stockQuantity: string | number; sku: string }[]>(
    initialData?.variants && initialData.variants.length > 1
      ? initialData?.variants.map((v) => {
          return {
            id: v.id,
            name: v.name,
            stockQuantity: v.stock_quantity,
            sku: v.sku,
          };
        })
      : [
          {
            id: "",
            name: "",
            stockQuantity: initialData?.variants?.find(Boolean)?.stock_quantity ?? 0,
            sku: "",
          },
        ],
  );
  const [itemImages, setItemImages] = useState<ItemImage[]>(initialData?.images || []);
  const [selectedMarketplaces, setSelectedMarketplaces] = useState<string[] | undefined>(
    initialData?.exhibitions?.map((e) => e.platform) ?? undefined,
  );
  const [baseCategories, setBaseCategories] = useState<number[]>(
    initialData?.exhibitions
      ?.find((e) => e.platform === "BASE")
      ?.base_categories?.map((c) => c.platform_base_category_id) ?? [],
  );
  const [storesCategories, setStoresCategories] = useState<string[]>(
    initialData?.exhibitions
      ?.find((e) => e.platform === "STORES")
      ?.stores_categories?.map((c) => c.platform_stores_category_id) ?? [],
  );
  const [mercariShopsSettings, setMercariShopsSettings] = useState<ExhibitionMercariShopsSettingFragment | null>(
    initialData?.exhibitions?.find((e) => e.platform === "MERCARI_SHOPS")?.mercari_shops_setting ?? null,
  );
  const [yahooShoppingSetting, setYahooShoppingSetting] = useState<ExhibitionYahooShoppingSettingFragment | undefined>(
    initialData?.exhibitions?.find((e) => e.platform === "YAHOO_SHOPPING")?.yahoo_shopping_setting ?? undefined,
  );
  const [shopifySetting, setShopifySetting] = useState<ExhibitionShopifySettingFragment | undefined>(
    initialData?.exhibitions?.find((e) => e.platform === "SHOPIFY")?.shopify_setting ?? undefined,
  );

  const [validationWarnings, setValidationWarnings] = useState<ValidationWarning[]>([]);
  const [nextStatus, setNextStatus] = useState<"DRAFT" | "ACTIVE">("DRAFT");

  const resetForm = () => {
    setItemStatus("INITIAL");
    setNextStatus("DRAFT");
    setItemName("");
    setItemPrice(undefined);
    setItemCompareAtPrice(undefined);
    setItemCost(undefined);
    setEnableCompareAtPrice(false);
    setItemDescription("");
    setItemCondition(undefined);
    setItemBrand(undefined);
    setSaleStartsAt(undefined);
    setVariants([{ id: "", name: "", stockQuantity: 1, sku: "" }]);
    setItemImages([]);
    setSelectedMarketplaces(initializeByShopHooks.data?.integrations.map((integ) => integ.platform));
    setBaseCategories([]);
    setStoresCategories([]);
    setMercariShopsSettings(null);
    setYahooShoppingSetting({
      product_category: 0,
      store_category_hierarchies: [[]],
      shopping_category_hierarchy: [],
    });
    setShopifySetting({
      category: "",
      product_type: "",
      vendor: "",
      tags: [],
    });
  };

  const PredictCategoryDisclosure = useDisclosure();
  const ListingConfirmModalDisclosure = useDisclosure();
  const ListingFinishedModalDisclosure = useDisclosure();
  const DeleteConfirmModalDisclosure = useDisclosure();
  const DeleteFinishedModalDisclosure = useDisclosure();

  const navigate = useNavigate();
  const { id } = useParams();
  const useGetItemQueryHook = useGetItemQuery({
    variables: {
      id: id,
    },
    skip: !user || !currentShopId || !!initialData,
    onCompleted: (data) => {
      if (!data.items_by_pk) {
        return alert("商品が見つかりません"); // TODO: 404ページに遷移する
      }

      setItemStatus(data.items_by_pk.status);
      setItemName(data.items_by_pk.name);
      setItemPrice(data.items_by_pk.price);
      setItemCompareAtPrice(data.items_by_pk.compare_at_price ?? undefined);
      setItemCost(data.items_by_pk.cost ?? undefined);
      setEnableCompareAtPrice(!!data.items_by_pk.compare_at_price);
      setItemDescription(data.items_by_pk.description ?? "");
      setItemBrand(data.items_by_pk.brand);
      setItemCondition(data.items_by_pk.condition);
      setSaleStartsAt(data.items_by_pk.sale_starts_at ? timestampToDateTime(data.items_by_pk.sale_starts_at) : null);
      if (data.items_by_pk.variants.length > 0) {
        setVariants(
          data.items_by_pk.variants.map((v) => {
            return {
              id: v.id,
              name: v.name,
              stockQuantity: v.stock_quantity,
              sku: v.sku,
            };
          }),
        );
      } else {
        setVariants([
          {
            id: "",
            name: "",
            stockQuantity: 0,
            sku: "",
          },
        ]);
      }
      setItemImages(data.items_by_pk.images);
      setSelectedMarketplaces(
        data.items_by_pk.exhibitions.map((exhibition) => {
          return exhibition.platform;
        }),
      );
      setBaseCategories(
        data.items_by_pk.exhibitions
          .find((exhibition) => exhibition.platform === "BASE")
          ?.base_categories?.map((category) => category.platform_base_category_id) ?? [],
      );
      setStoresCategories(
        data.items_by_pk.exhibitions
          .find((exhibition) => exhibition.platform === "STORES")
          ?.stores_categories?.map((category) => category.platform_stores_category_id) ?? [],
      );
      setMercariShopsSettings(
        data.items_by_pk.exhibitions.find((exhibition) => exhibition.platform === "MERCARI_SHOPS")
          ?.mercari_shops_setting ?? null,
      );
      setYahooShoppingSetting(
        data.items_by_pk.exhibitions.find((exhibition) => exhibition.platform === "YAHOO_SHOPPING")
          ?.yahoo_shopping_setting ?? {
          product_category: 0,
          store_category_hierarchies: [[]],
          shopping_category_hierarchy: [],
        },
      );
      setShopifySetting(
        data.items_by_pk.exhibitions.find((exhibition) => exhibition.platform === "SHOPIFY")?.shopify_setting ?? {
          category: "",
          product_type: "",
          vendor: "",
          tags: [],
        },
      );
    },
    onError(error) {
      if (!id) {
        resetForm();
        return;
      }
    },
  });

  const initializeHooks = useInitializeQuery({ skip: !user });
  const initializeByShopHooks = useInitializeByShopQuery({
    variables: {
      shop_id: currentShopId,
    },
    skip: !user || !currentShopId,
    onCompleted: (data) => {
      // 既存出品がない場合は、連携済みのものを設定する
      if (!selectedMarketplaces && !id) {
        setSelectedMarketplaces(data?.integrations.map((integ) => integ.platform));
      }
    },
  });
  const [predictCategory, predictCategoryHooks] = usePredictCategoryLazyQuery({
    onCompleted: (data) => {
      PredictCategoryDisclosure.onOpen();
    },
    onError(error) {
      console.error("An error occurred while predicting category", error);
      toast({
        title: "予測に失敗しました",
        status: "error",
        isClosable: true,
      });
    },
  });

  const [createItemMutation, createItemHooks] = useCreateItemMutation({
    // 出品ミューテーションが成功した後にキャッシュを更新する

    update(cache, { data }) {
      cache.modify({
        fields: {
          items(existingItems = []) {
            const newItemRef = cache.writeFragment({
              data: data?.insert_items_one,
              fragment: ItemFragmentDoc,
              fragmentName: "Item",
            });
            return [newItemRef, ...existingItems];
          },
        },
      });
    },
    onError(error) {
      toast({
        title: "出品に失敗しました。入力内容を確認してください。", // TODO: ちゃんとVaidationでエラーを出す
        status: "error",
        isClosable: true,
      });
      throw error;
    },
  });

  const [updateItemMutation, updateItemHooks] = useUpdateItemMutation({
    // 更新ミューテーションが成功した後にキャッシュを更新する
    update(cache, { data }) {
      cache.updateFragment(
        {
          id: data?.insert_items_one?.id,
          fragment: ItemFragmentDoc,
          fragmentName: "Item",
        },
        (data) => data,
      );
    },
    onError(error) {
      toast({
        title: "商品情報の更新に失敗しました。入力内容を確認してください。", // TODO: ちゃんとVaidationでエラーを出す
        status: "error",
        isClosable: true,
      });
      throw error;
    },
  });

  const [deleteFinished, setDeleteFinished] = useState(false);
  const [deletedItemId, setDeletedItemId] = useState(null);

  const [deleteItemMutation, deleteItemHooks] = useDeleteItemMutation({
    // 削除が成功したときに、削除が完了したことを状態に保存する
    onCompleted(data) {
      if (data?.delete_items_by_pk?.id) {
        setDeletedItemId(data.delete_items_by_pk.id);
        setDeleteFinished(true);
      }
    },
    // 削除が失敗したときに、削除が失敗したことを状態に保存する
    onError(error) {
      setDeleteFinished(false);
    },
  });

  const handleSubmit = () => {
    setValidationWarnings(
      validate(
        itemName,
        itemDescription,
        variants,
        itemImages,
        itemPrice,
        itemCompareAtPrice,
        selectedMarketplaces,
        mercariShopsSettings ?? undefined,
        yahooShoppingSetting,
      ),
    );
    // onOpen() を呼び出して確認モーダルを表示する
    ListingConfirmModalDisclosure.onOpen();
  };

  const handleUpdate = () => {
    setValidationWarnings(
      validate(
        itemName,
        itemDescription,
        variants,
        itemImages,
        itemPrice,
        itemCompareAtPrice,
        selectedMarketplaces,
        mercariShopsSettings ?? undefined,
        yahooShoppingSetting,
      ),
    );
    // onOpen() を呼び出して確認モーダルを表示する
    ListingConfirmModalDisclosure.onOpen();
  };

  const [createImageMutation, createImageHooks] = useCreateImageMutation();

  const handleConfirm = async () => {
    // 未アップロードの画像があるかチェック
    const notUploadedImages = itemImages.filter((img) => !img.path);

    // 未アップロードの画像がある場合は、アップロード処理を実行
    if (notUploadedImages.length > 0) {
      // 未アップロードの画像をアップロードする
      const uploadPromises = notUploadedImages.map(async (img) =>
        createImageMutation({
          variables: {
            base64str: img.base64,
            type: img.mime_type,
          },
        }),
      );

      try {
        // アップロードの結果を待つ
        const uploadedImages = await Promise.all(uploadPromises);
        setItemImages(
          itemImages.map((itemImage) => {
            if (itemImage.id) {
              return itemImage;
            }
            const i = notUploadedImages.findIndex((img) => img.base64 === itemImage.base64);
            if (i === -1) {
              console.error("Uploaded image not found");
              return itemImage;
            }

            itemImage.id = uploadedImages[i].data?.upload_image?.id;
            itemImage.path = uploadedImages[i].data?.upload_image?.path;
            return itemImage;
          }),
        );
      } catch (error) {
        console.error("An error occurred while uploading images", error);
        return;
      }
    }

    // 選択した商品とマーケットプレイスを利用して、実際の出品処理を実装
    // 例: submitItemToMarketplaces(item, selectedMarketplaces);
    if (!id) {
      const { data } = await createItemMutation({
        variables: {
          object: {
            id: id,
            name: itemName,
            price: typeof itemPrice === "string" ? Number.parseInt(itemPrice) : itemPrice ?? 0,
            compare_at_price: enableCompareAtPrice && itemCompareAtPrice ? itemCompareAtPrice : undefined,
            cost: itemCost,
            description: itemDescription,
            images: {
              data: itemImages.map((itemImage, position) => {
                return {
                  id: itemImage.id,
                  path: itemImage.path,
                  mime_type: itemImage.mime_type,
                  position,
                };
              }),
              on_conflict: {
                constraint: Item_Images_Constraint.ItemImagesPkey,
                update_columns: [Item_Images_Update_Column.ItemId, Item_Images_Update_Column.Position],
              },
            },
            sale_starts_at: saleStartsAt ? localDateTimeToJSTTimestamp(saleStartsAt) : undefined,
            variants: {
              data: variants.map((variant, position) => {
                return {
                  name: variant.name,
                  sku: variant.sku,
                  stock_quantity:
                    typeof variant.stockQuantity === "string"
                      ? Number.parseInt(variant.stockQuantity)
                      : variant.stockQuantity,
                  position,
                };
              }),
            },
            status: nextStatus,
            condition: itemCondition,
            brand_id: itemBrand?.id,
            shop_id: currentShopId,
            exhibitions: {
              data:
                selectedMarketplaces?.map((marketplace) => {
                  return {
                    platform: marketplace,
                    shop_id: currentShopId,
                    base_categories:
                      marketplace === "BASE"
                        ? {
                            data: baseCategories.map((c) => {
                              return {
                                platform_base_category_id: c,
                              };
                            }),
                          }
                        : undefined,
                    stores_categories:
                      marketplace === "STORES"
                        ? {
                            data: storesCategories.map((c) => {
                              return {
                                platform_stores_category_id: c,
                              };
                            }),
                          }
                        : undefined,
                    mercari_shops_setting:
                      marketplace === "MERCARI_SHOPS" && mercariShopsSettings
                        ? {
                            data: mercariShopsSettings,
                          }
                        : undefined,
                    yahoo_shopping_setting:
                      marketplace === "YAHOO_SHOPPING" && yahooShoppingSetting
                        ? {
                            data: {
                              product_category: yahooShoppingSetting.product_category,
                              store_category_hierarchies: `{${pad2dArray<string>(
                                yahooShoppingSetting.store_category_hierarchies,
                                "null",
                              )
                                .map((v: (string | null)[]) => `{${v.join(",")}}`)
                                .join(",")}}`,
                              shopping_category_hierarchy: `{${yahooShoppingSetting.shopping_category_hierarchy.join(
                                ",",
                              )}}`,
                              brand_code: yahooShoppingSetting.brand_code,
                              condition: yahooShoppingSetting.condition,
                              postage_set: yahooShoppingSetting.postage_set,
                              lead_time_instock: yahooShoppingSetting.lead_time_instock,
                              lead_time_outstock: yahooShoppingSetting.lead_time_outstock,
                              auction_category: yahooShoppingSetting.auction_category,
                              auction_shipping_from_prefecture: yahooShoppingSetting.auction_shipping_from_prefecture,
                            },
                          }
                        : undefined,
                    shopify_setting:
                      marketplace === "SHOPIFY" && shopifySetting
                        ? {
                            data: shopifySetting,
                          }
                        : undefined,
                  };
                }) ?? [],
            },
          },
        },
      });

      navigate(`/sell/${data?.insert_items_one?.id}`, { replace: true });
    } else {
      await updateItemMutation({
        variables: {
          item_id: id,
          item_variant_ids: variants?.flatMap((variant) => (isUuid(variant.id) ? [variant.id] : [])),
          active_item_image_ids: itemImages.map((itemImage) => itemImage.id),
          selected_platforms: initializeHooks.data?.platforms
            .filter((platform) => selectedMarketplaces?.includes(platform.key))
            .map((platform) => platform.key),
          platform_base_category_ids: baseCategories,
          platform_stores_category_ids: storesCategories,
          object: {
            id: id,
            name: itemName,
            price: typeof itemPrice === "string" ? Number.parseInt(itemPrice) : itemPrice,
            compare_at_price: enableCompareAtPrice && itemCompareAtPrice ? itemCompareAtPrice : undefined,
            cost: itemCost,
            description: itemDescription,
            status: nextStatus,
            images: {
              data: itemImages.map((itemImage, position) => {
                return {
                  id: itemImage.id,
                  path: itemImage.path,
                  mime_type: itemImage.mime_type,
                  position,
                };
              }),
              on_conflict: {
                constraint: Item_Images_Constraint.ItemImagesPkey,
                update_columns: [Item_Images_Update_Column.ItemId, Item_Images_Update_Column.Position],
              },
            },
            sale_starts_at: saleStartsAt ? localDateTimeToJSTTimestamp(saleStartsAt) : undefined,
            variants: {
              data: variants.map((variant, position) => {
                return {
                  id: isUuid(variant.id) ? variant.id : undefined,
                  name: variant.name,
                  sku: variant.sku,
                  stock_quantity:
                    typeof variant.stockQuantity === "string"
                      ? Number.parseInt(variant.stockQuantity)
                      : variant.stockQuantity,
                  position,
                };
              }),
              on_conflict: {
                constraint: Item_Variants_Constraint.ItemVariantsPkey,
                update_columns: [
                  Item_Variants_Update_Column.Name,
                  Item_Variants_Update_Column.Sku,
                  Item_Variants_Update_Column.StockQuantity,
                  Item_Variants_Update_Column.Position,
                ],
              },
            },
            condition: itemCondition,
            brand_id: itemBrand?.id,
            shop_id: currentShopId,
            exhibitions: {
              data:
                selectedMarketplaces?.map((marketplace) => {
                  return {
                    platform: marketplace,
                    status: "IN_PROGRESS",
                    shop_id: currentShopId,
                    base_categories:
                      marketplace === "BASE"
                        ? {
                            data: baseCategories.map((c) => {
                              return {
                                platform_base_category_id: c,
                              };
                            }),
                            on_conflict: {
                              constraint:
                                Exhibition_Base_Categories_Constraint.ExhibitionBaseCategoriesExhibitionIdPlatformBaseCategory,
                              update_columns: [],
                            },
                          }
                        : undefined,
                    stores_categories:
                      marketplace === "STORES"
                        ? {
                            data: storesCategories.map((c) => {
                              return {
                                platform_stores_category_id: c,
                              };
                            }),
                            on_conflict: {
                              constraint:
                                Exhibition_Stores_Categories_Constraint.ExhibitionStoresCategoriesExhibitionIdPlatformStoresCate,
                              update_columns: [],
                            },
                          }
                        : undefined,
                    mercari_shops_setting:
                      marketplace === "MERCARI_SHOPS" && mercariShopsSettings
                        ? {
                            data: mercariShopsSettings,
                            on_conflict: {
                              constraint:
                                Exhibition_Mercari_Shops_Settings_Constraint.ExhibitionMercariShopsSettingsExhibitionIdKey,
                              update_columns: [
                                Exhibition_Mercari_Shops_Settings_Update_Column.CategoryId,
                                Exhibition_Mercari_Shops_Settings_Update_Column.BrandId,
                                Exhibition_Mercari_Shops_Settings_Update_Column.Condition,
                                Exhibition_Mercari_Shops_Settings_Update_Column.ShippingDuration,
                                Exhibition_Mercari_Shops_Settings_Update_Column.ShippingFromStateId,
                                Exhibition_Mercari_Shops_Settings_Update_Column.ShippingMethod,
                                Exhibition_Mercari_Shops_Settings_Update_Column.ShippingPayer,
                              ],
                            },
                          }
                        : undefined,
                    yahoo_shopping_setting:
                      marketplace === "YAHOO_SHOPPING" && yahooShoppingSetting
                        ? {
                            data: {
                              product_category: yahooShoppingSetting.product_category,
                              store_category_hierarchies: `{${pad2dArray<string>(
                                yahooShoppingSetting.store_category_hierarchies,
                                "null",
                              )
                                .map((v: (string | null)[]) => `{${v.join(",")}}`)
                                .join(",")}}`,
                              shopping_category_hierarchy: `{${yahooShoppingSetting.shopping_category_hierarchy.join(
                                ",",
                              )}}`,
                              brand_code: yahooShoppingSetting.brand_code,
                              condition: yahooShoppingSetting.condition,
                              postage_set: yahooShoppingSetting.postage_set,
                              lead_time_instock: yahooShoppingSetting.lead_time_instock,
                              lead_time_outstock: yahooShoppingSetting.lead_time_outstock,
                              auction_category: yahooShoppingSetting.auction_category,
                              auction_shipping_from_prefecture: yahooShoppingSetting.auction_shipping_from_prefecture,
                            },
                            on_conflict: {
                              constraint:
                                Exhibition_Yahoo_Shopping_Settings_Constraint.ExhibitionYahooShoppingSettingsExhibitionIdKey,
                              update_columns: [
                                Exhibition_Yahoo_Shopping_Settings_Update_Column.ProductCategory,
                                Exhibition_Yahoo_Shopping_Settings_Update_Column.StoreCategoryHierarchies,
                                Exhibition_Yahoo_Shopping_Settings_Update_Column.ShoppingCategoryHierarchy,
                                Exhibition_Yahoo_Shopping_Settings_Update_Column.BrandCode,
                                Exhibition_Yahoo_Shopping_Settings_Update_Column.Condition,
                                Exhibition_Yahoo_Shopping_Settings_Update_Column.PostageSet,
                                Exhibition_Yahoo_Shopping_Settings_Update_Column.LeadTimeInstock,
                                Exhibition_Yahoo_Shopping_Settings_Update_Column.LeadTimeOutstock,
                                Exhibition_Yahoo_Shopping_Settings_Update_Column.AuctionCategory,
                                Exhibition_Yahoo_Shopping_Settings_Update_Column.AuctionShippingFromPrefecture,
                              ],
                            },
                          }
                        : undefined,
                    shopify_setting:
                      marketplace === "SHOPIFY" && shopifySetting
                        ? {
                            data: shopifySetting,
                            on_conflict: {
                              constraint:
                                Exhibition_Shopify_Settings_Constraint.ExhibitionShopifySettingsExhibitionIdKey,
                              update_columns: [
                                Exhibition_Shopify_Settings_Update_Column.Category,
                                Exhibition_Shopify_Settings_Update_Column.ProductType,
                                Exhibition_Shopify_Settings_Update_Column.Vendor,
                                Exhibition_Shopify_Settings_Update_Column.Tags,
                              ],
                            },
                          }
                        : undefined,
                  };
                }) ?? [],
              on_conflict: {
                constraint: Exhibitions_Constraint.ExhibitionsItemIdPlatformKey,
                update_columns: [Exhibitions_Update_Column.Status],
              },
            },
          },
        },
      });
    }

    setItemStatus(nextStatus);
    ListingConfirmModalDisclosure.onClose();
    ListingFinishedModalDisclosure.onOpen();
  };

  const handleDelete = async () => {
    await deleteItemMutation({
      variables: {
        id: id,
      },
    });
    DeleteConfirmModalDisclosure.onClose();
    DeleteFinishedModalDisclosure.onOpen();
  };

  const client = useApolloClient();

  const handleGoToListings = () => {
    if (deleteFinished && deletedItemId) {
      // 削除が完了した場合にのみ、キャッシュを削除する
      client.cache.evict({
        id: client.cache.identify({
          __typename: "items",
          id: deletedItemId,
        }),
      });
      //状態をリセットする
      setDeletedItemId(null);
      setDeleteFinished(false);
    }
    if (onFinished) {
      onFinished();
      return;
    }

    // ブラウザバックできる場合はブラウザバックする
    // NOTE: https://github.com/remix-run/react-router/discussions/8782#discussioncomment-7639594
    ListingFinishedModalDisclosure.onClose();
    if (window.history.state?.idx !== 0) {
      navigate(-1);
    } else {
      navigate("/listings");
    }
  };

  const handleGoToSell = () => {
    resetForm();
    navigate("/sell");
    ListingFinishedModalDisclosure.onClose();
  };

  const labelStyle = {
    pl: 2,
    fontSize: "sm",
    fontWeight: "bold",
  };

  return (
    <div key={id ?? "__new"}>
      <Flex direction="column" gap={8} p={4} pb={20} borderRadius="md" bg="gray.100">
        <FormControl>
          <FormLabel sx={labelStyle}>画像</FormLabel>
          <ImageUploader itemImages={itemImages} onImagesChanged={setItemImages} />
        </FormControl>
        <FormControl>
          <FormLabel sx={labelStyle}>商品名</FormLabel>
          <Input value={itemName} onChange={(e) => setItemName(e.target.value)} sx={{ borderColor: "gray.300" }} />
          <Box position="absolute" bottom="-22px" right="2" color="gray.500" fontSize="sm">
            {itemName.length}
            <Tooltip
              label={
                <Text>
                  文字数上限についての注意事項
                  <br />
                  ・BASE: 255文字まで
                  <br />
                  ・STORES: 100文字まで
                  <br />
                  ・メルカリShops: 130文字まで
                  <br />
                  ・Yahoo!ショッピング/オークション: 全角75文字まで
                </Text>
              }
              placement="top"
              hasArrow
            >
              <span>
                <InfoIcon sx={{ ml: "6px" }} />
              </span>
            </Tooltip>
          </Box>
        </FormControl>
        <FormControl>
          <FormLabel sx={labelStyle}>説明</FormLabel>
          <Textarea
            m="0"
            p="0"
            value={itemDescription}
            onChange={(e) => setItemDescription(e.target.value)}
            sx={{ borderColor: "gray.300" }}
            rows={5}
          />
          <Box position="absolute" bottom="-22px" right="2" color="gray.500" fontSize="sm">
            {itemDescription.length}
            <Tooltip
              label={
                <Text>
                  文字数上限についての注意事項
                  <br />
                  ・BASE: 約20,000文字まで
                  <br />
                  ・STORES: 12,000文字まで
                  <br />
                  ・メルカリShops: 3,000文字まで
                  <br />
                  ・Yahoo!ショッピング/オークション: 全角5,000文字まで
                </Text>
              }
              placement="top"
              hasArrow
            >
              <span>
                <InfoIcon sx={{ ml: "6px" }} />
              </span>
            </Tooltip>
          </Box>
          {/* </Box> */}
        </FormControl>
        {isFeatureEnabled("brand", {
          shopId: currentShopId,
          userId: user?.uid ?? undefined,
        }) ? (
          <FormControl>
            <Flex justifyContent="space-between" alignItems="center">
              <FormLabel sx={labelStyle}>
                ブランド
                <OptionalTag ml={2} />
              </FormLabel>
              <Select
                value={itemBrand?.id || ""}
                onChange={(e) =>
                  setItemBrand(
                    e.target.value
                      ? initializeHooks.data?.brands.find((brand) => brand.id === e.target.value)
                      : undefined,
                  )
                }
                disabled={initializeHooks.loading}
              >
                <option key={""} value={""}>
                  選択してください
                </option>
                {initializeHooks.data?.brands.map((brand) => {
                  return (
                    <option key={brand.id} value={brand.id}>
                      {brand.name}
                    </option>
                  );
                })}
              </Select>
            </Flex>
          </FormControl>
        ) : null}
        {isFeatureEnabled("condition", {
          shopId: currentShopId,
          userId: user?.uid ?? undefined,
        }) ? (
          <FormControl>
            <Flex justifyContent="space-between" alignItems="center">
              <FormLabel sx={labelStyle}>
                状態
                <OptionalTag ml={2} />
              </FormLabel>
              <Select
                value={itemCondition ?? undefined}
                onChange={
                  (e) => {
                    setItemCondition(e.target.value || undefined);
                  } // FIXME
                }
              >
                <option key={""} value={""}>
                  選択してください
                </option>
                {initializeHooks.data?.item_conditions.map((condition) => {
                  return (
                    <option key={condition.key} value={condition.key}>
                      {condition.name}
                    </option>
                  );
                })}
              </Select>
            </Flex>
          </FormControl>
        ) : null}
        <Flex direction="column" gap={2}>
          {enableCompareAtPrice ? (
            <Flex gap={4} justify="space-between" align="bottom">
              <FormControl>
                <FormLabel sx={labelStyle}>元価格</FormLabel>
                <NumberInput
                  min={0}
                  value={itemCompareAtPrice}
                  onChange={(v) => setItemCompareAtPrice(isRealNumber(v) ? v : undefined)}
                  borderColor="gray.300"
                >
                  <NumberInputField />
                </NumberInput>
              </FormControl>
              <Flex align="center">
                <Text fontSize="xl">→</Text>
              </Flex>
              <FormControl>
                <FormLabel sx={labelStyle}>
                  セール価格
                  <Tooltip
                    label={
                      <Text>
                        セール価格についての注意事項
                        <br />
                        ・STORES: 割引率で小数第2位までの精度での計算になります
                        <br />
                        ・BASE, メルカリShops: セール表記になりません
                      </Text>
                    }
                    placement="top"
                    hasArrow
                  >
                    <span>
                      <InfoIcon sx={{ ml: "4px" }} />
                    </span>
                  </Tooltip>
                </FormLabel>
                <NumberInput
                  min={0}
                  value={itemPrice}
                  onChange={(v) => setItemPrice(isRealNumber(v) ? v : undefined)}
                  borderColor="gray.300"
                >
                  <NumberInputField />
                </NumberInput>
              </FormControl>
            </Flex>
          ) : (
            <FormControl>
              <Grid templateColumns="repeat(5, 1fr)">
                <GridItem colSpan={[2, 1]}>
                  <FormLabel sx={labelStyle}>
                    価格
                    <Text fontSize="xs">（税込）</Text>
                  </FormLabel>
                </GridItem>
                <GridItem colSpan={[3, 4]}>
                  <NumberInput
                    min={0}
                    borderRadius="md"
                    borderColor="gray.300"
                    value={itemPrice ?? ""}
                    onChange={(v) => setItemPrice(isRealNumber(v) ? v : undefined)}
                  >
                    <NumberInputField />
                  </NumberInput>
                </GridItem>
              </Grid>
            </FormControl>
          )}
          <Checkbox
            isChecked={enableCompareAtPrice}
            onChange={(e) => {
              const { checked } = e.target;
              if (checked) {
                setItemCompareAtPrice(itemPrice);
                setItemPrice(undefined);
              } else {
                setItemPrice(itemCompareAtPrice);
                setItemCompareAtPrice(undefined);
              }

              setEnableCompareAtPrice(checked);
            }}
          >
            セール価格/元価格を設定する
          </Checkbox>
        </Flex>
        <FormControl>
          <FormLabel sx={labelStyle}>
            原価
            <OptionalTag ml={2} />
          </FormLabel>
          <NumberInput
            min={0}
            value={itemCost}
            onChange={(v) => setItemCost(isRealNumber(v) ? v : undefined)}
            borderColor="gray.300"
          >
            <NumberInputField />
          </NumberInput>
        </FormControl>
        <FormControl>
          <FormLabel sx={labelStyle}>
            販売開始日時
            <Tooltip
              label={
                <Text>
                  販売開始日時についての注意事項
                  <br />
                  ・STORES: 販売期間指定のアドオンをONにする必要があります
                  <br />
                  ・BASE, メルカリShops:
                  販売開始日時までは非公開状態で出品され、その後公開されます。約10分間隔で反映されます
                </Text>
              }
              placement="top"
              hasArrow
            >
              <InfoIcon sx={{ ml: "4px" }} />
            </Tooltip>
            <OptionalTag ml={2} />
          </FormLabel>
          <Flex alignItems="center" justifyContent="center">
            <InputGroup>
              <Input
                type="datetime-local"
                borderColor="gray.300"
                value={saleStartsAt ?? ""}
                onChange={(e) => {
                  setSaleStartsAt(e.target.value);
                }}
              />
              <InputRightElement>
                <CloseButton
                  onClick={() => {
                    setSaleStartsAt("");
                  }}
                  isDisabled={!saleStartsAt}
                />
              </InputRightElement>
            </InputGroup>
          </Flex>
        </FormControl>
        <FormControl>
          <FormLabel sx={labelStyle}>在庫</FormLabel>
          <VariantsForm variants={variants} onChange={setVariants} />
        </FormControl>
        <Button
          leftIcon={<Search2Icon />}
          isDisabled={!itemName || !itemDescription}
          isLoading={predictCategoryHooks.loading}
          onClick={() =>
            predictCategory({
              variables: {
                input: {
                  shopId: currentShopId,
                  title: itemName,
                  description: itemDescription,
                },
              },
            })
          }
        >
          出品カテゴリをAIで選ぶ(β)
        </Button>
        <FormControl>
          <Grid templateColumns="repeat(5, 1fr)">
            <GridItem colSpan={[5, 1]} as={Flex} align={"center"}>
              <FormLabel sx={labelStyle}>出品先</FormLabel>
            </GridItem>
            <GridItem colSpan={[5, 4]}>
              <PlatformExhibitionSettingsForm
                platforms={initializeHooks.data?.platforms ?? []}
                integrations={initializeByShopHooks.data?.integrations ?? []}
                selectedPlatforms={selectedMarketplaces}
                setSelectedPlatforms={setSelectedMarketplaces}
                setBaseCategories={setBaseCategories}
                setStoresCategories={setStoresCategories}
                setMercariShopsSetting={setMercariShopsSettings}
                setYahooShoppingSetting={setYahooShoppingSetting}
                baseCategories={baseCategories}
                storesCategories={storesCategories}
                mercariShopsSetting={mercariShopsSettings ?? undefined}
                yahooShoppingSetting={yahooShoppingSetting}
                shopifySetting={shopifySetting}
                setShopifySetting={setShopifySetting}
              />
            </GridItem>
          </Grid>
        </FormControl>
        <Flex
          direction={id ? "row" : "column"}
          gap={4}
          justifyContent="center"
          alignItems="center"
          sx={
            id && itemStatus !== "INITIAL"
              ? {
                  position: "fixed",
                  width: "60vw",
                  minWidth: "340px",
                  maxWidth: "600px",
                  margin: "0 auto",
                  px: 4,
                  pb: "40px",
                  left: 0,
                  right: 0,
                  bottom: 0,
                  zIndex: 1000,
                  button: {
                    boxShadow: "0 8px 15px rgba(0, 0, 0, 0.1)", // シャドウで浮遊感
                  },
                }
              : {}
          }
        >
          <Button
            onClick={() => {
              setNextStatus("ACTIVE");
              id ? handleUpdate() : handleSubmit();
            }}
            width="100%"
            variant="solid"
            colorScheme="yellow"
            isDisabled={!isActiveShop || itemStatus === "ARCHIVED"}
            px={4}
            borderRadius={999}
            isLoading={updateItemHooks.loading || createItemHooks.loading}
          >
            {id ? (itemStatus === "ACTIVE" ? "更新する" : "出品する") : "出品する"}
          </Button>
          {["INITIAL", "DRAFT"].includes(itemStatus) && (
            <Button
              onClick={() => {
                handleConfirm();
              }}
              width="100%"
              variant="outline"
              colorScheme="gray"
              backgroundColor="gray.200"
              px={4}
              borderRadius={999}
              isLoading={updateItemHooks.loading || createItemHooks.loading}
            >
              {id ? "上書き保存する" : "下書きで保存"}
            </Button>
          )}
        </Flex>
        {
          id && (
            <>
              <Button
                onClick={DeleteConfirmModalDisclosure.onOpen}
                color="red.500"
                variant="link"
                sx={{
                  textDecoration: "none",
                  _hover: {
                    textDecoration: "underline",
                  },
                  _focus: {
                    boxShadow: "none",
                    outline: "none",
                  },
                  _active: {
                    boxShadow: "none",
                    outline: "none",
                  },
                }}
              >
                {itemStatus === "DRAFT" ? "下書きを削除する" : "削除する"}
              </Button>
              <DeleteConfirmModal
                isOpen={DeleteConfirmModalDisclosure.isOpen}
                onClose={DeleteConfirmModalDisclosure.onClose}
                onConfirm={handleDelete}
                loading={deleteItemHooks.loading}
              />
              <DeleteFinishedModal
                isOpen={DeleteFinishedModalDisclosure.isOpen}
                onClose={DeleteFinishedModalDisclosure.onClose}
                onGoToListings={handleGoToListings}
              />
            </>
          ) /*既存商品の場合は削除ボタンを表示*/
        }
      </Flex>
      <GeneralModal
        isOpen={PredictCategoryDisclosure.isOpen}
        onClose={PredictCategoryDisclosure.onClose}
        title="更新確認"
        body={
          <>
            <Text>出品カテゴリをAIが予測しました。</Text>
            <UnorderedList>
              {predictCategoryHooks.data?.predictCategory?.results.map((result) => {
                if (result.predictedCategories.length === 0) {
                  return null;
                }

                return (
                  <ListItem key={result.target}>
                    {platformKeyToName(result.target)}: {result.predictedCategories[0].namePath}
                  </ListItem>
                );
              })}
            </UnorderedList>
            <Text>適用しますか？（すでに手動で入力していた場合、上書きされます）</Text>
          </>
        }
        mainAction={() => {
          if (!predictCategoryHooks.data?.predictCategory?.results) {
            toast({
              title: "予測データの取得に失敗しました",
              status: "error",
              isClosable: true,
            });
            return;
          }

          for (const result of predictCategoryHooks.data.predictCategory.results) {
            switch (result.target) {
              case "MERCARI_SHOPS":
                setMercariShopsSettings({
                  ...(mercariShopsSettings as ExhibitionMercariShopsSettingFragment),
                  category_id: result.predictedCategories[0].id,
                });
                break;
              case "YAHOO_SHOPPING":
                setYahooShoppingSetting({
                  ...(yahooShoppingSetting as ExhibitionYahooShoppingSettingFragment),
                  product_category: Number(result.predictedCategories[0].id),
                  shopping_category_hierarchy: [],
                });
                break;
            }
          }
          PredictCategoryDisclosure.onClose();
        }}
        mainActionLabel="適用する"
      />
      <ListingConfirmModal
        validationWarnings={validationWarnings}
        isOpen={ListingConfirmModalDisclosure.isOpen}
        onClose={ListingConfirmModalDisclosure.onClose}
        onConfirm={handleConfirm}
        loading={createItemHooks.loading || createImageHooks.loading}
      />
      <ListingFinishedModal
        isOpen={ListingFinishedModalDisclosure.isOpen}
        onClose={ListingFinishedModalDisclosure.onClose}
        onGoToListings={handleGoToListings}
        onGoToSell={handleGoToSell}
        itemStatus={nextStatus}
        itemId={id ?? createItemHooks.data?.insert_items_one?.id}
      />
    </div>
  );
}
