import { gql } from "@apollo/client";
import { ArrowDownIcon, ArrowLeftIcon, ArrowUpIcon, ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Button, ButtonGroup, Flex, IconButton, SimpleGrid, Tooltip, VStack, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuthContext } from "../auth";
import { Order_By, useItemsQuery } from "../graphql/generated/apollo";
import { useShopContext } from "../shop";
import { ListingCard } from "./ListingCard";
import { SearchBar } from "./SearchBar";

const LIMIT_PER_PAGE = 20;
const PAGE_BUTTON_RANGE = 2;

gql`
  query Items($shop_id: uuid!, $cond: [items_bool_exp!] = {}, $order_by: [items_order_by!] = {created_at: desc}, $limit: Int = 200, $offset: Int = 0) {
    items(
      where: {
        shop_id: { _eq: $shop_id }
        _and: $cond
      }
      order_by: $order_by
      limit: $limit
      offset: $offset
    ) {
      ...ListedItemsTab_Item
    }
    items_aggregate(where: { shop_id: { _eq: $shop_id }, _and: $cond }) {
      aggregate {
        count
      }
    }
  }
  fragment ListedItemsTab_Item on items {
    id
    name
    price
    exhibitions {
      id
      platform
      platform_item_id
      status
      error_message
    }
    variants(order_by: { position: asc }) {
      id
      name
      stock_quantity
      position
    }
    images(order_by: [{ position: asc }, { created_at: asc }]) {
      id
      mime_type
      path
      created_at
    }
    item_status {
      name
    }
    status
    created_at
    updated_at
  }
`;

export function ListedItemsTab() {
  return (
    <>
      <ItemList />
    </>
  );
}

function searchParam(term: string) {
  return term
    ? [
        {
          _or: [
            {
              name: { _ilike: `%${term}%` },
            },
            {
              description: { _ilike: `%${term}%` },
            },
          ],
        },
      ]
    : [];
}

function orderByParam(asc: boolean) {
  return asc ? { created_at: Order_By.Asc } : { created_at: Order_By.Desc };
}

function ItemList() {
  const { user } = useAuthContext();
  const { currentShopId } = useShopContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
  const query = searchParams.get("query") ?? "";
  const order = searchParams.get("order") ?? "desc";
  const ascending = order === "asc";

  const offset = (page - 1) * LIMIT_PER_PAGE;

  // 初期表示用のデータを取得し、検索時には検索結果を取得する。かつページネーションも行う
  const { data, loading, error } = useItemsQuery({
    variables: {
      shop_id: currentShopId,
      cond: searchParam(query),
      order_by: orderByParam(order === "asc"),
      limit: LIMIT_PER_PAGE,
      offset: offset < LIMIT_PER_PAGE ? 0 : offset,
    },
    notifyOnNetworkStatusChange: true,
    skip: !user || !currentShopId,
  });

  if (!currentShopId) return <p>ショップが選択されていません</p>;
  if (error) return <p>Error : {error.message}</p>;

  const totalCount = data?.items_aggregate?.aggregate?.count ?? 0;
  const totalPage = Math.ceil(totalCount / LIMIT_PER_PAGE);
  const movePage = (to: number, scrollToTop?: boolean, query?: string, ascending?: boolean) => {
    // 新規読み込みのあとに即ページ切り替えをする可能性があるので最終ページ+1まで許容
    if (to < 1 || totalPage + 1 < to) {
      return;
    }

    // URLパラメータを更新
    const params = new URLSearchParams();
    if (query) {
      params.set("query", query);
    }
    if (ascending) {
      params.set("order", "asc");
    }
    if (to && to > 1) {
      params.set("page", to.toString());
    }
    navigate(`?${params.toString()}`);
    if (scrollToTop) {
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      <Flex gap={2} justifyContent="space-between">
        <Box flex={1}>
          <SearchBar
            initialValue={query}
            onSearch={async (term: string) => {
              movePage(1, true, term, ascending);
            }}
            loading={loading}
          />
        </Box>
        <Tooltip label={`登録日の${ascending ? "古い順" : "新しい順"}`}>
          <IconButton
            aria-label={ascending ? "ArrowUpIcon" : "ArrowDownIcon"}
            icon={ascending ? <ArrowUpIcon /> : <ArrowDownIcon />}
            onClick={async () => {
              const newOrder = !ascending;
              movePage(1, true, query, newOrder);
            }}
            isLoading={loading}
          />
        </Tooltip>
      </Flex>
      <VStack spacing="4" align="stretch" mt={2}>
        {data?.items.map((item) => (
          <ListingCard key={item.id} item={item} />
        ))}
        {error && <p>Error : {error}</p>}
        {data?.items.length ? (
          <VStack align="center" justify="center" mx={-8}>
            <Flex gap={1} wrap="wrap" align="center" justify="center">
              {page > PAGE_BUTTON_RANGE + 1 && <Button onClick={() => movePage(1, true, query, ascending)}>1</Button>}
              {page > 1 && (
                <Button onClick={() => movePage(page - 1, true, query, ascending)}>
                  <ChevronLeftIcon />
                </Button>
              )}
              {Array.from({ length: totalPage })
                .map((_, i) => i + 1)
                .filter((to) => {
                  // 今見ているページの前後PAGE_BUTTON_RANGEページ分のボタンを表示する
                  // ただし、満たない場合は逆側にその分だけボタンを表示する
                  if (page <= PAGE_BUTTON_RANGE) {
                    return to <= page + PAGE_BUTTON_RANGE + (PAGE_BUTTON_RANGE - (page - 1));
                  }
                  return page - PAGE_BUTTON_RANGE <= to && to <= page + PAGE_BUTTON_RANGE;
                })
                .map((to) => (
                  <Button key={to} onClick={() => movePage(to, true, query, ascending)} isActive={page === to}>
                    {to}
                  </Button>
                ))}
              {page < totalPage && (
                <Button onClick={() => movePage(page + 1, true, query, ascending)}>
                  <ChevronRightIcon />
                </Button>
              )}
              {page < totalPage - PAGE_BUTTON_RANGE && (
                <>
                  <Button onClick={() => movePage(totalPage, true, query, ascending)}>{totalPage}</Button>
                </>
              )}
            </Flex>
          </VStack>
        ) : null}
      </VStack>
    </>
  );
}
